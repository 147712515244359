import { BasePagination, IconButtonWithTooltip, PlusIcon } from '@elkaso-app/web-design';
import { useGetRestaurantByIdApi } from 'apis/restaurants/use-get-restaurant-by-id-api';
import { useGetWarehouseListApi } from 'apis/restaurants/use-get-warehouse-list-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { BaseSortButton } from 'components/base-table/sorting';
import { Can } from 'configs/can';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { AddWarehouseModal } from 'pages/restaurants/components/add-warehouse-modal';
import { IWarehouseActionsCell, WarehouseActionsCell } from 'pages/restaurants/components/warehouse-actions-cell';
import { IWarehouseNameCell, WarehouseNameCell } from 'pages/restaurants/components/warehouse-name-cell';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';

export const WarehouseListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { isLoading, isFetching, data } = useGetWarehouseListApi(restaurantId);
  const { pageCount, total, data: warehousesData } = data ?? {};
  const { data: restaurantData } = useGetRestaurantByIdApi(restaurantId);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='Warehouse Name'
            filterButtonComponent={<NameFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='name' />}
          />
        ),
        accessor: 'name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['name']>) => (
          <CustomTableCell<IWarehouseNameCell>
            as={WarehouseNameCell}
            warehouseName={value?.name}
            warehouseId={value?.id}
          />
        ),
      },
      {
        Header: <TableHeader name='Creation Date' />,
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: <TableHeader name='Cut -off time' />,
        accessor: 'cut_off_time',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['cut_off_time']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} isTimeOnly />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IWarehouseActionsCell> as={WarehouseActionsCell} warehouseId={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    name: {
      id: string;
      name: string;
    };
    creation_date: string;
    cut_off_time: string;
    actions: any;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      warehousesData?.map((warehouse: (typeof warehousesData)[0]): IColumnsAccessorData => {
        return {
          id: warehouse?.id,
          name: {
            id: warehouse?.id,
            name: warehouse?.name,
          },
          creation_date: warehouse?.createdAt,
          cut_off_time: warehouse?.cutOffTime,
          actions: warehouse?.id,
        };
      }),
    [warehousesData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title={restaurantData?.data?.name} />

          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />

          <Can I='update' a='User'>
            <AddWarehouseModal
              variant='add'
              trigger={
                <IconButtonWithTooltip
                  label='Add warehouse'
                  aria-label='add-warehouse-btn'
                  Icon={<PlusIcon />}
                  disabled={warehousesData?.length >= 4}
                />
              }
            />
          </Can>
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
