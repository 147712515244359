import { Icon, IconProps } from '@elkaso-app/web-design';

export const OpenArrowIcon = (props: IconProps) => {
  return (
    <Icon width='10px' height='6px' viewBox='0 0 10 6' fill='none' {...props}>
      <g clipPath='url(#clip0_862_1122)'>
        <path d='M10 5.5L5 0.5L0 5.5L10 5.5Z' fill='#565656' />
      </g>
      <defs>
        <clipPath id='clip0_862_1122'>
          <rect width='10' height='5' fill='white' transform='translate(10 5.5) rotate(180)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
