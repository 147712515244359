import { HStack, StackProps, Text, VStack } from '@elkaso-app/web-design';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

interface IOrderComment extends StackProps {
  createdBy: string;
  createdAt: string;
  message: string;
}

export const OrderComment = ({ createdBy, createdAt, message, ...rest }: IOrderComment) => {
  return (
    <VStack spacing='xs' align={'start'} w='full' {...rest}>
      <HStack align='center' w='full'>
        <Text color='gray.600' fontWeight={'bold'} fontSize='md'>
          {createdBy}
        </Text>
        <Text color='gray.500'>{dayjs(createdAt).toNow(true)}</Text>
        <Text ml='auto !important' color='gray.500'>
          {dayjs(createdAt).format('DD/MM/YY | hh:mm A')}
        </Text>
      </HStack>
      <Text color='gray.600' fontSize={'md'}>
        {message}
      </Text>
    </VStack>
  );
};
