import {
  Button,
  HStack,
  IconButtonWithTooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useDeleteCampaignApi } from 'apis/campaigns/use-delete-campaign-api';
import { RemoveIcon } from 'pages/promotions/icons';

interface RemoveCampaignButtonProps {
  id: string;
  isActive: boolean;
}

export const RemoveCampaignButton = ({ id, isActive }: RemoveCampaignButtonProps) => {
  const { isLoading: isLoadingCampaign, mutate: deleteCampaign } = useDeleteCampaignApi();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDeleteCampaign = () => {
    const variables = {
      campaignId: id,
    };

    deleteCampaign(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <IconButtonWithTooltip
        label='Remove'
        aria-label='remove-btn'
        onClick={onOpen}
        isDisabled={isActive}
        Icon={<RemoveIcon boxSize={5} />}
        variant='unstyled'
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Campaign!</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text>Are you sure you want to delete this Campaign ?</Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='solid' colorScheme='red' onClick={handleDeleteCampaign} isLoading={isLoadingCampaign}>
                Delete
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
