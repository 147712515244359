import {
  BoxProps,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useDeleteCategoryApi } from 'apis/product-category/use-delete-category-api';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface IDeleteCategoryModalCell extends BoxProps {
  trigger: JSX.Element;
  categoryId: number;
  parentId: number;
  title?: string;
}

export const DeleteCategoryModal = ({ trigger, categoryId, title, parentId }: IDeleteCategoryModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isUpdateCategoryLoading, mutate: deleteCategory } = useDeleteCategoryApi();
  const history = useHistory();

  const handleDelete = () => {
    deleteCategory(
      { categoryId },
      {
        onSuccess: () => {
          onClose();
          history.push(`/app/catalog/product-categories/${parentId}`);
        },
      }
    );
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='sm'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent={'center'}>Delete Category</Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack justifyContent={'center'}>
              <Text color='gray.500' fontSize='md'>
                Are you sure you want to delete
              </Text>
              <HStack>
                <Text color='gray.500' fontWeight='semibold' fontSize='md'>
                  “{title}”
                </Text>
                <Text color='gray.500' fontSize='md'>
                  category?
                </Text>
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent={'center'}>
            <VStack spacing='md'>
              <Button
                isLoading={isUpdateCategoryLoading}
                variant='solid'
                onClick={handleDelete}
                colorScheme='red'
                type='submit'
                form='DeleteCategoryModal'>
                Delete Category
              </Button>
              <Button variant='outline' width={'full'} colorScheme='red' onClick={onClose}>
                Cancel
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
