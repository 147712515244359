import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  formatDateTime,
  HStack,
  Image,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  VStack,
} from '@elkaso-app/web-design';
import { useGetCategoriesApi } from 'apis/product-category/use-get-categories-api';
import { useGetCategoryDetailsApi } from 'apis/product-category/use-get-category-details-api';
import { Can } from 'configs/can';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BreadcrumbIcon } from '../../icons/breadcrumb-icon';
import { EditModeIcon } from '../../icons/edit-mode-icon';
import { ActivationSwitch } from './activation-switch';
import { AddCategoryModal } from './add-category-modal';
import { ProductsListTable } from './products-list-table';
import { SubCategoriesListTable } from './subCategories-list-table';
import { filter, language } from './tree-view-section';

export const CategoryDetailsSection = () => {
  const params = useParams<any>();
  const history = useHistory();
  const { isLoading, data: CategoryDetails } = useGetCategoryDetailsApi(params?.categoryId);
  const { isLoading: isLoadingCategories, data } = useGetCategoriesApi();
  const { data: CategoriesData } = data ?? {};

  const resultFilter = useMemo(() => {
    return filter(CategoriesData ?? [], params?.categoryId ? params?.categoryId?.toString() : '', 'filterById')[0];
  }, [CategoriesData, params?.categoryId]);

  const breadcrumbItems = () => {
    if (params.categoryId == resultFilter?.id) return [{ id: resultFilter.id, title: language(resultFilter, 'en') }];

    if (params.categoryId == resultFilter?.children[0]?.id)
      return [
        { id: resultFilter.id, title: language(resultFilter, 'en') },
        { id: resultFilter.children[0].id, title: language(resultFilter?.children[0], 'en') },
      ];

    if (params.categoryId == resultFilter?.children[0]?.children[0]?.id)
      return [
        { id: resultFilter.id, title: language(resultFilter, 'en') },
        { id: resultFilter.children[0].id, title: language(resultFilter?.children[0], 'en') },
        { id: resultFilter.children[0].children[0].id, title: language(resultFilter?.children[0]?.children[0], 'en') },
      ];
    if (params.categoryId == resultFilter?.children[0]?.children[0]?.children[0]?.id)
      return [
        { id: resultFilter.id, title: language(resultFilter, 'en') },
        { id: resultFilter.children[0].id, title: language(resultFilter?.children[0], 'en') },
        {
          id: resultFilter.children[0].children[0].id,
          title: language(resultFilter?.children[0]?.children[0], 'en'),
        },
        {
          id: resultFilter.children[0].children[0].children[0].id,
          title: language(resultFilter?.children[0]?.children[0]?.children[0], 'en'),
        },
      ];
    return [];
  };

  const parentCategory = breadcrumbItems()[breadcrumbItems().length - 2];

  return (
    <>
      {(isLoadingCategories && isLoading) || CategoryDetails == undefined ? (
        <Stack spacing={'md'} align='stretch' p='md'>
          <Skeleton height='20px' />
          <Skeleton width={'100px'} height='20px' />
          <HStack alignItems={'center'}>
            <SkeletonCircle width={'100%'} size='100px' />
            <Skeleton width={'50%'} height='20px' />
          </HStack>
        </Stack>
      ) : (
        <VStack maxHeight={'30%'} spacing={'md'} align='stretch' p='md'>
          <HStack justifyContent={'space-between'}>
            <Text fontSize='xl' fontWeight='bold'>
              Category Details
            </Text>

            <HStack spacing={'lg'}>
              <Can I='manage' a='CatalogCategory'>
                <AddCategoryModal
                  title={language(CategoryDetails, 'en')}
                  value={CategoryDetails}
                  variant={'edit'}
                  trigger={
                    <Button borderColor={'#e03d52'} color={'#e03d52'} leftIcon={<EditModeIcon />} variant='outline'>
                      Edit Category
                    </Button>
                  }
                />
              </Can>
              <HStack>
                <ActivationSwitch
                  categoryId={CategoryDetails?.id}
                  isActive={CategoryDetails?.status == 'active' ? true : false}
                />
              </HStack>
            </HStack>
          </HStack>

          <Breadcrumb spacing='sm' separator={<BreadcrumbIcon />}>
            {breadcrumbItems()?.map((breadcrumbItem, index) => {
              return (
                <BreadcrumbItem key={index}>
                  <BreadcrumbLink onClick={() => history.push(`/app/catalog/product-categories/${breadcrumbItem.id}`)}>
                    {breadcrumbItem.title}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>

          <HStack spacing='md'>
            <Image borderRadius='10px' boxSize='100px' objectFit='cover' src={CategoryDetails?.image} alt='Dan' />

            <HStack justifyContent={'space-between'} alignItems='flex-start' flex={1}>
              <VStack align={'start'}>
                <Text fontWeight={'bold'}> {CategoryDetails && language(CategoryDetails, 'en')}</Text>
                <Text>ID: {CategoryDetails?.id}</Text>
                <Text>Kaso ID: {CategoryDetails?.kasoId}</Text>
              </VStack>

              <VStack align={'start'}>
                <Text fontWeight={'bold'}>Creation Date</Text>
                <Text> {CategoryDetails && formatDateTime(CategoryDetails?.createdAt)}</Text>
              </VStack>

              <VStack align={'start'}>
                <Text fontWeight={'bold'}>Countries</Text>

                {CategoryDetails?.countries?.map((country: { name: string }, index: number) => {
                  return <Text key={index}>{country.name}</Text>;
                })}
              </VStack>

              <VStack align={'start'}>
                <Text fontWeight={'bold'}>Parent Category</Text>
                <Text>{parentCategory ? parentCategory.title : 'No Parent'}</Text>
              </VStack>
            </HStack>
          </HStack>
        </VStack>
      )}
      <Box maxHeight={'70%'}>
        <Divider borderTop={'7px solid #ECECEC'} />

        {CategoryDetails?.depth >= 3 ? (
          <ProductsListTable />
        ) : (
          <SubCategoriesListTable categoriesData={CategoryDetails} isLoading={isLoadingCategories && isLoading} />
        )}
      </Box>
    </>
  );
};
