import { Avatar, Button, FormControl, HStack, Input, StackProps } from '@elkaso-app/web-design';
import { useCreateRestaurantTransferCommentApi } from 'apis/restaurant-transfers/use-create-restaurant-transfer-comment-api';
import { getMeEndpointIdentifier } from 'apis/users/use-me-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useForm } from 'react-hook-form';

type TInputs = {
  comment: string;
};

const defaultValues: TInputs = {
  comment: '',
};

interface IAddRestaurantTransferCommentInput extends StackProps {
  restaurantTransferId: string;
}

export const AddRestaurantTransferCommentInput = ({
  restaurantTransferId,
  ...rest
}: IAddRestaurantTransferCommentInput) => {
  const { mutate: createRestaurantTransferCommentApi } = useCreateRestaurantTransferCommentApi(restaurantTransferId);
  const me = useGetQueryClientData(getMeEndpointIdentifier);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const onSubmit = (data: TInputs) => {
    const variables = {
      restaurantTransferId,
      body: {
        text: data?.comment,
      },
    };

    createRestaurantTransferCommentApi(variables);

    // Reset form data
    reset(defaultValues);
  };

  return (
    <HStack
      as='form'
      id='addCommentForm'
      spacing='md'
      align='center'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      {...rest}>
      <Avatar name={me?.name} bgColor='red.500' />

      <FormControl isRequired isInvalid={!!errors.comment}>
        <Input
          autoComplete='off'
          placeholder='Add a comment...'
          {...register('comment', {
            required: 'Comment is required.',
          })}
        />
      </FormControl>

      <Button
        type='submit'
        form='addCommentForm'
        variant='solid'
        colorScheme={'red'}
        isDisabled={!watch('comment').length}>
        Save
      </Button>
    </HStack>
  );
};
