import api from 'apis/categories';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAILED,
  SET_CATEGORIES_LOADER,
  SET_CATEGORY,
  SET_CATEGORY_ERRORS,
  SET_CATEGORY_FAILED,
  SET_LOADING_STATUS,
  SET_PAGES_COUNT,
  SET_TOTAL_COUNT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getCategories =
  ({ pagination, filters, sort, join }) =>
  (dispatch) => {
    dispatch({ type: SET_LOADING_STATUS, payload: true });
    dispatch({ type: SET_CATEGORIES_LOADER, payload: true });
    api
      .getCategories({ pagination, filters, sort, join })
      .then((response) => {
        dispatch({ type: GET_CATEGORIES, payload: response.data });
        dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
        dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
        dispatch({ type: SET_CATEGORIES_LOADER, payload: false });
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: GET_CATEGORIES_FAILED });
        dispatch({ type: SET_CATEGORIES_LOADER, payload: false });
      });
  };

export const getCategoryById =
  ({ id, params }) =>
  (dispatch) => {
    dispatch({ type: SET_LOADING_STATUS, payload: true });
    api
      .getCategoryById(id, params)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATUS, payload: false });
        dispatch({ type: SET_CATEGORY, payload: response.data });
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: SET_CATEGORY_FAILED });
      });
  };

export const addOrUpdateCategory = (data) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .addOrUpdateCategory(data)
    .then((response) => {
      dispatch({ type: SET_CATEGORY, payload: response.data });
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.reject(error);
    });
};

export const setCategoriesErrors = (errors) => (dispatch) => {
  dispatch({ type: SET_CATEGORY_ERRORS, payload: errors });
};
