import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'ums/v1/restaurants';
export const uploadRegionsCSVFileEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: FormData;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUploadRegionsCsvFileApi(restaurantId: string, supplierId: string, options?: Options): ReturnType {
  const toast = useCustomToast();

  const fullEndpoint = `${endpoint}/${restaurantId}/suppliers/${supplierId}/regions/upload`;

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(fullEndpoint, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast({
        description: 'Your CSV file has been successfully processed.',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: [endpoint], ...options });
}
