import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetLicensesApi } from './use-get-licenses-api';

const endpoint = '/ums/v1/restaurant-licenses/managing';
export const editLicensesEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

interface BodyType {
  status?: string;
  expiryDate: string;
  licenseNumber: string;
}

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useEditLicensesApi(LicensesId: string, options?: Options): ReturnType {
  const { refetch: refetchAllDoc } = useGetLicensesApi(['approved', 'rejected'], { enabled: false });
  const { refetch: refetchPending } = useGetLicensesApi(['pending'], { enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.put(endpoint + `/${LicensesId}`, body);

      refetchAllDoc();
      refetchPending();

      toast({
        description: 'Licenses has been updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: `${endpoint}/${LicensesId}`, ...options });
}
