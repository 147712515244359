import { BasePagination } from '@elkaso-app/web-design';
import { useGetRestaurantSAMsListApi } from 'apis/restaurants/restaurant-sams/use-get-restaurant-sams-list-api';
import { useGetRestaurantByIdApi } from 'apis/restaurants/use-get-restaurant-by-id-api';
import { useGetSupplierByIdApi } from 'apis/restaurants/use-get-supplier-by-id-api';
import { BaseTable, BaseTableContainer, NameFilterButton } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { TruncateText, TruncateTextProps } from 'components/truncate-text';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { ExportSAMs } from 'pages/restaurants/components/restaurant-sams/export-sams';
import { ImportSAMs } from 'pages/restaurants/components/restaurant-sams/import-sams';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';

export const SupplierSAMsScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { restaurantId, supplierId } = useParams<{ restaurantId: string; supplierId: string }>();
  const { isLoading, isFetching, data } = useGetRestaurantSAMsListApi(restaurantId, supplierId);
  const { pageCount, total, data: samsData } = data ?? {};
  const { data: restaurantData } = useGetRestaurantByIdApi(restaurantId);
  const { data: supplierData } = useGetSupplierByIdApi(supplierId);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Location Name' filterButtonComponent={<NameFilterButton />} />,
        accessor: 'locationName',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['locationName']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Type' />,
        accessor: 'type',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['type']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Address' />,
        accessor: 'address',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['address']>) => (
          <CustomTableCell<TruncateTextProps> as={TruncateText} text={value} />
        ),
      },
      {
        Header: <TableHeader name='Supplier Manager Name' />,
        accessor: 'supplierManagerName',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplierManagerName']>) => <TableCell value={value} />,
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    locationName: string;
    type: string;
    address: string;
    supplierManagerName: string;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      samsData?.map((sam: (typeof samsData)[0]): IColumnsAccessorData => {
        return {
          id: sam?.subEntity?.id,
          locationName: sam?.subEntity?.name,
          type: sam?.subEntity?.type,
          address: sam?.subEntity?.address,
          supplierManagerName: sam?.name,
        };
      }),
    [samsData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title={`${restaurantData?.data?.name} > ${supplierData?.data?.name}`} />

          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
          <ImportSAMs />
          <ExportSAMs restaurantName={restaurantData?.data?.name} />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
