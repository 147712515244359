import { Avatar, Button, FormControl, HStack, Input, StackProps } from '@elkaso-app/web-design';
import { useCreateOrderCommentApi } from 'apis/orders/use-create-order-comment-api';
import { getMeEndpointIdentifier } from 'apis/users/use-me-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useForm } from 'react-hook-form';

type TInputs = {
  comment: string;
};

const defaultValues: TInputs = {
  comment: '',
};

interface IAddOrderCommentInput extends StackProps {
  orderId: string;
}

export const AddOrderCommentInput = ({ orderId, ...rest }: IAddOrderCommentInput) => {
  const { mutate: createOrderCommentApi } = useCreateOrderCommentApi(orderId);
  const me = useGetQueryClientData(getMeEndpointIdentifier);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const onSubmit = (data: TInputs) => {
    const variables = {
      orderId: orderId,
      body: {
        text: data?.comment,
      },
    };

    createOrderCommentApi(variables);

    // Reset form data
    reset(defaultValues);
  };

  return (
    <HStack
      as='form'
      id='addCommentForm'
      spacing='md'
      align='center'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      {...rest}>
      <Avatar name={me?.name} bgColor='red.500' />

      <FormControl isRequired isInvalid={errors.comment ? true : false}>
        <Input
          autoComplete='off'
          placeholder='Add a comment...'
          {...register('comment', {
            required: 'Comment is required.',
          })}
        />
      </FormControl>

      <Button
        type='submit'
        form='addCommentForm'
        variant='solid'
        colorScheme={'red'}
        isDisabled={!watch('comment').length}>
        Save
      </Button>
    </HStack>
  );
};
