import Button from '@material-ui/core/Button';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import IntlMessages from 'utils/intl-messages';

const ExpiredToken = (props) => {
  return (
    <Fragment>
      <div className='class-404-background'>
        <div className='container flex-center h-100 expired-session scale-1'>
          <div className='flex-center expired-session'>
            <img src={require('assets/img/expiredToken.svg').default} alt="can't help" />
            <h1 className='login-title'>
              <IntlMessages id={'global.title.token.expired'} />
            </h1>
            <p className='login-info text-center'>
              <IntlMessages id={'global.details.token.expired'} />
            </p>
          </div>
          <Button color='primary' variant='contained' onClick={() => props.history.push(`/login`)}>
            <IntlMessages id='login.backLogin' />
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

// map state to props
const mapStateToProps = ({ settings }) => {
  return { settings };
};

export default connect(mapStateToProps)(ExpiredToken);
