import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'catalog/v1/catalog-categories';
export const getCategoryDetailsEndpointIdentifier = endpoint;

export type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = string[];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetCategoryDetailsApi(categoryId: number, options?: Options): ReturnType {
  const toast = useCustomToast();
  const isLoggedIn = useIsLoggedIn();

  const queryFn = async () => {
    try {
      const { data } = await http.get(`${endpoint}/${categoryId}/tree`, {
        params: {},
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([`${endpoint}/${categoryId}/tree`], queryFn, {
    enabled: isLoggedIn,
    ...options,
  });
}
