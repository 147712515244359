import { Button, HStack } from '@elkaso-app/web-design';
import { useGetDownloadRegionsCsvFileApi } from 'apis/supplier/use-download-regions-csv-file-api';
import { useParams } from 'react-router-dom';
import { DownArrowIcon } from '../icons/down-arrow-icon';
import { UpArrowIcon } from '../icons/up-arrow-icon';
import { UploadCsvModal } from './upload-csv-modal';

export interface IBrandActionsCell {
  action: any;
}

export const BrandActionsCell = ({ action }: IBrandActionsCell) => {
  const { supplierId } = useParams<{ supplierId: string }>();

  const { isLoading, refetch: getDownloadRegionsCsvFileApi } = useGetDownloadRegionsCsvFileApi(action?.id, supplierId);

  const onDownload = () => {
    getDownloadRegionsCsvFileApi();
  };

  return (
    <HStack spacing={'lg'}>
      <Button
        leftIcon={<DownArrowIcon />}
        isLoading={isLoading}
        onClick={onDownload}
        variant='outline'
        fontSize={'sm'}
        color={'blue.500'}>
        DOWNLOAD FILE
      </Button>
      <UploadCsvModal
        trigger={
          <Button leftIcon={<UpArrowIcon />} variant='solid' colorScheme='red' fontSize={'sm'}>
            UPLOAD FILE
          </Button>
        }
        restaurantId={action?.id}
      />
    </HStack>
  );
};
