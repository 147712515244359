import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetCatalogItemsApi } from './use-get-catalog-items-api';

const endpoint = 'catalog/v1/restaurants';
export const createCatalogItemEndpointIdentifier = endpoint;

interface BodyType {
  name: {
    en: string;
    ar: string | null;
  };
  brand: {
    en: string | null;
    ar: string | null;
  };
  sku: string | null;
  kasoId: string;
  unitId: number;
  price: number;
  description: string | null;
  images: string[];
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useCreateCatalogItemApi(restaurantId: string, warehouseId: string, options?: Options): ReturnType {
  const { refetch: refetchCatalogItems } = useGetCatalogItemsApi(restaurantId, warehouseId, { enabled: false });
  const toast = useCustomToast();
  const fullEndpoint = `${endpoint}/${restaurantId}/warehouses/${warehouseId}/items`;

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(fullEndpoint, body);

      // Refetch warehouse items
      refetchCatalogItems();

      toast({
        description: 'New item have been created successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
