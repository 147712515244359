import EN from 'assets/lang/locales/en_US';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import { LocalStorageKeys } from 'utils/constants';

export const onSuccessResponse = function (response) {
  return response.data;
};

export const onFailedResponse = function (error) {
  if (error.response) {
    // [1] - Access denied
    if (error.response.status === 403) {
      window.location.href = '/access-denied';
    }

    // [2] - Unauthorized
    if (error.response.status === 401) {
      localStorage.removeItem(LocalStorageKeys.cms_user);
      localStorage.removeItem(LocalStorageKeys.access_token);
      localStorage.removeItem(LocalStorageKeys.expiredTime);
      window.location.href = '/';
    }

    // [3] - Expired session
    if (error.response.data.code === 107) {
      window.location.href = '/expired-session';
    }
  }

  // global handle and show error messages.
  let message = 'global.errors.';

  if (error.response.data && error.response.data.message && error.response.data.message !== '') {
    message += error.response.data.code;
  } else if (error.response.data && !error.response.data.errors) {
    message += 'default';
  }

  if (EN[message] && EN[message] !== '') {
    NotificationManager.error(EN[message], '', AppConfig.notificationTimeout);
  }

  return Promise.reject(error.response.data);
};
