import { useEffect, useMemo, useState } from 'react';

export type CheckedItemType = { id: string; isChecked: boolean };

export const useCustomTableCheckboxes = (itemsData: any[]) => {
  const setCheckedItemsValue = (value = false): CheckedItemType[] => {
    if (itemsData?.length) {
      return itemsData?.map((item: any) => {
        return { id: item?.id, isChecked: value };
      });
    }

    return [];
  };

  const [checkedItems, setCheckedItems] = useState<CheckedItemType[]>(setCheckedItemsValue());
  const [checkboxState, setCheckboxState] = useState(false);

  useEffect(() => {
    setCheckedItems(setCheckedItemsValue());
  }, [itemsData]);

  const allChecked = useMemo(() => {
    return !!checkedItems.length && checkedItems?.map((item) => item?.isChecked)?.every(Boolean);
  }, [checkedItems, checkboxState]);

  const isIndeterminate = useMemo(() => {
    return checkedItems?.map((item) => item?.isChecked)?.some(Boolean) && !allChecked;
  }, [checkedItems, allChecked]);

  const handleParentChange = (checked: boolean) => {
    setCheckedItems(setCheckedItemsValue(checked));
  };

  const handleChildChange = (index: number, checked: boolean) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index].isChecked = checked;
    setCheckedItems(newCheckedItems);
  };

  return {
    checkedItems,
    allChecked,
    setCheckboxState,
    isIndeterminate,
    handleParentChange,
    handleChildChange,
  };
};
