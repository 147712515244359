import { Avatar, HStack, Text, VStack } from '@elkaso-app/web-design';
import { AiOutlineUser } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export interface IUserNameCell {
  name: string;
  phoneNumber: string;
}

export const UserNameCell = ({ name, phoneNumber }: IUserNameCell) => {
  return (
    <HStack spacing='md'>
      <Avatar bg='red.500' boxSize='40px' icon={<AiOutlineUser fontSize='md' />} />

      <VStack align={'start'}>
        <Link
          onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
          to={`/app/elkasoUsers/list?limit=45&page=1&phoneNumber=${encodeURIComponent(phoneNumber)}`}>
          <Text fontWeight='semibold' color='gray.600'>
            {name}
          </Text>
        </Link>

        <Text color='gray.600'>{phoneNumber}</Text>
      </VStack>
    </HStack>
  );
};
