import { Icon, IconProps } from '@elkaso-app/web-design';

export const MoveIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='none' {...props}>
      <g clipPath='url(#clip0_1037_12459)'>
        <path
          d='M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V8H20V18ZM11.99 9L10.58 10.41L12.16 12H8V14H12.16L10.57 15.59L11.99 17L16 13.01L11.99 9Z'
          fill='#323232'
        />
      </g>
      <defs>
        <clipPath id='clip0_1037_12459'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
