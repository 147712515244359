import { Box, FormControl, FormLabel, Spinner, Switch } from '@elkaso-app/web-design';
import { useEditAggSheetsRelationsApi } from 'apis/agg-sheets-relations/use-edit-agg-sheets-relations-api';

export interface ILastOrderRuleCell {
  id: number;
  value: boolean;
}

export const LastOrderRuleCell = ({ id, value }: ILastOrderRuleCell) => {
  const { mutate: editAggSheetsRelationsApi, isLoading: isLoadingEditAggSheetsRelationsApi } =
    useEditAggSheetsRelationsApi();

  const onChange = () => {
    const variables = {
      aggSheetId: id,
      body: {
        hasLastOrderRule: !value,
      },
    };

    editAggSheetsRelationsApi(variables);
  };

  return (
    <FormControl display='flex' alignItems='center'>
      <FormLabel htmlFor='email-alerts' mb='0' fontSize='sm' w='60px'>
        {value ? 'Active' : 'Inactive'}
      </FormLabel>
      <Switch isChecked={value} size='md' onChange={onChange} isDisabled={isLoadingEditAggSheetsRelationsApi} />

      <Box w='18px' ms='sm'>
        {isLoadingEditAggSheetsRelationsApi && <Spinner color='red.500' size='sm' />}
      </Box>
    </FormControl>
  );
};
