import { Button, FileUploader, HStack, Text, UploadedFile, VStack } from '@elkaso-app/web-design';
import { useUploadSupplierItemsCSVFileApi } from 'apis/restaurants/branch-supplier-items/use-upload-supplier-items-csv-file-api';
import { UpArrowIcon } from 'pages/restaurants/icons/up-arrow-icon';
import { useState } from 'react';

export const UploadCSVFiles = () => {
  const [file, setFile] = useState<UploadedFile>();
  const { mutate: uploadApi, isLoading } = useUploadSupplierItemsCSVFileApi();

  const onUploadButton = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file as File);

    const variables = {
      body: formData,
    };

    uploadApi(variables, {
      onSuccess: () => {
        setFile(undefined);
      },
    });
  };

  return (
    <VStack spacing='md' align='start' w='full'>
      <HStack spacing='xs'>
        <Text color='gray.500' fontWeight='semibold'>
          Note:
        </Text>
        <Text color='gray.500'>One of nameEn and nameAr is required in addition to unit and code.</Text>
      </HStack>

      <FileUploader
        style='style_01'
        message='Drag "n" drop CSV file here, or click to select file...only CSV file type is allowed.'
        acceptedTypes={['text/csv']}
        multiple={false}
        onRemove={() => {
          setFile(undefined);
        }}
        onRemoveAll={() => {
          setFile(undefined);
        }}
        onUpload={(files) => {
          setFile(files[0]);
        }}
      />

      {!!file && (
        <Button
          colorScheme='red'
          rightIcon={<UpArrowIcon />}
          size='lg'
          isLoading={isLoading}
          alignSelf='end'
          onClick={onUploadButton}
          isDisabled={!file}>
          Upload File
        </Button>
      )}
    </VStack>
  );
};
