import { GET_UNITS, GET_UNITS_FAILED, SET_LOADING_STATUS, SET_UNIT } from 'redux/types';

const INIT_STATE = {
  unitsList: [],
  unit: {},
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return { ...state, isLoading: action.payload };
    case GET_UNITS:
      return { ...state, unitsList: action.payload, isLoading: false };
    case GET_UNITS_FAILED:
      return { ...state, unitsList: [], isLoading: false };
    case SET_UNIT:
      return { ...state, unit: action.payload, isLoading: false };
    default:
      return { ...state };
  }
};
