/**
 * Dialog Actions
 */

import {
  ASSIGN_RESTAURANT,
  ATTACH_SUPPLIER_DIALOG,
  CATALOG_DIALOG,
  COMMENT_DIALOG,
  IMAGE_CROPPER_DIALOG,
  IMAGE_DIALOG,
  INFO_DIALOG,
  LOGIN_DIALOG,
  MAP_DIALOG,
  MERGE_DIALOG,
  MERGE_RESTAURANT_DIALOG,
  MERGE_SUPPLIER_USER_DIALOG,
  OPEN_DIALOG,
  SELECT_ADMIN,
  SELECT_ASSIGN_METHOD,
} from 'redux/types';

export const ConfirmationToggleDialog = (value) => (dispatch) => {
  dispatch({ type: OPEN_DIALOG, payload: value });
};
export const UserLoginToggleDialog = (value) => (dispatch) => {
  dispatch({ type: LOGIN_DIALOG, payload: value });
};
export const AttachSupplierToggleDialog = (value) => (dispatch) => {
  dispatch({ type: ATTACH_SUPPLIER_DIALOG, payload: value });
};
export const commentToggleDialog = (value) => (dispatch) => {
  dispatch({ type: COMMENT_DIALOG, payload: value });
};
export const CatalogToggleDialog = (value) => (dispatch) => {
  dispatch({ type: CATALOG_DIALOG, payload: value });
};
export const mergeSupplierToggleDialog = (value) => (dispatch) => {
  dispatch({ type: MERGE_DIALOG, payload: value });
};
export const imageToggleDialog = (value) => (dispatch) => {
  dispatch({ type: IMAGE_DIALOG, payload: value });
};
export const MapToggleDialog = (value) => (dispatch) => {
  dispatch({ type: MAP_DIALOG, payload: value });
};
export const InfoToggleDialog = (value) => (dispatch) => {
  dispatch({ type: INFO_DIALOG, payload: value });
};
export const ImageCropperDialog = (value) => (dispatch) => {
  dispatch({ type: IMAGE_CROPPER_DIALOG, payload: value });
};
export const MergeSupplierUserDialog = (value) => (dispatch) => {
  dispatch({ type: MERGE_SUPPLIER_USER_DIALOG, payload: value });
};

export const MergeRestaurantCompanyDialog = (value) => (dispatch) => {
  dispatch({ type: MERGE_RESTAURANT_DIALOG, payload: value });
};

export const AssignRestaurantAdmin = (value) => (dispatch) => {
  dispatch({ type: ASSIGN_RESTAURANT, payload: value });
};
export const SelectAdminDialogAction = (value) => (dispatch) => {
  dispatch({ type: SELECT_ADMIN, payload: value });
};
export const SelectAssignMethodAction = (value) => (dispatch) => {
  dispatch({ type: SELECT_ASSIGN_METHOD, payload: value });
};
