import {
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useCreateCategoryApi } from 'apis/categories/use-create-category-api';
import { useUpdateCategoryApi } from 'apis/categories/use-update-category-api';
import { useUploadFileApi } from 'apis/config/use-upload-file-api';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UploadCategoryImageV2 } from './upload-category-image-v2';

type TInputs = {
  titleAr: string;
  titleEn: string;
  imageAE: string;
  imageSA: string;
};

const defaultValues: TInputs = {
  titleAr: '',
  titleEn: '',
  imageAE: '',
  imageSA: '',
};

export interface IAddCategoryModalCell extends BoxProps {
  variant: 'add' | 'edit';
  trigger: JSX.Element;
  category?: any;
}

interface Image {
  imageUrl: string;
  countryCode: string;
}

const getImageUrlByCountry = (images: Image[], countryCode: Image['countryCode']) => {
  if (!images || !countryCode) return '';

  const image = images.find((image) => image.countryCode === countryCode);
  return image ? image.imageUrl : '';
};

export const AddCategoryModal = ({ variant, trigger, category }: IAddCategoryModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: uploadImageAEData,
    mutate: uploadFileAEApi,
    isLoading: isImageAELoading,
    isSuccess: isImageAESuccess,
  } = useUploadFileApi();
  const {
    data: uploadImageSAData,
    mutate: uploadFileSAApi,
    isLoading: isImageSALoading,
    isSuccess: isImageSASuccess,
  } = useUploadFileApi();

  const { isLoading: isCreateCategoryLoading, mutate: createCategory } = useCreateCategoryApi();
  const { isLoading: isUpdateCategoryLoading, mutate: updateCategory } = useUpdateCategoryApi();

  const isEdit = variant === 'edit';

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = () => {
    setValue('titleAr', category?.titleAr ?? '');
    setValue('titleEn', category?.titleEn ?? '');
    setValue('imageAE', getImageUrlByCountry(category?.images, 'AE'));
    setValue('imageSA', getImageUrlByCountry(category?.images, 'SA'));
  };

  useEffect(() => {
    updateValues();
  }, [isOpen]);

  const onSubmit = (data: TInputs) => {
    // Create new category
    if (variant === 'add') {
      const variables = {
        body: {
          titleAr: data.titleAr,
          titleEn: data.titleEn,
          images: [
            {
              imageUrl: data.imageAE,
              countryCode: 'AE',
            },
            {
              imageUrl: data.imageSA,
              countryCode: 'SA',
            },
          ],
        },
      };

      createCategory(variables, {
        onSuccess: () => {
          onCloseModal();
        },
      });
    }

    // Edit existing category
    if (variant === 'edit') {
      const variables = {
        categoryId: category?.id,
        body: {
          titleEn: data.titleEn,
          titleAr: data.titleAr,
          images: [
            {
              imageUrl: data.imageAE,
              countryCode: 'AE',
            },
            {
              imageUrl: data.imageSA,
              countryCode: 'SA',
            },
          ],
        },
      };

      updateCategory(variables, {
        onSuccess: () => {
          onCloseModal();
        },
      });
    }

    // Update form data
    reset(data);
  };

  const onCloseModal = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onCloseModal} closeOnOverlayClick={false} size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? 'Edit' : 'Add new'} category</ModalHeader>
          <ModalBody>
            <Stack as='form' id='addCategoryModal' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <Text color='gray.500' fontWeight='semibold' fontSize='md'>
                Category Name:
              </Text>

              <FormControl isRequired isInvalid={errors.titleEn ? true : false}>
                <FormLabel>Name (En)</FormLabel>
                <Input
                  {...register('titleEn', {
                    required: 'English Name is required.',
                  })}
                />

                <FormErrorMessage>{errors.titleEn?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.titleAr ? true : false}>
                <FormLabel>Name (Ar)</FormLabel>
                <Input
                  {...register('titleAr', {
                    pattern: {
                      value: /[\u0600-\u06FF\u0750-\u077F]/g,
                      message: 'Please enter Arabic letters only.',
                    },
                  })}
                />

                <FormErrorMessage>{errors.titleAr?.message}</FormErrorMessage>
              </FormControl>

              <Text color='gray.500' fontWeight='semibold' fontSize='md'>
                UAE Image
              </Text>

              <Controller
                control={control}
                name='imageAE'
                rules={{ required: 'Image is required.' }}
                render={({ fieldState: { error } }) => (
                  <UploadCategoryImageV2
                    uploadImageData={uploadImageAEData}
                    uploadFileApi={uploadFileAEApi}
                    isSuccess={isImageAESuccess}
                    fieldName='imageAE'
                    image={getImageUrlByCountry(category?.images, 'AE')}
                    error={error}
                    setValue={setValue}
                  />
                )}
              />

              <Text color='gray.500' fontWeight='semibold' fontSize='md'>
                KSA Image
              </Text>

              <Controller
                control={control}
                name='imageSA'
                rules={{ required: 'Image is required.' }}
                render={({ fieldState: { error } }) => (
                  <UploadCategoryImageV2
                    uploadImageData={uploadImageSAData}
                    uploadFileApi={uploadFileSAApi}
                    isSuccess={isImageSASuccess}
                    fieldName='imageSA'
                    image={getImageUrlByCountry(category?.images, 'SA')}
                    error={error}
                    setValue={setValue}
                  />
                )}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onCloseModal}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addCategoryModal'
                isLoading={isCreateCategoryLoading || isUpdateCategoryLoading || isImageAELoading || isImageSALoading}>
                {isEdit ? 'Edit Category' : 'Create Category'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
