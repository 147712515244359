export default (theme) =>
  (theme = {
    ...theme,
    direction: 'rtl',
    overrides: {
      ...theme.overrides,
      MuiInputLabel: {
        ...theme.overrides.MuiInputLabel,
        '&$shrink': { transformOrigin: 'top right !important' },
      },
      MuiDataTableHeadCell: {
        data: {
          textAlign: 'right',
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 500,
          fontSize: '1rem',
          textAlign: 'right',
          minWidth: 150,
          color: '#9699A9',
          letterSpacing: -0.5,
          paddingBottom: 15,
          paddingTop: 15,
        },
        body: {
          textAlign: 'right',
          textWrap: 'normal',
          wordWrap: 'break-word', //new line
          color: 'rgba(66, 68, 77, 0.65)',
        },
        root: {
          textAlign: 'right',
          borderBottom: 'none',
        },
        stickyHeader: {
          backgroundColor: '#FFFFFF !important',
        },
      },
      MuiListItemSecondaryAction: {
        root: {
          right: 16,
          left: 'unset',
        },
      },
      MuiListItemText: {
        multiline: {
          textAlign: 'right !important',
        },
      },
    },
  });
