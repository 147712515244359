import api from 'apis/suppliers';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_SUPPLIERS,
  GET_SUPPLIERS_FAILED,
  GET_SUPPLIER_ADMIN_USERS,
  GET_SUPPLIER_ADMIN_USERS_FAILED,
  GET_SUPPLIER_KPI,
  GET_SUPPLIER_MANAGER_USERS,
  GET_SUPPLIER_MANAGER_USERS_FAILED,
  GET_SUPPLIER_USERS,
  GET_SUPPLIER_USERS_FAILED,
  GET_WHATS_NUMBERS,
  GET_WHATS_NUMBERS_FAILED,
  SET_CATEGORIES_LOADER,
  SET_LOADING_STATUS,
  SET_LOADING_STATUS2,
  SET_PAGES_COUNT,
  SET_SUPPLIER,
  SET_SUPPLIER_FAILED,
  SET_SUPPLIER_LOADER,
  SET_TOTAL_COUNT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getSuppliers = (pagination, filters, join) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  dispatch({ type: SET_SUPPLIER_LOADER, payload: true });
  api
    .getSuppliers({ pagination, filters, join })
    .then((response) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: GET_SUPPLIERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
      dispatch({ type: SET_SUPPLIER_LOADER, payload: false });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_SUPPLIERS_FAILED });
      dispatch({ type: SET_SUPPLIER_LOADER, payload: false });
    });
};

export const getSupplierById = (id, params) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS2, payload: true });
  api
    .getSupplierById(id, params)
    .then((response) => {
      dispatch({ type: SET_LOADING_STATUS2, payload: false });
      dispatch({ type: SET_SUPPLIER, payload: response.data });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: SET_SUPPLIER_FAILED });
    });
};

export const updateSupplier = (supplier) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS2, payload: true });
  return api
    .updateSupplier(supplier)
    .then((response) => {
      dispatch({ type: SET_LOADING_STATUS2, payload: false });
      dispatch({ type: SET_SUPPLIER, payload: response });
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS2, payload: false });
      let { code } = error;
      !(code === 312 || code === 313 || code === 503 || code === 506 || code === 301) &&
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
      return Promise.reject(error);
    });
};

export const getSupplierUsers = (id, pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getSupplierUsers(id, { pagination, filters })
    .then((response) => {
      dispatch({ type: GET_SUPPLIER_USERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_SUPPLIER_USERS_FAILED });
    });
};

export const getWhatsNumbers =
  ({ pagination, filters, sort, join }) =>
  (dispatch) => {
    dispatch({ type: SET_LOADING_STATUS, payload: true });
    api
      .getWhatsNumbers({ pagination, filters, sort, join })
      .then((response) => {
        dispatch({ type: GET_WHATS_NUMBERS, payload: response.data });
        dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
        dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: GET_WHATS_NUMBERS_FAILED });
      });
  };

export const getSupplierAdminUsers = (id, pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getSupplierAdminUsers(id, { pagination, filters })
    .then((response) => {
      dispatch({ type: GET_SUPPLIER_ADMIN_USERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_SUPPLIER_ADMIN_USERS_FAILED });
    });
};

export const getSupplierManagerUsers = (id, pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getSupplierAdminUsers(id, { pagination, filters })
    .then((response) => {
      dispatch({ type: GET_SUPPLIER_MANAGER_USERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_SUPPLIER_MANAGER_USERS_FAILED });
    });
};

export const getSupplierKpi = (supplierId, params) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getSupplierKpi(supplierId, params)
    .then((response) => {
      dispatch({ type: GET_SUPPLIER_KPI, payload: response });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_SUPPLIER_KPI, payload: {} });
    });
};

export const setCategoriesLoader = (status) => (dispatch) => {
  dispatch({ type: SET_CATEGORIES_LOADER, payload: status });
};

export const setSuppliersLoader = (status) => (dispatch) => {
  dispatch({ type: SET_SUPPLIER_LOADER, payload: status });
};

export const toggleWhatsStatus = (data) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .updateWhatsNumbers(data)
    .then((res) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.resolve(res);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};
