/**
 * Image component
 */
import { Button, FormControl, FormLabel, IconButton, Tooltip } from '@material-ui/core';
import { mdiCrop } from '@mdi/js';
import Icon from '@mdi/react';
import DropZoneFileUploader from 'components/FileUpload/dropZoneFileUploader';
import { addDefaultSrc } from 'components/general-functions/images.js';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ImageCropperDialog, InfoToggleDialog } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const ImageComponent = (props) => {
  const [error, setError] = useState(false);
  let {
    errors,
    hasAccess,
    message,
    state,
    id,
    label,
    name,
    customClass,
    resizedName,
    fileId,
    required,
    token,
    withCropper,
  } = props;

  const onError = (event) => {
    setError(true);
    addDefaultSrc(event);
  };

  useEffect(() => {
    setError(false);
  }, [state.onUploadFile]);

  const updateCroppedImage = (croppedImage) => {
    state.setFile({ [name]: croppedImage });
  };

  if (!name) name = 'image';

  return (
    <FormControl id={id} fullWidth>
      <small className='mb-15'>{message && <IntlMessages id={`${message}`} />}</small>
      {!state.file[name] && (
        <DropZoneFileUploader
          handleParentUpload={(data) => state.onUploadFile(data, name, resizedName, fileId)}
          formInputName={name}
          disabled={!hasAccess}
          uploadAnotherImage={() => state.editFile(name, resizedName, fileId)}
          token={token}
        />
      )}

      {errors && (
        <span className='small-error'>
          <IntlMessages id={`global.errors.required`} />
        </span>
      )}

      {state.file[name] && (
        <Fragment>
          <img
            src={`${state.file[name]}?access_token=${token}`}
            onError={onError}
            className={`${customClass ? customClass : `img-bg-preview`} ${
              withCropper ? 'radius-circle' : ''
            } border-primary`}
            alt=''
          />
          {withCropper && (
            <Tooltip placement='top' title={<IntlMessages id={`global.crop`} />}>
              <IconButton
                className={'image-cropper-icon'}
                onClick={() =>
                  props.ImageCropperDialog({
                    openCropper: true,
                    title: 'global.crop.image',
                    image: `${state.file[name]}${token ? `?access_token=${token}` : ''}`,
                    token: token ? token : '',
                    updateCroppedImage: updateCroppedImage,
                  })
                }>
                <Icon path={mdiCrop} size={1} color={'rgba(0, 0, 0, 0.54)'} />
              </IconButton>
            </Tooltip>
          )}
          {error && (
            <span className='small-error'>
              <IntlMessages id={`global.errors.brokenImage`} />
            </span>
          )}
        </Fragment>
      )}
      <div className={'img-form'}>
        <FormLabel required={required} className={'mt-10'}>
          {<IntlMessages id={label ? `${label}` : 'global.image'} />}
        </FormLabel>
        <Button
          disabled={!state.file[name]}
          className={`${state.file[name] ? 'img-button' : 'img-button-disable'} ${
            customClass ? customClass : ``
          } mt-10`}
          hidden={!hasAccess}
          onClick={() => {
            state.editFile(name, resizedName, fileId);
          }}>
          <IntlMessages id='global.actions.delete' />
        </Button>
      </div>
    </FormControl>
  );
};

export default withRouter(connect(null, { InfoToggleDialog, ImageCropperDialog })(ImageComponent));
