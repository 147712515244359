import { Checkbox, HStack, IconButtonWithTooltip, PenIcon } from '@elkaso-app/web-design';
import { AddItemModalCell } from 'pages/suppliers/components/supplier-items/add-item-modal';
import { Row } from 'react-table';

export interface IProductListActionsCell {
  row: Row<any>;
  item: Record<string, any>;
  isSelected: boolean;
  onSelectChange: (index: number, checked: boolean) => void;
}

export const ProductListActionsCell = ({ row, item, isSelected, onSelectChange }: IProductListActionsCell) => {
  return (
    <HStack spacing='sm'>
      <AddItemModalCell
        variant='edit'
        item={item}
        trigger={
          <IconButtonWithTooltip
            label='Edit item'
            aria-label='edit-item-btn'
            Icon={<PenIcon boxSize='md' />}
            variant='unstyled'
            colorScheme='white'
            size='sm'
          />
        }
      />

      <Checkbox
        colorScheme='gray'
        borderColor='#757575'
        isChecked={isSelected}
        onChange={(e) => onSelectChange(row?.index, e.target.checked)}
      />
    </HStack>
  );
};
