import { Icon, IconProps } from '@elkaso-app/web-design';

export const EditIcon = (props: IconProps) => {
  return (
    <Icon width='18px' height='18px' viewBox='0 0 18 18' fill='none' {...props}>
      <path
        d='M0.99162 18C0.854587 17.9998 0.719103 17.971 0.593783 17.9155C0.468463 17.8601 0.356049 17.7792 0.263689 17.6779C0.169659 17.5775 0.0979076 17.4584 0.0531406 17.3283C0.00837351 17.1982 -0.00839809 17.0601 0.0039252 16.9231L0.24591 14.2615L11.4246 3.08372L14.9181 6.57716L3.74235 17.754L1.0815 17.996C1.05162 17.9988 1.02163 18.0001 0.99162 18ZM15.6154 5.87867L12.1229 2.38523L14.2178 0.289757C14.3096 0.197899 14.4185 0.125028 14.5384 0.0753094C14.6583 0.0255909 14.7868 0 14.9166 0C15.0464 0 15.175 0.0255909 15.2949 0.0753094C15.4148 0.125028 15.5237 0.197899 15.6154 0.289757L17.7103 2.38523C17.8022 2.47698 17.875 2.58594 17.9247 2.70588C17.9744 2.82582 18 2.95438 18 3.08421C18 3.21405 17.9744 3.34261 17.9247 3.46254C17.875 3.58248 17.8022 3.69144 17.7103 3.7832L15.6164 5.87768L15.6154 5.87867Z'
        fill='#757575'
      />
    </Icon>
  );
};
