import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetSupplierItemsApi } from './use-get-supplier-items-api';

const endpoint = 'catalog/v1/legacy-products';
export const patchSupplierItemEndpointIdentifier = endpoint;

interface BodyType {
  isActive: boolean;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  itemId: string;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function usePatchSupplierItemApi(options?: Options): ReturnType {
  const { refetch: refetchSupplierItems } = useGetSupplierItemsApi({
    enabled: false,
  });

  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ body, itemId }: TVariables) => {
    fullEndpoint = `${endpoint}/${itemId}`;

    try {
      const { data } = await http.patch(fullEndpoint, body);

      // Refetch warehouse supplier items
      refetchSupplierItems();

      toast({
        description: 'Item have been updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
