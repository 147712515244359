import { Badge } from '@elkaso-app/web-design';

export interface ITwoFacAuthBadgeCell {
  isTwoFacAuthActive: boolean;
}

export const TwoFacAuthBadge = ({ isTwoFacAuthActive }: ITwoFacAuthBadgeCell) => {
  if (!isTwoFacAuthActive) {
    return (
      <Badge colorScheme='red' py='sm' px='sm' rounded={'full'}>
        Inactive
      </Badge>
    );
  }

  return (
    <Badge colorScheme='green' py='sm' px='sm' rounded={'full'}>
      Active
    </Badge>
  );
};
