import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { catchError } from 'components/general-functions/formErrors';
import { valid } from 'components/general-functions/forms-validations';
import useForm from 'components/general-functions/forms.js';
import { genericPhone } from 'components/phoneComponent';
import AppConfig from 'constants/AppConfig';
import _ from 'lodash';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Fragment, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AssignRestaurantAdmin, getCountries } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const AssignAdminDialog = (props) => {
  const [state, setState] = useState({ errors: {}, uniqueError: {} });
  const { errors, uniqueError } = state;
  const close = () => {
    props.AssignRestaurantAdmin({
      openAssignRes: false,
    });
    props.dialog.onClose();
  };

  //for normal fields
  let stateUser = useForm({});
  let { setValue } = stateUser;
  let userValues = stateUser.value;

  const {
    countries: { countriesList },
    dialog: {
      openAssignRes,
      data: { item },
    },
    restaurants: { mergeErrors, isSuccessAssignAdmin },
  } = props;

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    setValue(item ? item : {});
  }, [openAssignRes]);

  useEffect(() => {
    if (!_.isEmpty(errors) || !_.isEmpty(uniqueError)) {
      setState({ ...state, errors: {}, uniqueError: {} });
    }
  }, [userValues]);

  useEffect(() => {
    if (!_.isEmpty(mergeErrors)) {
      let { code } = mergeErrors;
      let invalidInput = {};
      if (code === 313) {
        catchError.checkUnique('phone_number', invalidInput);
      } else if (code === 312) {
        catchError.checkUnique('email', invalidInput);
      }
      setState({
        ...state,
        uniqueError: invalidInput,
      });
    }
  }, [mergeErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!valid.isValidPhoneNumber(userValues.phoneNumber)) {
      setState({ ...state, errors: { phoneNumber: true } });
      return;
    }
    if (!userValues.phoneNumber) {
      setState({ ...state, errors: { phoneNumberRequired: true } });
      return;
    }
    if (!valid.isValidEmail(userValues.email)) {
      setState({ ...state, errors: { email: true } });
      return;
    }
    props.dialog.onConfirm(userValues);
  };

  useEffect(() => {
    if (isSuccessAssignAdmin) {
      close();
    }
  }, [isSuccessAssignAdmin]);

  const getCountriesCode = (data) => {
    const countriesCodes = [];
    data?.map((country) => countriesCodes.push(country?.code.toLowerCase()));
    return countriesCodes;
  };

  return (
    <Fragment>
      <Dialog
        open={openAssignRes || false}
        onClose={() => close()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'>
        <ValidatorForm
          noValidate
          onSubmit={handleSubmit}
          onError={() => {
            if (!userValues.phoneNumber) {
              setState({ ...state, errors: { phoneNumberRequired: true } });
              return;
            }
            NotificationManager.error(
              <IntlMessages id='global.errors.fill' />,
              <IntlMessages id={`${AppConfig.errorTitle}`} />,
              AppConfig.errorNotificationTimeout
            );
          }}>
          <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title'>
            <div className='page-title'>
              <h2>
                <IntlMessages id={item ? `restaurant.assignAdmin.edit` : `restaurant.assignAdmin.add`} />
              </h2>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: '8px 60px', width: '700px' }}>
            <div className='form-group'>
              <TextValidator
                label={<IntlMessages id='user.name' />}
                onChange={stateUser.onChange}
                name='name'
                fullWidth
                value={userValues.name || ''}
                validators={['required']}
                errorMessages={[<IntlMessages id={`global.errors.required`} key='key-0' />]}
              />
            </div>
            <div className='form-group'>
              <TextValidator
                label={<IntlMessages id='user.email' />}
                type='email'
                onChange={stateUser.onChange}
                name='email'
                error={errors.email || (uniqueError && uniqueError.email)}
                helperText={
                  (errors.email && <IntlMessages id={`global.errors.email.notValid`} />) ||
                  (uniqueError && uniqueError.email && <IntlMessages id={`global.errors.email.unique`} />)
                }
                fullWidth
                value={userValues.email || ''}
                validators={['required']}
                errorMessages={[<IntlMessages id={`global.errors.required`} key='key-1' />]}
              />
            </div>
            <div className='form-group'>
              <MuiPhoneNumber
                className={'direction-ltr'}
                onlyCountries={getCountriesCode(countriesList)}
                defaultCountry='sa'
                excludeCountries='il'
                label={<IntlMessages id='user.phoneNumber' />}
                name='phoneNumber'
                fullWidth
                InputProps={{
                  inputComponent: genericPhone,
                  onChange: stateUser.onChange,
                }}
                value={userValues.phoneNumber || ''}
                error={errors.phoneNumber || errors.phoneNumberRequired || (uniqueError && uniqueError.phone_number)}
                helperText={
                  (errors.phoneNumber && <IntlMessages id={`global.errors.phoneNumber.notValid`} />) ||
                  (errors.phoneNumberRequired && <IntlMessages id={`global.errors.required`} />) ||
                  (uniqueError && uniqueError.phone_number && <IntlMessages id={`global.errors.phone_number.unique`} />)
                }
              />
            </div>
          </DialogContent>
          <DialogActions style={{ padding: '8px 60px 30px' }}>
            <Button onClick={close} color='primary' variant='outlined'>
              <IntlMessages id={`button.cancel`} />
            </Button>
            <Button variant='contained' type='submit' color='primary'>
              <IntlMessages id={item ? `button.edit` : `button.add`} />
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ countries, dialog, restaurants }) => {
  return { countries, dialog, restaurants };
};

export default withRouter(connect(mapStateToProps, { AssignRestaurantAdmin, getCountries })(AssignAdminDialog));
