type TTicketStatusObj = {
  [key: string]: { slug: string; name: string };
};

export const ticketStatusObj: TTicketStatusObj = {
  open: {
    slug: 'open',
    name: 'Open',
  },
  closed: {
    slug: 'closed',
    name: 'Closed',
  },
  escalated: {
    slug: 'escalated',
    name: 'Escalated',
  },
  'in progress': {
    slug: 'in progress',
    name: 'In Progress',
  },
};
