import api from 'apis/units';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_UNITS,
  GET_UNITS_FAILED,
  SET_LOADING_STATUS,
  SET_PAGES_COUNT,
  SET_TOTAL_COUNT,
  SET_UNIT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getUnits = (pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getUnits({ pagination, filters })
    .then((response) => {
      dispatch({ type: GET_UNITS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_UNITS_FAILED });
    });
};

export const getUnitById = (id) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api.getUnitById(id).then((unit) => {
    dispatch({ type: SET_LOADING_STATUS, payload: false });
    dispatch({ type: SET_UNIT, payload: unit.data });
    return Promise.resolve(unit);
  });
};

export const addOrUpdateUnit = (unit) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .addOrUpdateUnit(unit)
    .then((unit) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.resolve(unit);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};
