import {
  SET_ANOTHER_PAGES_COUNT,
  SET_ANOTHER_PAGE_NUMBER,
  SET_ANOTHER_TOTAL_COUNT,
  SET_FILTERS,
  SET_PAGES_COUNT,
  SET_PAGE_NUMBER,
  SET_PAGINATION,
  SET_TOTAL_COUNT,
} from 'redux/types';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case SET_FILTERS:
      return { ...state, filters: action.payload };
    case SET_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SET_PAGES_COUNT:
      return { ...state, pagesCount: action.payload };
    case SET_PAGE_NUMBER:
      return { ...state, pageNumber: action.payload };
    case SET_ANOTHER_TOTAL_COUNT:
      return { ...state, anotherTotalCount: action.payload };
    case SET_ANOTHER_PAGES_COUNT:
      return { ...state, anotherPagesCount: action.payload };
    case SET_ANOTHER_PAGE_NUMBER:
      return { ...state, anotherPageNumber: action.payload };
    default:
      return { ...state };
  }
};
