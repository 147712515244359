import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import { getMeEndpointIdentifier } from 'apis/users/use-me-api';
import type { AxiosError } from 'axios';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'ums/v1/zoho/tickets/unassigned-tickets';
export const getOrdersEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [typeof endpoint];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetUnassignedTicketsApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const isLoggedIn = useIsLoggedIn();
  const me = useGetQueryClientData(getMeEndpointIdentifier);

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        headers: {
          'Accept-Country': me?.countryCode ?? '',
        },
      });

      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message ?? 'An error occurred while fetching unassigned tickets.';

      toast({
        description: errorMessage,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: isLoggedIn,
    ...options,
  });
}
