import { getValidatedLimitParam, getValidatedPageParam, useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { TParam, usePageParams } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = '/ums/v1/restaurant-licenses/managing';
export const getLicensesEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [
  typeof endpoint,
  {
    page: TParam;
    limit: TParam;
    restaurantId: TParam;
    licenseNumber: TParam;
    statusParams: TParam;
    statusPage: TParam;
  }
];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetLicensesApi(statusPage: TParam, options?: Options): ReturnType {
  const toast = useCustomToast();
  const page = getValidatedPageParam();
  const limit = getValidatedLimitParam();
  const isLoggedIn = useIsLoggedIn();
  const { getPageParams } = usePageParams();
  const { restaurantId, licenseNumber, status: statusParams } = getPageParams();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: {
          page,
          limit,
          restaurantId,
          licenseNumber,
          status: statusParams ?? statusPage,
        },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([endpoint, { page, limit, restaurantId, licenseNumber, statusParams, statusPage }], queryFn, {
    enabled: isLoggedIn,
    ...options,
  });
}
