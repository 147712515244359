import api from 'apis/elkasoUsers';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_ELKASO_USERS,
  GET_ELKASO_USERS_FAILED,
  SET_LOADING_STATUS,
  SET_PAGES_COUNT,
  SET_TOTAL_COUNT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getElkasoUsers = (pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getElkasoUsers({ pagination, filters })
    .then((response) => {
      dispatch({ type: GET_ELKASO_USERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_ELKASO_USERS_FAILED });
    });
};
