import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import queryString from 'query-string';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetCatalogItemsApi } from './use-get-catalog-items-api';

const endpoint = 'catalog/v1/restaurants';
export const deleteCatalogItemsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = {
  ids: string[];
};

type TVariables = {
  query: TQueryKey;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables, unknown>;

export function useDeleteCatalogItemsApi(restaurantId: string, warehouseId: string, options?: Options): ReturnType {
  const { refetch: refetchCatalogItems } = useGetCatalogItemsApi(restaurantId, warehouseId, { enabled: false });
  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ query }: TVariables) => {
    const stringifiedQuery = queryString.stringify(query, { arrayFormat: 'bracket' });
    fullEndpoint = `${endpoint}/${restaurantId}/warehouses/${warehouseId}/items?${stringifiedQuery}`;

    try {
      const { data } = await http.delete(fullEndpoint);

      // Refetch warehouse items
      refetchCatalogItems();

      toast({
        description: 'Items have been deleted successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
