import { getValidatedLimitParam, getValidatedPageParam, useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { TParam, usePageParams } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'oms/v1/orders';
export const getOrdersEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [
  typeof endpoint,
  {
    page: TParam;
    limit: TParam;
    sort: TParam;
    code: TParam;
    restaurantId: TParam;
    subEntityId: TParam;
    subEntityType: TParam;
    supplierId: TParam;
    status: TParam;
    deliveryDate: TParam;
    createdAt: TParam;
    supplierUserId: TParam;
  }
];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetOrdersApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const page = getValidatedPageParam();
  const limit = getValidatedLimitParam();
  const isLoggedIn = useIsLoggedIn();
  const { getPageParams } = usePageParams();
  const {
    sort,
    code,
    restaurantId,
    subEntityId,
    subEntityType,
    supplierId,
    status,
    deliveryDate,
    createdAt,
    supplierUserId,
  } = getPageParams();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: {
          page,
          limit,
          sort,
          code,
          restaurantId,
          subEntityId,
          subEntityType,
          supplierId,
          status,
          deliveryDate,
          createdAt,
          supplierUserId,
        },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper(
    [
      endpoint,
      {
        page,
        limit,
        sort,
        code,
        restaurantId,
        subEntityId,
        subEntityType,
        supplierId,
        status,
        deliveryDate,
        createdAt,
        supplierUserId,
      },
    ],
    queryFn,
    {
      enabled: isLoggedIn,
      ...options,
    }
  );
}
