import { Icon, IconProps } from '@elkaso-app/web-design';

export const UnLinkIcon = (props: IconProps) => {
  return (
    <Icon width='25px' height='24px' viewBox='0 0 25 24' fill='#757575' {...props}>
      <g clipPath='url(#clip0_3393_3148)'>
        <path
          d='M17.5 7H13.5V8.9H17.5C19.21 8.9 20.6 10.29 20.6 12C20.6 13.43 19.62 14.63 18.29 14.98L19.75 16.44C21.38 15.61 22.5 13.95 22.5 12C22.5 9.24 20.26 7 17.5 7ZM16.5 11H14.31L16.31 13H16.5V11ZM2.5 4.27L5.61 7.38C3.79 8.12 2.5 9.91 2.5 12C2.5 14.76 4.74 17 7.5 17H11.5V15.1H7.5C5.79 15.1 4.4 13.71 4.4 12C4.4 10.41 5.61 9.1 7.16 8.93L9.23 11H8.5V13H11.23L13.5 15.27V17H15.23L19.24 21L20.5 19.74L3.77 3L2.5 4.27Z'
          fill='inherit'
        />
      </g>
      <defs>
        <clipPath id='clip0_3393_3148'>
          <rect width='24' height='24' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
