import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetCatalogItemsApi } from './use-get-catalog-items-api';

const endpoint = 'catalog/v1/restaurants';
export const uploadCatalogCSVFilesEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: FormData;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUploadCatalogCSVFileApi(restaurantId: string, warehouseId: string, options?: Options): ReturnType {
  const { refetch: refetchCatalogItems } = useGetCatalogItemsApi(restaurantId, warehouseId, { enabled: false });
  const toast = useCustomToast();

  const fullEndpoint = `${endpoint}/${restaurantId}/warehouses/${warehouseId}/items/upload`;

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(fullEndpoint, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast({
        description: 'CSV file has been uploaded successfully.',
        status: 'success',
      });

      // Refetch warehouse items
      refetchCatalogItems();

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: [endpoint], ...options });
}
