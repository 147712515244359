import {
  ASSIGN_RESTAURANT,
  ATTACH_SUPPLIER_DIALOG,
  CATALOG_DIALOG,
  COMMENT_DIALOG,
  IMAGE_CROPPER_DIALOG,
  IMAGE_DIALOG,
  INFO_DIALOG,
  LOGIN_DIALOG,
  MAP_DIALOG,
  MERGE_DIALOG,
  MERGE_RESTAURANT_DIALOG,
  MERGE_SUPPLIER_USER_DIALOG,
  OPEN_DIALOG,
  SELECT_ADMIN,
  SELECT_ASSIGN_METHOD,
} from 'redux/types';

const INIT_STATE = {
  open: false,
  data: {},
  changedValue: {},
  name: '',
  onConfirm: function () {
    return;
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { ...state, ...action.payload };
    case LOGIN_DIALOG:
      return { ...state, ...action.payload };
    case COMMENT_DIALOG:
      return { ...state, ...action.payload };
    case CATALOG_DIALOG:
      return { ...state, ...action.payload };
    case ATTACH_SUPPLIER_DIALOG:
      return { ...state, ...action.payload };
    case MERGE_DIALOG:
      return { ...state, ...action.payload };
    case IMAGE_DIALOG:
      return { ...state, ...action.payload };
    case MAP_DIALOG:
      return { ...state, ...action.payload };
    case INFO_DIALOG:
      return { ...state, ...action.payload };
    case IMAGE_CROPPER_DIALOG:
      return { ...state, ...action.payload };
    case MERGE_SUPPLIER_USER_DIALOG:
      return { ...state, ...action.payload };
    case MERGE_RESTAURANT_DIALOG:
      return { ...state, ...action.payload };
    case ASSIGN_RESTAURANT:
      return { ...state, ...action.payload };
    case SELECT_ADMIN:
      return { ...state, ...action.payload };
    case SELECT_ASSIGN_METHOD:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};
