/**
 *  Info Dialog
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Icon from '@mdi/react';
import _ from 'lodash';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InfoToggleDialog } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const InfoDialog = (props) => {
  const close = () => props.InfoToggleDialog({ openInfo: false, items: [], info: '' });

  const {
    dialog: { openInfo, items = [], title, info, justInfo },
  } = props;
  if (!openInfo) return <Fragment />;
  return (
    <Dialog
      open={openInfo || false}
      onClose={() => close()}
      aria-labelledby='info-dialog-title'
      aria-describedby='info-dialog-description'>
      <DialogTitle id='simple-dialog-title' className={`confirmation-dialog`}>
        <IntlMessages id={title} />
      </DialogTitle>
      <DialogContent className={`break-word`} style={{ minWidth: '300px', maxWidth: '400px' }}>
        {!!items && !_.isEmpty(items) ? (
          <List component='nav' aria-label='info'>
            {items.map(
              (item, key) =>
                item.label && (
                  <ListItem button key={key} className={'align-right'}>
                    {item.icon && (
                      <ListItemIcon>
                        <Icon path={item.icon} size={1} color='rgba(0, 0, 0, 0.54)' />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={item.label} secondary={item.data} />
                  </ListItem>
                )
            )}
          </List>
        ) : (
          <DialogContentText id='alert-dialog-description'>
            <div>{!!info && !justInfo ? <IntlMessages id={`dialog.${info}`} /> : justInfo}</div>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className={'dialog-actions'}>
        <Button onClick={close} color='primary' variant='outlined' className={'p-0'}>
          {
            <p className={'button-dialog-text'}>
              <IntlMessages id='global.button.gotIt' />
            </p>
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};
export default withRouter(connect(mapStateToProps, { InfoToggleDialog })(InfoDialog));
