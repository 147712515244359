import { HStack, Link, Text } from '@elkaso-app/web-design';
import { DownArrowIcon } from 'pages/restaurants/icons/down-arrow-icon';

export const OpenMappingSheet = () => {
  return (
    <Link
      href='https://docs.google.com/spreadsheets/d/1mHgTYJ6hfvzUX5vgQrQ82GsKbaMGPWNwuEi_G75WFz0/edit#gid=1952415088'
      target='_blank'
      _hover={{ textDecoration: 'none' }}>
      <HStack spacing='sm' cursor='pointer' w='fit-content'>
        <Text color='red.500' fontWeight='semibold' userSelect='none'>
          Open Mapping Sheet
        </Text>
        <DownArrowIcon />
      </HStack>
    </Link>
  );
};
