import {
  Button,
  ChakraAsyncSelect,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  useBoolean,
  useDisclosure,
} from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
};

type TInputs = {
  supplier: TOption | undefined;
  supplierUser: TOption | undefined;
};

const defaultValues: TInputs = {
  supplier: undefined,
  supplierUser: undefined,
};

const getSupplierDetails = async (supplierId: string) => {
  const { data } = await http.get(`suppliers/${supplierId}`);
  return data;
};

const getSupplierUserDetails = async (supplierUserId: string) => {
  const { data } = await http.get(`users/${supplierUserId}`);
  return data;
};

export const SupplierIdFilterButton = () => {
  const [isActive, setIsActive] = useBoolean(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  // Update active filter button
  useEffect(() => {
    if (params?.supplierId) setIsActive.on();
    else setIsActive.off();
  }, [params?.supplierId]);

  const { control, handleSubmit, formState, reset, getValues } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = async () => {
    const values: TInputs = {
      supplier: undefined,
      supplierUser: undefined,
    };

    // Update supplier value
    if (params?.supplierId) {
      const { data } = await getSupplierDetails(params?.supplierId as string);

      values.supplier = {
        label: data?.name,
        value: data?.id,
      };
    }

    if (!params?.supplierId) {
      values.supplier = undefined;
      values.supplierUser = undefined;
    }

    // Update supplier user value
    if (params?.supplierId && params?.supplierUserId) {
      const { data } = await getSupplierUserDetails(params?.supplierUserId as string);

      values.supplierUser = {
        label: data?.name,
        value: data?.id,
      };
    }

    if (!params?.supplierId || !params?.supplierUserId) {
      values.supplierUser = undefined;
    }

    reset(values);
  };

  useEffect(() => {
    updateValues();
  }, [params?.supplierId]);

  const onSubmit = (data: TInputs) => {
    params.supplierId = data.supplier?.value as string;
    params.supplierUserId = data.supplierUser?.value as string;

    setPageParams(params);
    onClose();
    reset(data);
  };

  const handleClose = () => {
    onClose();
    updateValues();
  };

  const handleDisableFilterButton = () => {
    const isDisabled = Object.keys(formState.dirtyFields).length ? false : true;
    return isDisabled;
  };

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={isActive}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='supplier'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Supplier</FormLabel>
                <ChakraAsyncSelect
                  id='supplierId'
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  loadOptions={(inputValue, callback) => {
                    http
                      .get('suppliers', {
                        params: { filter: `name||$contL||${inputValue}` },
                      })
                      .then((res) => {
                        const values: TOption[] = [];

                        res?.data?.data?.map((item: any) => {
                          values.push({ value: item?.id, label: item?.name });
                        });

                        callback(values);
                      });
                  }}
                />
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name='supplierUser'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>SAM</FormLabel>
                <ChakraAsyncSelect
                  id='supplierUserId'
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  isDisabled={!getValues('supplier.value')}
                  loadOptions={(inputValue, callback) => {
                    http
                      .get(
                        `users?filter=supplierId||$eq||${getValues(
                          'supplier.value'
                        )}&filter=name||$contL||${inputValue}`
                      )
                      .then((res) => {
                        const values: TOption[] = [];

                        res?.data?.data?.map((item: any) => {
                          values.push({ value: item?.id, label: item?.name });
                        });

                        callback(values);
                      });
                  }}
                />
              </FormControl>
            )}
          />

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' disabled={handleDisableFilterButton()}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
