import { getValidatedLimitParam, getValidatedPageParam, useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { TParam, usePageParams } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'pos/v2/integrations';
export const getPosIntegrationsEndpointIdentifier = endpoint;

export interface PosIntegrationData {
  id: number;
  createdAt: string;
  posVendor: {
    id: number;
    name: string;
    imageUrl: string;
  };
  restaurant: {
    id: number;
    nameEn: string;
  };
  user: {
    id: number;
    name: string;
    email: string;
    phoneNumber: string;
  };
}

type TData = {
  data: PosIntegrationData[];
  total: number;
  pageCount: number;
};

type TError = AxiosError;
type TQueryKey = [
  typeof endpoint,
  {
    page: TParam;
    limit: TParam;
    sort: TParam;
    restaurantId: TParam;
  }
];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetPosIntegrationsApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const page = getValidatedPageParam();
  const limit = getValidatedLimitParam();
  const isLoggedIn = useIsLoggedIn();
  const { getPageParams } = usePageParams();
  const { sort, restaurantId } = getPageParams();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: {
          page,
          limit,
          sort,
          restaurantId,
        },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper(
    [
      endpoint,
      {
        page,
        limit,
        sort,
        restaurantId,
      },
    ],
    queryFn,
    {
      enabled: isLoggedIn,
      ...options,
    }
  );
}
