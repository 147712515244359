import { Icon, IconProps } from '@elkaso-app/web-design';

export const FullscreenIcon = (props: IconProps) => {
  return (
    <Icon viewBox='0 0 22 22' fill='#757575' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.7276 18.7227L4.52894 18.7242L9.69009 13.5631L8.18976 12.0627L3.02861 17.2239L3.03011 12.0252L0.908637 12.0252L0.907137 19.7834L1.96937 20.8457L9.7276 20.8442L9.7276 18.7227ZM12.6533 0.280601V2.40207L17.8519 2.40057L12.6908 7.56172L14.1911 9.06206L19.3522 3.90091L19.3507 9.09957L21.4722 9.09957L21.4737 1.34134L20.4115 0.2791L12.6533 0.280601ZM9.7276 2.40207L4.52894 2.40057L9.6901 7.56172L8.18976 9.06206L3.02861 3.90091L3.03011 9.09957L0.908637 9.09957L0.907136 1.34134L1.96937 0.2791L9.7276 0.280601V2.40207ZM14.1911 12.0627L19.3522 17.2239L19.3507 12.0252L21.4722 12.0252L21.4737 19.7834L20.4115 20.8457L12.6533 20.8442L12.6533 18.7227L17.8519 18.7242L12.6908 13.5631L14.1911 12.0627Z'
        fill='inherit'
      />
    </Icon>
  );
};
