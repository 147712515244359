import { BasePagination } from '@elkaso-app/web-design';
import { useGetOrdersApi } from 'apis/orders/use-get-orders-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import {
  CreatedAtFilterButton,
  OrderCodeFilterButton,
  OrderDeliveryDateFilterButton,
  OrderStatusFilterButton,
  SupplierIdFilterButton,
} from 'components/base-table/filters';
import { RestaurantBranchIdFilterButton } from 'components/base-table/filters/restaurant-branch-id';
import { BaseSortButton } from 'components/base-table/sorting';
import dayjs from 'dayjs';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React, { useEffect } from 'react';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { IOrderActionsCell, OrderActionsCell } from '../components/order-actions-cell';
import { IOrderCodeCell, OrderCodeCell } from '../components/order-code-cell';
import { IRestaurantInfoCell, RestaurantInfoCell } from '../components/restaurant-info-cell';
import { IStatusBadgeCell, StatusBadgeCell } from '../components/status-badge-cell';
import { ISupplierInfoCell, SupplierInfoCell } from '../components/supplier-info-cell';

export const OrdersListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetOrdersApi();
  const { pageCount, total, data: ordersData } = data ?? {};

  /**
   * This function made for re-formatting the ordersData response
   * because we have one missing status and we have to do come calculations logic to find it,
   * so instead of making some mess in the frontend code I will do the logic here and update the response
   * as I expected to get it from backend .. what to do :|
   */
  const _ordersData = React.useMemo(() => {
    const _data: any = [];

    ordersData?.map((item: any) => {
      // Clone item
      const _item = JSON.parse(JSON.stringify(item));

      // If order modified
      if (_item?.isModifiedBySupplier) {
        // Update order status value
        if (_item?.status === 'approved') {
          _item.status = 'modified';
        }
      }

      // Correct canceled status and history
      if (_item?.status === 'canceled' || _item?.status === 'rejected') {
        _item.status = 'canceled';

        const historyRejectedStatusIndex = _item?.history?.findIndex((item: any) => item?.status === 'rejected');

        if (_item?.history?.[historyRejectedStatusIndex]) {
          _item.history[historyRejectedStatusIndex].status = 'canceled';
        }

        // Add rejected status
        if (_item?.lastUpdatedByUser?.id === _item?.supplier?.user?.id) {
          _item.status = 'rejected';

          const historyCanceledStatusIndex = _item?.history?.findIndex((item: any) => item?.status === 'canceled');

          if (_item?.history?.[historyCanceledStatusIndex]) {
            _item.history[historyCanceledStatusIndex].status = 'rejected';
          }
        }
      }

      // Push this item to new data object
      _data.push(_item);
    });

    return _data;
  }, [ordersData]);

  useEffect(() => {
    dispatch(changeTitle('Orders'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Code' filterButtonComponent={<OrderCodeFilterButton />} />,
        accessor: 'code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['code']>) => (
          <CustomTableCell<IOrderCodeCell> as={OrderCodeCell} code={value} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Restaurant'
            filterButtonComponent={<RestaurantBranchIdFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='restaurantId' />}
          />
        ),
        accessor: 'restaurant',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['restaurant']>) => (
          <CustomTableCell<IRestaurantInfoCell>
            as={RestaurantInfoCell}
            restaurantId={value.restaurantId}
            name={value.name}
            subEntityName={value.subEntityName}
            phoneNumber={value.phoneNumber}
          />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Supplier'
            filterButtonComponent={<SupplierIdFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='supplierId' />}
          />
        ),
        accessor: 'supplier',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier']>) => (
          <CustomTableCell<ISupplierInfoCell>
            as={SupplierInfoCell}
            supplierId={value.supplierId}
            name={value.name}
            supplierUserName={value.supplierUserName}
            phoneNumber={value.phoneNumber}
          />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Status'
            filterButtonComponent={<OrderStatusFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='status' />}
          />
        ),
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => (
          <CustomTableCell<IStatusBadgeCell> as={StatusBadgeCell} status={value.status} history={value.history} />
        ),
      },
      {
        Header: <TableHeader name='Cutoff Time' />,
        accessor: 'cutOffTime',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['cutOffTime']>) => <TableCell value={value} />,
      },
      {
        Header: (
          <TableHeader
            name='Delivery'
            filterButtonComponent={<OrderDeliveryDateFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='deliveryDate' />}
          />
        ),
        accessor: 'delivery',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['delivery']>) => <TableCell value={value} />,
      },
      {
        Header: (
          <TableHeader
            name='Created'
            filterButtonComponent={<CreatedAtFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='createdAt' />}
          />
        ),
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['createdAt']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IOrderActionsCell> as={OrderActionsCell} order={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    code: string;
    restaurant: {
      restaurantId: string;
      name: string;
      subEntityName: string;
      phoneNumber: string;
    };
    supplier: {
      supplierId: string;
      name: string;
      supplierUserName: string;
      phoneNumber: string;
    };
    status: {
      status: string;
      history: any;
    };
    cutOffTime: string;
    delivery: string;
    createdAt: string;
    actions: (typeof _ordersData)[0];
  }

  const columnsAccessorData = React.useMemo(
    () =>
      _ordersData?.map((order: (typeof _ordersData)[0]): IColumnsAccessorData => {
        return {
          id: order?.id,
          code: order?.code,
          restaurant: {
            restaurantId: order?.restaurant?.id,
            name: order?.restaurant?.name,
            subEntityName: order?.restaurant?.subEntity?.name,
            phoneNumber: order?.restaurant?.user?.phoneNumber,
          },
          supplier: {
            supplierId: order?.supplier?.id,
            name: order?.supplier?.name,
            supplierUserName: order?.supplier?.user?.name,
            phoneNumber: order?.supplier?.user?.phoneNumber,
          },
          status: {
            status: order?.status,
            history: order?.history,
          },
          cutOffTime: order?.supplier?.cutOffTime ? dayjs(order?.supplier?.cutOffTime).format('hh:mm a') : '---',
          delivery: order?.deliveryDate ? dayjs(order?.deliveryDate).format('DD-MM-YYYY hh:mm a') : '---',
          createdAt: dayjs(order?.createdAt).format('DD-MM-YYYY hh:mm a'),
          actions: order,
        };
      }),
    [_ordersData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Orders' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
