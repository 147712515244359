import { Icon, IconProps } from '@elkaso-app/web-design';

export const RestaurantsIcons = (props: IconProps) => {
  return (
    <Icon width='22' height='22' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M19.4372 2.00208C19.4372 2.00208 4.19094 1.9974 4.19531 2.00208C2.79 2.00208 2 2.72865 2 4.21271V19.5146C2 20.903 2.71031 21.6299 4.11437 21.6299H19.4809C20.885 21.6299 21.5963 20.9393 21.5963 19.5146V4.21271C21.5966 2.76458 20.8853 2.00208 19.4372 2.00208ZM11.4178 9.08177C11.4178 9.56177 10.9806 10.0758 10.4131 10.0758V18.1805C10.4131 19.3311 8.845 19.3311 8.845 18.1805V10.0758C8.29281 10.0758 7.81187 9.65052 7.81187 9.01333V4.55302C7.81187 4.1649 8.37281 4.1499 8.37281 4.56833V7.86521H8.8425V4.53771C8.8425 4.18115 9.38281 4.15833 9.38281 4.55302V7.86521H9.86813V4.5424C9.86813 4.16958 10.3925 4.15458 10.3925 4.55708V7.86521H10.87V4.5424C10.87 4.17365 11.4178 4.15896 11.4178 4.55708V9.08177ZM15.6009 18.1768C15.6009 19.3021 14.0297 19.2852 14.0297 18.1768V13.1824H13.1931V5.58958C13.1931 3.82208 15.6013 3.82208 15.6013 5.58958V18.1768H15.6009Z'
        fill='inherit'
      />
    </Icon>
  );
};
