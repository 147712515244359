import { useCustomToast } from '@elkaso-app/web-design';
import { useGetCategoriesApi } from 'apis/categories/use-get-categories-api';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'ums/v1/categories';
export const createCategoryV2EndpointIdentifier = endpoint;
interface BodyType {
  titleAr: string;
  titleEn: string;
  images: {
    imageUrl: string;
    countryCode: string;
  }[];
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useCreateCategoryApi(options?: Options): ReturnType {
  const { refetch: refetchCategories } = useGetCategoriesApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint, body);

      // Refetch categories list
      refetchCategories();

      toast({
        description: 'New category has been created successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
