import api from 'apis/users';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_USERS,
  GET_USER_FAILED,
  SET_ANOTHER_LOADING,
  SET_LOADING_STATUS,
  SET_PAGES_COUNT,
  SET_TOTAL_COUNT,
  SET_USER,
} from 'redux/types';
import { LocalStorageKeys } from 'utils/constants';
import IntlMessages from 'utils/intl-messages';

export const getUsers = (pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getUsers({ pagination, filters })
    .then((response) => {
      dispatch({ type: GET_USERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_USER_FAILED });
    });
};

export const setUser = (user) => (dispatch) => {
  dispatch({ type: SET_USER, payload: user });
};

export const getUserById = (userId) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api.getUserById(userId).then((user) => {
    dispatch({ type: SET_LOADING_STATUS, payload: false });
    dispatch({ type: SET_USER, payload: user.data });
    return Promise.resolve(user);
  });
};

export const getUserByMe = () => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api.getUserByMe().then((user) => {
    localStorage.getItem(LocalStorageKeys.cms_user);
    dispatch({ type: SET_LOADING_STATUS, payload: false });
    dispatch({ type: SET_USER, payload: user.data });
    return Promise.resolve(user);
  });
};

export const addOrUpdateUser = (user) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .addOrUpdateUser(user)
    .then((user) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.resolve(user);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};

export const changePassword = (oldPassword, newPassword) => (dispatch) => {
  dispatch({ type: SET_ANOTHER_LOADING, payload: true });
  return api
    .changePassword(oldPassword, newPassword)
    .then(() => {
      dispatch({ type: SET_ANOTHER_LOADING, payload: false });
    })
    .catch((error) => {
      dispatch({ type: SET_ANOTHER_LOADING, payload: false });
      return Promise.reject(error);
    });
};
