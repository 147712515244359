import { Text, VStack } from '@elkaso-app/web-design';

export interface ICategoryCountriesCell {
  countries: {
    id: number;
    name: string;
  }[];
}
[];

export const CategoryCountries = ({ countries }: ICategoryCountriesCell) => {
  return (
    <VStack align={'start'}>
      {countries?.map((country) => {
        return <Text key={country.id}>{country.name}</Text>;
      })}
    </VStack>
  );
};
