import { BasePagination, Button } from '@elkaso-app/web-design';
import { RestaurantUser } from 'apis/restaurants/restaurant-users/types';
import { useGetRestaurantUsersApi } from 'apis/restaurants/restaurant-users/use-get-restaurant-users-api';
import {
  BaseSortButton,
  BaseTable,
  BaseTableContainer,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
} from 'components/base-table';
import { BaseTableSpinner, BaseTableTitle, TableHeader } from 'components/base-table/components';
import { NameEmailPhoneFiltersButton } from 'components/base-table/filters/name-email-phone';
import { RestaurantRoleIdFilterButton } from 'components/base-table/filters/restaurant-role-id';
import { SubEntityIdFilterButton } from 'components/base-table/filters/sub-entity-id';
import { setHiddenColumns } from 'components/base-table/utils';
import { AddUserModal } from 'pages/restaurants/components/restaurant-users/add-user-modal';
import {
  ResUsersActionsCell,
  ResUsersActionsCellProps,
} from 'pages/restaurants/components/restaurant-users/res-users-actions-cell';
import { UserInfoCell, UserInfoCellProps } from 'pages/restaurants/components/restaurant-users/user-info-cell';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { useSetPaginationParamsV1 } from '../../../hooks/use-set-pagination-params-v1';

export const RestaurantUsersScreen = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const setPaginationParams = useSetPaginationParamsV1();

  const {
    data,
    isLoading: isLoadingRestaurantUsersData,
    isFetching: isFetchingRestaurantUsersData,
  } = useGetRestaurantUsersApi(restaurantId);
  const { pageCount, total, data: restaurantUsersData } = data ?? {};

  const isLoading = isLoadingRestaurantUsersData;
  const isFetching = isFetchingRestaurantUsersData;

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='User Info'
            fontWeight='semibold'
            color='gray.500'
            filterButtonComponent={<NameEmailPhoneFiltersButton />}
            sortButtonComponent={<BaseSortButton sortBy='name' />}
          />
        ),
        accessor: 'userInfo',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['userInfo']>) => (
          <CustomTableCell<UserInfoCellProps> as={UserInfoCell} {...value} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='User Role'
            fontWeight='semibold'
            color='gray.500'
            filterButtonComponent={<RestaurantRoleIdFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='role' />}
          />
        ),
        accessor: 'userRoles',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['userRoles']>) => (
          <TableCell value={value.role} color='gray.600' />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Assigned Branches'
            fontWeight='semibold'
            color='gray.500'
            filterButtonComponent={<SubEntityIdFilterButton restaurantId={restaurantId} />}
          />
        ),
        accessor: 'assignedBranches',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['assignedBranches']>) => (
          <div style={{ whiteSpace: 'pre-wrap', color: 'gray.600' }}>{value.subEntities.join('\n')}</div>
        ),
      },
      {
        Header: <TableHeader name='Actions' fontWeight='semibold' color='gray.500' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<ResUsersActionsCellProps> as={ResUsersActionsCell} user={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    userInfo: {
      name: string;
      email: string;
      phoneNumber: string;
    };
    userRoles: {
      role: string;
    };
    assignedBranches: {
      subEntities: string[];
    };
    actions: RestaurantUser;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      restaurantUsersData?.map((user: RestaurantUser): IColumnsAccessorData => {
        return {
          id: user?.id,
          userInfo: {
            name: user?.name,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          },
          userRoles: {
            role: user?.roles?.[0]?.name,
          },
          assignedBranches: {
            subEntities: user?.subEntities?.map((e: any) => e?.name),
          },
          actions: user,
        };
      }),
    [restaurantUsersData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title={`Restaurant Users`} />

          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <AddUserModal
            variant='add'
            restaurantId={restaurantId}
            trigger={<Button colorScheme='red'>Add New User</Button>}
          />
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} initialHiddenColumns={setHiddenColumns(['actions'])} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
