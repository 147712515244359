import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetCategoriesApi } from './use-get-categories-api';
import { useGetCategoryDetailsApi } from './use-get-category-details-api';

const endpoint = 'catalog/v1/catalog-categories';
export const updateCategoryEndpointIdentifier = endpoint;

interface BodyType {
  names?: {
    title: string;
    description?: string;
    language: string;
  }[];
  parentId?: number;
  countries?: number[];
  image?: string;
  status?: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  categoryId: number;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUpdateCategoryApi(categoryId: number, options?: Options): ReturnType {
  const { refetch: refetchCategories } = useGetCategoriesApi({ enabled: false });
  const { refetch: refetchCategoryDetails } = useGetCategoryDetailsApi(categoryId, { enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ categoryId, body }: TVariables) => {
    try {
      const { data } = await http.put(endpoint + `/${categoryId}`, body);

      // Refetch categories list
      refetchCategories();
      refetchCategoryDetails();

      toast({
        description: 'Category has been updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
