interface IdleTimerConfig {
  /**
   * A list of paths that should be excluded from the idle timer functionality.
   * If the current path (e.g., window.location.pathname) matches any in this list,
   * the timer will not run.
   */
  whitelist: string[];

  /**
   * The amount of inactivity time (in seconds) after which the user is considered idle.
   * When this time is reached, the `onTimeout` callback is executed.
   */
  timeout: number;

  /**
   * The key under which the expiration time will be stored in the localStorage.
   * This is used to keep track of the remaining idle time even if the page is reloaded.
   */
  localStorageKey: string;

  /**
   * A callback function that is executed when the user is deemed idle (i.e., when
   * the inactivity period exceeds the specified `timeout` value).
   */
  onTimeout: () => void;

  /**
   * A callback function that is executed when the expiration time is found to
   * be in the past during initialization (e.g., if the page is reloaded after
   * the timeout duration).
   */
  onExpired: () => void;
}

class IdleTimer {
  private timeout: number;
  private whitelist: string[];
  private localStorageKey: string;
  private onTimeout: () => void;
  private eventHandler: () => void;
  private interval?: number;
  private timeoutTracker?: number;

  constructor({ whitelist, timeout, localStorageKey, onTimeout, onExpired }: IdleTimerConfig) {
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.whitelist = whitelist;
    this.localStorageKey = localStorageKey;

    if (this.isWhitelisted()) {
      this.cleanUp();
      return;
    }

    const expiredTime = parseInt(localStorage.getItem(this.localStorageKey) || '0', 10);

    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      this.cleanUp();
      return;
    }

    this.tracker();
    this.startInterval();
  }

  private isWhitelisted(): boolean {
    return this.whitelist.includes(location.pathname);
  }

  private startInterval(): void {
    this.updateExpiredTime();

    this.interval = window.setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(this.localStorageKey) || '0', 10);

      if (expiredTime < Date.now()) {
        this.onTimeout();
        this.cleanUp();
      }
    }, 1000);
  }

  private updateExpiredTime(): void {
    if (this.timeoutTracker) {
      window.clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = window.setTimeout(() => {
      localStorage.setItem(this.localStorageKey, (Date.now() + this.timeout * 1000).toString());
    }, 300);
  }

  private tracker(): void {
    window.addEventListener('mousemove', this.eventHandler, { passive: true });
    window.addEventListener('scroll', this.eventHandler, { passive: true });
    window.addEventListener('keydown', this.eventHandler, { passive: true });
  }

  public getRemainingTime(): number {
    const expiredTime = parseInt(localStorage.getItem(this.localStorageKey) || '0', 10);
    const remainingTime = expiredTime - Date.now();

    // Return the value in seconds or return 0 if the value is negative (i.e., timer already expired)
    return Math.max(remainingTime / 1000, 0);
  }

  public cleanUp(): void {
    localStorage.removeItem(this.localStorageKey);

    if (this.interval) window.clearInterval(this.interval);

    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}

export default IdleTimer;
