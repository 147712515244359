/**
 *  User Login Dialog
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UserLoginToggleDialog } from 'redux/actions';
import { LocalStorageKeys } from 'utils/constants';

const UserLoginDialog = (props) => {
  const {
    dialog: { isOpen, phoneNumber },
  } = props;

  const onClose = () => {
    props.UserLoginToggleDialog({
      isOpen: false,
      phoneNumber: '',
    });
  };

  const constructUrl = (redirect) => {
    const accessToken = localStorage.getItem(LocalStorageKeys.access_token);
    const params = `access_token=${accessToken}&phone_number=${phoneNumber}&redirect=${redirect}`;
    return `${process.env.REACT_APP_BASE_URL}/ums/v1/auth/support/login?${params}`;
  };

  const onWebAppLogin = () => {
    window.open(constructUrl('web_app'));
  };

  const onSupplierPortalLogin = () => {
    window.open(constructUrl('supplier_portal'));
  };

  return (
    <Dialog open={isOpen || false} onClose={onClose}>
      <div className={'confirmation-dialog'} style={{ width: '400px' }}>
        <DialogTitle id='alert-dialog-title' className='header-dialog'>
          Login into
        </DialogTitle>

        <DialogContent style={{ display: 'flex' }}>
          <Button
            variant='contained'
            onClick={onWebAppLogin}
            color='primary'
            size='medium'
            style={{ marginInlineEnd: '10px', height: '60px', flex: '1' }}>
            Web App
          </Button>

          <Button
            variant='contained'
            onClick={onSupplierPortalLogin}
            color='primary'
            size='medium'
            style={{ height: '60px', flex: '1' }}>
            Supplier Portal
          </Button>
        </DialogContent>

        <DialogActions className='dialog-actions'>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(connect(mapStateToProps, { UserLoginToggleDialog })(UserLoginDialog));
