import { BasePagination } from '@elkaso-app/web-design';
import { useGetRestaurantPosIssuesListApi } from 'apis/pos-integrations/use-get-restaurant-pos-issues-list-api';
import { BaseTable, BaseTableContainer, TableCell } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableHeader,
} from 'components/base-table/components';
import { TruncateText, TruncateTextProps } from 'components/truncate-text';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React from 'react';
import { CellProps } from 'react-table';
import { RestaurantNameFilterButton } from '../../../components/base-table/filters/restaurantName';
import { RetryActionPosIssueCellProps, RetryPosIssueButton } from '../components/retry-pos-issue-button';

export const PosIssuesListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { isLoading, isFetching, data } = useGetRestaurantPosIssuesListApi();
  const { pageCount, total, data: posIssuesData } = data ?? {};

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Restaurant Name' filterButtonComponent={<RestaurantNameFilterButton />} />,
        accessor: 'restaurant',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['restaurant']>) => (
          <TableCell value={value.name} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Input Data' />,
        accessor: 'input_data',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['input_data']>) => (
          <TableCell value={JSON.stringify(value)} />
        ),
      },
      {
        Header: <TableHeader name='Attempts' />,
        accessor: 'attempts',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['attempts']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Results' />,
        accessor: 'results',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['results']>) => (
          <CustomTableCell<TruncateTextProps> as={TruncateText} text={value} />
        ),
      },
      {
        Header: <TableHeader name='Creation Date' />,
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<RetryActionPosIssueCellProps> as={RetryPosIssueButton} id={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    restaurant: {
      id: string;
      name: string;
    };
    creation_date: string;
    input_data: string;
    results: string;
    actions: number;
    attempts: any;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      posIssuesData?.map((posIssue: (typeof posIssuesData)[0]): IColumnsAccessorData => {
        return {
          id: posIssue?.id,
          restaurant: {
            id: posIssue.restaurant?.id,
            name: posIssue.restaurant?.name,
          },
          creation_date: posIssue?.createdAt,
          input_data: posIssue?.data,
          results: posIssue.results?.error,
          actions: posIssue?.id,
          attempts: posIssue?.attempts,
        };
      }),
    [posIssuesData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='POS Issues' />

          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
