import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useUpdateActivationCampaignApi } from 'apis/campaigns/use-update-activation-campaign-api';
import { rest } from 'lodash';

export interface IActivationSwitchCell {
  campaignId: number;
  isActive: boolean;
}

export const ActivationSwitchCell = ({ campaignId, isActive }: IActivationSwitchCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutate: updateCampaigns, isLoading: isUpdateCampaignsLoading } = useUpdateActivationCampaignApi();

  const handleActivateCategory = () => {
    const variables = {
      campaignId,
      isActive: isActive ? 'deactivate' : 'activate',
    };

    updateCampaigns(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <FormControl display='flex' alignItems='center'>
        <Switch id={`active-category-${campaignId}`} onChange={onOpen} isChecked={isActive} {...rest} />
        <FormLabel
          htmlFor={`active-category-${campaignId}`}
          mb='0'
          ms='sm'
          userSelect='none'
          fontWeight='normal'
          fontSize='sm'>
          {isActive ? 'Active' : 'Inactive'}
        </FormLabel>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isActive ? 'Deactivate' : 'Activate'} campaign!</ModalHeader>

          <ModalBody>
            {isActive && <Text>Are you sure you want to deactivate this campaign?</Text>}
            {!isActive && <Text>Are you sure you want to activate this campaign?</Text>}
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                onClick={handleActivateCategory}
                isLoading={isUpdateCampaignsLoading}>
                {isActive ? 'Deactivate' : 'Activate'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
