import { getValidatedLimitParam, getValidatedPageParam, useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { TParam, usePageParams } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

const endpoint = 'catalog/v1/legacy-products';
export const getSupplierItemsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [
  string,
  {
    supplierId: TParam;
    restaurantId: TParam;
    subEntityId: TParam;
    subEntityType: TParam;
    page: TParam;
    limit: TParam;
    sort: TParam;
    name: TParam;
    brandEn: TParam;
    categoryId: TParam;
    code: TParam;
    price: TParam;
    unit: TParam;
  }
];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

function constructEndpoint() {
  return `${endpoint}`;
}

export function useGetSupplierItemsApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const page = getValidatedPageParam();
  const limit = getValidatedLimitParam();
  const isLoggedIn = useIsLoggedIn();
  const { getPageParams } = usePageParams();
  const { sort, name, brandEn, categoryId, code, price, unit } = getPageParams();
  const fullEndpoint = constructEndpoint();

  const { restaurantId, branchId, supplierId } = useParams<{
    supplierId: string;
    restaurantId: string;
    branchId: string;
  }>();

  const subEntityId = branchId;
  const subEntityType = 'branch';

  const queryFn = async () => {
    try {
      const { data } = await http.get(fullEndpoint, {
        params: {
          supplierId,
          restaurantId,
          subEntityId,
          subEntityType,
          page,
          limit,
          sort,
          name,
          brand: brandEn,
          categoryId,
          code,
          price,
          unit,
        },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper(
    [
      fullEndpoint,
      {
        supplierId,
        restaurantId,
        subEntityId,
        subEntityType,
        page,
        limit,
        sort,
        name,
        brandEn,
        categoryId,
        code,
        price,
        unit,
      },
    ],
    queryFn,
    {
      enabled: isLoggedIn,
      ...options,
    }
  );
}
