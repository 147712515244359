import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AutoCompleteComponent from 'components/AutoComplete/AutoComplete';
import useForm from 'components/general-functions/forms.js';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectAdminDialogAction } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const SelectAdminDialog = (props) => {
  const [users, setUsers] = useState([]);
  const close = () => {
    props.SelectAdminDialogAction({
      openSelectAdmin: false,
    });
    props.dialog.onClose();
  };

  let stateUser = useForm({});
  let userValues = stateUser.value;

  const {
    dialog: { openSelectAdmin, items = [] },
    restaurants: { isSuccessAssignAdmin },
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    props.dialog.onConfirm(
      userValues.userObj && userValues.userObj.id,
      userValues.userObj && userValues.userObj.restaurantId
    );
  };

  useEffect(() => {
    if (!_.isEmpty(items)) {
      setUsers(items);
    }
  }, [items]);

  useEffect(() => {
    if (isSuccessAssignAdmin) {
      close();
    }
  }, [isSuccessAssignAdmin]);

  return (
    <Fragment>
      <Dialog
        open={openSelectAdmin || false}
        onClose={() => close()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'>
        <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title'>
          <div className='page-title'>
            <h2>
              <IntlMessages id={`restaurant.selectAdmin`} />
            </h2>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: '8px 60px', width: '700px' }}>
          <div className={'form-group'}>
            <AutoCompleteComponent
              filterSelectedOptions={true}
              value={!_.isEmpty(userValues.userObj) && userValues.userObj}
              options={!_.isEmpty(users) ? users : []}
              getOptionLabel={(option) => (option.name ? option.name || '' : option.phoneNumber || '')}
              getOptionSelected={(option, value) =>
                option.name ? option.name === value.name : option.phoneNumber === value.phoneNumber
              }
              onChange={(e, value) => stateUser.changeAutoComplete(e, value, 'userObj')}
              label={!userValues.userObj ? 'restaurants.select.admin' : ''}
              noOptionsText={<IntlMessages id={'global.options.noMatched'} />}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 60px 30px' }}>
          <Button onClick={close} color='primary' variant='outlined'>
            <IntlMessages id={`button.cancel`} />
          </Button>
          <Button
            disabled={_.isEmpty(userValues.userObj)}
            variant='contained'
            type='submit'
            color='primary'
            onClick={handleSubmit}>
            <IntlMessages id={`button.submit`} />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog, restaurants }) => {
  return { dialog, restaurants };
};

export default withRouter(connect(mapStateToProps, { SelectAdminDialogAction })(SelectAdminDialog));
