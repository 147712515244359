import { HStack, Text } from '@elkaso-app/web-design';
import { useDownloadItemsCSVFileApi } from 'apis/restaurants/branch-supplier-items/use-download-items-csv-file';
import { DownArrowIcon } from 'pages/restaurants/icons/down-arrow-icon';

export const DownloadProductCSVFile = () => {
  const { refetch: downloadItemsCSVFile } = useDownloadItemsCSVFileApi();

  return (
    <HStack spacing='sm' cursor='pointer' w='fit-content' onClick={() => downloadItemsCSVFile()}>
      <Text userSelect='none'>Download products as CSV file</Text>
      <DownArrowIcon />
    </HStack>
  );
};
