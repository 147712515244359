import { Icon, IconProps } from '@elkaso-app/web-design';

export const MobileIcon = (props: IconProps) => {
  return (
    <Icon width='42px' height='42px' viewBox='0 0 42 42' {...props}>
      <path
        d='M30.0157 2.47314H11.5062C10.098 2.47314 8.95312 3.61802 8.95312 5.02618V35.6626C8.95312 37.0708 10.098 38.2157 11.5062 38.2157H30.0157C31.4238 38.2157 32.5687 37.0708 32.5687 35.6626V5.02618C32.5687 3.61802 31.4238 2.47314 30.0157 2.47314ZM29.6966 35.3435H11.8253V5.34531H29.6966V35.3435ZM19.1653 31.2746C19.1653 31.6978 19.3334 32.1037 19.6326 32.4029C19.9319 32.7021 20.3377 32.8702 20.7609 32.8702C21.1841 32.8702 21.59 32.7021 21.8892 32.4029C22.1885 32.1037 22.3566 31.6978 22.3566 31.2746C22.3566 30.8514 22.1885 30.4455 21.8892 30.1463C21.59 29.8471 21.1841 29.6789 20.7609 29.6789C20.3377 29.6789 19.9319 29.8471 19.6326 30.1463C19.3334 30.4455 19.1653 30.8514 19.1653 31.2746Z'
        fill='inherit'
      />
    </Icon>
  );
};
