import {
  GET_INVITATION,
  GET_INVITATION_FAILED,
  GET_INVITATION_REQUESTS,
  GET_INVITATION_REQUESTS_FAILED,
  SET_LOADING_STATUS,
} from 'redux/types';

const INIT_STATE = {
  invitationRequestsList: [],
  invitation: {},
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return { ...state, isLoading: action.payload };
    case GET_INVITATION_REQUESTS:
      return { ...state, invitationRequestsList: action.payload, isLoading: false };
    case GET_INVITATION_REQUESTS_FAILED:
      return { ...state, invitationRequestsList: [], isLoading: false };
    case GET_INVITATION:
      return { ...state, invitation: action.payload, isLoading: false };
    case GET_INVITATION_FAILED:
      return { ...state, invitation: {}, isLoading: false };
    default:
      return { ...state };
  }
};
