import { HStack, useRadioGroup } from '@chakra-ui/react';
import {
  BoxProps,
  Button,
  ChakraAsyncSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import http from 'apis/config/http-service';
import { RestaurantUser } from 'apis/restaurants/restaurant-users/types';
import {
  CreateRestaurantUserVariables,
  useCreateRestaurantUserApi,
} from 'apis/restaurants/restaurant-users/use-create-restaurant-user-api';
import { useEditRestaurantUserApi } from 'apis/restaurants/restaurant-users/use-edit-restaurant-user-api';
import { useGetRestaurantRolesApi } from 'apis/restaurants/restaurant-users/use-get-restaurant-roles-api';
import { RadioCard } from 'pages/user-management/components/user-role-buttons';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

type SubEntityDetailsProps = {
  restaurantId: string;
  subEntityId?: string;
  subEntityType?: string;
  name?: string;
};

const getSubEntityDetails = async ({ restaurantId, subEntityId, subEntityType, name }: SubEntityDetailsProps) => {
  const { data } = await http.get(`/ums/v1/restaurants/${restaurantId}/locations/all`, {
    params: {
      name,
      subEntityId,
      subEntityType,
    },
  });

  return data;
};

export interface AddUserModalProps extends BoxProps {
  variant: 'add' | 'edit';
  restaurantId: string;
  trigger: JSX.Element;
  user?: RestaurantUser;
}

type SubEntityOption = {
  label: string;
  value: string;
  type: 'branch' | 'warehouse';
};

type FormInputs = {
  name: string;
  email: string;
  phoneNumber: string;
  roleId: string;
  subEntities: SubEntityOption[];
};

const defaultValues: FormInputs = {
  name: '',
  email: '',
  phoneNumber: '',
  roleId: '',
  subEntities: [],
};

const subEntitySchema = yup.object().shape({
  label: yup.string().required(),
  value: yup.string().required(),
  type: yup.string().oneOf(['branch', 'warehouse']).required(),
});

const schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  email: yup.string().email().required('Email is required.'),
  phoneNumber: yup.string().required('Phone number is required.'),
  roleId: yup.string().required('Role is required.'),
  subEntities: yup.array().of(subEntitySchema).min(1, 'Select at least one location.').required(),
});

export const AddUserModal = ({ variant, restaurantId, trigger, user }: AddUserModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isEdit = useMemo(() => {
    return variant === 'edit';
  }, [variant]);

  const { isLoading: isCreateRestaurantUserLoading, mutate: createRestaurantUser } =
    useCreateRestaurantUserApi(restaurantId);

  const { isLoading: isEditRestaurantUserLoading, mutate: editRestaurantUser } = useEditRestaurantUserApi(
    restaurantId,
    user?.id ?? 0
  );

  const { isLoading: isRolesLoading, data: rolesData = [] } = useGetRestaurantRolesApi();

  const [options, setOptions] = useState<{ label: string; value: number }[]>([]);

  useEffect(() => {
    if (rolesData.length > 0) {
      const roleOptions = rolesData.map((role) => ({
        label: role.name,
        value: role.id,
      }));

      setOptions(roleOptions);
    }
  }, [rolesData]);

  const isLoading = isCreateRestaurantUserLoading || isEditRestaurantUserLoading || isRolesLoading;

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    getRootProps,
    getRadioProps,
    setValue: setRadioValue,
  } = useRadioGroup({
    name: 'role',
    onChange: (val) => setValue('roleId', val, { shouldValidate: true }),
  });

  const group = getRootProps();

  useEffect(() => {
    if (isEdit && isOpen && user && options?.length) {
      const userValues = {
        name: user?.name,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        roleId: user?.roles?.[0]?.id.toString(),
        subEntities: user?.subEntities?.map((i) => {
          return {
            label: i.name,
            value: i.id,
            type: i.type,
          };
        }),
      };

      setRadioValue(userValues.roleId);
      reset(userValues);
    }
  }, [isOpen, isEdit, user, options]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const variables: CreateRestaurantUserVariables = {
      body: {
        name: data.name,
        phoneNumber: data.phoneNumber,
        email: data.email,
        roleId: Number(data.roleId),
        subEntities: data.subEntities.map((i) => {
          return {
            id: Number(i.value),
            type: i.type,
          };
        }),
      },
    };

    if (!isEdit) {
      createRestaurantUser(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }

    if (isEdit) {
      editRestaurantUser(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  const onCloseComplete = () => {
    setRadioValue('');
    reset(defaultValues);
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={onCloseComplete}
        closeOnOverlayClick={false}
        isCentered
        size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? 'Edit' : 'Add New'} User</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack as='form' id='addProductForm' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <VStack spacing='md' align='start'>
                <FormControl isInvalid={!!errors.name} isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input placeholder='Please enter name' {...register('name')} />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.email} isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input placeholder='Please enter email address' {...register('email')} />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.phoneNumber} isRequired>
                  <FormLabel>Phone Number</FormLabel>
                  <Input placeholder='Please enter phone number' {...register('phoneNumber')} />
                  <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
                </FormControl>

                {!isRolesLoading && (
                  <FormControl isInvalid={!!errors.roleId} isRequired>
                    <FormLabel>User Role</FormLabel>
                    <HStack w='full' {...group}>
                      {options.map((option) => {
                        const radio = getRadioProps({ value: option.value.toString() });
                        return (
                          <RadioCard key={option.value} value={option.value.toString()} {...radio}>
                            {option.label}
                          </RadioCard>
                        );
                      })}
                    </HStack>
                    <FormErrorMessage>{errors.roleId?.message}</FormErrorMessage>
                  </FormControl>
                )}

                <Controller
                  control={control}
                  name='subEntities'
                  render={({ field: { onChange, name, onBlur, value } }) => (
                    <FormControl isInvalid={!!errors.subEntities}>
                      <FormLabel>Locations</FormLabel>
                      <ChakraAsyncSelect
                        onChange={onChange}
                        name={name}
                        onBlur={onBlur}
                        value={value}
                        isMulti
                        defaultOptions={true}
                        loadOptions={(inputValue, callback) => {
                          getSubEntityDetails({ restaurantId, name: inputValue }).then(({ data }) => {
                            const values: SubEntityOption[] = [];

                            data?.map((item: any) => {
                              values.push({ label: item?.name, value: item?.id, type: item?.type });
                            });

                            callback(values);
                          });
                        }}
                      />

                      <FormErrorMessage>{errors.subEntities?.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />
              </VStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='solid' colorScheme='red' type='submit' form='addProductForm' isLoading={isLoading}>
                {isEdit ? 'Save' : 'Add User'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
