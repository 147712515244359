/**
 *  Confirmation Dialog
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConfirmationToggleDialog } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const ConfirmationDialog = (props) => {
  const close = () => {
    props.ConfirmationToggleDialog({
      openConfirmation: false,
      confirmButton: '',
      cancelButton: '',
      extraInfo: '',
    });
  };

  const updateValue = () => {
    close();
    props.dialog.onConfirm();
  };

  const {
    dialog: {
      colorClass = 'primary',
      name,
      title,
      openConfirmation,
      confirmButton,
      cancelButton,
      error,
      values,
      extraInfo,
    },
  } = props;

  return (
    <Fragment>
      {!!name && (
        <Dialog
          open={openConfirmation || false}
          onClose={() => close()}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <div className={'confirmation-dialog'}>
            <DialogTitle id='alert-dialog-title' className={`header-dialog ${error && 'error'}`}>
              <IntlMessages id={`dialog.${title}`} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                <IntlMessages id={`dialog.message.${name}`} values={{ ...values }} />
                {extraInfo && (
                  <div>
                    <IntlMessages id={`dialog.extraInfo.${extraInfo}`} />
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={`dialog-actions ${colorClass ? 'red' : colorClass}`}>
              <Button onClick={close} color='primary' variant='outlined'>
                {<IntlMessages id={cancelButton ? `button.${cancelButton}` : 'button.cancel'} />}
              </Button>
              <Button variant='contained' onClick={updateValue} color='primary'>
                {<IntlMessages id={confirmButton ? `button.${confirmButton}` : 'button.yes'} />}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(connect(mapStateToProps, { ConfirmationToggleDialog })(ConfirmationDialog));
