import { Icon, IconProps } from '@elkaso-app/web-design';

export const CountiesIcon = (props: IconProps) => {
  return (
    <Icon width='22' height='22' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M3 12.3603H7.67861C7.82478 15.0848 8.68707 17.7213 10.1772 20C8.31068 19.7047 6.59391 18.7948 5.29533 17.4126C3.99675 16.0303 3.18957 14.2536 3 12.3603ZM3 10.6397C3.18957 8.74636 3.99675 6.96969 5.29533 5.58744C6.59391 4.20518 8.31068 3.29528 10.1772 3C8.68707 5.27866 7.82478 7.91516 7.67861 10.6397H3ZM19.9991 10.6397H15.3205C15.1744 7.91516 14.3121 5.27866 12.8219 3C14.6885 3.29528 16.4052 4.20518 17.7038 5.58744C19.0024 6.96969 19.8096 8.74636 19.9991 10.6397ZM19.9991 12.3603C19.8096 14.2536 19.0024 16.0303 17.7038 17.4126C16.4052 18.7948 14.6885 19.7047 12.8219 20C14.3121 17.7213 15.1744 15.0848 15.3205 12.3603H20H19.9991ZM9.38963 12.3603H13.6095C13.4689 14.6828 12.7427 16.9307 11.4996 18.8919C10.2564 16.9307 9.53023 14.6828 9.38963 12.3603ZM9.38963 10.6397C9.53023 8.31725 10.2564 6.06926 11.4996 4.1081C12.7427 6.06926 13.4689 8.31725 13.6095 10.6397H9.38963Z'
        fill='inherit'
      />
    </Icon>
  );
};
