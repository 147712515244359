import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import restaurantsApi from 'apis/restaurants';
import AutoCompleteComponent from 'components/AutoComplete/AutoComplete';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearErrors, MergeRestaurantCompanyDialog } from 'redux/actions';
import { useDebounce } from 'use-lodash-debounce';
import IntlMessages from 'utils/intl-messages';

const MergeRestaurantDialog = (props) => {
  const close = () => {
    props.MergeRestaurantCompanyDialog({
      openMergeResDialog: false,
      confirmButton: 'merge.yes',
      cancelButton: '',
    });
  };

  const {
    dialog: {
      colorClass = 'primary',
      title,
      openMergeResDialog,
      confirmButton,
      cancelButton,
      fetchMergedData,
      countryCode,
      restaurantId,
    },
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantObj, setRestaurantObj] = useState({});

  const debouncedValue = useDebounce(inputValue, 500);

  const { supplierUserError } = errors;

  useEffect(() => {
    setInputValue('');
    setRestaurantObj({});
    setRestaurants([]);
    setErrors({});
  }, [openMergeResDialog]);

  /*  const onChangeSearch = value => {
    setSearch(value);
  };
  const onSelect = () => {
    setSelectedMergedUser(search);
    setMergedResId(restaurants.find(el => el.name === search).id)
  };*/

  const getRestaurants = () => {
    if (inputValue) {
      setSearchLoading(true);
      let filters = {
        filters: {
          nameEn: { $contL: inputValue },
          id: { ne: restaurantId },
          countryCode: countryCode !== 'GL' ? { eq: countryCode } : {},
        },
        sort: 'nameEn,ASC',
      };
      restaurantsApi
        .getRestaurants(filters)
        .then((res) => {
          setRestaurants(res.data);
          setSearchLoading(false);
          props.clearErrors();
        })
        .catch(() => {
          setSearchLoading(false);
          props.clearErrors();
        });
    }
  };

  /*  useEffect(() => {
    if(search.length === 0){
      setRestaurants([]);
      setSelectedMergedUser('');
    }
  }, [search]);*/

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      setErrors({});
    }
  }, [debouncedValue]);

  useEffect(() => {
    getRestaurants();
  }, [debouncedValue]);

  const updateValue = () => {
    if (restaurantObj && restaurantObj.id) fetchMergedData(restaurantObj.id);
    close();
  };

  const changeAutoCompleteTextField = (value) => {
    setInputValue(value);
    setRestaurantObj({ name: value });
  };

  return (
    <Fragment>
      <Dialog open={openMergeResDialog || false} onClose={() => close()}>
        <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title'>
          <IntlMessages id={`dialog.${title}`} />
        </DialogTitle>
        <DialogContent style={{ padding: '8px 60px', width: '550px' }}>
          <DialogContentText id='alert-dialog-description' className={supplierUserError ? 'error' : ''}>
            <IntlMessages id='dialog.mergeRestaurant.note' />
          </DialogContentText>
          <AutoCompleteComponent
            disableClearable={true}
            customClass={'min-width-300 pr-20'}
            getOptionSelected={(option, value) => option.id === value.id}
            loading={!!searchLoading}
            value={!_.isEmpty(restaurantObj) && restaurantObj}
            disabled={!!searchLoading}
            options={!_.isEmpty(restaurants) ? restaurants : []}
            getOptionLabel={(option) => option.name || ''}
            onChangeTextField={(e) => changeAutoCompleteTextField(e.target.value)}
            onChange={(e, value) => setRestaurantObj(value)}
            label={'restaurant.name'}
            inputValue={!_.isEmpty(restaurantObj) ? restaurantObj.name : inputValue}
            noOptionsText={<IntlMessages id={'global.options.noMatched'} />}
          />
        </DialogContent>
        <DialogActions className={colorClass ? 'red' : colorClass}>
          <Button onClick={close} color='primary'>
            {<IntlMessages id={cancelButton ? `button.${cancelButton}` : 'button.cancel'} />}
          </Button>
          <Button disabled={_.isEmpty(restaurantObj)} variant='contained' onClick={updateValue} color='primary'>
            {<IntlMessages id={confirmButton ? `button.${confirmButton}` : 'button.yes'} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(
  connect(mapStateToProps, { MergeRestaurantCompanyDialog, clearErrors })(MergeRestaurantDialog)
);
