import { Layout, Section } from 'layout';
import { PosIntegrationsListScreen } from 'pages/pos-integrations/screens/pos-integrations-list-screen';
import { PosIssuesListScreen } from 'pages/pos-integrations/screens/pos-issues-list-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const PosIntegrationsPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
          <Route path={`${match.url}/list`} component={PosIntegrationsListScreen} />
          <Route path={`${match.url}/issues`} component={PosIssuesListScreen} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default PosIntegrationsPage;
