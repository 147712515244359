import api from 'apis/otp';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_OTP_LIST,
  GET_OTP_LIST_FAILED,
  SET_LOADING_STATUS,
  SET_OTP,
  SET_PAGES_COUNT,
  SET_TOTAL_COUNT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getOtpList = (pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getOTPList({ pagination, filters })
    .then((response) => {
      dispatch({ type: GET_OTP_LIST, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_OTP_LIST_FAILED });
    });
};

export const getOTPById = (id, params) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getOTPById(id, params)
    .then((response) => {
      dispatch({ type: { SET_LOADING_STATUS }, payload: false });
      dispatch({ type: SET_OTP, payload: response.data });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: SET_OTP, payload: [] });
      dispatch({ type: SET_LOADING_STATUS, payload: false });
    });
};
