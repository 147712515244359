import { HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { PosIntegrationData } from 'apis/pos-integrations/use-get-pos-integrations-api';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';

export interface ActionsCellData {
  integration: PosIntegrationData;
}

export const ActionsCell = ({ integration }: ActionsCellData) => {
  const history = useHistory();

  const onIssuesClick = () => {
    history.push({
      pathname: '/app/pos-integrations/issues',
      search: `restaurantName=${integration.restaurant.nameEn}`,
    });
  };

  return (
    <HStack>
      <IconButtonWithTooltip
        Icon={<FaTriangleExclamation fontSize='20px' color='gray' />}
        label='Issues'
        aria-label='Issues'
        size='xs'
        variant='unstyled'
        onClick={onIssuesClick}
      />
    </HStack>
  );
};
