import {
  GET_BRANCHES_SUPPLIERS,
  GET_BRANCHES_SUPPLIERS_FAILED,
  GET_MERGED_DATA,
  GET_MERGED_DATA_FAILED,
  GET_MERGING_RESTAURANT_BRANCHES,
  GET_MERGING_RESTAURANT_BRANCHES_FAILED,
  GET_RESTAURANTS,
  GET_RESTAURANTS_FAILED,
  GET_RESTAURANTS_KPI,
  GET_RESTAURANTS_SUPPLIERS,
  GET_RESTAURANT_ADMIN_MANAGERS,
  GET_RESTAURANT_ADMIN_MANAGERS_FAILED,
  GET_RESTAURANT_BRANCHES,
  GET_RESTAURANT_BRANCHES_FAILED,
  GET_RESTAURANT_ORDERS_LIST,
  GET_RESTAURANT_ORDERS_LIST_FAILED,
  GET_RESTAURANT_SUPPLIER_EMAILS,
  GET_RESTAURANT_USERS,
  GET_RESTAURANT_USERS_FAILED,
  IS_SUCCESS_ASSIGN_ADMIN,
  SET_ANOTHER_LOADING,
  SET_BRANCH,
  SET_BRANCH_FAILED,
  SET_LOADING_STATUS,
  SET_MERGE_ERRORS,
  SET_RESTAURANT,
  SET_RESTAURANT_FAILED,
} from 'redux/types';

const INIT_STATE = {
  restaurantsList: [],
  restaurantOrdersList: [],
  restaurantBranches: [],
  restaurant: {},
  branch: {},
  restaurantUsersList: [],
  restaurantSuppliersList: [],
  restaurantSuppliersEmailsList: [],
  restaurantKpi: {},
  isLoading: false,
  isLoading2: false,
  adminsManagersUsers: [],
  isMerged: false,
  mergeErrors: {},
  isSuccessAssignAdmin: false,
  branchesSuppliers: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return { ...state, isLoading: action.payload };
    case SET_ANOTHER_LOADING:
      return { ...state, isLoading2: action.payload };
    case GET_RESTAURANTS:
      return { ...state, restaurantsList: action.payload, isLoading: false };
    case GET_RESTAURANTS_FAILED:
      return { ...state, restaurantsList: [], isLoading: false };
    case SET_RESTAURANT:
      return { ...state, restaurant: action.payload, isLoading: false };
    case SET_RESTAURANT_FAILED:
      return { ...state, restaurant: {}, isLoading: false };
    case SET_BRANCH:
      return { ...state, branch: action.payload, isLoading: false };
    case SET_BRANCH_FAILED:
      return { ...state, branch: {}, isLoading: false };
    case GET_RESTAURANT_USERS:
      return { ...state, restaurantUsersList: action.payload, isLoading: false };
    case GET_RESTAURANT_USERS_FAILED:
      return { ...state, restaurantUsersList: [] };
    case GET_RESTAURANTS_SUPPLIERS:
      return { ...state, restaurantSuppliersList: action.payload, isLoading: false };
    case GET_RESTAURANTS_KPI:
      return { ...state, restaurantKpi: action.payload, isLoading: false };
    case GET_RESTAURANT_ADMIN_MANAGERS:
      return { ...state, adminsManagersUsers: action.payload, isLoading: false };
    case GET_RESTAURANT_ADMIN_MANAGERS_FAILED:
      return { ...state, adminsManagersUsers: [], isLoading: false };
    case GET_RESTAURANT_BRANCHES_FAILED:
      return { ...state, restaurantBranches: [], isLoading: false };
    case GET_RESTAURANT_BRANCHES:
      return { ...state, restaurantBranches: action.payload, isLoading: false };
    case GET_MERGING_RESTAURANT_BRANCHES:
      return { ...state, restaurantMergingBranches: action.payload };
    case GET_MERGING_RESTAURANT_BRANCHES_FAILED:
      return { ...state, restaurantMergingBranches: [] };
    case GET_MERGED_DATA_FAILED:
      return { ...state, isMerged: false };
    case GET_MERGED_DATA:
      return { ...state, isMerged: true };
    case SET_MERGE_ERRORS:
      return { ...state, mergeErrors: action.payload };
    case IS_SUCCESS_ASSIGN_ADMIN:
      return { ...state, isSuccessAssignAdmin: action.payload };
    case GET_RESTAURANT_ORDERS_LIST:
      return { ...state, restaurantOrdersList: action.payload };
    case GET_RESTAURANT_ORDERS_LIST_FAILED:
      return { ...state, restaurantOrdersList: [], isLoading: false };
    case GET_RESTAURANT_SUPPLIER_EMAILS:
      return { ...state, restaurantSuppliersEmailsList: action.payload, isLoading: false };
    case GET_BRANCHES_SUPPLIERS:
      return { ...state, branchesSuppliers: action.payload, isLoading: false };
    case GET_BRANCHES_SUPPLIERS_FAILED:
      return { ...state, branchesSuppliers: [], isLoading: false };
    default:
      return { ...state };
  }
};
