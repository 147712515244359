const TwoItemsRow = (props) => {
  const { firstComponent, secondComponent } = props;
  return (
    <div className={'row'}>
      <div className={'col'}>{firstComponent}</div>
      <div className={'col'}>{secondComponent}</div>
    </div>
  );
};

export default TwoItemsRow;
