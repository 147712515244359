import { HStack, IconButtonWithTooltip, PenIcon } from '@elkaso-app/web-design';
import { RemoveCampaignButton } from 'pages/promotions/components/remove-campaign-button';
import { useHistory } from 'react-router-dom';

export interface CampaignActionsCellProps {
  id: string;
  isActive: boolean;
}
export const CampaignActionsCell = ({ id, isActive }: CampaignActionsCellProps) => {
  const history = useHistory();

  const handleGoToEditCampaign = () => {
    history.push(`/app/promotions/campaigns/edit/${id}`);
  };

  return (
    <HStack>
      <IconButtonWithTooltip
        onClick={handleGoToEditCampaign}
        size='sm'
        label='Edit'
        aria-label='edit-btn'
        Icon={<PenIcon />}
        variant='unstyled'
      />
      <RemoveCampaignButton id={id} isActive={isActive} />
    </HStack>
  );
};
