import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { HStack, Td, Tr } from '@elkaso-app/web-design';
import { DragHandle } from 'components/base-table/components';

interface IDraggableTableRow {
  row: any;
  enableDragging: boolean;
}

export const DraggableTableRow = ({ row, enableDragging }: IDraggableTableRow) => {
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <Tr ref={setNodeRef} style={style} {...row.getRowProps()}>
      {!!isDragging && <Td colSpan={row.cells.length}>&nbsp;</Td>}

      {!isDragging &&
        row.cells?.map((cell: any, i: any) => {
          if (i === 0) {
            return (
              <Td key={i} {...cell.getCellProps()}>
                <HStack spacing='md' align='center'>
                  {!!enableDragging && <DragHandle isDragging={false} {...attributes} {...listeners} />}
                  {cell.render('Cell')}
                </HStack>
              </Td>
            );
          }

          return (
            <Td key={i} {...cell.getCellProps()}>
              {cell.render('Cell')}
            </Td>
          );
        })}
    </Tr>
  );
};
