import { Icon, IconProps } from '@elkaso-app/web-design';

export const UserManagementIcon = (props: IconProps) => {
  return (
    <Icon width='22px' height='22px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M13.5984 13.2383C13.4824 13.1979 12.7495 12.8458 13.2075 11.3618H13.2009C14.3947 10.0513 15.307 7.94236 15.307 5.8661C15.307 2.6736 13.3154 1 11.0007 1C8.6846 1 6.70392 2.67282 6.70392 5.8661C6.70392 7.95091 7.61112 10.0684 8.81222 11.3758C9.2804 12.6849 8.44321 13.1707 8.26819 13.2383C5.84412 14.1735 3 15.8766 3 17.558V18.1884C3 20.4792 7.167 21 11.0233 21C14.8855 21 19 20.4792 19 18.1884V17.558C19 15.8261 16.142 14.1361 13.5984 13.2383ZM9.57575 20.0081C9.57575 18.0267 10.7593 15.3519 10.7593 15.3519L9.94038 14.6671C9.94038 14.0126 11 13.3277 11 13.3277C11 13.3277 12.0567 14.0234 12.0567 14.6671L11.2407 15.3519C11.2407 15.3519 12.4242 18.0166 12.4242 20.0299C12.4242 20.3455 9.57575 20.2724 9.57575 20.0081Z'
        fill='inherit'
      />
    </Icon>
  );
};
