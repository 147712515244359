import { LocalStorageKeys } from 'utils/constants';

const language = localStorage.getItem(LocalStorageKeys.language)
  ? localStorage.getItem(LocalStorageKeys.language)
  : localStorage.setItem(LocalStorageKeys.language, 'en');
const isAr = language === 'ar';
const lang = isAr
  ? {
      languageId: 'arabic',
      locale: 'ar',
      name: 'عربي',
      icon: 'ar',
    }
  : {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'en',
    };

const country = localStorage.getItem('country');
const countryCode = country ? { country: country, icon: country } : { country: 'GL', icon: 'GL' };

const AppConfig = {
  appLogo: require('assets/elkaso/logo.svg').default, // App Logo
  brandName: 'Alpha Apps', // Brand Name
  rtlLayout: isAr, // RTL Layout
  locale: lang,
  isBreadcrumb: true,
  copyRightText: 'AlphaApps © 2020 All Rights Reserved.', // Copy Right Text
  // Notifications timeout
  notificationTimeout: 2000,
  successNotificationTimeout: 2000,
  errorNotificationTimeout: 8000,
  errorTitle: 'notifications.error',
  successTitle: 'notifications.success',

  // Curreny
  currency: 'AED',

  //CountryCode
  countryCode,

  // Pagination Object
  paginationObject: {
    page: 1,
    rowsPerPage: 45,
    rowsPerPageOptions: [15, 30, 45],
    sort: '',
  },
};

export default AppConfig;
