import { Box, HStack, SystemStyleObject, Text } from '@elkaso-app/web-design';
import { Can } from 'configs/can';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActiveSectionIcon } from '../../icons/active-section-icon';
import { AddSubCategoryIcon } from '../../icons/add-sub-category-icon';
import { ClosedArrowIcon } from '../../icons/closed-arrow-icon';
import { ClosedSectionIcon } from '../../icons/closed-section-icon';
import { OpenArrowIcon } from '../../icons/open-arrow-icon';
import { OpenedSectionIcon } from '../../icons/opened-section-icon';
import { AddCategoryModal } from '.././product-category/add-category-modal';
import { MenuLevelComponent } from './menu-level-component';

const styleDetailsBefore: SystemStyleObject = {
  borderLeft: '1px solid #333',
  content: '""',
  left: '28px',
  position: 'absolute',
  top: 'xl',
  height: 'calc(100% - 55px)',
};

interface ILevel {
  id: number;
  name: string;
  children?: ReactNode;
  depth?: number;
  open?: boolean;
  isModal?: boolean;
  activeId: number;
  variant?: string;
  setActiveId: (id: number) => void;
}

export const LevelComponent = ({
  variant,
  open,
  depth,
  id,
  name,
  children,
  isModal,
  activeId,
  setActiveId,
}: ILevel) => {
  const params = useParams<any>();
  const [isOpen, setIsOpen] = useState(false);
  const ref: any = useRef(null);
  const history = useHistory();
  const handleClickOpen = () => {
    setIsOpen(!isOpen);
    if (!isModal) history.push(`/app/catalog/product-categories/${id}`);
    setActiveId(id);
  };

  useEffect(() => {
    if (open) {
      ref.current.open = true;
      setIsOpen(true);
    } else {
      ref.current.open = false;
      setIsOpen(false);
    }
  }, [open]);

  return (
    <Box ref={ref} pos={'relative'} _before={styleDetailsBefore} ms='lg' as='details'>
      <HStack
        py={'sm'}
        px={'md'}
        transition={'.3s'}
        bgColor={(activeId == id && isModal) || (params.categoryId == id && !isModal) ? '#dad2df66' : 'transparent'}
        _hover={{ backgroundColor: '#dad2df66' }}
        onClick={handleClickOpen}
        justifyContent={'space-between'}
        as='summary'>
        <HStack>
          {(activeId == id && isModal) || (params.categoryId == id && !isModal) ? (
            <ActiveSectionIcon />
          ) : !React.Children.count(children) ? (
            <ClosedSectionIcon />
          ) : isOpen && !isModal ? (
            <OpenedSectionIcon />
          ) : isOpen && isModal && (depth != 2 || variant == 'edit') ? (
            <OpenedSectionIcon />
          ) : (
            <ClosedSectionIcon />
          )}
          <Text>{name}</Text>
          {!React.Children.count(children) || ((depth == 2 || depth == 3) && variant != 'edit' && isModal) ? (
            ''
          ) : isOpen ? (
            <OpenArrowIcon />
          ) : (
            <ClosedArrowIcon />
          )}
        </HStack>

        {params.categoryId == id && !isModal && (
          <HStack
            onClick={(e) => {
              e.stopPropagation();
              ref.current.open = !ref.current.open;
              setIsOpen(!ref.current.open);
            }}>
            {depth != 3 && (
              <Can I='manage' a='CatalogCategory'>
                <AddCategoryModal title={name} variant={'add'} trigger={<AddSubCategoryIcon />} />
              </Can>
            )}
            <Can I='manage' a='CatalogCategory'>
              <MenuLevelComponent />
            </Can>
          </HStack>
        )}
      </HStack>

      {children}
    </Box>
  );
};
