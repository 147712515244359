import { createAuthorizationHeader, getAccessToken } from 'apis/config/http-service';
import axios from 'axios';
import { LocalStorageKeys } from 'utils/constants';

// Create instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/',
});

instance.defaults.headers.common['Authorization'] = createAuthorizationHeader(getAccessToken());

instance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response) {
      // [1] - Access denied
      if (error.response.status === 403) {
        window.location.href = '/access-denied';
      }

      // [2] - Unauthorized
      if (error.response.status === 401) {
        localStorage.removeItem(LocalStorageKeys.cms_user);
        localStorage.removeItem(LocalStorageKeys.access_token);
        localStorage.removeItem(LocalStorageKeys.expiredTime);
        window.location.href = '/';
      }

      // [3] - Expired session
      if (error.response.data.code === 107) {
        window.location.href = '/expired-session';
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
