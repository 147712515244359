import { HStack, IconProps, Text, useMultiStyleConfig, useTab, VStack } from '@elkaso-app/web-design';
import React from 'react';

interface IMediaButton {
  label: string;
  size: string;
  icon: (props: IconProps) => JSX.Element;
}

export const MediaButton = React.forwardRef<HTMLElement, IMediaButton>(
  ({ label, size, icon: IconComponent, ...rest }, ref) => {
    const tabProps = useTab({ ...rest, ref });
    const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <HStack __css={styles.tab} cursor='pointer' flex={1} {...tabProps}>
        <IconComponent fill={isSelected ? 'red.500' : '#757575'} />
        <VStack spacing='0' align='start'>
          <Text userSelect='none'>{label}</Text>
          <Text userSelect='none'>{size}</Text>
        </VStack>
      </HStack>
    );
  }
);
