import {
  Button,
  HStack,
  IconButtonWithTooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { CheckedItemType } from 'components/base-table';
import { BsToggleOn } from 'react-icons/bs';
import { useActivateDeactivateItemsApi } from '../../../../apis/supplier/supplier-items/use-bulk-activation-items-api';

interface ActivateDeactivateItemsButtonProps {
  checkedItems: CheckedItemType[];
  items: any;
}

export const ActivateDeactivateItemsButton = ({ checkedItems, items }: ActivateDeactivateItemsButtonProps) => {
  const { isLoading, mutate: activateDeactivateBulkItems } = useActivateDeactivateItemsApi();

  const { isOpen, onOpen, onClose } = useDisclosure();

  let isActive = true;
  checkedItems
    ?.filter((i) => i.isChecked)
    .forEach((checkedItem) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === checkedItem.id && items[i].isActive) {
          isActive = false;
          break;
        }
      }
    });
  const handleActivateDeactivateBulkItems = () => {
    const variables = {
      body: { ids: checkedItems?.filter((i) => i.isChecked)?.map((i) => i.id), isActive: isActive },
    };
    activateDeactivateBulkItems(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };
  return (
    <>
      <IconButtonWithTooltip
        label={isActive ? 'Activate Items' : 'Deactivate Items'}
        aria-label='remove-btn'
        isDisabled={!checkedItems.find((i) => i.isChecked)}
        onClick={onOpen}
        Icon={<BsToggleOn fontSize={19} fill='#757575' />}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{` ${isActive ? 'Activate' : 'Deactivate'} Selected Items!`}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text>{`Are you sure you want to ${isActive ? 'activate' : 'deactivate'} the selected items?`}</Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                onClick={handleActivateDeactivateBulkItems}
                isLoading={isLoading}>
                Ok
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
