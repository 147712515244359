import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@elkaso-app/web-design';
import { getMeEndpointIdentifier, useMeApi } from 'apis/users/use-me-api';
import { useUpdateUserApi } from 'apis/users/use-update-user-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type TInputs = {
  name: string;
};

const defaultValues: TInputs = {
  name: '',
};

export const ProfileTab = () => {
  const me = useGetQueryClientData(getMeEndpointIdentifier);
  const { isLoading: isUpdateUserLoading, mutate: updateUser } = useUpdateUserApi();
  const { refetch: refetchMeApi } = useMeApi({ enabled: false });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = () => {
    setValue('name', me?.name ?? '');
  };

  useEffect(() => {
    updateValues();
  }, []);

  const onSubmit = (data: TInputs) => {
    const variables = {
      userId: me.id,
      body: {
        name: data.name,
      },
    };

    updateUser(variables, {
      onSuccess: () => {
        refetchMeApi();
      },
    });
  };

  return (
    <Stack as='form' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl isRequired isInvalid={errors.name ? true : false}>
        <FormLabel>Name</FormLabel>
        <Input
          placeholder='Please enter your name'
          {...register('name', {
            required: 'Name is required.',
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isDisabled>
        <FormLabel>Email address</FormLabel>
        <Input type='email' value={me?.email} />
      </FormControl>

      <Button
        type='submit'
        colorScheme='red'
        variant='solid'
        isLoading={isUpdateUserLoading}
        alignSelf='flex-end'
        px='lg'
        size='lg'>
        Update
      </Button>
    </Stack>
  );
};
