import { Box, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from '@elkaso-app/web-design';
import { useGetCategoryDetailsApi } from 'apis/product-category/use-get-category-details-api';
import { useParams } from 'react-router-dom';
import { DeleteIcon } from '../../icons/delete-icon';
import { DotsIcon } from '../../icons/dots-icon';
import { EditIcon } from '../../icons/edit-icon';
import { MoveIcon } from '../../icons/move-icon';
import { AddCategoryModal } from '.././product-category/add-category-modal';
import { DeleteCategoryModal } from './delete-category-modal';
import { MoveCategoryModal } from './move-category-modal';
import { language } from './tree-view-section';

export const MenuLevelComponent = () => {
  const params = useParams<any>();
  const { data: value } = useGetCategoryDetailsApi(params.categoryId);

  return (
    <Box>
      <Menu placement='bottom-end'>
        <MenuButton onClick={(e) => e.stopPropagation()}>
          <DotsIcon />
        </MenuButton>

        <MenuList onClick={(e) => e.stopPropagation()}>
          {value?.children.length == 0 && value?.parentId && (
            <DeleteCategoryModal
              parentId={value?.parentId}
              title={language(value, 'en')}
              categoryId={params.categoryId}
              trigger={
                <MenuItem py='sm' icon={<DeleteIcon />}>
                  <Text>Delete Category</Text>
                </MenuItem>
              }
            />
          )}

          <MenuDivider m='0' />

          <AddCategoryModal
            title={language(value, 'en')}
            value={value}
            variant={'edit'}
            trigger={
              <MenuItem py='sm' icon={<EditIcon />}>
                <Text>Edit Category</Text>
              </MenuItem>
            }
          />

          <MenuDivider m='0' />
          {value?.parentId && (
            <MoveCategoryModal
              variant={'move'}
              trigger={
                <MenuItem py='sm' icon={<MoveIcon />}>
                  <Text>Move Category</Text>
                </MenuItem>
              }
            />
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};
