import {
  Box,
  BoxProps,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useCreateWarehouseApi } from 'apis/restaurants/use-create-warehouse-api';
import { useGetWarehouseByIdApi } from 'apis/restaurants/use-get-warehouse-by-id-api';
import { useUpdateWarehouseApi } from 'apis/restaurants/use-update-warehouse-api';
import { AddIcon } from 'pages/restaurants/icons/add-icon';
import { CloseIcon } from 'pages/restaurants/icons/close-icon';
import { EmailIcon } from 'pages/restaurants/icons/email-icon';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type TInputs = {
  name: {
    en: string;
    ar: string;
  };
  cutOffTime: string;
  email: string;
  emailList: string[];
  hasAutoApproval: boolean;
  landlineNumber: string;
  coordinates?: {
    lat: string;
    lng: string;
  };
  address: {
    en: string;
    ar: string;
  };
};

const defaultValues: TInputs = {
  name: {
    en: '',
    ar: '',
  },
  cutOffTime: '',
  email: '',
  emailList: [],
  hasAutoApproval: true,
  landlineNumber: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  address: {
    en: '',
    ar: '',
  },
};

export interface IAddWarehouseModalCell extends BoxProps {
  variant: 'add' | 'edit';
  trigger: JSX.Element;
  warehouseId?: string;
}

export const AddWarehouseModal = ({ variant, trigger, warehouseId = '' }: IAddWarehouseModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { isLoading: isCreateWarehouseLoading, mutate: createWarehouse } = useCreateWarehouseApi(restaurantId);
  const { isLoading: isUpdateWarehouseLoading, mutate: updateWarehouse } = useUpdateWarehouseApi(restaurantId);

  const isEdit = useMemo(() => {
    if (warehouseId && variant === 'edit') return true;
    return false;
  }, [warehouseId, variant]);

  const {
    isLoading: isWarehouseLoading,
    data: warehouse,
    refetch: refetchWarehouseData,
  } = useGetWarehouseByIdApi(restaurantId, warehouseId, {
    enabled: isEdit,
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const emailValue = watch('email');
  const emailListValue = watch('emailList');

  // Update values
  const updateValues = () => {
    setValue('name.en', warehouse?.name?.en ?? '');
    setValue('name.ar', warehouse?.name?.ar ?? '');

    setValue('address.en', warehouse?.address?.en ?? '');
    setValue('address.ar', warehouse?.address?.ar ?? '');

    setValue('cutOffTime', warehouse?.cutOffTime);

    setValue('email', '');
    setValue('emailList', warehouse?.emails ?? []);

    setValue('landlineNumber', warehouse?.landlineNumber ?? '');
    setValue('hasAutoApproval', warehouse?.hasAutoApproval ?? true);
  };

  useEffect(() => {
    updateValues();
  }, [isOpen]);

  const onSubmit = (data: TInputs) => {
    // Create new warehouse
    if (variant === 'add') {
      const variables = {
        body: {
          name: {
            en: data.name.en,
            ar: data.name.ar,
          },
          address: {
            en: data.address.en,
            ar: data.address.ar,
          },
          emails: data.emailList,
          cutOffTime: data.cutOffTime,
          landlineNumber: data.landlineNumber !== '' ? data.landlineNumber : null,
          hasAutoApproval: data.hasAutoApproval,
        },
      };

      createWarehouse(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }

    // Edit existing warehouse
    if (variant === 'edit') {
      const variables = {
        warehouseId: warehouse?.id,
        body: {
          name: {
            en: data.name.en,
            ar: data.name.ar,
          },
          address: {
            en: data.address.en,
            ar: data.address.ar,
          },
          emails: data.emailList,
          cutOffTime: data.cutOffTime,
          landlineNumber: data.landlineNumber !== '' ? data.landlineNumber : null,
          hasAutoApproval: data.hasAutoApproval,
        },
      };

      updateWarehouse(variables, {
        onSuccess: () => {
          refetchWarehouseData();
          onClose();
        },
      });
    }
  };

  const onEmailListAdd = () => {
    if (!emailValue) return;

    const isEmailExists = emailListValue?.find((email) => email === emailValue);
    if (isEmailExists) return setError('emailList', { message: 'Email already exists.' }, { shouldFocus: true });

    const isValidEmail = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailValue);
    if (!isValidEmail) return setError('emailList', { message: 'Email address is incorrect.' }, { shouldFocus: true });

    const isEmailLimitExceeded = emailListValue?.length === 5;
    if (isEmailLimitExceeded) {
      return setError('emailList', { message: 'Maximum number of emails are 5.' }, { shouldFocus: true });
    }

    setValue('emailList', [...emailListValue, emailValue], { shouldValidate: true });
    setValue('email', defaultValues.email);
  };

  const onEmailListRemove = (email: string) => {
    setValue(
      'emailList',
      emailListValue.filter((item) => item !== email),
      { shouldValidate: true }
    );
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => reset(defaultValues)}
        closeOnOverlayClick={false}
        size='3xl'>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='xs'>
              {!isEdit && (
                <>
                  <Heading as='h2' size='lg'>
                    Add New Warehouse
                  </Heading>
                  <Text color='gray.500' fontWeight='normal' fontSize='md'>
                    Fill the form below to create a new warehouse.
                  </Text>
                </>
              )}

              {!!isEdit && (
                <>
                  <Heading as='h2' size='lg'>
                    Edit Warehouse Details
                  </Heading>
                  <Text color='gray.500' fontWeight='normal' fontSize='md'>
                    Make sure to save the changes after editing the warehouse details.
                  </Text>
                </>
              )}
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack as='form' id='addCategoryModal' spacing='md' onSubmit={handleSubmit(onSubmit)} noValidate>
              <HStack align='start'>
                <FormControl isRequired isInvalid={errors?.name?.en ? true : false}>
                  <FormLabel>Warehouse Name (En)</FormLabel>
                  <Input
                    variant='outline'
                    placeholder='ex. Warehouse 1'
                    {...register('name.en', {
                      required: 'English name is required.',
                    })}
                  />

                  <FormErrorMessage>{errors.name?.en?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors?.name?.ar ? true : false}>
                  <FormLabel>Warehouse Name (Ar)</FormLabel>
                  <Input
                    variant='outline'
                    placeholder='مثال: مخزن 1'
                    dir='rtl'
                    textAlign='start'
                    {...register('name.ar', {
                      pattern: {
                        value: /^[\u0600-\u06FF\s0-9\u0660-\u0669\u06F0-\u06F9]+$/,
                        message: 'Please enter Arabic letters only.',
                      },
                    })}
                  />

                  <FormErrorMessage>{errors.name?.ar?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <HStack align='start'>
                <FormControl isRequired isInvalid={errors?.address?.en ? true : false}>
                  <FormLabel>Address (En)</FormLabel>
                  <Input
                    variant='outline'
                    placeholder='ex. Warehouse address 1'
                    {...register('address.en', {
                      required: 'English address is required.',
                    })}
                  />

                  <FormErrorMessage>{errors.address?.en?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors?.address?.ar ? true : false}>
                  <FormLabel>Address (Ar)</FormLabel>
                  <Input
                    variant='outline'
                    placeholder='مثال: عنوان مخزن 1'
                    dir='rtl'
                    textAlign='start'
                    {...register('address.ar', {
                      pattern: {
                        value: /^[\u0600-\u06FF\s0-9\u0660-\u0669\u06F0-\u06F9]+$/,
                        message: 'Please enter Arabic letters only.',
                      },
                    })}
                  />

                  <FormErrorMessage>{errors.address?.ar?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <FormControl isRequired isInvalid={errors.cutOffTime ? true : false}>
                <FormLabel>Cut-off Time</FormLabel>
                <Input
                  variant='outline'
                  placeholder='ex. 04:00 PM'
                  type='time'
                  {...register('cutOffTime', {
                    required: 'Cut -off time is required.',
                  })}
                />

                <FormErrorMessage>{errors.cutOffTime?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors?.landlineNumber ? true : false}>
                <FormLabel>Landline Number</FormLabel>
                <Input variant='outline' placeholder='ex. 04 888 2225' {...register('landlineNumber')} />

                <FormErrorMessage>{errors.landlineNumber?.message}</FormErrorMessage>
              </FormControl>

              <Controller
                control={control}
                name='emailList'
                render={() => (
                  <FormControl isInvalid={errors.emailList ? true : false}>
                    <FormLabel>Email List</FormLabel>

                    <InputGroup>
                      <Input
                        variant='outline'
                        placeholder='Add Email'
                        type='email'
                        {...register('email', {
                          onBlur: () => {
                            if (!emailValue) clearErrors('emailList');
                          },
                        })}
                      />

                      <InputRightElement cursor={emailValue ? 'pointer' : 'default'} onClick={onEmailListAdd}>
                        <AddIcon fill={emailValue ? '#E03E52' : 'gray.200'} />
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>{errors.emailList?.message}</FormErrorMessage>

                    <VStack spacing='sm' align='stretch' mt='md'>
                      {emailListValue?.map((email) => (
                        <Flex key={email} justify='space-between' align='center'>
                          <EmailIcon me='md' />
                          <Text flex={1}>{email}</Text>
                          <CloseIcon cursor='pointer' onClick={() => onEmailListRemove(email)} />
                        </Flex>
                      ))}
                    </VStack>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name='hasAutoApproval'
                render={({ field: { value, onChange, onBlur, name } }) => (
                  <Flex justify='space-between' align='center'>
                    <Box>
                      <Text color='black' fontWeight='600' fontSize='md'>
                        Auto-approval
                      </Text>
                      <Text color='gray.400' fontSize='sm'>
                        Orders will be confirmed directly and do not require approval.
                      </Text>
                    </Box>

                    <Switch
                      size='lg'
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as any}
                      isChecked={value}
                    />
                  </Flex>
                )}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>

              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addCategoryModal'
                isLoading={isCreateWarehouseLoading || isUpdateWarehouseLoading || isWarehouseLoading}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
