import {
  BoxProps,
  Button,
  ChakraSelect,
  FileUploader,
  formatDateTime,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UploadedFile,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useUploadFileApi } from 'apis/config/use-upload-file-api';
import { useDownloadImageApi } from 'apis/restaurants/restaurants-licenses/use-download-image-api';
import { useEditLicensesApi } from 'apis/restaurants/restaurants-licenses/use-edit-licenses-api';
import { DownloadIcon } from 'pages/restaurants/icons/download-icon';
import { FileIcon } from 'pages/restaurants/icons/file-icon';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const statusOptions = [
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
];

type TOption = {
  value: string;
  label: string;
};

type TInputs = {
  licenseNumber: string;
  expiryDate: string;
  status?: TOption;
};

const defaultValues: TInputs = {
  licenseNumber: '',
  expiryDate: '',
  status: undefined,
};

export interface ActionsModalProps extends BoxProps {
  variant: 'pending' | 'edit';
  actions: any;
  trigger: JSX.Element;
}

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const ActionsModal = ({ variant, trigger, actions }: ActionsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isImageModeActive, setIsImageModeActive] = useState(false);

  const isPending = variant === 'pending';

  const { mutate: updateLicense, isLoading } = useEditLicensesApi(actions?.id);
  const ext = actions?.url.split('.').pop();

  const { data: imageURL, mutate: uploadFileApi, isLoading: isLoadingImage } = useUploadFileApi();

  const { refetch: downloadImage, isFetching: isLoadingDownloadImage } = useDownloadImageApi(actions?.url, ext);

  const onUploadButton = (file: UploadedFile) => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file as File);

    const variables = {
      body: formData,
    };

    uploadFileApi(variables);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const updateValues = () => {
    setIsImageModeActive(false);
    reset({
      status: actions?.status
        ? {
            value: actions?.status,
            label: capitalizeFirstLetter(actions?.status),
          }
        : undefined,
      licenseNumber: actions?.licenseNumber ?? '',
      expiryDate: formatDateTime(actions?.expiryDate, 'YYYY-MM-DD') ?? '',
    });
  };

  useEffect(() => {
    updateValues();
  }, []);

  const onSubmit = (data: TInputs) => {
    const variables = {
      body: {
        url: isImageModeActive ? imageURL?.url : actions?.url,
        restaurantId: actions?.restaurantId,
        status: data?.status?.value,
        expiryDate: data?.expiryDate,
        licenseNumber: data?.licenseNumber,
      },
    };

    updateLicense(variables, {
      onSuccess: () => {
        onClose();
      },
    });
    reset(data);
  };

  const onCloseModal = () => {
    onClose();
    updateValues();
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onCloseModal} closeOnOverlayClick={false} size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isPending ? ' Review Trade License' : 'Edit Trade License Details'}
            <Text color='gray.500' fontWeight='normal' fontSize='md'>
              {isPending
                ? ' Please download and review the attached trade license document. Enter the license number and expiry date before approval.'
                : 'Here you can edit the trade license details, replace the document, and update the license number and expiry date as needed.'}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack as='form' id='ActionsModal' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <VStack spacing={'lg'}>
                {!isImageModeActive && (
                  <HStack bg={'gray.100'} borderRadius={'base'} justifyContent={'space-between'} p={'sm'} w={'full'}>
                    <HStack>
                      <FileIcon />
                      <Text>License.{ext}</Text>
                    </HStack>
                    <HStack>
                      <Button
                        variant='outline'
                        color={'#160C45'}
                        borderColor={'#160C45'}
                        rightIcon={<DownloadIcon />}
                        isLoading={isLoadingDownloadImage}
                        onClick={() => downloadImage()}>
                        Download File
                      </Button>
                      {!isPending && (
                        <Button
                          onClick={() => setIsImageModeActive(true)}
                          variant='outline'
                          isDisabled={isLoadingDownloadImage}
                          colorScheme='red'>
                          Replace File
                        </Button>
                      )}
                    </HStack>
                  </HStack>
                )}

                {isImageModeActive && (
                  <FileUploader
                    multiple={false}
                    acceptedTypes={['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']}
                    onRemove={() => {
                      setIsImageModeActive(false);
                    }}
                    onRemoveAll={() => {
                      setIsImageModeActive(false);
                    }}
                    onUpload={(files) => {
                      onUploadButton(files[0]);
                    }}
                  />
                )}

                {!isPending && (
                  <Controller
                    control={control}
                    name='status'
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormControl isInvalid={!!errors.status}>
                        <FormLabel>Status</FormLabel>

                        <ChakraSelect
                          placeholder='Select Status'
                          variant='outline'
                          options={statusOptions}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />

                        <FormErrorMessage>{errors?.status?.value?.message}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                )}

                <FormControl isInvalid={errors.licenseNumber ? true : false}>
                  <FormLabel>Trade License Number</FormLabel>
                  <Input placeholder='ex. 322882112' variant={'outline'} {...register('licenseNumber')} />

                  <FormErrorMessage>{errors.licenseNumber?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.expiryDate}>
                  <FormLabel>Expiry Date</FormLabel>
                  <Input variant={'outline'} type='date' {...register('expiryDate')} />
                  <FormErrorMessage>{errors.expiryDate?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              {isPending ? (
                <Button
                  onClick={() =>
                    setValue('status', {
                      value: 'rejected',
                      label: 'Rejected',
                    })
                  }
                  isDisabled={isLoading}
                  variant='ghost'
                  colorScheme='red'
                  type='submit'
                  form='ActionsModal'>
                  Reject
                </Button>
              ) : (
                <Button variant='ghost' colorScheme='red' onClick={onCloseModal}>
                  Cancel
                </Button>
              )}

              <Button
                onClick={() =>
                  isPending &&
                  setValue('status', {
                    value: 'approved',
                    label: 'Approved',
                  })
                }
                isDisabled={
                  isLoading || isLoadingImage || watch('licenseNumber')?.trim() === '' || !watch('expiryDate')
                }
                variant='solid'
                colorScheme='green'
                type='submit'
                form='ActionsModal'>
                {isPending ? 'Approve' : 'Save'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
