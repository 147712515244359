import {
  Box,
  Center,
  HStack,
  IconButtonWithTooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@elkaso-app/web-design';
import { RestaurantTransferComments } from 'pages/restaurant-transfers/components/restaurant-transfer-comments';
import { RestaurantTransferCommentsIcon } from '../icons/restaurant-transfer-comments-icon';

interface IRestaurantTransferCommentButton {
  transfer: Record<string, never>;
}

const RestaurantTransferCommentButton = ({ transfer }: IRestaurantTransferCommentButton) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box pos='relative'>
      {!!transfer?.commentsCount && (
        <Center
          pos='absolute'
          top={0}
          right={0}
          bgColor='red.500'
          h='15px'
          minW='15px'
          fontSize='2xs'
          color='white'
          borderRadius='full'
          zIndex={1}
          px='4px'
          pt='1px'
          shadow='md'>
          {transfer?.commentsCount > 99 ? '+99' : transfer?.commentsCount}
        </Center>
      )}

      <IconButtonWithTooltip
        label='Comments'
        aria-label='transfer-comments'
        Icon={<RestaurantTransferCommentsIcon />}
        variant='unstyled'
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transfer Comments</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RestaurantTransferComments restaurantTransferId={transfer?.id} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export interface IRestaurantTransferActionsCell {
  transfer: any;
}

export const RestaurantTransferActionsCell = ({ transfer }: IRestaurantTransferActionsCell) => {
  return (
    <HStack>
      <RestaurantTransferCommentButton transfer={transfer} />
    </HStack>
  );
};
