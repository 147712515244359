import {
  Button,
  ChakraAsyncSelect,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  useDisclosure,
} from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Option = {
  label: string;
  value: string;
  type?: string; // type is optional for the restaurant
};

type TInputs = {
  subEntity?: Option | null;
};

const defaultValues: TInputs = {
  subEntity: null,
};

type SubEntityDetailsProps = {
  restaurantId: string;
  subEntityId?: string;
  subEntityType?: string;
  name?: string;
};

const getSubEntityDetails = async ({ restaurantId, subEntityId, subEntityType, name }: SubEntityDetailsProps) => {
  const { data } = await http.get(`/ums/v1/restaurants/${restaurantId}/locations/all`, {
    params: {
      name,
      subEntityId,
      subEntityType,
    },
  });

  return data;
};

interface SubEntityIdFilterButtonProps {
  restaurantId: string;
}

export const SubEntityIdFilterButton = ({ restaurantId }: SubEntityIdFilterButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const { control, handleSubmit, watch, reset } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  const isFilterActive = useMemo(() => {
    return Boolean(params.subEntityId && params.subEntityType);
  }, [params.subEntityId, params.subEntityType]);

  const updateValues = async () => {
    const values: TInputs = {
      subEntity: null,
    };

    // Update sub-entity value
    if (restaurantId && params.subEntityId && params.subEntityType) {
      const { data } = await getSubEntityDetails({
        restaurantId: restaurantId,
        subEntityId: params.subEntityId as string,
        subEntityType: params.subEntityType as string,
      });

      values.subEntity = {
        label: data[0]?.name,
        value: data[0]?.id,
        type: data[0]?.type,
      };
    }

    if (!restaurantId || !params.subEntityId || !params.subEntityType) {
      values.subEntity = null;
    }

    reset(values);
  };

  useEffect(() => {
    updateValues();
  }, [restaurantId, params.subEntityId, params.subEntityType]);

  const onSubmit = (data: TInputs) => {
    setPageParams({
      ...params,
      subEntityId: data.subEntity?.value,
      subEntityType: data.subEntity?.type,
    });

    onClose();
    reset(data);
  };

  const handleClose = () => {
    updateValues();
    onClose();
  };

  const allFields = watch();

  const isFilterButtonDisabled = useMemo(() => {
    return Object.values(allFields).every((value) => !value);
  }, [allFields]);

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={isFilterActive}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='subEntity'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Branch</FormLabel>
                <ChakraAsyncSelect
                  id='subEntity'
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  loadOptions={(inputValue, callback) => {
                    getSubEntityDetails({ restaurantId, name: inputValue }).then(({ data }) => {
                      const values: Option[] = [];

                      data?.map((item: any) => {
                        values.push({ label: item?.name, value: item?.id, type: item?.type });
                      });

                      callback(values);
                    });
                  }}
                />
              </FormControl>
            )}
          />

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' isDisabled={isFilterButtonDisabled}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
