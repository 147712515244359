import environmentFeatures, { EnvironmentFeatures } from 'feature-flags/initial-data';
import { createContext, FC, ReactNode, useContext } from 'react';

const FeatureFlagsContext = createContext<EnvironmentFeatures>(environmentFeatures);

/*
 **************************
 * Get Object
 **************************
 */
export let getFeatureFlags = { ...environmentFeatures };

/*
 *****************
 * Provider
 *****************
 */
interface IFeatureFlagsProvider {
  children: ReactNode;
}

export const FeatureFlagsProvider: FC<IFeatureFlagsProvider> = ({ children }) => {
  // When we have an API to dynamically fetch the features we should call it here.
  // And use the coming data as value of context.
  // And check if data is not provided use features constant as fallback.
  // Check also for API isLoading and load the full page spinner until we fetch our data.
  // ...

  // Override getFeatureFlags value with API data value
  getFeatureFlags = { ...environmentFeatures };

  return <FeatureFlagsContext.Provider value={environmentFeatures}>{children}</FeatureFlagsContext.Provider>;
};

/*
 *****************
 * Hook
 *****************
 */
export const useFeatureFlags = (): EnvironmentFeatures => useContext(FeatureFlagsContext);

/*
 *****************
 * React Component
 *****************
 */
interface IFeatureFlags {
  /**
   * The name of feature
   */
  feature: keyof EnvironmentFeatures;
  /**
   * This flag will reverse the default result of the returned value
   */
  reverse?: boolean;
  /**
   * Any ReactNode elements
   */
  children?: ReactNode;
}

export const FeatureFlags: FC<IFeatureFlags> = ({ feature, reverse, children }) => {
  const features = useFeatureFlags();

  // Reverse
  if (reverse) {
    if (features[feature]) return null;
    return <>{children}</>;
  }

  // Default
  if (!features[feature]) return null;
  return <>{children}</>;
};
