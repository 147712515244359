import { Box, HStack, IconButtonWithTooltip, StackDivider, Text, VStack } from '@elkaso-app/web-design';
import dayjs from 'dayjs';
import { CopyIcon } from '../icons/copy-icon';
import { statusObj } from '../utils/restaurant-transfer-status';
import { RestaurantInfoCell } from './restaurant-info-cell';
import { SupplierInfoCell } from './supplier-info-cell';

interface IStatusBannerStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBannerStyleProps: IStatusBannerStyleProps = {
  approved: {
    color: '#71BD4D',
    bgColor: 'rgba(113, 189, 77, 0.08)',
  },
  modified: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  canceled: {
    color: '#110000',
    bgColor: 'rgba(17, 0, 0, 0.05)',
  },
  created: {
    color: '#160C45',
    bgColor: 'rgba(22, 12, 69, 0.08)',
  },
  rejected: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
  received: {
    color: '#5BC0DE',
    bgColor: 'rgba(91, 192, 222, 0.08)',
  },
};

interface IRestaurantTransferStatusBanner {
  code: string;
  createdAt: string;
  status: string;
}

const TransferStatusBanner = ({ code, createdAt, status }: IRestaurantTransferStatusBanner) => {
  return (
    <HStack justify='space-between' p='md' borderRadius='base' bgColor={statusBannerStyleProps[status]?.bgColor}>
      <Box>
        <Text fontWeight='500'>Transfer #{code}</Text>
        <Text>Creation Date: {dayjs(createdAt).format('DD-MM-YYYY')}</Text>
      </Box>
      <Text color={statusBannerStyleProps[status]?.color} fontWeight='500' fontSize='md'>
        {statusObj[status]?.name}
      </Text>
    </HStack>
  );
};

interface IInfoRow {
  title: string;
  body: string;
  enableBodyCopyButton?: boolean;
}

const InfoRow = ({ title, body, enableBodyCopyButton = false }: IInfoRow) => {
  const onCopy = () => {
    return navigator.clipboard.writeText(body);
  };

  return (
    <HStack spacing='sm'>
      <VStack spacing='2xs' align='start' flex={1}>
        <Text textTransform='capitalize' color='gray.500' fontSize='sm'>
          {title}
        </Text>
        <Text color='gray.800' fontSize='md' fontWeight='500'>
          {body ?? '---'}
        </Text>
      </VStack>

      {!!enableBodyCopyButton && (
        <IconButtonWithTooltip
          label='Copy'
          aria-label='copy'
          Icon={<CopyIcon />}
          onClick={onCopy}
          variant='ghost'
          disabled={!body}
        />
      )}
    </HStack>
  );
};

interface IRestaurantDetails {
  restaurantId: string;
  name: string;
  subEntity: string;
  phoneNumber: string;
}

const RestaurantDetails = ({ restaurantId, name, subEntity, phoneNumber }: IRestaurantDetails) => {
  return (
    <Box>
      <Text mb='xs' color='gray.600' fontWeight='bold'>
        Restaurant Details
      </Text>

      <RestaurantInfoCell
        restaurantId={restaurantId}
        name={name}
        subEntityName={subEntity}
        phoneNumber={phoneNumber}
        spacing='0'
      />
    </Box>
  );
};

interface ISupplierDetails {
  restaurantId: string;
  supplierId: string;
  name: string;
  supplierUserName: string;
  phoneNumber: string;
  isAutoApproved: boolean;
}

const SupplierDetails = ({
  restaurantId,
  supplierId,
  name,
  supplierUserName,
  phoneNumber,
  isAutoApproved,
}: ISupplierDetails) => {
  return (
    <Box>
      <Text mb='xs' color='gray.600' fontWeight='bold'>
        Supplier Details
      </Text>

      <SupplierInfoCell
        restaurantId={restaurantId}
        supplierId={supplierId}
        name={name}
        supplierUserName={supplierUserName}
        phoneNumber={phoneNumber}
        isAutoApproved={isAutoApproved}
        spacing='0'
      />
    </Box>
  );
};

interface IRestaurantTransferDetailsSection {
  transfer: any;
}

export const RestaurantTransferDetailsSection = ({ transfer }: IRestaurantTransferDetailsSection) => {
  return (
    <HStack spacing='0' divider={<StackDivider borderWidth='4px' borderColor='gray.100' />} align='start' flex={1}>
      <VStack spacing='md' flex={1} align='stretch' pt='md' pe='md' minW='400px'>
        <TransferStatusBanner code={transfer?.code} createdAt={transfer?.createdAt} status={transfer?.status} />
        <InfoRow title='Invoice Number' body={transfer?.invoiceNumber} enableBodyCopyButton />
        <InfoRow title='Delivery Details' body={transfer?.restaurant?.subEntity?.address} enableBodyCopyButton />
        <InfoRow title='Expected Delivery Date' body={dayjs(transfer?.deliveryDate).format('DD-MM-YYYY')} />
        <InfoRow title='Delivery Instructions / Notes' body={transfer?.note} />
      </VStack>

      <VStack spacing='lg' pt='md' ps='md' minW='250px' align='stretch'>
        <RestaurantDetails
          restaurantId={transfer?.restaurant?.id}
          name={transfer?.restaurant?.name}
          subEntity={transfer?.restaurant?.subEntity?.name}
          phoneNumber={transfer?.restaurant?.user?.phoneNumber}
        />

        <SupplierDetails
          restaurantId={transfer?.restaurant?.id}
          supplierId={transfer?.supplier?.id}
          name={transfer?.supplier?.name}
          supplierUserName={transfer?.supplier?.user?.name}
          phoneNumber={transfer?.supplier?.user?.phoneNumber}
          isAutoApproved={transfer?.isAutoApproved}
        />

        <HStack spacing='lg'>
          <InfoRow title='Cut-off Time' body={transfer?.supplier?.cutOffTime || '---'} />
        </HStack>
      </VStack>
    </HStack>
  );
};
