import { Avatar, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@elkaso-app/web-design';
import { getMeEndpointIdentifier } from 'apis/users/use-me-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { logoutUser } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

export const UserMenu = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const me = useGetQueryClientData(getMeEndpointIdentifier);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const handleGoToProfile = () => {
    history.push(`/app/profile/user-profile`);
  };

  return (
    <Menu>
      <MenuButton>
        <Avatar name={me?.name} size='md' bg='red.500' color='white' />
      </MenuButton>

      <MenuList>
        <MenuItem icon={<Icon as={AiOutlineUser} />} onClick={handleGoToProfile}>
          Profile
        </MenuItem>
        <MenuDivider />
        <MenuItem icon={<Icon as={AiOutlineLogout} />} onClick={handleLogout}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
