import { Box, formatDateTime, Text } from '@elkaso-app/web-design';

export interface IDateTimeCell {
  date: string;
  isDateOnly?: boolean;
  isTimeOnly?: boolean;
}

export const DateTimeCell = ({ date, isDateOnly, isTimeOnly }: IDateTimeCell) => {
  if (!date) return <Text>---</Text>;

  if (isDateOnly) {
    return <Text>{formatDateTime(date, 'DD-MM-YYYY')}</Text>;
  }

  if (isTimeOnly) {
    return <Text>{formatDateTime(date, 'hh:mm A')}</Text>;
  }

  return (
    <Box>
      <Text>{formatDateTime(date, 'DD-MM-YYYY')}</Text>
      <Text>{formatDateTime(date, 'hh:mm A')}</Text>
    </Box>
  );
};
