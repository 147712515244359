import { Box, Center, Divider, Spinner, Text, VStack } from '@elkaso-app/web-design';
import { useGetRestaurantTransferCommentsApi } from 'apis/restaurant-transfers/use-get-restaurant-transfer-comments-api';
import _orderBy from 'lodash/orderBy';
import { AddRestaurantTransferCommentInput } from 'pages/restaurant-transfers/components/add-restaurant-transfer-comment-input';
import { RestaurantTransferComment } from 'pages/restaurant-transfers/components/restaurant-transfer-comment';

interface IRestaurantTransferComments {
  restaurantTransferId: string;
}

export const RestaurantTransferComments = ({ restaurantTransferId }: IRestaurantTransferComments) => {
  const { isLoading, isFetching, data: comments } = useGetRestaurantTransferCommentsApi(restaurantTransferId);
  const sortedComments = _orderBy(comments, ['createdAt'], ['asc']);

  return (
    <Box>
      {!isLoading && !isFetching && !comments?.length && (
        <Box textAlign='center' color='gray.500' py='md'>
          <Text>Comments box is empty!</Text>
          <Text>Start adding your fist comment from below</Text>
        </Box>
      )}

      {!isLoading && !isFetching && !!comments?.length && (
        <VStack spacing='md' align='start' maxH='191px' overflow='auto'>
          {sortedComments?.map((comment: any) => (
            <RestaurantTransferComment
              key={comment?.id}
              createdBy={comment?.author?.name}
              createdAt={comment?.createdAt}
              message={comment?.comment}
            />
          ))}
        </VStack>
      )}

      {(isLoading || isFetching) && (
        <Center mt='md'>
          <Spinner color='red.500' />
        </Center>
      )}

      <Divider my='md' borderWidth='2px' />

      <AddRestaurantTransferCommentInput restaurantTransferId={restaurantTransferId} />
    </Box>
  );
};
