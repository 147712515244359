import { Icon, IconProps } from '@elkaso-app/web-design';

export const ClearFilter = (props: IconProps) => {
  return (
    <Icon width='22px' height='20px' viewBox='0 0 22 20' fill='#757575' {...props}>
      <path
        d='M12.7318 17.83L15.5653 15L12.7318 12.17L14.1386 10.76L16.9621 13.57L19.7856 10.76L21.1924 12.17L18.3888 15L21.1924 17.83L19.7856 19.24L16.9621 16.4L14.1386 19.24L12.7318 17.83V17.83ZM9.97815 9V16.88C10.0181 17.18 9.91829 17.5 9.68882 17.71C9.59652 17.8027 9.48688 17.8763 9.36618 17.9264C9.24549 17.9766 9.1161 18.0024 8.98544 18.0024C8.85477 18.0024 8.72538 17.9766 8.60469 17.9264C8.48399 17.8763 8.37436 17.8027 8.28205 17.71L6.27667 15.7C6.16785 15.5934 6.08511 15.463 6.03492 15.319C5.98473 15.175 5.96844 15.0213 5.98733 14.87V9H5.9574L0.210623 1.62C0.0486042 1.41153 -0.0245022 1.14726 0.00727879 0.88493C0.0390598 0.622602 0.173144 0.383546 0.380233 0.22C0.569797 0.08 0.779315 0 0.99881 0H14.9667C15.1862 0 15.3957 0.08 15.5853 0.22C15.7923 0.383546 15.9264 0.622602 15.9582 0.88493C15.99 1.14726 15.9169 1.41153 15.7549 1.62L10.0081 9H9.97815Z'
        fill='inherit'
      />
    </Icon>
  );
};
