import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import queryString from 'query-string';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

const endpoint = 'catalog/v1/legacy-products/download-csv';
export const downloadItemsCSVFileEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = [
  string,
  {
    supplierId: string;
    restaurantId: string;
    subEntityId: string;
    subEntityType: string;
  }
];

type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

function constructEndpoint(query: TQueryKey['1']) {
  const stringifiedQuery = queryString.stringify(query, { arrayFormat: 'bracket' });
  return `${endpoint}/?${stringifiedQuery}`;
}

export function useDownloadItemsCSVFileApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const { restaurantId, warehouseId, supplierId } = useParams<{
    supplierId: string;
    restaurantId: string;
    warehouseId: string;
  }>();

  const fullEndpoint = constructEndpoint({
    supplierId,
    restaurantId,
    subEntityId: warehouseId,
    subEntityType: 'warehouse',
  });

  const queryFn = async () => {
    try {
      await http
        .get(fullEndpoint, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          fileDownload(response.data, `WH_catalog_CSV_template.csv`);
        });
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper(
    [
      fullEndpoint,
      {
        supplierId,
        restaurantId,
        subEntityId: warehouseId,
        subEntityType: 'warehouse',
      },
    ],
    queryFn,
    {
      enabled: false,
      ...options,
    }
  );
}
