import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  formatDateTime,
  FullPageSpinner,
  Heading,
  HStack,
  Text,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useGetTicketByIdApi } from 'apis/ticket-management';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

interface ITicketDetailsItem extends BoxProps {
  title: string;
  value: string;
  onClick?(): void;
}

const TicketDetailsItem = ({ title, value, onClick, ...rest }: ITicketDetailsItem) => {
  const isValidOnClick = value && onClick;
  return (
    <Box {...rest}>
      <Text color='gray.500' fontSize='md'>
        {title}:
      </Text>

      <Text
        color={isValidOnClick ? 'red.500' : 'gray.500'}
        cursor={isValidOnClick ? 'pointer' : 'text'}
        fontWeight={'bold'}
        mt='2xs'
        fontSize='md'
        whiteSpace='pre-line'
        onClick={isValidOnClick ? onClick : undefined}>
        {value ? value : '---'}
      </Text>
    </Box>
  );
};

interface ITicketInfoItem extends FlexProps {
  title: string;
  value: string;
}

const TicketInfoItem = ({ title, value, ...rest }: ITicketInfoItem) => {
  return (
    <Flex justifyContent='space-between' {...rest}>
      <Text fontSize='md' fontWeight='500'>
        {title}:
      </Text>
      <Text fontSize='md' fontWeight='500' color='gray.600'>
        {value ? value : '---'}
      </Text>
    </Flex>
  );
};

export const TicketDetailsScreen = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const { isLoading, isFetching, data } = useGetTicketByIdApi(ticketId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeTitle('Ticket Details'));
  }, []);

  const onViewTicketClick = (url: string) => {
    return window.open(url);
  };

  if (isLoading || isFetching) return <FullPageSpinner />;
  return (
    <HStack h='100%' align='stretch'>
      <WhiteCard flex={0.65} p='lg' overflow={'auto'}>
        <Heading as='h1' size='lg' mb='xl'>
          Ticket Details
        </Heading>

        <VStack spacing='md' align='start'>
          <TicketDetailsItem title='Ticket Category' value={data?.category} />

          <Box
            w='100%'
            h='1px'
            bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23565656FF' stroke-width='4' stroke-dasharray='3%2c 17' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");`}
          />

          <TicketDetailsItem
            title='Order Code'
            value={data?.customFields?.ordercode}
            onClick={() => window.open(data?.customFields?.linkOrderDetail)}
          />

          <TicketDetailsItem
            title='Onboarding Type'
            value={data?.customFields?.onboardingType}
            onClick={() => window.open(data?.customFields?.brandOrSupplierLink)}
          />

          <TicketDetailsItem title='Supplier Allocation' value={data?.customFields?.supplierAllocation} />

          <TicketDetailsItem title='CMS Update Purpose' value={data?.customFields?.cmsUpdatePurpose} />

          <TicketDetailsItem title='Catalog Related' value={data?.customFields?.catalogRelated} />

          <TicketDetailsItem title='Order Issue Type' value={data?.customFields?.orderIssueType} />

          <TicketDetailsItem
            title='Additional Suppliers Or Branches'
            value={data?.customFields?.additionalSuppliersOrBranches}
          />

          <TicketDetailsItem title='Delivery Reason' value={data?.customFields?.deliveryReason} />

          <TicketDetailsItem title='Payment Mode' value={data?.customFields?.paymentMode} />

          <TicketDetailsItem title='Department' value={data?.department?.name} />

          <TicketDetailsItem title='Additional Info' value={data?.customFields?.additionalInfo} />
        </VStack>
      </WhiteCard>

      <WhiteCard flex={0.35} p='lg'>
        <Heading as='h1' size='lg' mb='xl'>
          Ticket Info
        </Heading>

        <WhiteCard p='md'>
          <VStack spacing='lg' align='stretch'>
            <TicketInfoItem title='Ticket No' value={data?.ticketNumber} />
            <TicketInfoItem title='Status' value={data?.status} />
            <TicketInfoItem title='Created by' value={data?.contact?.firstName} />
            <TicketInfoItem title='Created on' value={formatDateTime(data?.createdTime)} />
            <TicketInfoItem title='Assigned to' value={data?.assignee?.firstName} />
            <TicketInfoItem title='Closed on' value={formatDateTime(data?.closedTime)} />
          </VStack>
        </WhiteCard>

        <Button colorScheme='red' mt='lg' w='100%' size='lg' onClick={() => onViewTicketClick(data?.webUrl)}>
          View Ticket
        </Button>
      </WhiteCard>
    </HStack>
  );
};
