import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useUnassignSupplierApi } from 'apis/restaurants/warehouse-suppliers/use-unassign-supplier-api';
import { cloneElement } from 'react';
import { useParams } from 'react-router-dom';

interface IDetachSupplierModal {
  trigger: JSX.Element;
  supplierId: string;
  name: string;
}

export const DetachSupplierModal = ({ trigger, supplierId, name }: IDetachSupplierModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { restaurantId, warehouseId } = useParams<{ restaurantId: string; warehouseId: string }>();
  const { isLoading: isUnassignSupplierLoading, mutate: unassignSupplierApi } = useUnassignSupplierApi(
    restaurantId,
    warehouseId
  );

  const onDetachClick = () => {
    const variables = {
      supplierId: supplierId,
    };

    unassignSupplierApi(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      {cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detach Supplier?</ModalHeader>

          <ModalBody>
            <Text fontSize='md' color='gray.600'>
              Are you sure you want to detach <Text as='b'>{name}</Text>?
            </Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>

              <Button variant='solid' colorScheme='red' onClick={onDetachClick} isLoading={isUnassignSupplierLoading}>
                Detach
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
