import { Icon, IconProps } from '@elkaso-app/web-design';

export const FilterIcon = (props: IconProps) => {
  return (
    <Icon width='14px' height='14px' viewBox='0 0 16 16' fill='#757575' {...props}>
      <path
        d='M0.212784 1.61C2.24405 4.2 5.99485 9 5.99485 9V15C5.99485 15.55 6.44735 16 7.00042 16H9.01157C9.56464 16 10.0171 15.55 10.0171 15V9C10.0171 9 13.7579 4.2 15.7892 1.61C15.9044 1.46237 15.9757 1.28553 15.9948 1.09961C16.0139 0.913684 15.9802 0.726154 15.8974 0.558371C15.8146 0.390588 15.6861 0.249293 15.5265 0.150577C15.3669 0.0518598 15.1827 -0.000312035 14.9948 1.40404e-06H1.00719C0.172561 1.40404e-06 -0.30006 0.950001 0.212784 1.61Z'
        fill='inherit'
      />
    </Icon>
  );
};
