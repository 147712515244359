import {
  Button,
  ButtonProps,
  FullPageSpinner,
  HStack,
  StackProps,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useDownloadLpoApi } from 'apis/orders/use-download-lpo-api';
import { useGetOrderDetailsApi } from 'apis/orders/use-get-order-details-api';
import { ability } from 'configs/ability';
import React from 'react';
import { useParams } from 'react-router-dom';
import { OrderComments } from '../components/order-comments';
import { OrderDetailsSection } from '../components/order-details-section';
import { OrderInvoiceModal } from '../components/order-invoice-modal';
import { OrderItemsTable } from '../components/order-items-table';
import { OrderLifeTimeModal } from '../components/order-lifetime-modal';
import { ResendEmailModal } from '../components/resend-email-modal';
import { RevertOrderModal } from '../components/revert-order-modal';

interface ISectionHeader extends StackProps {
  sectionName: string;
  buttonArray?: ButtonProps[];
}

const SectionHeader = ({ sectionName, buttonArray, ...rest }: ISectionHeader) => {
  return (
    <HStack spacing='sm' pb='md' mb='md' borderBottom='4px solid' borderColor='gray.100' {...rest}>
      <Text fontSize='xl' fontWeight='bold' me='auto' textTransform='capitalize'>
        {sectionName}
      </Text>

      {buttonArray?.map(({ children, title, ...props }, i) => {
        if (title) {
          return (
            <Tooltip key={i} label={title}>
              <Button {...props}>{children}</Button>
            </Tooltip>
          );
        }

        return (
          <Button key={i} {...props}>
            {children}
          </Button>
        );
      })}
    </HStack>
  );
};

export const OrderDetailsScreen = () => {
  const { code } = useParams<{ code: string }>();
  const { isOpen: isOpenLifetimeModal, onClose: onCloseLifetimeModal, onOpen: onOpenLifetimeModal } = useDisclosure();

  const {
    isOpen: isOpenRevertOrderModal,
    onClose: onCloseRevertOrderModal,
    onOpen: onOpenRevertOrderModal,
  } = useDisclosure();

  const { isOpen: isOpenEmailModal, onClose: onCloseEmailModal, onOpen: onOpenEmailModal } = useDisclosure();

  const { isOpen: isOpenInvoiceModal, onClose: onCloseInvoiceModal, onOpen: onOpenInvoiceModal } = useDisclosure();

  const { isLoading, isFetching, data: orderData } = useGetOrderDetailsApi(code);
  const {
    isLoading: isLoadingDownloadLpo,
    isFetching: isFetchingDownloadLpo,
    refetch: downloadLpoApi,
  } = useDownloadLpoApi(orderData?.id, orderData?.code);

  /**
   * This function made for re-formatting the orderData response
   * because we have one missing status and we have to do come calculations logic to find it,
   * so instead of making some mess in the frontend code I will do the logic here and update the response
   * as I expected to get it from backend .. what to do :|
   */
  const _orderData = React.useMemo(() => {
    if (!orderData) return;

    // Clone order
    const _order = JSON.parse(JSON.stringify(orderData));

    // If order modified
    if (_order?.isModifiedBySupplier) {
      // Update order status value
      if (_order?.status === 'approved') {
        _order.status = 'modified';
      }
    }

    // Correct canceled status and history
    if (_order?.status === 'canceled' || _order?.status === 'rejected') {
      _order.status = 'canceled';

      const historyRejectedStatusIndex = _order?.history?.findIndex((item: any) => item.status === 'rejected');

      if (_order?.history?.[historyRejectedStatusIndex]) {
        _order.history[historyRejectedStatusIndex].status = 'canceled';
      }

      // Add rejected status
      if (_order?.lastUpdatedByUser?.id === _order?.supplier?.user?.id) {
        _order.status = 'rejected';

        const historyCanceledStatusIndex = _order?.history?.findIndex((item: any) => item.status === 'canceled');

        if (_order?.history?.[historyCanceledStatusIndex]) {
          _order.history[historyCanceledStatusIndex].status = 'rejected';
        }
      }
    }

    return _order;
  }, [orderData]);

  if (isLoading || isFetching) return <FullPageSpinner />;

  let arr = [
    {
      children: 'Revert Order',
      variant: 'outline',
      colorScheme: 'red',
      onClick: onOpenRevertOrderModal,
    },
    {
      children: 'Order Lifetime',
      variant: 'outline',
      colorScheme: 'red',
      onClick: onOpenLifetimeModal,
    },
    {
      children: 'Resend Email',
      variant: 'solid',
      colorScheme: 'red',
      onClick: onOpenEmailModal,
      disabled: _orderData?.status !== 'restaurant_placed',
    },
  ];

  const currentStatus = orderData?.status;
  const history = orderData?.history || [];
  let previousStatus = currentStatus;

  // Find the previous status that is different from the current status
  for (let i = history.length - 1; i >= 0; i--) {
    if (history[i].status !== currentStatus) {
      previousStatus = history[i].status;
      break;
    }
  }

  const hasOrderLifetime =
    (currentStatus === 'canceled' || currentStatus === 'received' || currentStatus === 'approved') &&
    previousStatus !== currentStatus &&
    (previousStatus === 'approved' || previousStatus === 'restaurant_placed') &&
    ability.can('manage', 'Order');

  arr = arr.filter((i) => {
    if (hasOrderLifetime) return i; // Return all buttons
    return i.children !== 'Revert Order'; // Remove revert order button only
  });

  return (
    <>
      <HStack spacing='md' align='stretch' h='full'>
        <VStack flex={1} align='stretch' spacing='md'>
          <WhiteCard p='md' flex={1} display='flex' flexDir='column'>
            <SectionHeader sectionName='Order Details' buttonArray={arr} mb='0' />

            <OrderDetailsSection order={_orderData} />
          </WhiteCard>

          <WhiteCard p='md'>
            <SectionHeader sectionName='Order Comments' />
            <OrderComments orderId={_orderData?.id} />
          </WhiteCard>
        </VStack>

        <WhiteCard p='md' flex={-1} flexDir='column' display='flex'>
          <SectionHeader
            sectionName='Order Items'
            buttonArray={[
              {
                children: 'View attached invoices',
                colorScheme: 'red',
                variant: 'outline',
                disabled: !_orderData?.invoiceImages?.length,
                px: 'sm',
                onClick: onOpenInvoiceModal,
              },
              {
                children: 'Download LPO',
                variant: 'solid',
                colorScheme: 'red',
                onClick: () => downloadLpoApi(),
                isLoading: isLoadingDownloadLpo || isFetchingDownloadLpo,
              },
            ]}
          />

          <OrderItemsTable
            items={_orderData?.orderItems}
            totalItems={_orderData?.orderItems?.length}
            subtotal={_orderData?.subtotal}
            vat={_orderData?.vatValue}
            vatPercent={_orderData?.vatPercent}
            total={_orderData?.total}
            currencyCode={_orderData?.currencyCode}
            orderStatus={_orderData?.status}
          />
        </WhiteCard>
      </HStack>

      <RevertOrderModal
        isOpen={isOpenRevertOrderModal}
        onClose={onCloseRevertOrderModal}
        orderId={_orderData?.id}
        previousStatus={previousStatus}
      />

      <OrderLifeTimeModal isOpen={isOpenLifetimeModal} onClose={onCloseLifetimeModal} history={_orderData?.history} />

      <ResendEmailModal isOpen={isOpenEmailModal} onClose={onCloseEmailModal} orderId={_orderData?.id} />

      <OrderInvoiceModal isOpen={isOpenInvoiceModal} onClose={onCloseInvoiceModal} images={_orderData?.invoiceImages} />
    </>
  );
};
