import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'ums/v1/restaurants/sams';
export const getDownloadRestaurantSAMsFileEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [string];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

function constructEndpoint(restaurantId: string, supplierId: string) {
  return `${endpoint}/${restaurantId}/${supplierId}/download`;
}

export function useGetDownloadRestaurantSAMsFileApi(
  restaurantId: string,
  supplierId: string,
  restaurantName: string,
  options?: Options
): ReturnType {
  const toast = useCustomToast();
  const fullEndpoint = constructEndpoint(restaurantId, supplierId);

  const queryFn = async () => {
    try {
      await http
        .get(fullEndpoint, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          fileDownload(response.data, `restaurant-${restaurantName}-sams.xls`);
        });
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      // Important to not show the toast in unauthorized case.
      if (error.response?.status === 401) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([fullEndpoint], queryFn, {
    enabled: false,
    ...options,
  });
}
