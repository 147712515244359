import { Text, VStack } from '@elkaso-app/web-design';

export interface ISupplierCell {
  name: string;
  type: string;
}

export const SupplierCell = ({ name, type }: ISupplierCell) => {
  return (
    <VStack align='start' spacing='xs'>
      <Text fontSize='sm' color='black'>
        {name}
      </Text>
      <Text fontSize='sm'>{type}</Text>
    </VStack>
  );
};
