import { Icon, IconProps } from '@chakra-ui/react';

export const UpArrowIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#fff' {...props}>
      <g clipPath='url(#clip0_1819_11957)'>
        <path d='M9 16H15V10H19L12 3L5 10H9V16ZM5 18H19V20H5V18Z' fill='inherit' />
      </g>
      <defs>
        <clipPath id='clip0_1819_11957'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
