import { HStack, IconButtonWithTooltip, PenIcon } from '@elkaso-app/web-design';
import { RestaurantUser } from 'apis/restaurants/restaurant-users/types';
import { useParams } from 'react-router-dom';
import { AddUserModal } from './add-user-modal';

export interface ResUsersActionsCellProps {
  user: RestaurantUser;
}

export const ResUsersActionsCell = ({ user }: ResUsersActionsCellProps) => {
  const { restaurantId } = useParams<{ restaurantId: string }>();

  return (
    <HStack>
      <AddUserModal
        variant='edit'
        restaurantId={restaurantId}
        user={user}
        trigger={
          <IconButtonWithTooltip
            Icon={<PenIcon fontSize='20px' color='gray' />}
            label='Edit'
            aria-label='edit'
            size='xs'
            variant='unstyled'
          />
        }
      />
    </HStack>
  );
};
