import api from 'apis/invitationRequest';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_INVITATION,
  GET_INVITATION_FAILED,
  GET_INVITATION_REQUESTS,
  GET_INVITATION_REQUESTS_FAILED,
  SET_LOADING_STATUS,
  SET_PAGES_COUNT,
  SET_TOTAL_COUNT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getInvitationRequests = (pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getInvitationRequests({ pagination, filters })
    .then((response) => {
      dispatch({ type: GET_INVITATION_REQUESTS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_INVITATION_REQUESTS_FAILED });
    });
};

export const getInvitationById = (id) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getInvitationById(id)
    .then((response) => {
      dispatch({ type: GET_INVITATION, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_INVITATION_FAILED });
    });
};

export const updateInvitation = (invitation) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .updateInvitation(invitation)
    .then((invitation) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: GET_INVITATION, payload: invitation });
      return Promise.resolve(invitation);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: GET_INVITATION_FAILED });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};

export const saveAndNotify = (invitation) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .saveAndNotify(invitation)
    .then((invitation) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.success(
        <IntlMessages id={'global.success.message.saveAndNotify'} />,
        <IntlMessages id={`${AppConfig.successTitle}`} />,
        AppConfig.successNotificationTimeout
      );
      return Promise.resolve(invitation);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};
