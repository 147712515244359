import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  SwitchProps,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useUpdateCategoryApi } from 'apis/product-category/use-update-category-api';
import { Can } from 'configs/can';
import { useParams } from 'react-router-dom';

export interface IActivationSwitchCell extends SwitchProps {
  categoryId: number;
  isActive: boolean;
}

export const ActivationSwitch = ({ categoryId, isActive, ...rest }: IActivationSwitchCell) => {
  const params = useParams<any>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isUpdateCategoryLoading, mutate: updateCategory } = useUpdateCategoryApi(params?.categoryId);

  const handleActivateCategory = () => {
    const variables = {
      categoryId: categoryId,
      body: {
        status: isActive ? 'inactive' : 'active',
      },
    };
    updateCategory(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <FormControl display='flex' alignItems='center'>
        <Switch id={`active-category-${categoryId}`} onChange={onOpen} isChecked={isActive} {...rest} />
        <FormLabel
          htmlFor={`active-category-${categoryId}`}
          mb='0'
          ms='sm'
          userSelect='none'
          fontWeight='normal'
          fontSize='sm'>
          {isActive ? 'Active' : 'Inactive'}
        </FormLabel>
      </FormControl>
      <Can I='manage' a='CatalogCategory'>
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{isActive ? 'Inactivate' : 'Activate'} category!</ModalHeader>

            <ModalBody>
              {isActive && <Text>Are you sure you want to deactivate this category?</Text>}
              {!isActive && <Text>Are you sure you want to activate this category?</Text>}
            </ModalBody>

            <ModalFooter>
              <HStack spacing='md'>
                <Button variant='outline' colorScheme='red' onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant='solid'
                  colorScheme='red'
                  onClick={handleActivateCategory}
                  isLoading={isUpdateCategoryLoading}>
                  {isActive ? 'Inactivate' : 'Activate'}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Can>
    </>
  );
};
