import { BasePagination } from '@elkaso-app/web-design';
import { useGetRestaurantTransfersApi } from 'apis/restaurant-transfers/use-get-restaurant-transfers-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import {
  CreatedAtFilterButton,
  RestaurantTransferCodeFilter,
  RestaurantTransferDeliveryDateFilterButton,
  RestaurantTransferStatusFilterButton,
  RestaurantWarehouseIdFilterButton,
} from 'components/base-table/filters';
import { RestaurantBranchIdFilterButton } from 'components/base-table/filters/restaurant-branch-id';
import { BaseSortButton } from 'components/base-table/sorting';
import dayjs from 'dayjs';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React, { useEffect } from 'react';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { IRestaurantInfoCell, RestaurantInfoCell } from '../components/restaurant-info-cell';
import {
  IRestaurantTransferActionsCell,
  RestaurantTransferActionsCell,
} from '../components/restaurant-transfer-actions-cell';
import { IRestaurantTransferCodeCell, RestaurantTransferCodeCell } from '../components/restaurant-transfer-code-cell';
import { IStatusBadgeCell, StatusBadgeCell } from '../components/status-badge-cell';
import { ISupplierInfoCell, SupplierInfoCell } from '../components/supplier-info-cell';

export const RestaurantTransfersListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetRestaurantTransfersApi();
  const { pageCount, total, data: transfersData } = data ?? {};

  useEffect(() => {
    dispatch(changeTitle('Restaurant Transfers'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Code' filterButtonComponent={<RestaurantTransferCodeFilter />} />,
        accessor: 'code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['code']>) => (
          <CustomTableCell<IRestaurantTransferCodeCell> as={RestaurantTransferCodeCell} code={value} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Restaurant'
            filterButtonComponent={<RestaurantBranchIdFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='restaurantId' />}
          />
        ),
        accessor: 'restaurant',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['restaurant']>) => (
          <CustomTableCell<IRestaurantInfoCell>
            as={RestaurantInfoCell}
            restaurantId={value.restaurantId}
            name={value.name}
            subEntityName={value.subEntityName}
            phoneNumber={value.phoneNumber}
          />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Supplier'
            filterButtonComponent={<RestaurantWarehouseIdFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='supplierId' />}
          />
        ),
        accessor: 'supplier',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier']>) => (
          <CustomTableCell<ISupplierInfoCell>
            as={SupplierInfoCell}
            restaurantId={value.restaurantId}
            supplierId={value.supplierId}
            name={value.name}
            supplierUserName={value.user.name}
            phoneNumber={value.user.phoneNumber}
            isAutoApproved={value.isAutoApproved}
          />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Status'
            filterButtonComponent={<RestaurantTransferStatusFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='status' />}
          />
        ),
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => (
          <CustomTableCell<IStatusBadgeCell> as={StatusBadgeCell} status={value.status} history={value.history} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Delivery Date'
            filterButtonComponent={<RestaurantTransferDeliveryDateFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='deliveryDate' />}
          />
        ),
        accessor: 'deliveryDate',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['deliveryDate']>) => <TableCell value={value} />,
      },
      {
        Header: (
          <TableHeader
            name='Created'
            filterButtonComponent={<CreatedAtFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='createdAt' />}
          />
        ),
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['createdAt']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IRestaurantTransferActionsCell> as={RestaurantTransferActionsCell} transfer={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    code: string;
    deliveryDate: string;
    status: {
      status: string;
      history: {
        status: string;
        createdBy: string;
        createdAt: string;
      }[];
    };
    createdAt: string;
    restaurant: {
      restaurantId: string;
      name: string;
      subEntityName: string;
      phoneNumber: string;
    };
    supplier: {
      restaurantId: string;
      supplierId: string;
      type: string;
      name: string;
      isAutoApproved: boolean;
      user: {
        id: number;
        name: string;
        email: string;
        phoneNumber: string;
      };
    };
    actions: (typeof transfersData)[0];
  }

  const columnsAccessorData = React.useMemo(
    () =>
      transfersData?.map((restaurantTransfer: (typeof transfersData)[0]): IColumnsAccessorData => {
        return {
          id: restaurantTransfer?.id,
          code: restaurantTransfer?.code,
          restaurant: {
            restaurantId: restaurantTransfer?.restaurant?.id,
            name: restaurantTransfer?.restaurant?.nameEn,
            subEntityName: restaurantTransfer?.restaurant?.subEntity?.name,
            phoneNumber: restaurantTransfer?.restaurant?.user?.phoneNumber,
          },
          supplier: {
            restaurantId: restaurantTransfer?.restaurant?.id,
            supplierId: restaurantTransfer?.supplier?.id,
            type: restaurantTransfer?.supplier?.type,
            name: restaurantTransfer?.supplier?.name,
            isAutoApproved: restaurantTransfer?.isAutoApproved,
            user: {
              id: restaurantTransfer?.supplier?.id,
              name: restaurantTransfer?.supplier?.user?.name,
              email: restaurantTransfer?.supplier?.user?.email,
              phoneNumber: restaurantTransfer?.supplier?.user?.phoneNumber,
            },
          },
          status: {
            status: restaurantTransfer?.status,
            history: restaurantTransfer?.history,
          },
          deliveryDate: restaurantTransfer?.deliveryDate
            ? dayjs(restaurantTransfer?.deliveryDate).format('DD-MM-YYYY hh:mm a')
            : '---',
          createdAt: dayjs(restaurantTransfer?.createdAt).format('DD-MM-YYYY hh:mm a'),
          actions: restaurantTransfer,
        };
      }),
    [transfersData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Restaurant Transfers' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
