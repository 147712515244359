import {
  BoxProps,
  Button,
  ChakraSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useCreateCategoryApi } from 'apis/product-category/use-create-category-api';
import { useUpdateCategoryApi } from 'apis/product-category/use-update-category-api';
import { useGetCountriesApi } from 'apis/users/use-get-countries-api';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { DeleteCategoryModal } from './delete-category-modal';
import { language } from './tree-view-section';
import { UploadCategoryImage } from './upload-category-image';

type TOption = {
  label: string;
  value: string;
};

type TInputs = {
  nameAR: string;
  nameEN: string;
  countries: TOption[] | undefined;
  image: string;
  kasoId: string;
};

const initialValues: TInputs = {
  kasoId: '',
  nameAR: '',
  nameEN: '',
  countries: undefined,
  image: '',
};

export interface IAddCategoryModalCell extends BoxProps {
  variant: 'add' | 'edit';
  trigger: JSX.Element;
  value?: Record<string, any>;
  title?: string;
}

export const AddCategoryModal = ({ variant, trigger, value, title }: IAddCategoryModalCell) => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const isEdit = useMemo(() => variant === 'edit', [variant]);
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isCountriesLoading, data: countries = [] } = useGetCountriesApi();
  const { isLoading: isCreateCategoryLoading, mutate: createCategory } = useCreateCategoryApi();
  const { isLoading: isUpdateCategoryLoading, mutate: updateCategory } = useUpdateCategoryApi(Number(categoryId));

  const countryOptions = countries?.map((country) => {
    return { value: country.id.toString(), label: country.name };
  });

  useEffect(() => {
    onCloseModal();
  }, [location]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...initialValues },
  });

  const defaultValues: TInputs = {
    kasoId: value?.kasoId,
    nameAR: language(value, 'ar'),
    nameEN: language(value, 'en'),
    countries: value?.countries?.map((c: any) => {
      return { label: c?.name, value: c?.id };
    }),
    image: value?.image,
  };

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen, value]);

  const onSubmit = (data: TInputs) => {
    if (variant === 'add') {
      const variables = {
        body: {
          names: [
            {
              title: data.nameEN,
              language: 'en',
            },
            {
              title: data.nameAR,
              language: 'ar',
            },
          ],
          parentId: Number(categoryId),
          kasoId: data.kasoId,
          countries: data?.countries?.map((c) => Number(c.value)) ?? [],
          image: data.image,
          status: 'inactive',
        },
      };

      createCategory(variables, {
        onSuccess: () => {
          onCloseModal();
        },
      });
    }

    if (variant === 'edit') {
      const variables = {
        categoryId: Number(categoryId),
        body: {
          names: [
            {
              title: data.nameEN,
              language: 'en',
            },
            {
              title: data.nameAR,
              language: 'ar',
            },
          ],
          countries: data?.countries?.map((c) => Number(c.value)),
          image: data.image,
          kasoId: data.kasoId,
        },
      };

      updateCategory(variables, {
        onSuccess: () => {
          onCloseModal();
        },
      });
    }

    reset(data);
  };

  const onCloseModal = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onCloseModal} closeOnOverlayClick={false} size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEdit ? 'Edit' : 'Add New'} Category - {title}
            <Text color='gray.500' fontWeight='semibold' fontSize='md'>
              Make sure to fill all the required fields.
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack as='form' id='addCategoryFormId' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <HStack spacing='lg' align='start'>
                <FormControl isRequired isInvalid={errors.nameEN ? true : false}>
                  <FormLabel>English Name</FormLabel>
                  <Input
                    {...register('nameEN', {
                      required: 'English Name is required.',
                    })}
                  />

                  <FormErrorMessage>{errors.nameEN?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={errors.nameAR ? true : false}>
                  <FormLabel>Arabic Name</FormLabel>
                  <Input
                    {...register('nameAR', {
                      pattern: {
                        value: /[\u0600-\u06FF\u0750-\u077F]/g,
                        message: 'Please enter Arabic letters only.',
                      },
                      required: 'Arabic Name is required.',
                    })}
                  />

                  <FormErrorMessage>{errors.nameAR?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={errors.kasoId ? true : false}>
                  <FormLabel>Kaso Id</FormLabel>
                  <Input
                    {...register('kasoId', {
                      required: 'Kaso Id is required.',
                    })}
                  />

                  <FormErrorMessage>{errors.kasoId?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <Controller
                control={control}
                name='countries'
                render={({ field: { onChange, name, onBlur, value } }) => (
                  <FormControl isRequired isInvalid={errors.countries ? true : false}>
                    <FormLabel>Countries</FormLabel>

                    <ChakraSelect
                      id='countries'
                      options={countryOptions}
                      onChange={onChange}
                      name={name}
                      onBlur={onBlur}
                      value={value}
                      placeholder='Select countries...'
                      isMulti
                      closeMenuOnSelect={false}
                    />

                    <FormErrorMessage>{errors?.countries?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />

              <Text color='gray.500' fontWeight='semibold' fontSize='md'>
                Upload Image
              </Text>

              <Controller
                control={control}
                name='image'
                rules={{ required: 'Image is required.' }}
                render={({ fieldState: { error } }) => (
                  <UploadCategoryImage image={value?.image ?? ''} error={error} setValue={setValue} />
                )}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              {value?.children.length == 0 && (
                <DeleteCategoryModal
                  parentId={value?.parentId}
                  title={title}
                  categoryId={value?.id}
                  trigger={
                    <Button variant='ghost' colorScheme='red'>
                      Delete
                    </Button>
                  }
                />
              )}

              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addCategoryFormId'
                isLoading={isCreateCategoryLoading || isUpdateCategoryLoading || isCountriesLoading}>
                {isEdit ? 'Edit Category' : 'Create Category'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
