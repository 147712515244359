import { BasePagination, formatDateTime, IconButtonWithTooltip, PlusIcon } from '@elkaso-app/web-design';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

import { useGetCampaignsApi } from 'apis/campaigns/use-get-campaigns-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { CountryCodeFilterButton } from 'components/base-table/filters/country-code';
import { InternalNameFilterButton } from 'components/base-table/filters/internal-name';
import { PublisherIdsFilterButton } from 'components/base-table/filters/publisher-ids';
import { TypeFilterButton } from 'components/base-table/filters/type';
import { TypeTargetFilterButton } from 'components/base-table/filters/type-target';
import { setHiddenColumns } from 'components/base-table/utils';
import { ActivationSwitchCell, IActivationSwitchCell } from 'pages/promotions/components/activation-switch-cell';
import { AddCampaignModal } from 'pages/promotions/components/add-campaign-modal';
import { CampaignActionsCell, CampaignActionsCellProps } from 'pages/promotions/components/campaign-actions-cell';
import { DateTimeCell, IDateTimeCell } from 'pages/promotions/components/date-time-cell';
import { IPublishersCell, PublishersCell } from 'pages/promotions/components/publishers-cell';
import { CellProps } from 'react-table';

export const CampaignsScreen = () => {
  const dispatch = useAppDispatch();
  const setPaginationParams = useSetPaginationParamsV1();

  const { isLoading, isFetching, data } = useGetCampaignsApi();
  const { pageCount, total, data: campaignsData } = data ?? {};

  useEffect(() => {
    dispatch(changeTitle('Campaign'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Campaign Name' filterButtonComponent={<InternalNameFilterButton />} />,
        accessor: 'internalName',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['internalName']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Country' filterButtonComponent={<CountryCodeFilterButton />} />,
        accessor: 'country',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['country']>) => (
          <TableCell value={value} color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Publisher' filterButtonComponent={<PublisherIdsFilterButton />} />,
        accessor: 'publishers',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['publishers']>) => (
          <CustomTableCell<IPublishersCell> as={PublishersCell} publishers={value} />
        ),
      },
      {
        Header: <TableHeader name='Type' filterButtonComponent={<TypeFilterButton />} />,
        accessor: 'type',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['type']>) => <TableCell value={value} color='gray.600' />,
      },
      {
        Header: <TableHeader name='Target' filterButtonComponent={<TypeTargetFilterButton />} />,
        accessor: 'target',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['target']>) => (
          <TableCell value={value} color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Creation Date' filterButtonComponent={<TypeTargetFilterButton />} />,
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['createdAt']>) => (
          <TableCell value={formatDateTime(value, 'DD-MM-YYYY')} color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Modify Date' filterButtonComponent={<TypeTargetFilterButton />} />,
        accessor: 'updatedAt',

        Cell: ({ value }: CellProps<any, IColumnsAccessorData['updatedAt']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: <TableHeader name='Activation' />,
        accessor: 'activation',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['activation']>) => (
          <CustomTableCell<IActivationSwitchCell>
            as={ActivationSwitchCell}
            campaignId={value.id}
            isActive={value.isActive}
          />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<CampaignActionsCellProps> as={CampaignActionsCell} id={value.id} isActive={value.isActive} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    internalName: string;
    country: string;
    publishers: {
      id: string;
      name: string;
    }[];
    type: string;
    target: string;
    activation: {
      id: number;
      isActive: boolean;
    };
    createdAt: string;
    updatedAt: string;
    actions: {
      id: string;
      isActive: boolean;
    };
  }

  const columnsAccessorData = React.useMemo(
    () =>
      campaignsData?.map((campaign: Record<string, any>): IColumnsAccessorData => {
        return {
          id: campaign?.id,
          internalName: campaign?.internalName,
          country: campaign?.country?.name,
          publishers: campaign?.publishers,
          type: campaign?.type,
          target: campaign?.typeTarget,
          activation: {
            id: campaign?.id,
            isActive: campaign?.isActive,
          },
          createdAt: campaign?.createdAt,
          updatedAt: campaign?.createdAt === campaign?.updatedAt ? '' : campaign?.updatedAt,
          actions: {
            id: campaign?.id,
            isActive: campaign?.isActive,
          },
        };
      }),
    [campaignsData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Campaigns' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />

          <AddCampaignModal
            trigger={<IconButtonWithTooltip label='Add Campaign' aria-label='add-campaigns-btn' Icon={<PlusIcon />} />}
          />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable
          columns={columns}
          data={columnsAccessorData}
          initialHiddenColumns={[...setHiddenColumns(['activation', 'actions'])]}
        />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
