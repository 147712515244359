import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useRetryAggSheetJobApi } from 'apis/agg-sheets-relations/use-retry-agg-sheet-job-api';

export interface RetryAggSheetButtonCellProps {
  id: number;
  status: string;
}

export const RetryAggSheetCell = ({ id, status }: RetryAggSheetButtonCellProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isLoading, mutate: retryAggSheetJob } = useRetryAggSheetJobApi();

  const handleRetryAggSheetJob = () => {
    const variables = {
      id: id,
    };

    retryAggSheetJob(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <Button variant='outline' onClick={onOpen} size='sm' colorScheme='red' isDisabled={!(status === 'failed')}>
        Retry
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Restart Job!</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text>Are you sure you want to restart this Job?</Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='sm'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='solid' colorScheme='red' onClick={handleRetryAggSheetJob} isLoading={isLoading}>
                Retry
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
