/// TODO: Moaaz, This file will be deleted later when we git rid of all the old endpoints.
// NOTE: don't use this file it's deprecated please use [http-service] instead.
import { createAuthorizationHeader, getAccessToken, getCountry, getLanguage } from 'apis/config/http-service';
import axios from 'axios';
import { onFailedRequest, onSuccessRequest } from './on-request-interceptor';
import { onFailedResponse, onSuccessResponse } from './on-response-interceptor';

// Create instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/',
});

instance.defaults.headers.common['Authorization'] = createAuthorizationHeader(getAccessToken());
instance.defaults.headers.common['Accept-Language'] = getLanguage();
instance.defaults.headers.common['Accept-Country'] = getCountry();
instance.defaults.headers.post['Content-Type'] = 'application/json';

// Add a request interceptor
instance.interceptors.request.use(onSuccessRequest, onFailedRequest);

// Add a response interceptor
instance.interceptors.response.use(onSuccessResponse, onFailedResponse);

export default instance;
