import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import restaurantApi from 'apis/restaurants';
import supplierApi from 'apis/suppliers';
import AutoCompleteComponent from 'components/AutoComplete/AutoComplete';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AttachSupplierToggleDialog } from 'redux/actions';
import { useDebounce } from 'use-lodash-debounce';
import IntlMessages from 'utils/intl-messages';

const AttachSupplierDialog = (props) => {
  const close = () => {
    props.AttachSupplierToggleDialog({
      openAttachDialog: false,
      confirmButton: '',
      cancelButton: '',
    });
  };

  const {
    dialog: {
      colorClass = 'primary',
      title,
      openAttachDialog,
      confirmButton,
      cancelButton,
      error,
      countryCode,
      onAttachSupplier,
      resId,
      subEntityId,
      subEntityType,
    },
  } = props;

  const [searchLoading, setSearchLoading] = useState(false);
  const [supplierNames, setSupplierNames] = useState([]);
  const [supplierUserObj, setSupplierUserObj] = useState({});
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    setInputValue('');
    setSupplierNames([]);
    setSupplierUserObj({});
  }, [openAttachDialog]);
  const getSupplierNames = () => {
    setSearchLoading(true);
    let customParams = {
      subEntityId,
      subEntityType,
    };
    let params = {
      filters: { name: { $contL: inputValue }, countryCode: { eq: countryCode } },
      customParams,
      sort: 'name,ASC',
    };

    resId
      ? restaurantApi.getRestaurantSuppliers(resId, params).then((res) => {
          setSupplierNames(res.data);
          setSearchLoading(false);
        })
      : supplierApi.getSuppliers(params).then((res) => {
          setSupplierNames(res.data);
          setSearchLoading(false);
        });
  };

  useEffect(() => {
    if (openAttachDialog) {
      getSupplierNames();
    }
  }, [openAttachDialog]);

  useEffect(() => {
    getSupplierNames();
  }, [debouncedValue]);

  const changeAutoCompleteTextField = (value) => {
    setInputValue(value);
    setSupplierUserObj({ name: value });
  };

  return (
    <Fragment>
      <Dialog open={openAttachDialog || false} onClose={() => close()}>
        <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title' className={error && 'error'}>
          <IntlMessages id={`dialog.${title}`} />
        </DialogTitle>
        <DialogContent style={{ padding: '8px 60px' }}>
          <DialogContentText id='alert-dialog-description'>
            <IntlMessages id='dialog.attachSupplier.note' />
          </DialogContentText>
          <AutoCompleteComponent
            disableClearable={true}
            customClass={'min-width-300 pr-20'}
            getOptionSelected={(option, value) => option.id === value.id}
            loading={!!searchLoading}
            value={!_.isEmpty(supplierUserObj) && supplierUserObj}
            disabled={!!searchLoading}
            options={!_.isEmpty(supplierNames) ? supplierNames : []}
            getOptionLabel={(option) => option.name || ''}
            onChangeTextField={(e) => changeAutoCompleteTextField(e.target.value)}
            onChange={(e, value) => setSupplierUserObj(value)}
            label={'company.name'}
            inputValue={!_.isEmpty(supplierUserObj) ? supplierUserObj.name : inputValue}
            noOptionsText={<IntlMessages id={'global.options.noMatched'} />}
          />
        </DialogContent>
        <DialogActions className={colorClass ? 'red' : colorClass}>
          <Button onClick={close} color='primary'>
            {<IntlMessages id={cancelButton ? `button.${cancelButton}` : 'button.cancel'} />}
          </Button>
          <Button variant='contained' onClick={() => onAttachSupplier(supplierUserObj.id)} color='primary'>
            {<IntlMessages id={confirmButton ? `button.${confirmButton}` : 'button.yes'} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(connect(mapStateToProps, { AttachSupplierToggleDialog })(AttachSupplierDialog));
