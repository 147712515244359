import {
  Box,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@elkaso-app/web-design';

interface IOrderInvoiceModal {
  images: string[];
  isOpen: boolean;
  onClose: () => void;
}

export const OrderInvoiceModal = ({ isOpen, onClose, images }: IOrderInvoiceModal) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invoice Images</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid minChildWidth='120px' spacing='md'>
            {images?.map((item) => (
              <Link key={item} href={item} download>
                <Box bgColor='red.500' height='80px' overflow='hidden' shadow='md' borderRadius='base'>
                  <Image src={item} w='full' minH='full' />
                </Box>
              </Link>
            ))}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter justifyContent='center'>
          <Text color='gray.500'>Click on the image to download.</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
