import { Image, StackProps } from '@elkaso-app/web-design';
import AltImage from '../../../assets/img/alt-image.png';

export interface PosIntegrationInfoCellData extends StackProps {
  integrationId: number;
  name: string;
  imageUrl: string;
}

export const PosIntegrationInfoCell = ({ name, imageUrl }: PosIntegrationInfoCellData) => {
  return (
    <Image src={imageUrl} alt={name} fallbackSrc={AltImage} boxSize='80px' borderRadius='base' overflow='hidden' />
  );
};
