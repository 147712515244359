import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AppConfig from 'constants/AppConfig';
import { Fragment, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { commentToggleDialog } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const CommentDialog = (props) => {
  const close = () => {
    props.commentToggleDialog({
      openCommentDialog: false,
      confirmButton: '',
      cancelButton: '',
      value: '',
    });
    setComment('');
  };

  const {
    dialog: {
      colorClass = 'primary',
      title,
      value,
      itemId,
      fetchData,
      confirmAction,
      openCommentDialog,
      confirmButton,
      cancelButton,
      error,
      isReadOnly,
    },
  } = props;

  const [comment, setComment] = useState('');

  useEffect(() => {
    value && setComment(value);
  }, [value]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isEmpty', (value) => {
      return value.replace(/\s/g, '').length;
    });
  }, [value]);

  const handleAddComment = () => {
    if (comment.length > 0) {
      let data = { comment: comment };
      confirmAction(itemId, data)
        .then(() => {
          close();
          fetchData();
          NotificationManager.success(
            <IntlMessages id={`global.success.message.${value ? 'edit' : 'new'}`} />,
            <IntlMessages id={`${AppConfig.successTitle}`} />,
            AppConfig.notificationTimeout
          );
        })
        .catch(() => {
          NotificationManager.error(
            <IntlMessages id={`global.error.somethingWentWrong`} />,
            <IntlMessages id={`${AppConfig.errorTitle}`} />,
            AppConfig.errorNotificationTimeout
          );
        });
    }
  };

  if (isReadOnly) {
    return (
      <Dialog open={openCommentDialog || false} onClose={() => close()}>
        <div className={'width-400'}>
          <DialogTitle id='alert-dialog-title' className={`header-dialog ${error && 'error'}`}>
            <IntlMessages id={title} />
          </DialogTitle>
          <DialogContent>
            <textarea style={{ width: '100%', resize: 'none' }} readOnly={true}>
              {value}
            </textarea>
          </DialogContent>
          <DialogActions className={`dialog-actions ${colorClass ? 'red' : colorClass}`}>
            <Button onClick={close} color='primary' variant='outlined'>
              {<IntlMessages id={cancelButton ? `button.${cancelButton}` : 'button.cancel'} />}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  return (
    <Fragment>
      <Dialog open={openCommentDialog || false} onClose={() => close()}>
        <ValidatorForm
          noValidate
          onSubmit={handleAddComment}
          onError={() => {
            NotificationManager.error(
              <IntlMessages id='error.required' />,
              <IntlMessages id={`${AppConfig.errorTitle}`} />,
              AppConfig.errorNotificationTimeout
            );
          }}>
          <div className={'width-400'}>
            <DialogTitle id='alert-dialog-title' className={`header-dialog ${error && 'error'}`}>
              <IntlMessages id={`dialog.${title}`} />
            </DialogTitle>
            <DialogContent>
              <TextValidator
                id='outlined-basic'
                fullWidth
                autoFocus
                onChange={(e) => setComment(e.target.value)}
                label={<IntlMessages id='dialog.comment' key='key-0' />}
                value={comment || ''}
                multiline
                rows={2}
                rowsMax={5}
                validators={['isEmpty']}
                errorMessages={[<IntlMessages id={`global.errors.required`} key='key-1' />]}
              />
            </DialogContent>
            <DialogActions className={`dialog-actions ${colorClass ? 'red' : colorClass}`}>
              <Button onClick={close} color='primary' variant='outlined'>
                {<IntlMessages id={cancelButton ? `button.${cancelButton}` : 'button.cancel'} />}
              </Button>
              <Button type={'submit'} variant='contained' color='primary' disabled={!comment.length > 0}>
                {<IntlMessages id={confirmButton ? `button.${confirmButton}` : 'button.yes'} />}
              </Button>
            </DialogActions>
          </div>
        </ValidatorForm>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(connect(mapStateToProps, { commentToggleDialog })(CommentDialog));
