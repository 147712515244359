import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import queryString from 'query-string';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetSupplierItemsApi } from './use-get-supplier-items-api';

const endpoint = 'catalog/v1/legacy-products';
export const deleteSupplierItemsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = {
  ids: string[];
};

type TVariables = {
  query: TQueryKey;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables, unknown>;

function constructEndpoint(query: TQueryKey) {
  const stringifiedQuery = queryString.stringify(query, { arrayFormat: 'bracket' });
  return `${endpoint}?${stringifiedQuery}`;
}

export function useDeleteSupplierItemsApi(options?: Options): ReturnType {
  const { refetch: refetchSupplierItems } = useGetSupplierItemsApi({
    enabled: false,
  });

  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ query }: TVariables) => {
    fullEndpoint = constructEndpoint(query);

    try {
      const { data } = await http.delete(fullEndpoint);

      // Refetch supplier items
      refetchSupplierItems();

      toast({
        description: 'Items have been deleted successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
