import { BasePagination, Button, PlusIcon } from '@elkaso-app/web-design';
import { useGetTicketsApi } from 'apis/ticket-management';
import { getMeEndpointIdentifier } from 'apis/users/use-me-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { TicketAssignedToFilterButton } from 'components/base-table/filters/ticket-assigned-to';
import { TicketStatusFilterButton } from 'components/base-table/filters/ticket-status';
import { BaseSortButton } from 'components/base-table/sorting';
import { setHiddenColumns } from 'components/base-table/utils';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { CreateTicketModal } from 'pages/ticket-management/components/create-ticket-modal';
import { ITicketNumberCell, TicketNumberCell } from 'pages/ticket-management/components/ticket-number-cell';
import { ITicketStatusCell, TicketStatusCell } from 'pages/ticket-management/components/ticket-status-cell';
import React, { useEffect } from 'react';
import { FaUserCheck } from 'react-icons/fa';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { AssignTicketsModal } from '../components/assign-ticket-modal';

export const TicketsListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetTicketsApi({ enabled: true });
  const { pageCount, total, data: ticketsData } = data ?? {};
  const me = useGetQueryClientData(getMeEndpointIdentifier);

  useEffect(() => {
    dispatch(changeTitle('Ticket Management'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Ticket No' sortButtonComponent={<BaseSortButton sortBy='ticketNumber' />} />,
        accessor: 'ticket',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['ticket']>) => (
          <CustomTableCell<ITicketNumberCell>
            as={TicketNumberCell}
            ticketId={value?.ticketId}
            ticketNo={value?.ticketNo}
          />
        ),
      },
      {
        Header: <TableHeader name='Category' />,
        accessor: 'category',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['category']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Status' filterButtonComponent={<TicketStatusFilterButton />} />,
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => (
          <CustomTableCell<ITicketStatusCell> as={TicketStatusCell} status={value} />
        ),
      },
      {
        Header: <TableHeader name='Created By' />,
        accessor: 'createdBy',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['createdBy']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Created On' sortButtonComponent={<BaseSortButton sortBy='createdTime' />} />,
        accessor: 'createdOn',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['createdOn']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: <TableHeader name='Assigned To' filterButtonComponent={<TicketAssignedToFilterButton />} />,
        accessor: 'assignedTo',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['assignedTo']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Closed On' />,
        accessor: 'closedOn',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['closedOn']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    ticket: {
      ticketId: string;
      ticketNo: string;
    };
    category: string;
    status: string;
    createdBy: string;
    createdOn: string;
    assignedTo: string;
    closedOn: string;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      ticketsData?.map((ticket: (typeof ticketsData)[0]): IColumnsAccessorData => {
        return {
          id: ticket?.id,
          ticket: {
            ticketId: ticket?.id,
            ticketNo: ticket?.ticketNumber,
          },
          category: ticket?.category,
          status: ticket?.status,
          createdBy: ticket?.contact?.firstName,
          createdOn: ticket?.createdTime,
          assignedTo: `${ticket?.assignee?.firstName} ${ticket?.assignee?.lastName}`,
          closedOn: ticket?.closedTime,
        };
      }),
    [ticketsData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Ticket Dashboard' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          {me?.countryCode?.toUpperCase() === 'AE' && (
            <AssignTicketsModal
              trigger={
                <Button leftIcon={<FaUserCheck fill='white' fontSize='sm' />} colorScheme='red' px='md'>
                  Assign ticket
                </Button>
              }
            />
          )}
          <CreateTicketModal
            trigger={
              <Button leftIcon={<PlusIcon fill='white' boxSize='sm' />} colorScheme='red' px='md'>
                Create ticket
              </Button>
            }
          />

          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable
          columns={columns}
          data={columnsAccessorData}
          initialHiddenColumns={setHiddenColumns(['activation', 'actions'])}
        />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
