import { Icon, IconProps } from '@elkaso-app/web-design';

export const CatalogIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M4.5 8.5H8.5V4.5H4.5V8.5ZM10.5 20.5H14.5V16.5H10.5V20.5ZM4.5 20.5H8.5V16.5H4.5V20.5ZM4.5 14.5H8.5V10.5H4.5V14.5ZM10.5 14.5H14.5V10.5H10.5V14.5ZM16.5 4.5V8.5H20.5V4.5H16.5ZM10.5 8.5H14.5V4.5H10.5V8.5ZM16.5 14.5H20.5V10.5H16.5V14.5ZM16.5 20.5H20.5V16.5H16.5V20.5Z'
        fill='inherit'
      />
    </Icon>
  );
};
