import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAILED,
  SET_CATEGORIES_LOADER,
  SET_CATEGORY_ERRORS,
  SET_LOADING_STATUS,
  SET_PROMOTION,
} from 'redux/types';

const INIT_STATE = {
  categoriesList: [],
  category: {},
  categoryErrors: {},
  isLoading: false,
  categoriesLoader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS: {
      return { ...state, isLoading: action.payload };
    }
    case GET_CATEGORIES:
      return { ...state, categoriesList: action.payload, isLoading: false };
    case GET_CATEGORIES_FAILED:
      return { ...state, categoriesList: [], isLoading: false };
    case SET_PROMOTION:
      return { ...state, category: action.payload };
    case SET_CATEGORY_ERRORS:
      return { ...state, categoryErrors: action.payload };
    case SET_CATEGORIES_LOADER:
      return { ...state, categoriesLoader: action.payload };
    default:
      return { ...state };
  }
};
