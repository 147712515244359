import {
  BoxProps,
  Button,
  FileUploader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  MultiValuesInput,
  OnAddItemProps,
  OnRemoveItemProps,
  Stack,
  Text,
  UploadedFile,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUploadFileApi } from 'apis/config/use-upload-file-api';
import { useCreateSupplierItemApi } from 'apis/supplier/supplier-items/use-create-supplier-item-api';
import { useUpdateSupplierItemApi } from 'apis/supplier/supplier-items/use-update-supplier-item-api';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

type FormValues = {
  images: string[];
  name: {
    en: string;
    ar: string;
  };
  brand: {
    en: string;
    ar: string;
  };
  code: string;
  comparePrice: string;
  price: string;
  kasoId: string;
  unit: string;
  description: string;
  synonymsList: string[];
};

const schema = yup.object().shape({
  images: yup.array().of(yup.string()),
  name: yup.object().shape({
    en: yup.string().required('Item name (EN) is required'),
    ar: yup
      .string()
      .matches(/^$|[\u0600-\u06FF\u0750-\u077F]+/, 'Please enter Arabic letters only.')
      .nullable(),
  }),
  brand: yup.object().shape({
    en: yup.string(),
    ar: yup
      .string()
      .matches(/^$|[\u0600-\u06FF\u0750-\u077F]+/, 'Please enter Arabic letters only.')
      .nullable(),
  }),
  code: yup.string().required('Code is required'),
  comparePrice: yup.string().matches(/^\s*(\d+(\.\d+)?)?\s*$/, 'Please enter a valid price'),
  price: yup.string().matches(/^\s*(\d+(\.\d+)?)?\s*$/, 'Please enter a valid price'),
  kasoId: yup
    .string()
    .matches(/^([A-Z0-9]{1,9}-[A-Z0-9]{1,9}-[A-Z0-9]{1,9}-[A-Z0-9]{1,9})?$/, 'Please enter a correct Kaso ID'),
  unit: yup.string().required('Unit is required.'),
  description: yup.string(),
  synonymsList: yup.array().of(yup.string()),
});

const defaultValues: FormValues = {
  images: [],
  name: {
    en: '',
    ar: '',
  },
  brand: {
    en: '',
    ar: '',
  },
  code: '',
  comparePrice: '',
  price: '',
  kasoId: '',
  unit: '',
  description: '',
  synonymsList: [],
};

export interface IAddItemModalCell extends BoxProps {
  variant: 'add' | 'edit';
  trigger: JSX.Element;
  item?: Record<string, any>;
}

export const AddItemModalCell = ({ variant, trigger, item }: IAddItemModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { supplierId } = useParams<{
    supplierId: string;
  }>();

  const { isLoading: isAddItemLoading, mutate: addItem } = useCreateSupplierItemApi();
  const { isLoading: isEditItemLoading, mutate: editItem } = useUpdateSupplierItemApi(item?.id);

  const { mutate: uploadFileApi } = useUploadFileApi();

  const isEdit = variant === 'edit';

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  // Update values
  const updateValues = () => {
    setValue('images', item?.images ?? []);
    setValue('name.en', item?.name?.en ?? '');
    setValue('name.ar', item?.name?.ar ?? '');
    setValue('brand.en', item?.brand?.en ?? '');
    setValue('brand.ar', item?.brand?.ar ?? '');
    setValue('code', item?.code ?? '');
    setValue('comparePrice', item?.priceBeforeDiscount ?? '');
    setValue('price', item?.price ?? '');
    setValue('kasoId', item?.kasoId ?? '');
    setValue('unit', item?.unit?.abbreviation ?? '');
    setValue('description', item?.description ?? '');
    setValue('synonymsList', item?.synonyms ?? []);
  };

  useEffect(() => {
    updateValues();
  }, [isOpen]);

  const synonymsListValue = watch('synonymsList');

  const onSynonymListAdd = ({ value, clearInput }: OnAddItemProps) => {
    if (!value) return;

    const isSynonymExists = synonymsListValue?.find((s) => s === value);
    if (isSynonymExists) return setError('synonymsList', { message: 'Synonym already exists' }, { shouldFocus: true });

    setValue('synonymsList', [...synonymsListValue, value], { shouldValidate: true });

    // Should be called to empty the input after success add
    clearInput();
  };

  const onSynonymListRemove = ({ item }: OnRemoveItemProps) => {
    setValue(
      'synonymsList',
      synonymsListValue.filter((s) => s !== item),
      { shouldValidate: true }
    );
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const body = {
      supplierId: Number(supplierId),
      images: data?.images,
      name: {
        en: data?.name.en,
        ar: data?.name.ar || null,
      },
      brand: {
        en: data?.brand.en || null,
        ar: data?.brand.ar || null,
      },
      code: data?.code,
      kasoId: data?.kasoId || null,
      unit: data?.unit,
      priceBeforeDiscount: Number(data?.comparePrice) || null,
      price: Number(data?.price) || 0,
      description: data?.description || null,
      synonyms: data?.synonymsList,
    };

    // Create new product
    if (variant === 'add') {
      const variables = {
        body: { ...body },
      };

      addItem(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }

    // Edit existing product
    if (variant === 'edit') {
      const variables = {
        itemId: item?.id,
        body: { ...body },
      };

      editItem(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => reset(defaultValues)}
        closeOnOverlayClick={false}
        isCentered={false}
        size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack spacing='xs' align='start'>
              <Heading as='h3' size='lg'>
                {isEdit ? 'Edit' : 'Add New'} Item
              </Heading>
              <Text color='gray.500' fontSize='md' fontWeight='normal'>
                {!isEdit && 'Make sure to fill all the required fields before adding the new item'}
                {isEdit && 'Make sure to fill all the required fields before saving your changes'}
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack as='form' id='addProductForm' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                control={control}
                name='images'
                render={({ fieldState: { error } }) => {
                  const [imagesState, setImagesState] = useState<{ file: UploadedFile; url: string }[]>([]);

                  return (
                    <FormControl isInvalid={!!error}>
                      <FormLabel>Item Image</FormLabel>

                      <FileUploader
                        defaultUrlList={item?.images}
                        acceptedTypes={['image/jpeg', 'image/png']}
                        multiple={false}
                        onRemove={(file) => {
                          // Remove this file from the state
                          setImagesState(imagesState.filter((image) => image.file !== file));

                          // Update the form value
                          setValue(
                            'images',
                            imagesState.map((i) => i.url),
                            { shouldValidate: true }
                          );
                        }}
                        onRemoveAll={() => {
                          // Remove all files from the state
                          setImagesState([]);

                          // Update the form value
                          setValue('images', [], { shouldValidate: true });
                        }}
                        onUpload={async (files) => {
                          const images: { file: UploadedFile; url: string }[] = [];

                          for (const file of files) {
                            const formData = new FormData();
                            formData.append('file', file);

                            try {
                              const data: any = await new Promise((resolve, reject) => {
                                uploadFileApi(
                                  { body: formData },
                                  {
                                    onSuccess: (data) => {
                                      resolve(data);
                                    },
                                    onError: (error) => {
                                      reject(error);
                                    },
                                  }
                                );
                              });

                              images.push({ file, url: data?.url });
                            } catch (error) {
                              // Handle any errors that occurred during upload
                            }
                          }

                          // Store our data in a state
                          setImagesState(images);

                          // Update the form value
                          setValue(
                            'images',
                            images.map((i) => i.url),
                            { shouldValidate: true }
                          );
                        }}
                      />

                      <FormErrorMessage>{error?.message}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              />

              <HStack spacing='md' align='start' justify='start'>
                <FormControl isInvalid={!!errors?.name?.en} isRequired>
                  <FormLabel>Item Name (EN)</FormLabel>
                  <Input variant='outline' placeholder='ex. Pepsi' {...register('name.en')} />

                  <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors?.name?.ar}>
                  <FormLabel>Item Name (AR)</FormLabel>
                  <Input variant='outline' placeholder='مثال: بيبسي' {...register('name.ar')} />

                  <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <HStack spacing='md' align='start' justify='start'>
                <FormControl isInvalid={!!errors?.brand?.en}>
                  <FormLabel>Brand Name (EN)</FormLabel>
                  <Input variant='outline' placeholder='ex. Pepsi' {...register('brand.en')} />

                  <FormErrorMessage>{errors?.brand?.en?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors?.brand?.ar}>
                  <FormLabel>Brand Name (AR)</FormLabel>
                  <Input variant='outline' placeholder='مثال: بيبسي' {...register('brand.ar')} />

                  <FormErrorMessage>{errors?.brand?.ar?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <FormControl isInvalid={!!errors.code} isRequired>
                <FormLabel>Code</FormLabel>
                <Input variant='outline' placeholder='ex. YC2122' {...register('code')} />

                <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
              </FormControl>

              <HStack spacing='md' align='start' justify='start'>
                <FormControl isInvalid={!!errors.price}>
                  <FormLabel>Price</FormLabel>
                  <Input variant='outline' placeholder='ex. 50.0 AED' {...register('price')} />

                  <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.comparePrice}>
                  <FormLabel>Slashed price</FormLabel>
                  <Input variant='outline' placeholder='ex. 50.0 AED' {...register('comparePrice')} />

                  <FormErrorMessage>{errors.comparePrice?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <FormControl isInvalid={!!errors.kasoId}>
                <FormLabel>Kaso ID</FormLabel>
                <Input variant='outline' placeholder='ex.: FB-BV-WT-1' {...register('kasoId')} />

                <FormErrorMessage>{errors.kasoId?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.unit} isRequired>
                <FormLabel>Unit</FormLabel>
                <Input variant='outline' placeholder='ex.: KG' {...register('unit')} />

                <FormErrorMessage>{errors.unit?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.description}>
                <FormLabel>Description</FormLabel>
                <Input variant='outline' placeholder='Add Description' {...register('description')} />

                <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
              </FormControl>

              <Controller
                control={control}
                name='synonymsList'
                render={() => (
                  <FormControl isInvalid={!!errors.synonymsList}>
                    <FormLabel>Synonyms</FormLabel>

                    <MultiValuesInput.Input
                      variant='outline'
                      placeholder='Add new synonym'
                      type='text'
                      onAddItem={onSynonymListAdd}
                    />

                    <FormErrorMessage>{errors.synonymsList?.message}</FormErrorMessage>

                    <MultiValuesInput.List valuesArray={synonymsListValue} onRemoveItem={onSynonymListRemove} />
                  </FormControl>
                )}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addProductForm'
                isLoading={isAddItemLoading || isEditItemLoading}>
                {isEdit ? 'Save' : 'Add Item'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
