import { GET_OTP_LIST, GET_OTP_LIST_FAILED, SET_LOADING_STATUS, SET_OTP } from 'redux/types';

const INIT_STATE = {
  otpList: [],
  otp: {},
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return { ...state, isLoading: action.payload };
    case GET_OTP_LIST:
      return { ...state, otpList: action.payload, isLoading: false };
    case GET_OTP_LIST_FAILED:
      return { ...state, otpList: [], isLoading: false };
    case SET_OTP:
      return { ...state, otp: action.payload, isLoading: false };
    default:
      return { ...state };
  }
};
