import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  SwitchProps,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useUpdateUserApi } from 'apis/users/use-update-user-api';

export interface IUserActivationSwitchCell extends SwitchProps {
  userId: number;
  isActive: boolean;
}

export const UserActivationSwitch = ({ userId, isActive, ...rest }: IUserActivationSwitchCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: updateUser, isLoading: isUpdateUserLoading } = useUpdateUserApi();

  const handleActivateUser = () => {
    const variables = {
      userId,
      body: {
        isActive: !isActive,
      },
    };

    updateUser(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <FormControl display='flex' alignItems='center'>
        <Switch id={`active-user-${userId}`} onChange={onOpen} isChecked={isActive} {...rest} />
        <FormLabel htmlFor={`active-user-${userId}`} mb='0' ms='sm' userSelect='none' fontWeight='normal' fontSize='sm'>
          {isActive ? 'Active' : 'Inactive'}
        </FormLabel>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isActive ? 'Deactivate' : 'Activate'} user!</ModalHeader>

          <ModalBody>
            {isActive && (
              <>
                <Text>Are you sure you want to deactivate this user?</Text>
                <Text>This user won&apos;t be able to login until the user is activated again.</Text>
              </>
            )}

            {!isActive && (
              <>
                <Text>Are you sure you want to activate this user?</Text>
                <Text>This user will be able to login after activation.</Text>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='solid' colorScheme='red' onClick={handleActivateUser} isLoading={isUpdateUserLoading}>
                {isActive ? 'Deactivate' : 'Activate'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
