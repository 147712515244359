import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment-timezone';

export const valid = {
  isValidPhoneNumber: (phoneNumber) => {
    if (!phoneNumber) return true;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
      const isPossible = phoneUtil.isPossibleNumber(number);
      if (isPossible) {
        return phoneUtil.isValidNumber(number) || phoneUtil.getNumberType(number) === 0;
      }
      return false;
    } catch (e) {
      return false;
    }
  },

  isValidEmail: (email) => {
    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regEx.test(email);
  },

  isPasswordValid: (password) => {
    if (password === '') return true;
    const passw = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.{8,}).*$/;
    return password.match(passw);
  },

  matchPassword: (newPassword, passwordConfirmation) => {
    return passwordConfirmation === newPassword;
  },
  isDateValid: (start, end) => {
    if (!start && !end) return true;
    const startDate = moment(start);
    const endDate = moment(end);
    return endDate.diff(startDate) >= 0;
  },
  isTimeValid: (start, end) => {
    if (!start || !end) return true;
    const startDate = moment(start);
    const endDate = moment(end);
    return endDate.diff(startDate, 'seconds') > 0;
  },
};
