import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useBoolean,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { use2FAGetSecretApi } from 'apis/auth/use-2fa-get-secret';
import { use2FASendVerifyApi } from 'apis/auth/use-2fa-send-verify';
import { getMeEndpointIdentifier, useMeApi } from 'apis/users/use-me-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

export const SecurityTab = () => {
  const [isPinInputsDisabled, setIsPinInputsDisabled] = useBoolean();
  const submitButton: React.LegacyRef<HTMLButtonElement> | undefined = React.createRef();
  const me = useGetQueryClientData(getMeEndpointIdentifier);
  const { data: secretData } = use2FAGetSecretApi({ enabled: !me?.totpEnabled });
  const { isLoading: isSendVerificationLoading, mutate: sendVerifyToken } = use2FASendVerifyApi();
  const { isLoading: isMeApiLoading, refetch: refetchMeData } = useMeApi({ enabled: false });

  // Form
  type TInputs = {
    code: string;
  };

  const defaultValues: TInputs = {
    code: '',
  };

  const formSchema = Yup.object().shape({
    code: Yup.string().required('Pin code is required.').length(6, 'Pin code must be exactly 6 digits.'),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data: TInputs) => {
    // Submit verification code
    const variables = {
      body: {
        token: data.code,
      },
    };

    sendVerifyToken(variables, {
      onSuccess: () => {
        refetchMeData();
      },
    });

    // Reset pin input value
    setValue('code', '');

    // Enable pin inputs again if invalid pin code
    setIsPinInputsDisabled.off();
  };

  const onComplete = () => {
    // Disable pin inputs
    setIsPinInputsDisabled.on();

    // Call submit verification code Fn
    submitButton.current?.click();
  };

  if (me?.totpEnabled) {
    return <Text fontSize='md'>Your account is secured by two factor authentication method already.</Text>;
  }

  return (
    <>
      <Center mb='2xl' flexDir='column'>
        <Image src={secretData?.base64Image} boxSize='150px' alt='QR code' boxShadow='md' title='QR code' />
        <Text fontSize='md' mt='md' color='gray.500'>
          Please scan the above QR code to google authenticator app
        </Text>
      </Center>

      <Stack as='form' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormControl isRequired isInvalid={errors.code ? true : false}>
          <FormLabel>Verification code</FormLabel>
          <FormHelperText mb='sm'>Please enter the six digits code from google authenticator app</FormHelperText>

          <HStack spacing='sm' justify='flex-start'>
            <Controller
              control={control}
              name='code'
              render={({ field: { onChange, value } }) => (
                <PinInput onComplete={onComplete} isDisabled={isPinInputsDisabled} onChange={onChange} value={value}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              )}
            />
          </HStack>

          <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
        </FormControl>

        <Button
          type='submit'
          colorScheme='red'
          variant='solid'
          isLoading={isSendVerificationLoading || isMeApiLoading}
          alignSelf='flex-end'
          ref={submitButton}
          px='lg'
          size='lg'>
          Verify
        </Button>
      </Stack>
    </>
  );
};
