import { Center } from '@elkaso-app/web-design';

interface IStatusBadgeStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBadgeStyleProps: IStatusBadgeStyleProps = {
  Open: {
    color: '#000000',
    bgColor: 'rgba(237, 237, 237, 1)',
  },
  'In Progress': {
    color: '#000000',
    bgColor: 'rgba(237, 237, 237, 1)',
  },
  Closed: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  'On Hold': {
    color: '#F0AD4E',
    bgColor: 'rgba(240, 173, 78, 0.08)',
  },
  Escalated: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
};

export interface ITicketStatusCell {
  status: string;
}

export const TicketStatusCell = ({ status }: ITicketStatusCell) => {
  return (
    <Center
      bgColor={statusBadgeStyleProps[status]?.bgColor}
      borderRadius={'full'}
      fontWeight='semibold'
      my='sm'
      color={statusBadgeStyleProps[status]?.color}
      p='sm'>
      {status}
    </Center>
  );
};
