/**
 * Redux App Settings Actions
 */
import {
  CHANGE_TITLE,
  RTL_LAYOUT,
  SET_COUNTRY_CODE,
  SET_COUNTRY_DISPLAY,
  SET_LANGUAGE,
  SET_REDIRECTED_URL,
  SET_ROOT_OBJECT,
  UPDATE_TOUR,
} from 'redux/types';

export const rtlLayoutAction = (isRtlLayout) => ({
  type: RTL_LAYOUT,
  payload: isRtlLayout,
});

export const updateTour = (tour) => ({
  type: UPDATE_TOUR,
  payload: tour,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const changeTitle = (title) => (dispatch) => {
  dispatch({ type: CHANGE_TITLE, payload: title });
};

export const setRootObject = (rootObject) => ({
  type: SET_ROOT_OBJECT,
  payload: rootObject,
});

export const setCountryCode = (countryCode) => ({
  type: SET_COUNTRY_CODE,
  payload: countryCode,
});

export const setCountryDisplay = (countryDisplay) => ({
  type: SET_COUNTRY_DISPLAY,
  payload: countryDisplay,
});

export const setRedirectedUrl = (redirectedUrl) => ({
  type: SET_REDIRECTED_URL,
  payload: redirectedUrl,
});
