import { Box, Button, Heading, HStack, PinInput, PinInputField, Text, useBoolean } from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { use2FASendValidationApi } from 'apis/auth/use-2fa-send-validation';
import { Layout, Section } from 'layout';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

const ValidationCodePage = () => {
  const { mutate: sendValidateToken, isLoading } = use2FASendValidationApi();
  const [isPinInputsDisabled, setIsPinInputsDisabled] = useBoolean();
  const submitButton: React.LegacyRef<HTMLButtonElement> | undefined = React.createRef();
  const history = useHistory();

  // Redirect to login page after 10 minutes
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      history.push('/login');
    }, 1000 * 60 * 10); // 10 Minutes

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  // Form
  type TInputs = {
    code: string;
  };

  const defaultValues: TInputs = {
    code: '',
  };

  const formSchema = Yup.object().shape({
    code: Yup.string().required('Pin code is required.').length(6, 'Pin code must be exactly 6 digits.'),
  });

  const { handleSubmit, control, setValue } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data: TInputs) => {
    // Submit validation code
    const variables = {
      body: {
        token: data.code,
      },
    };

    sendValidateToken(variables);

    // Reset pin input value
    setValue('code', '');

    // Enable pin inputs again if invalid pin code
    setIsPinInputsDisabled.off();
  };

  const onComplete = () => {
    // Disable pin inputs
    setIsPinInputsDisabled.on();

    // Call submit validation code Fn
    submitButton.current?.click();
  };

  const handleGoToLogin = () => {
    history.push('/login');
  };

  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <Box w='100%' maxW='500px'>
          <Heading as='h3' mb='sm'>
            Validation Code
          </Heading>

          <Text fontSize='md' color='gray.500' mb='lg'>
            Please enter your six digits validation code
          </Text>

          <HStack
            as='form'
            id='validationCodeForm'
            spacing='md'
            justify='space-between'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            mb='lg'>
            <Controller
              control={control}
              name='code'
              render={({ field: { onChange, value } }) => (
                <PinInput onComplete={onComplete} isDisabled={isPinInputsDisabled} onChange={onChange} value={value}>
                  <PinInputField flexGrow={1} />
                  <PinInputField flexGrow={1} />
                  <PinInputField flexGrow={1} />
                  <PinInputField flexGrow={1} />
                  <PinInputField flexGrow={1} />
                  <PinInputField flexGrow={1} />
                </PinInput>
              )}
            />
          </HStack>

          <HStack align='center' justify='space-between'>
            <Button variant='link' colorScheme='gray' size='sm' onClick={handleGoToLogin}>
              Back to login page
            </Button>

            <Button
              variant='solid'
              colorScheme='red'
              size='lg'
              type='submit'
              form='validationCodeForm'
              w='150px'
              isLoading={isLoading}
              ref={submitButton}>
              Validate
            </Button>
          </HStack>
        </Box>
      </Section>
    </Layout>
  );
};

export default ValidationCodePage;
