import { Box, Divider, HStack, IconButton } from '@elkaso-app/web-design';
import { mdiHelpCircle } from '@mdi/js/mdi';
import Icon from '@mdi/react';
import { WhatsAppChatInitiative } from 'components/whatsapp-chat-initiative';
import { useToggleFullScreen } from 'hooks/use-toggle-full-screen';
import { FullscreenIcon } from 'layout/components/header/icons/fullscreen-icon';
import LanguageProvider from 'layout/components/header/LanguageProvider';
import { SelectCountry } from 'layout/components/header/select-country';
import { UserMenu } from 'layout/components/header/user-menu';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTour } from 'redux/actions';

export function HeaderRight(): JSX.Element {
  const { countryDisplay, title, tour } = useSelector((state: any) => state.settings);

  const [state, setState] = useState({
    title: '',
  });

  const dispatch = useDispatch();
  const toggleFullScreen = useToggleFullScreen();

  useEffect(() => {
    if (title && title !== state.title) {
      setState({
        ...state,
        title: title,
      });
    }
  }, [title]);

  const runTour = () => {
    dispatch(updateTour({ steps: tour.steps, run: true }));
  };

  return (
    <Box ms={'auto'}>
      <HStack spacing='1rem'>
        <WhatsAppChatInitiative />

        <Divider orientation='vertical' h='29px' borderColor={'#C5C5C5'} />

        {!!countryDisplay && <SelectCountry />}

        <Divider orientation='vertical' h='29px' borderColor={'#C5C5C5'} />

        <LanguageProvider />

        <IconButton
          size={'lg'}
          aria-label='fullscreen'
          icon={<FullscreenIcon />}
          onClick={toggleFullScreen}
          isRound
          variant={'ghost'}
        />

        {tour && tour.steps && tour.steps.length > 0 && (
          <IconButton
            aria-label='user'
            icon={<Icon path={mdiHelpCircle} size={1} color='#324BE6' />}
            onClick={runTour}
            isRound
            variant={'ghost'}
          />
        )}

        <UserMenu />
      </HStack>
    </Box>
  );
}
