import { Box, BoxProps, Input, InputGroup, InputLeftElement } from '@elkaso-app/web-design';
import { ChangeEvent } from 'react';
import { SearchIcon } from '../../icons/search-icon';

interface ISearch extends BoxProps {
  filterName: string;
  title: string;
  onFilterName: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchComponent = ({ title, filterName, onFilterName, ...rest }: ISearch) => {
  return (
    <Box {...rest}>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon />
        </InputLeftElement>
        <Input value={filterName} onChange={onFilterName} placeholder={`Search ${title}`} variant='outline' />
      </InputGroup>
    </Box>
  );
};
