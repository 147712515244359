import { useState } from 'react';
import { withRouter } from 'react-router-dom';

const ErrorBoundary = (props) => {
  const [state] = useState({ error: null, errorInfo: null });

  if (state.errorInfo) {
    // Error path
    return (
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {state.error && state.error.toString()}
          <br />
          {state.errorInfo.componentStack}
        </details>
      </div>
    );
  }
  // Normally, just render children
  return props.children;
};

export default withRouter(ErrorBoundary);
