import {
  ChakraSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InfoIcon,
  Input,
  Text,
  Tooltip,
  VStack,
} from '@elkaso-app/web-design';
import { useGetCatalogItemCategoriesApi } from 'apis/campaigns/use-get-catalog-categories-api';
import { useGetPublishersApi } from 'apis/campaigns/use-get-publishers-api';
import { useGetCountriesApi } from 'apis/users/use-get-countries-api';
import { CampaignFormValues } from 'pages/promotions/hooks/use-campaign-form-handler';
import { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form';

const targetOptions = [
  {
    value: 'supplier',
    label: 'Supplier',
  },
  {
    value: 'product',
    label: 'Product',
  },
];
interface FormSectionProps {
  control: Control<CampaignFormValues>;
  errors: FieldErrors<CampaignFormValues>;
  register: UseFormRegister<CampaignFormValues>;
  isEdit: boolean;
}

export const FormSection = ({ control, errors, register, isEdit }: FormSectionProps) => {
  const { isLoading: isLoadingPublishers } = useGetPublishersApi();
  const { isLoading: isLoadingCatalogCategories } = useGetCatalogItemCategoriesApi();
  const { isLoading: isLoadingCountries } = useGetCountriesApi();

  const { data: catalogCategories = [] } = useGetCatalogItemCategoriesApi();
  const { data: countries = [] } = useGetCountriesApi();
  const { data: getPublishers = [] } = useGetPublishersApi();

  const publisherOptions = useMemo(() => {
    return getPublishers?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
  }, [getPublishers]);

  const catalogCategoryOptions = useMemo(() => {
    return catalogCategories?.map((item) => {
      return {
        value: item?.id,
        label: item?.title,
      };
    });
  }, [catalogCategories]);

  const countryOptions = useMemo(() => {
    return countries?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
  }, [countries]);

  return (
    <>
      <Text fontSize='xl' fontWeight='bold'>
        Campaign Details
      </Text>

      <VStack spacing={'lg'}>
        {isEdit && (
          <FormControl isInvalid={!!errors.compaignName} isRequired>
            <FormLabel>Campaign Name</FormLabel>
            <Input variant='outline' placeholder='Enter Name' {...register('compaignName')} />

            <FormErrorMessage>{errors.compaignName?.message}</FormErrorMessage>
          </FormControl>
        )}

        <Controller
          control={control}
          name='country'
          render={({ field: { onChange, onBlur, value } }) => (
            <FormControl isInvalid={!!errors.country} isRequired>
              <FormLabel>Country</FormLabel>
              <ChakraSelect
                variant='outline'
                placeholder='Select Country'
                options={countryOptions}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                isLoading={isLoadingCountries}
              />

              <FormErrorMessage>{errors?.country?.value?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='publishers'
          render={({ field: { onChange, onBlur, value, name } }) => (
            <FormControl isInvalid={!!errors.publishers} isRequired>
              <FormLabel>Publisher</FormLabel>
              <ChakraSelect
                isMulti
                variant='outline'
                name={name}
                placeholder='Select Publishers'
                options={publisherOptions}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                closeMenuOnSelect={false}
                isLoading={isLoadingPublishers}
              />

              <FormErrorMessage>{errors?.publishers?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='catalogCategories'
          render={({ field: { onChange, onBlur, value, name } }) => (
            <FormControl isInvalid={!!errors.catalogCategories}>
              <FormLabel>Catalog Categories</FormLabel>
              <ChakraSelect
                isMulti
                variant='outline'
                name={name}
                placeholder='Select Catalog categories'
                options={catalogCategoryOptions}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                closeMenuOnSelect={false}
                isLoading={isLoadingCatalogCategories}
              />

              <FormErrorMessage>{errors?.catalogCategories?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='target'
          render={({ field: { onChange, onBlur, value } }) => (
            <FormControl isInvalid={!!errors.target} isRequired>
              <FormLabel>Target</FormLabel>

              <ChakraSelect
                placeholder='Select Target'
                variant='outline'
                options={targetOptions}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />

              <FormErrorMessage>{errors?.target?.value?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <FormControl isInvalid={!!errors.targetId} isRequired>
          <Tooltip
            placement='bottom-start'
            label='Copy the Supplier or Product ID, then paste it into the field below to navigate users to the campaign landing page.'>
            <FormLabel>
              Target ID <InfoIcon />
            </FormLabel>
          </Tooltip>

          <Input variant='outline' placeholder='Enter Target ID' {...register('targetId')} />

          <FormErrorMessage>{errors.targetId?.message}</FormErrorMessage>
        </FormControl>
      </VStack>
    </>
  );
};
