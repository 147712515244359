import { Icon, IconProps } from '@elkaso-app/web-design';

export const UploadFilesIcon = (props: IconProps) => {
  return (
    <Icon width='72px' height='69px' viewBox='0 0 72 69' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36 10C31.7108 10.0135 27.5678 11.5684 24.3195 14.3839C21.83 16.5399 20.0262 19.3492 19.5615 22.1683C14.1145 23.377 10 28.1463 10 33.9055C10 40.5956 15.551 45.9333 22.2882 45.9333H34.375V28.6429L27.4005 35.6564C27.0954 35.9631 26.6815 36.1354 26.25 36.1354C25.8185 36.1354 25.4046 35.9631 25.0995 35.6564C24.7944 35.3497 24.6229 34.9337 24.6229 34.5C24.6229 34.0663 24.7944 33.6503 25.0995 33.3436L34.8495 23.5436C35.0004 23.3915 35.1798 23.2708 35.3772 23.1885C35.5746 23.1061 35.7863 23.0637 36 23.0637C36.2137 23.0637 36.4254 23.1061 36.6228 23.1885C36.8202 23.2708 36.9996 23.3915 37.1505 23.5436L46.9005 33.3436C47.2056 33.6503 47.3771 34.0663 47.3771 34.5C47.3771 34.9337 47.2056 35.3497 46.9005 35.6564C46.5954 35.9631 46.1815 36.1354 45.75 36.1354C45.3185 36.1354 44.9046 35.9631 44.5995 35.6564L37.625 28.6429V45.9333H51.236C57.1315 45.9333 62 41.262 62 35.3918C62 30.0475 57.9635 25.6931 52.7895 24.9581C51.9998 16.5301 44.7425 10 36 10ZM34.375 57.3667V45.9333H37.625V57.3667C37.625 57.7999 37.4538 58.2153 37.149 58.5216C36.8443 58.8279 36.431 59 36 59C35.569 59 35.1557 58.8279 34.851 58.5216C34.5462 58.2153 34.375 57.7999 34.375 57.3667Z'
        fill='#939393'
      />
    </Icon>
  );
};
