import {
  GET_SUPPLIERS,
  GET_SUPPLIERS_FAILED,
  GET_SUPPLIER_ADMIN_USERS,
  GET_SUPPLIER_ADMIN_USERS_FAILED,
  GET_SUPPLIER_KPI,
  GET_SUPPLIER_MANAGER_USERS,
  GET_SUPPLIER_MANAGER_USERS_FAILED,
  GET_SUPPLIER_USERS,
  GET_SUPPLIER_USERS_FAILED,
  GET_WHATS_NUMBERS,
  GET_WHATS_NUMBERS_FAILED,
  SET_LOADING_STATUS,
  SET_LOADING_STATUS2,
  SET_SUPPLIER,
  SET_SUPPLIER_FAILED,
  SET_SUPPLIER_LOADER,
} from 'redux/types';

const INIT_STATE = {
  suppliersList: [],
  supplier: {},
  supplierUsers: [],
  supplierKpi: {},
  isLoading: false,
  isLoading2: false,
  supplierLoader: false,
  suppliersAdminList: [],
  suppliersManagerList: [],
  whatsNumbersList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return { ...state, isLoading: action.payload };
    case SET_LOADING_STATUS2:
      return { ...state, isLoading2: action.payload };
    case GET_SUPPLIERS:
      return { ...state, suppliersList: action.payload, isLoading: false };
    case GET_SUPPLIERS_FAILED:
      return { ...state, suppliersList: [], isLoading: false };
    case SET_SUPPLIER:
      return { ...state, supplier: action.payload, isLoading2: false };
    case SET_SUPPLIER_FAILED:
      return { ...state, supplier: {}, isLoading2: false };
    case GET_SUPPLIER_USERS:
      return { ...state, supplierUsers: action.payload, isLoading: false };
    case GET_SUPPLIER_USERS_FAILED:
      return { ...state, supplierUsers: [], isLoading: false };
    case GET_SUPPLIER_KPI:
      return { ...state, supplierKpi: action.payload, isLoading: false };
    case SET_SUPPLIER_LOADER:
      return { ...state, supplierLoader: action.payload, isLoading: false };
    case GET_SUPPLIER_ADMIN_USERS:
      return { ...state, suppliersAdminList: action.payload, isLoading: false };
    case GET_SUPPLIER_ADMIN_USERS_FAILED:
      return { ...state, suppliersAdminList: [], isLoading: false };
    case GET_SUPPLIER_MANAGER_USERS:
      return { ...state, suppliersManagerList: action.payload, isLoading: false };
    case GET_SUPPLIER_MANAGER_USERS_FAILED:
      return { ...state, suppliersManagerList: [], isLoading: false };
    case GET_WHATS_NUMBERS:
      return { ...state, whatsNumbersList: action.payload, isLoading: false };
    case GET_WHATS_NUMBERS_FAILED:
      return { ...state, whatsNumbersList: [], isLoading: false };
    default:
      return { ...state };
  }
};
