import { BasePagination, IconButtonWithTooltip, PenIcon, PlusIcon } from '@elkaso-app/web-design';
import { UserDto } from 'apis/config/swagger-types';
import { useGetUsersApi } from 'apis/users/use-get-users-api';
import { getMeEndpointIdentifier } from 'apis/users/use-me-api';
import { Can } from 'configs/can';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { AddUserModal, IAddUserModalCell } from 'pages/user-management/components/add-user-modal';
import {
  IUserActivationSwitchCell,
  UserActivationSwitch,
} from 'pages/user-management/components/user-activation-switch';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  AccountInfo,
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  IUserInfoCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { ActivationFilterButton, CreatedAtFilterButton, UserInfoFilterButton } from 'components/base-table/filters';
import { BaseSortButton } from 'components/base-table/sorting';
import { listUserCountries, listUserRoles, setHiddenColumns } from 'components/base-table/utils';
import { TwoFacAuthBadge } from 'pages/user-management/components/two-fac-auth-badge';
import { CellProps } from 'react-table';

export const UsersListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetUsersApi();
  const { pageCount, total, data: usersData } = data ?? {};
  const meApiResponse = useGetQueryClientData(getMeEndpointIdentifier);

  const disableAdminToAdminActions = (user: any) => {
    const isMeSuperAdmin = meApiResponse?.roles?.find((r: any) => r?.slug === 'super_admin');
    const isMeAdmin = meApiResponse?.roles?.find((r: any) => r?.slug === 'admin');

    const isUserSuperAdmin = user?.roles?.find((r: any) => r?.slug === 'super_admin');
    const isUserAdmin = user?.roles?.find((r: any) => r?.slug === 'admin');

    if (isMeAdmin && !isMeSuperAdmin && (isUserAdmin || isUserSuperAdmin)) return true;
    return false;
  };

  useEffect(() => {
    dispatch(changeTitle('breadcrumb.userManagement'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='User Info'
            filterButtonComponent={<UserInfoFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='name' />}
          />
        ),
        accessor: 'user_info',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['user_info']>) => (
          <CustomTableCell<IUserInfoCell>
            as={AccountInfo}
            name={value?.name}
            email={value?.email}
            phoneNumber={value?.phoneNumber}
          />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Creation Date'
            filterButtonComponent={<CreatedAtFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='createdAt' />}
          />
        ),
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Activation'
            filterButtonComponent={<ActivationFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='isActive' />}
          />
        ),
        accessor: 'activation',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['activation']>) => (
          <CustomTableCell<IUserActivationSwitchCell>
            as={UserActivationSwitch}
            userId={value?.id}
            isActive={value?.isActive}
            isDisabled={disableAdminToAdminActions(value)}
          />
        ),
      },
      {
        Header: <TableHeader name='2FA' sortButtonComponent={<BaseSortButton sortBy='totpEnabled' />} />,
        accessor: 'twoFacAuth',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['twoFacAuth']>) => (
          <CustomTableCell as={TwoFacAuthBadge} isTwoFacAuthActive={value} />
        ),
      },
      {
        Header: <TableHeader name='Role' />,
        accessor: 'roles',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['roles']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Countries' />,
        accessor: 'countries',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['countries']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IAddUserModalCell>
            as={AddUserModal}
            variant='edit'
            user={value}
            trigger={
              <IconButtonWithTooltip
                size='sm'
                label='Edit'
                aria-label='edit-user-btn'
                Icon={<PenIcon />}
                variant='unstyled'
                isDisabled={disableAdminToAdminActions(value)}
              />
            }
          />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    user_info: {
      name: string;
      email: string;
      phoneNumber: string;
    };
    creation_date: string;
    activation: UserDto;
    twoFacAuth: boolean;
    roles: string;
    countries: string;
    actions: UserDto;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      usersData?.map((user: UserDto): IColumnsAccessorData => {
        return {
          id: user?.id,
          user_info: {
            name: user?.name,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          },
          creation_date: user?.createdAt,
          activation: user,
          twoFacAuth: user?.totpEnabled,
          roles: listUserRoles(user?.roles),
          countries: listUserCountries(user?.permittedCountries),
          actions: user,
        };
      }),
    [usersData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='breadcrumb.userManagement' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />

          <Can I='update' a='User'>
            <AddUserModal
              variant='add'
              trigger={<IconButtonWithTooltip label='Add user' aria-label='add-user-btn' Icon={<PlusIcon />} />}
            />
          </Can>
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable
          columns={columns}
          data={columnsAccessorData}
          initialHiddenColumns={setHiddenColumns(['activation', 'actions'])}
        />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
