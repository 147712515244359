import { Container, ContainerProps } from '@elkaso-app/web-design';

export function HeaderContainer({ children }: ContainerProps): JSX.Element {
  return (
    <Container
      h='68px'
      maxW='100%'
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      py='sm'
      px='lg'
      borderBottom={'2px solid'}
      borderColor={'#e6e7ef'}>
      {children}
    </Container>
  );
}
