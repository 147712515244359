import { HStack, Text } from '@elkaso-app/web-design';
import { useDownloadDemoFileApi } from 'apis/restaurants/branch-supplier-items/use-download-demo-file';
import { DownArrowIcon } from 'pages/restaurants/icons/down-arrow-icon';

export const DownloadCSVDemoFile = () => {
  const { refetch: downloadDemoFile } = useDownloadDemoFileApi();

  return (
    <HStack spacing='sm' cursor='pointer' w='fit-content' onClick={() => downloadDemoFile()}>
      <Text color='red.500' fontWeight='semibold' userSelect='none'>
        Download Demo File
      </Text>
      <DownArrowIcon />
    </HStack>
  );
};
