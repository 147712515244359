import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useCreateSupplierUserApi } from 'apis/supplier/use-create-supplier-user-api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

type TInputs = {
  name: string;
  email: string;
  phoneNumber: string;
};

const defaultValues: TInputs = {
  name: '',
  email: '',
  phoneNumber: '',
};

export const AddSupplierUser = () => {
  const dispatch = useAppDispatch();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { isLoading, mutate: createSupplierUserApi } = useCreateSupplierUserApi();

  useEffect(() => {
    dispatch(changeTitle('add'));
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const onSubmit = (data: TInputs) => {
    const variables = {
      supplierId: supplierId,
      body: {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      },
    };

    createSupplierUserApi(variables);

    // Reset form data
    reset(defaultValues);
  };

  return (
    <Container maxW='container.md' centerContent>
      <WhiteCard p='lg' w='full'>
        <VStack spacing='lg' align='stretch'>
          <Text color='red.500' fontWeight='500' fontSize='2xl'>
            Add Supplier User
          </Text>

          <Stack as='form' id='addSupplierUser' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormControl isRequired isInvalid={errors.name ? true : false}>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', {
                  required: 'Name is required.',
                })}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={errors.email ? true : false}>
              <FormLabel>E-mail</FormLabel>
              <Input
                type='email'
                {...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value: /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: 'Email address is incorrect.',
                  },
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.phoneNumber ? true : false}>
              <FormLabel>Phone number</FormLabel>
              <Input
                type='tel'
                {...register('phoneNumber', {
                  required: false,
                  pattern: {
                    value: /^\+[1-9]\d{1,14}$/,
                    message: 'Phone number is incorrect.',
                  },
                })}
              />

              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <Button
            variant='solid'
            colorScheme='red'
            type='submit'
            form='addSupplierUser'
            isLoading={isLoading}
            alignSelf='end'>
            Create User
          </Button>
        </VStack>
      </WhiteCard>
    </Container>
  );
};
