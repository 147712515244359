import { Icon, IconProps } from '@elkaso-app/web-design';

export const WebIcon = (props: IconProps) => {
  return (
    <Icon width='42px' height='42px' viewBox='0 0 42 42' {...props}>
      <path
        d='M33.4703 6.38281H7.93906C6.92337 6.38281 5.94927 6.7863 5.23106 7.5045C4.51286 8.22271 4.10938 9.1968 4.10938 10.2125V28.0844C4.10938 29.1001 4.51286 30.0742 5.23106 30.7924C5.94927 31.5106 6.92337 31.9141 7.93906 31.9141H19.4281V34.4672H15.5984C15.2599 34.4672 14.9352 34.6017 14.6958 34.8411C14.4564 35.0805 14.3219 35.4052 14.3219 35.7437C14.3219 36.0823 14.4564 36.407 14.6958 36.6464C14.9352 36.8858 15.2599 37.0203 15.5984 37.0203H25.8109C26.1495 37.0203 26.4742 36.8858 26.7136 36.6464C26.953 36.407 27.0875 36.0823 27.0875 35.7437C27.0875 35.4052 26.953 35.0805 26.7136 34.8411C26.4742 34.6017 26.1495 34.4672 25.8109 34.4672H21.9812V31.9141H33.4703C34.486 31.9141 35.4601 31.5106 36.1783 30.7924C36.8965 30.0742 37.3 29.1001 37.3 28.0844V10.2125C37.3 9.1968 36.8965 8.22271 36.1783 7.5045C35.4601 6.7863 34.486 6.38281 33.4703 6.38281ZM7.93906 8.93594H33.4703C33.8089 8.93594 34.1336 9.07043 34.373 9.30983C34.6124 9.54924 34.7469 9.87393 34.7469 10.2125V22.9781H6.6625V10.2125C6.6625 9.87393 6.79699 9.54924 7.0364 9.30983C7.2758 9.07043 7.6005 8.93594 7.93906 8.93594ZM33.4703 29.3609H7.93906C7.6005 29.3609 7.2758 29.2264 7.0364 28.987C6.79699 28.7476 6.6625 28.4229 6.6625 28.0844V25.5312H34.7469V28.0844C34.7469 28.4229 34.6124 28.7476 34.373 28.987C34.1336 29.2264 33.8089 29.3609 33.4703 29.3609Z'
        fill='inherit'
      />
    </Icon>
  );
};
