import api from 'apis/countriesApi';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import { GET_COUNTRIES, SET_LOADING_STATUS, SET_PAGES_COUNT, SET_TOTAL_COUNT } from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getCountries = (pagination, filters, sort) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getCountries({ pagination, filters, sort })
    .then((response) => {
      dispatch({ type: GET_COUNTRIES, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch((err) => {
      NotificationManager.error(
        <IntlMessages id={err.message} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_COUNTRIES, payload: [] });
      dispatch({ type: SET_LOADING_STATUS, payload: false });
    });
};

export const addOrUpdateCountry = (data) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .addOrUpdateCountry(data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.reject(error);
    });
};

export const getCountryById = (id) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api.getCountryById(id).then((country) => {
    dispatch({ type: SET_LOADING_STATUS, payload: false });
    return Promise.resolve(country);
  });
};
