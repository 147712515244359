import { FormControl, MenuItem, Select } from '@material-ui/core';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { rtlLayoutAction, setLanguage } from 'redux/actions';
import { ArabicIcon } from './icons/arabic-icon';
import { EnglishIcon } from './icons/english-icon';

const LanguageProvider = () => {
  let { locale, languages } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const onChangeLanguage = (e) => {
    let lang = e.target.value;
    if (locale !== lang) {
      dispatch(setLanguage(lang));
      rtlLayoutHandler(lang.locale === 'ar');
    }
  };

  const rtlLayoutHandler = (isTrue) => {
    let dir = isTrue ? 'rtl' : 'ltr';
    $('html').attr('dir', dir);
    $('body').attr('dir', dir);
    isTrue
      ? $('body').addClass('rtl') && localStorage.setItem('language', 'ar')
      : $('body').removeClass('rtl') && localStorage.setItem('language', 'en');
    dispatch(rtlLayoutAction(isTrue));
  };

  locale = locale.name === languages[0].name ? languages[0] : languages[1];

  return (
    <FormControl className='no-border-select'>
      <Select name='language' id='language' value={locale} onChange={(e) => onChangeLanguage(e)}>
        {languages.map((language, index) => (
          <MenuItem key={index} value={language}>
            {language.icon === 'en' ? <EnglishIcon /> : <ArabicIcon />}
            <span style={{ margin: '0 10px' }}>{language.name}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageProvider;
