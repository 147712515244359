import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import IntlMessages from 'utils/intl-messages';

export const catchError = {
  checkUnique: (path, invalidInput, message) => {
    NotificationManager.error(
      <IntlMessages id={`global.errors.${path !== undefined ? path : message}.unique`} />,
      <IntlMessages id={`${AppConfig.errorTitle}`} />,
      AppConfig.errorNotificationTimeout
    );
    return (invalidInput[path] = true);
  },
};
