import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  VStack,
} from '@elkaso-app/web-design';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useRevertOrderApi } from '../../../apis/orders/use-revert-order-api';
dayjs.extend(advancedFormat);

const options = [
  'Partial order was received',
  'Supplier delivered the cancelled order',
  'Restaurant mistakenly received the order',
  'Other',
];

interface FormValues {
  reason: string;
  otherReason?: string;
}

const defaultValues: FormValues = {
  reason: 'Other',
  otherReason: '',
};

interface RevertOrderModalProps {
  orderId: number;
  previousStatus: string;
  isOpen: boolean;
  onClose: () => void;
}

export const RevertOrderModal = ({ orderId, isOpen, onClose, previousStatus }: RevertOrderModalProps) => {
  const { isLoading, mutate: revertOrderApi } = useRevertOrderApi();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues, reValidateMode: 'onChange' });

  const selectedReason = watch('reason', 'Other');

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const reason =
      data.reason === 'Other' ? `Revert Order (${data.reason}): ${data.otherReason}` : `Revert Order (${data.reason})`;

    const variables = {
      orderId: orderId,
      body: {
        comment: reason,
        status: previousStatus,
      },
    };

    revertOrderApi(variables, {
      onSuccess: () => {
        onClose();
        reset();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={reset} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <VStack align='start' spacing='xs'>
            <Heading fontSize='24px' fontWeight='semibold'>
              Revert Order
            </Heading>
            <Text color='gray.500' fontWeight='normal' fontSize='md'>
              Please give a reason for reverting the order.
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <form id='revertOrderModalForm' onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='reason'
              control={control}
              defaultValue='Other'
              render={({ field }) => (
                <RadioGroup {...field} colorScheme='red'>
                  <VStack align='flex-start'>
                    {options.map((value) => (
                      <Radio key={value} value={value} color='black'>
                        {value}
                      </Radio>
                    ))}
                  </VStack>
                </RadioGroup>
              )}
            />

            {selectedReason === 'Other' && (
              <FormControl isInvalid={!!errors.otherReason}>
                <Controller
                  name='otherReason'
                  control={control}
                  defaultValue=''
                  rules={{ required: selectedReason === 'Other', minLength: 10 }}
                  render={({ field }) => (
                    <Textarea {...field} placeholder='Please Type The Reason For Reverting The Order' size='sm' />
                  )}
                />

                <FormErrorMessage>Reason should be at least 10 characters</FormErrorMessage>
              </FormControl>
            )}
          </form>
        </ModalBody>

        <ModalFooter>
          <HStack spacing='sm'>
            <Button colorScheme='red' variant='outline' onClick={onClose}>
              Cancel
            </Button>
            <Button type='submit' form='revertOrderModalForm' colorScheme='red' variant='solid' isLoading={isLoading}>
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
