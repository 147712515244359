import { Badge } from '@elkaso-app/web-design';

type StatusObj = {
  [key: string]: { slug: string; name: string };
};

export const statusObj: StatusObj = {
  approved: {
    slug: 'approved',
    name: 'approved',
  },
  rejected: {
    slug: 'rejected',
    name: 'rejected',
  },
};

interface IStatusBadgeStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBadgeStyleProps: IStatusBadgeStyleProps = {
  approved: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  rejected: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
};

export interface IStatusBadgeCell {
  status: string;
}

export const StatusBadgeCell = ({ status }: IStatusBadgeCell) => {
  return (
    <>
      <Badge
        color={statusBadgeStyleProps[status]?.color}
        bgColor={statusBadgeStyleProps[status]?.bgColor}
        px='md'
        py='sm'
        borderRadius='full'
        fontSize='xs'
        textTransform={'capitalize'}
        _hover={{ shadow: 'sm' }}>
        {statusObj?.[status]?.name}
      </Badge>
    </>
  );
};
