import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

import { useGetCampaignsApi } from './use-get-campaigns-api';

const endpoint = 'ums/v1/advertising/managing/banners';
export const deleteCampaignEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  campaignId: string;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables, unknown>;

export function useDeleteCampaignApi(options?: Options): ReturnType {
  const { refetch: refetchCampaigns } = useGetCampaignsApi({ enabled: false });

  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ campaignId }: TVariables) => {
    try {
      const { data } = await http.delete(endpoint + `/${campaignId}`);

      refetchCampaigns();

      toast({
        description: 'Campaign have been deleted successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
