import { HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, WhiteCard } from '@elkaso-app/web-design';
import { ClearFilterButton } from 'components/base-table';
import { usePageParams } from 'hooks/use-page-params';
import { AllDocumentList } from 'pages/restaurants/components/restaurant-licenses/all-document-list';
import { PendingRequestList } from 'pages/restaurants/components/restaurant-licenses/pending-request-list';

export const DocumentsScreen = () => {
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const handleClearFilters = () => {
    const newParams: Record<string, any> = {};
    newParams.page = params?.page;
    newParams.limit = params?.limit;
    setPageParams(newParams);
  };

  return (
    <WhiteCard>
      <HStack p={'md'} align={'center'} justifyContent={'space-between'}>
        <Text fontSize={'lg'} fontWeight={'semibold'}>
          Documents
        </Text>
        <ClearFilterButton />
      </HStack>

      <Tabs>
        <TabList>
          <Tab onClick={handleClearFilters} fontSize={'md'}>
            Pending Requests
          </Tab>
          <Tab onClick={handleClearFilters} fontSize={'md'}>
            All Documents
          </Tab>
        </TabList>
        <TabPanels mt={0}>
          <TabPanel p={0}>
            <PendingRequestList />
          </TabPanel>
          <TabPanel p={0}>
            <AllDocumentList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </WhiteCard>
  );
};
