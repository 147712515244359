import {
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@elkaso-app/web-design';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import _orderBy from 'lodash/orderBy';
import { StepIcon } from '../icons/step-icon';
import { statusObj } from '../utils/order-status';
dayjs.extend(advancedFormat);

interface IOrderLifeTimeModal {
  isOpen: boolean;
  onClose: () => void;
  history: any;
}

export const OrderLifeTimeModal = ({ isOpen, onClose, history }: IOrderLifeTimeModal) => {
  const handleStepStrokeColor = (status: string) => {
    const isApproved = history?.find((s: any) => s?.status === 'approved');
    const isReceived = history?.find((s: any) => s?.status === 'received');

    if (isApproved && isReceived) {
      if (status === 'received') return '#51E03E';
      if (status === 'approved') return '#B1B1B1';
    }

    if (status === 'received' || status === 'approved') return '#51E03E';
    if (status === 'rejected' || status === 'canceled') return 'red.500';
    return '#B1B1B1';
  };

  const handleStepFillColor = (status: string) => {
    const isApproved = history?.find((s: any) => s?.status === 'approved');
    const isReceived = history?.find((s: any) => s?.status === 'received');

    if (isApproved && isReceived) {
      if (status === 'received') return '#51E03E';
      if (status === 'approved') return 'none';
    }

    if (status === 'received' || status === 'approved') return '#51E03E';
    if (status === 'rejected' || status === 'canceled') return 'red.500';
    return 'none';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Order Lifetime</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing='none' align='stretch'>
            {_orderBy(history, ['createdAt'], ['asc'])?.map(
              ({ status = '', createdAt = '', createdBy = '___' }, index: any) => (
                <HStack spacing='sm' key={index} align='flex-start' minH='82px' _last={{ minH: '0' }}>
                  <VStack mt='6px' justify='center'>
                    <StepIcon stroke={handleStepStrokeColor(status)} fill={handleStepFillColor(status)} />

                    {history?.length !== index + 1 && <Divider orientation='vertical' h='53px' />}
                  </VStack>

                  <Flex justify='space-between' align='center' flex={1}>
                    <VStack spacing={'2xs'} align='start'>
                      <Text fontSize={'lg'} fontWeight='bold' color={'gray.600'}>
                        {statusObj?.[status]?.name}
                      </Text>
                      <Text fontSize={'sm'} color='gray.500'>
                        By {createdBy}
                      </Text>
                    </VStack>

                    <VStack spacing={'2xs'} align='end'>
                      <Text fontSize={'sm'} color='gray.500'>
                        {dayjs(createdAt).format('MMMM Do YYYY')}
                      </Text>
                      <Text fontSize={'sm'} color='gray.500'>
                        {dayjs(createdAt).format('hh:mm A')}
                      </Text>
                    </VStack>
                  </Flex>
                </HStack>
              )
            )}
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
