import { HStack, Image, Text } from '@elkaso-app/web-design';

export interface ISupplierNameCell {
  image: string;
  name: string;
}

export const SupplierNameCell = ({ image, name }: ISupplierNameCell) => {
  return (
    <HStack spacing='md'>
      <Image borderRadius='50%' overflow='hidden' boxSize='50px' objectFit='cover' src={image} alt='IMG' />

      <Text color='gray.600' fontWeight='semibold' textTransform='capitalize'>
        {name}
      </Text>
    </HStack>
  );
};
