import { Badge } from '@elkaso-app/web-design';

export interface ItemLevelCellProps {
  branchId: number;
  subEntityType: 'branch' | 'warehouse';
}

export const ItemLevelCell = ({ branchId, subEntityType }: ItemLevelCellProps) => {
  if (branchId && subEntityType === 'branch') {
    return <Badge colorScheme='gray'>Branch</Badge>;
  }

  if (branchId && subEntityType === 'warehouse') {
    return <Badge colorScheme='orange'>Warehouse</Badge>;
  }

  return <Badge colorScheme='red'>Brand</Badge>;
};
