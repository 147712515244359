import { Icon, IconProps } from '@elkaso-app/web-design';

export const EnglishIcon = (props: IconProps) => {
  return (
    <Icon width='20px' height='20px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M14 10H16V10.757C16.6777 10.3042 17.4656 10.044 18.2796 10.0041C19.0936 9.96414 19.9032 10.146 20.6219 10.5303C21.3406 10.9147 21.9415 11.4869 22.3603 12.1861C22.7791 12.8852 23.0002 13.685 23 14.5V20H21V14.5C21 13.07 19.825 12 18.5 12C17.175 12 16 13.07 16 14.5V20H14V10ZM12 4V6H4V11H12V13H4V18H12V20H2V4H12Z'
        fill='inherit'
      />
    </Icon>
  );
};
