import { BasePagination, IconButtonWithTooltip, PlusIcon } from '@elkaso-app/web-design';
import { useGetRestaurantByIdApi } from 'apis/restaurants/use-get-restaurant-by-id-api';
import { useGetWarehouseByIdApi } from 'apis/restaurants/use-get-warehouse-by-id-api';
import { useGetWarehouseUsersApi } from 'apis/restaurants/use-get-warehouse-users-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { ActivationFilterButton, NameFilterButton, RoleIdFilterButton } from 'components/base-table/filters';
import { BaseSortButton } from 'components/base-table/sorting';
import { setHiddenColumns } from 'components/base-table/utils';
import { Can } from 'configs/can';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { AddWarehouseUserModal } from 'pages/restaurants/components/add-warehouse-user-modal';
import { IUserNameCell, UserNameCell } from 'pages/restaurants/components/user-name-cell';
import { IUserStatusCell, UserStatusCell } from 'pages/restaurants/components/user-status-cell';
import { IUsersActionsCell, UsersActionsCell } from 'pages/restaurants/components/users-actions-cell';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';

export const WarehouseUsersScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { restaurantId, warehouseId } = useParams<{ restaurantId: string; warehouseId: string }>();
  const { isLoading, isFetching, data } = useGetWarehouseUsersApi(restaurantId, warehouseId);
  const { data: warehouse } = useGetWarehouseByIdApi(restaurantId, warehouseId);
  const { pageCount, total, data: usersData } = data ?? {};

  // Get restaurant details
  const { data: restaurantData } = useGetRestaurantByIdApi(restaurantId);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='User Name'
            filterButtonComponent={<NameFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='name' />}
          />
        ),
        accessor: 'user_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['user_name']>) => (
          <CustomTableCell<IUserNameCell> as={UserNameCell} name={value.name} phoneNumber={value.phoneNumber} />
        ),
      },
      {
        Header: <TableHeader name='Role' filterButtonComponent={<RoleIdFilterButton />} />,
        accessor: 'role',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['role']>) => <TableCell value={value} />,
      },
      {
        Header: (
          <TableHeader
            name='Status'
            filterButtonComponent={<ActivationFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='isActive' />}
          />
        ),
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => (
          <CustomTableCell<IUserStatusCell> as={UserStatusCell} status={value} />
        ),
      },
      {
        Header: <TableHeader name='Creation Date' />,
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IUsersActionsCell> as={UsersActionsCell} userData={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    user_name: {
      name: string;
      phoneNumber: string;
    };
    role: string;
    status: any;
    creation_date: string;
    actions: any;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      usersData?.map((user: (typeof usersData)[0]): IColumnsAccessorData => {
        return {
          id: user?.id,
          user_name: {
            name: user?.name,
            phoneNumber: user?.phoneNumber,
          },
          role: user?.roles?.[0]?.name,
          status: user?.isActive ? 'active' : 'inactive',
          creation_date: user?.createdAt,
          actions: user,
        };
      }),
    [usersData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title={`${restaurantData?.data?.name} > ${warehouse?.name?.en} `} />

          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />

          <Can I='update' a='User'>
            <AddWarehouseUserModal
              trigger={
                <IconButtonWithTooltip label='Assign new user' aria-label='assign-new-user-btn' Icon={<PlusIcon />} />
              }
            />
          </Can>
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} initialHiddenColumns={setHiddenColumns(['actions'])} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
