import AppConfig from 'constants/AppConfig';
import generator from 'generate-password';
import { useState } from 'react';
import Geocode from 'react-geocode';
import { NotificationManager } from 'react-notifications';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import IntlMessages from 'utils/intl-messages';
// import moment from 'moment';
import moment from 'moment-timezone';

Geocode.setApiKey('AIzaSyD0eC_j5MVeV5YsOIRnLVRyIx9npurleUA');
export default (input, filesInput) => {
  const [value, setInfo] = useState(input);
  const [file, setFile] = useState(filesInput);

  const setValue = (value) => {
    setInfo(value);
  };

  const onChange = (e) => {
    if (e) {
      setInfo({
        ...value,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onChangeNumber = (value, name) => {
    setInfo({
      ...value,
      [name]: value,
    });
  };

  const onCheckChange = (e) => {
    if (e) {
      setInfo({
        ...value,
        [e.target.name]: e.target.checked,
      });
    }
  };
  const clearFields = (initFields) => {
    setInfo({
      ...value,
      ...initFields,
    });
  };
  const onMapClick = (e) => {
    let NewAddress;
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        NewAddress = response.results[0].formatted_address;
        setInfo({
          ...value,
          fullLine: NewAddress,
          coordinates: {
            type: 'Point',
            coordinates: [e.latLng.lng(), e.latLng.lat()],
          },
          lat: e.latLng.lat(),
          long: e.latLng.lng(),
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  //places actions
  const onChangeGoogleAddress = (address) =>
    setInfo({
      ...value,
      fullLine: address || ' ',
    });

  const onSelectGoogleAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0])
          .then(({ lat, lng }) => {
            setInfo({
              ...value,
              fullLine: results[0].formatted_address,
              ...{ coordinates: { type: 'Point', coordinates: [lng, lat] } },
              ...{ lat, long: lng },
              coordinates: {
                type: 'Point',
                coordinates: [lng, lat],
              },
              lat,
              long: lng,
            });
          })
          .catch((error) => console.error('Error getLatLng', error));
      })
      .catch((error) => console.error('Error geocodeByAddress', error));
  };

  //password actions
  const generatePassword = () => {
    const password = generator.generate({
      length: 8,
      numbers: true,
      uppercase: true,
      lowerCase: true,
      exclude: '',
    });
    setInfo({ ...value, password });
  };

  const toggleValue = (passedValue) => {
    setInfo({
      ...value,
      [passedValue]: !value[passedValue],
    });
  };

  //File actions
  const onUploadFile = (data, inputName, resizedName, fileId) => {
    if (data) {
      setFile({
        ...file,
        [inputName]: data.url,
        [resizedName]: data.urlResized,
        [fileId]: data.fileId,
      });
    } else
      NotificationManager.error(
        <IntlMessages id={'global.errors.notUpload'} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.notificationTimout
      );
  };

  const editFile = (inputName, resizedName, fileId) => {
    setFile({
      ...file,
      [inputName]: undefined,
      [resizedName]: undefined,
      [fileId]: undefined,
    });
  };

  const onChangeEditor = (html, name) => {
    setInfo({
      ...value,
      [name]: html,
    });
  };

  const onChangeTab = (e, newValue) => {
    if (e) {
      setInfo({
        ...value,
        activeTab: newValue,
      });
    }
  };

  const onEdit = (e, id, props, customPath) => {
    props.history.push(`${customPath ? customPath : 'edit'}/${id}`);
  };

  const onChangeFilterDate = (date, name) => {
    setInfo({
      ...value,
      [name]: {
        gte: moment(date)
          .utc(date.valueOf() + date.utcOffset() * 60000)
          .startOf('day')
          .toISOString(),
        lte: moment(date)
          .utc(date.valueOf() + date.utcOffset() * 60000)
          .endOf('day')
          .toISOString(),
      },
    });
  };

  const onChangeDate = (date, name) => {
    setInfo({
      ...value,
      [name]: moment(date)
        .utc(date.valueOf() + date.utcOffset() * 60000)
        .startOf('day')
        .toISOString(),
    });
  };

  const onChangeTime = (date, name) => {
    setInfo({ ...value, [name]: moment(date).toISOString() });
  };

  const changeAutoComplete = (e, val, name) => {
    setInfo({
      ...value,
      [name]: val,
    });
  };

  const changeAutoCompleteTextField = (e, name) => {
    setInfo({
      ...value,
      [name]: e.target.value,
    });
  };

  const blockInvalidChar = (e, array) => {
    array.includes(e.key) && e.preventDefault();
  };

  return {
    value,
    file,
    setValue,
    setFile,
    onChange,
    onChangeNumber,
    onCheckChange,
    onMapClick,
    onChangeGoogleAddress,
    onSelectGoogleAddress,
    generatePassword,
    toggleValue,
    editFile,
    onUploadFile,
    clearFields,
    onChangeEditor,
    onEdit,
    onChangeTab,
    onChangeFilterDate,
    changeAutoComplete,
    onChangeDate,
    blockInvalidChar,
    onChangeTime,
    changeAutoCompleteTextField,
  };
};
