import { uploadFileApi } from 'apis/config/upload-file-api';
import RctSectionLoader from 'components/RctSectionLoader/RctSectionLoader';
import AppConfig from 'constants/AppConfig';
import Types from 'constants/Files';
import { useState } from 'react';
import { DropzoneComponent } from 'react-dropzone-component';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConfirmationToggleDialog } from 'redux/actions';
import { LocalStorageKeys } from 'utils/constants';
import IntlMessages from 'utils/intl-messages';

let myDropzone = {};
const DropZoneFileUploader = (props) => {
  const accessToken = localStorage.getItem(LocalStorageKeys.access_token);
  const [state, setState] = useState({
    loading: false,
  });
  // For a full list of possible configurations,
  // please consult http://www.dropzonejs.com/#configuration

  const djsConfig = {
    addRemoveLinks: true,
    acceptedFiles: `image/${Types.JPG},image/${Types.PNG},image/${Types.JPEG}`,
    maxFiles: 1,
  };

  const componentConfig = {
    iconFiletypes: [`.${Types.JPG}`, `.${Types.PNG}`, `.${Types.JPEG}`],
    showFiletypeIcon: true,
    postUrl: 'no-url',
  };

  let filesCount = 0;
  const handleFileAdded = (file) => {
    filesCount += 1;
    if (filesCount > 1 && !props.isMulti) {
      NotificationManager.error(
        <IntlMessages id={'global.errors.multi'} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.notificationTimout
      );
      myDropzone.removeFile(file);
      return;
    }
    setState({ ...state, loading: true });
    let fileExtension = file.name.split('.').pop().toUpperCase();
    if (fileExtension !== Types.JPG && fileExtension !== Types.PNG && fileExtension !== Types.JPEG) {
      props.ConfirmationToggleDialog({
        onConfirm: () => handleFileAdded,
        title: 'error',
        name: 'errorExt',
        error: true,
        confirmButton: 'anotherImage',
        openConfirmation: true,
      });
      setState({ ...state, loading: false });
      NotificationManager.error(
        <IntlMessages id={'global.errors.notUpload'} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.notificationTimout
      );
      myDropzone.removeFile(file);
      filesCount = 0;
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    setState({ ...state, loading: true });
    uploadFileApi(formData, props.url, props.token, {}, accessToken)
      .then((response) => {
        setState({ ...state, loading: false });
        props.handleParentUpload(response.data, props.formInputName);
        myDropzone.removeFile(file);
      })
      .catch(() => {
        setState({ ...state, loading: false });
        myDropzone.removeFile(file);
        filesCount = 0;
        NotificationManager.error(
          <IntlMessages id={'global.errors.notUpload'} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.notificationTimout
        );
      });
  };

  const initCallback = (dropzone) => {
    myDropzone = dropzone;
  };

  const eventHandlers = {
    addedfile: handleFileAdded,
    init: initCallback,
  };

  return (
    <div className='dropzone-wrapper'>
      {state.loading && <RctSectionLoader />}
      <DropzoneComponent
        config={componentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}></DropzoneComponent>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, { ConfirmationToggleDialog })(DropZoneFileUploader));
