import { Layout, Section } from 'layout';
import { AggSheetsRelationsScreen } from 'pages/aggregated-sheets/screens/agg-sheets-relations-screens';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AggSheetsHistoryScreen } from './screens/agg-sheets-history-screen';

export const AggregatedSheets = () => {
  const match = useRouteMatch();

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
          <Route path={`${match.url}/list`} component={AggSheetsRelationsScreen} />
          <Route path={`${match.url}/history`} component={AggSheetsHistoryScreen} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};
