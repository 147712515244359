import {
  Button,
  ButtonProps,
  FullPageSpinner,
  HStack,
  StackProps,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useDownloadLpoApi } from 'apis/restaurant-transfers/use-download-lpo-api';
import { useGetRestaurantTransferDetailsApi } from 'apis/restaurant-transfers/use-get-restaurant-transfer-details-api';
import { useParams } from 'react-router-dom';
import { RestaurantTransferInvoiceModal } from '../components/restaruant-transfer-invoice-modal';
import { RestaurantTransferComments } from '../components/restaurant-transfer-comments';
import { RestaurantTransferDetailsSection } from '../components/restaurant-transfer-details-section';
import { RestaurantTransferItemsTable } from '../components/restaurant-transfer-items-table';
import { RestaurantTransferLifetimeModal } from '../components/restaurant-transfer-lifetime-modal';

interface ISectionHeader extends StackProps {
  sectionName: string;
  buttonArray?: ButtonProps[];
}

const SectionHeader = ({ sectionName, buttonArray, ...rest }: ISectionHeader) => {
  return (
    <HStack spacing='sm' pb='md' mb='md' borderBottom='4px solid' borderColor='gray.100' {...rest}>
      <Text fontSize='xl' fontWeight='bold' me='auto' textTransform='capitalize'>
        {sectionName}
      </Text>

      {buttonArray?.map(({ children, title, ...props }, i) => {
        if (title) {
          return (
            <Tooltip key={i} label={title}>
              <Button {...props}>{children}</Button>
            </Tooltip>
          );
        }

        return (
          <Button key={i} {...props}>
            {children}
          </Button>
        );
      })}
    </HStack>
  );
};

export const RestaurantTransfersDetailsScreen = () => {
  const { code } = useParams<{ code: string }>();
  const { isOpen: isOpenLifetimeModal, onClose: onCloseLifetimeModal, onOpen: onOpenLifetimeModal } = useDisclosure();
  const { isOpen: isOpenInvoiceModal, onClose: onCloseInvoiceModal, onOpen: onOpenInvoiceModal } = useDisclosure();

  const { isLoading, isFetching, data: transferData } = useGetRestaurantTransferDetailsApi(code);

  const {
    isLoading: isLoadingDownloadLpo,
    isFetching: isFetchingDownloadLpo,
    refetch: downloadLpoApi,
  } = useDownloadLpoApi(transferData?.id, transferData?.code);

  if (isLoading || isFetching) return <FullPageSpinner />;

  return (
    <>
      <HStack spacing='md' align='stretch' h='full'>
        <VStack flex={1} align='stretch' spacing='md'>
          <WhiteCard p='md' flex={1} display='flex' flexDir='column'>
            <SectionHeader
              sectionName='Transfer Details'
              buttonArray={[
                {
                  children: 'Transfer Lifetime',
                  variant: 'outline',
                  colorScheme: 'red',
                  onClick: onOpenLifetimeModal,
                },
              ]}
              mb='0'
            />

            <RestaurantTransferDetailsSection transfer={transferData} />
          </WhiteCard>

          <WhiteCard p='md'>
            <SectionHeader sectionName='Transfer Comments' />
            <RestaurantTransferComments restaurantTransferId={transferData?.id} />
          </WhiteCard>
        </VStack>

        <WhiteCard p='md' flex={-1} flexDir='column' display='flex'>
          <SectionHeader
            sectionName='Transfer Items'
            buttonArray={[
              {
                children: 'Attached Invoices',
                colorScheme: 'red',
                variant: 'outline',
                disabled: !transferData?.invoiceImages?.length,
                px: 'sm',
                onClick: onOpenInvoiceModal,
              },
              {
                children: 'Download LPO',
                variant: 'solid',
                colorScheme: 'red',
                onClick: () => downloadLpoApi(),
                isLoading: isLoadingDownloadLpo || isFetchingDownloadLpo,
              },
            ]}
          />

          <RestaurantTransferItemsTable
            items={transferData?.items}
            totalItems={transferData?.items?.length}
            subtotal={transferData?.subtotal}
            vatPercent={transferData?.vat}
            vatValue={transferData?.vatValue}
            total={transferData?.total}
            currencyCode={transferData?.currencyCode}
            status={transferData?.status}
          />
        </WhiteCard>
      </HStack>

      <RestaurantTransferLifetimeModal
        isOpen={isOpenLifetimeModal}
        onClose={onCloseLifetimeModal}
        history={transferData?.history}
      />

      <RestaurantTransferInvoiceModal
        isOpen={isOpenInvoiceModal}
        onClose={onCloseInvoiceModal}
        images={transferData?.invoiceImages}
      />
    </>
  );
};
