import { IconButtonWithTooltip } from '@elkaso-app/web-design';
import { ClearFilter } from 'components/base-table/icons/clear-filter';
import { usePageParams } from 'hooks/use-page-params';

interface ClearFilterButtonProps {
  whitelist?: string[];
}

export const ClearFilterButton = ({ whitelist }: ClearFilterButtonProps) => {
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const handleClearFilters = () => {
    const newParams: Record<string, any> = {};
    newParams.page = params?.page;
    newParams.limit = params?.limit;

    whitelist?.map((param) => {
      newParams[param] = params?.[param];
    });

    setPageParams(newParams);
  };

  return (
    <IconButtonWithTooltip
      label='Clear filter'
      aria-label='clear-filter-btn'
      Icon={<ClearFilter boxSize={5} />}
      onClick={handleClearFilters}
    />
  );
};
