import http from 'apis/config/http-service';
import { LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS, LOGOUT_USER, RE_BUILD_PERMISSIONS } from 'redux/types';
import { LocalStorageKeys } from 'utils/constants';

export const getCurrentUser = (newToken) => (dispatch) => {
  http
    .get(`${process.env.REACT_APP_BASE_URL}/users/me`)
    .then((res) => {
      dispatch({ type: LOGIN_USER_SUCCESS, payload: res.data.user });

      localStorage.setItem(LocalStorageKeys.cms_user, JSON.stringify(res.data.user));

      if (newToken) localStorage.setItem(LocalStorageKeys?.access_token, newToken);

      dispatch({
        type: RE_BUILD_PERMISSIONS,
        payload: {
          permissions: {},
          isSuperAdmin: false,
          user: res.data.user,
        },
      });
    })
    .catch(() => {
      dispatch(logoutUser());
      dispatch({ type: LOGIN_USER_FAILURE });
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER });
  localStorage.removeItem(LocalStorageKeys.cms_user);
  localStorage.removeItem(LocalStorageKeys.access_token);
  localStorage.removeItem(LocalStorageKeys.expiredTime);
};
