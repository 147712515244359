import api from 'apis/restaurants';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_BRANCHES_SUPPLIERS,
  GET_MERGED_DATA,
  GET_MERGED_DATA_FAILED,
  GET_MERGING_RESTAURANT_BRANCHES,
  GET_MERGING_RESTAURANT_BRANCHES_FAILED,
  GET_RESTAURANTS,
  GET_RESTAURANTS_FAILED,
  GET_RESTAURANTS_KPI,
  GET_RESTAURANTS_SUPPLIERS,
  GET_RESTAURANT_ADMIN_MANAGERS,
  GET_RESTAURANT_ADMIN_MANAGERS_FAILED,
  GET_RESTAURANT_BRANCHES,
  GET_RESTAURANT_BRANCHES_FAILED,
  GET_RESTAURANT_ORDERS_LIST,
  GET_RESTAURANT_ORDERS_LIST_FAILED,
  GET_RESTAURANT_SUPPLIER_EMAILS,
  GET_RESTAURANT_USERS,
  GET_RESTAURANT_USERS_FAILED,
  IS_SUCCESS_ASSIGN_ADMIN,
  SET_ANOTHER_LOADING,
  SET_ANOTHER_PAGES_COUNT,
  SET_ANOTHER_PAGE_NUMBER,
  SET_ANOTHER_TOTAL_COUNT,
  SET_BRANCH,
  SET_BRANCH_FAILED,
  SET_LOADING_STATUS,
  SET_MERGE_ERRORS,
  SET_PAGES_COUNT,
  SET_PAGE_NUMBER,
  SET_RESTAURANT,
  SET_RESTAURANT_FAILED,
  SET_TOTAL_COUNT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getRestaurants = (pagination, filters, join) => (dispatch) => {
  dispatch({ type: GET_RESTAURANT_USERS_FAILED });
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  dispatch({ type: GET_MERGED_DATA_FAILED });
  api
    .getRestaurants({ pagination, filters, join })
    .then((response) => {
      dispatch({ type: GET_RESTAURANTS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
      dispatch({ type: SET_LOADING_STATUS, payload: false });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_RESTAURANTS_FAILED });
      dispatch({ type: SET_LOADING_STATUS, payload: false });
    });
};

export const getRestaurantById = (id, params, entity) => (dispatch) => {
  if (!entity) dispatch({ type: { SET_LOADING_STATUS }, payload: true });
  else dispatch({ type: SET_ANOTHER_LOADING, payload: true });
  api
    .getRestaurantById(id, params)
    .then((response) => {
      if (!entity) dispatch({ type: { SET_LOADING_STATUS }, payload: false });
      else dispatch({ type: SET_ANOTHER_LOADING, payload: false });
      dispatch({ type: SET_RESTAURANT, payload: response.data });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: SET_RESTAURANT_FAILED });
      dispatch({ type: SET_ANOTHER_LOADING, payload: false });
    });
};

export const updateRestaurant = (restaurant) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .updateRestaurant(restaurant)
    .then((restaurant) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.resolve(restaurant);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};

export const getBranchById = (id) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getBranchById(id)
    .then((response) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: SET_BRANCH, payload: response.data });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: SET_BRANCH_FAILED });
    });
};

export const updateBranch = (branch) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .updateBranch(branch)
    .then((branch) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.resolve(branch);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      if (error && error.code !== 301) {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
      }
      return Promise.reject(error);
    });
};

export const getRestaurantUsers = (id, pagination, filters) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getRestaurantUsers(id, { pagination, filters })
    .then((response) => {
      dispatch({ type: GET_RESTAURANT_USERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_RESTAURANT_USERS_FAILED });
    });
};

export const getRestaurantSuppliers = (id, pagination, filters, sort, entity, join) => (dispatch) => {
  if (!entity) dispatch({ type: SET_LOADING_STATUS, payload: true });
  else dispatch({ type: SET_ANOTHER_LOADING, payload: true });
  api
    .getRestaurantSuppliers(id, { pagination, filters, sort, join })
    .then((response) => {
      dispatch({ type: GET_RESTAURANTS_SUPPLIERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
      dispatch({ type: SET_PAGE_NUMBER, payload: response.page });
      if (!entity) dispatch({ type: SET_LOADING_STATUS, payload: false });
      else dispatch({ type: SET_ANOTHER_LOADING, payload: false });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_RESTAURANTS_SUPPLIERS, payload: [] });
    });
};

export const getRestaurantKpi = (restaurantId, params) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getRestaurantKpi(restaurantId, params)
    .then((response) => {
      dispatch({ type: GET_RESTAURANTS_KPI, payload: response });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_RESTAURANTS_KPI, payload: {} });
    });
};

export const setRestaurant = (value) => (dispatch) => {
  dispatch({ type: SET_RESTAURANT, payload: value });
};

export const getRestaurantAdminManagersUsers = (id, pagination, customParams) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getRestaurantAdminManagersUsers(id, { pagination, customParams })
    .then((response) => {
      dispatch({ type: GET_RESTAURANT_ADMIN_MANAGERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_RESTAURANT_ADMIN_MANAGERS_FAILED });
    });
};

export const clearTable = () => (dispatch) => {
  dispatch({ type: GET_RESTAURANT_ADMIN_MANAGERS_FAILED });
};

export const changePreference = (data, params) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .changePreference(data, { params })
    .then((res) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.resolve(res);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};

export const getRestaurantBranches =
  ({ pagination, filters, join, entity, sort }) =>
  (dispatch) => {
    if (!entity) dispatch({ type: SET_LOADING_STATUS, payload: true });
    else dispatch({ type: SET_ANOTHER_LOADING, payload: true });
    dispatch({ type: GET_MERGING_RESTAURANT_BRANCHES_FAILED });
    dispatch({ type: SET_MERGE_ERRORS, payload: {} });
    api
      .getRestaurantBranches({ pagination, filters, join, sort })
      .then((response) => {
        dispatch({ type: GET_RESTAURANT_BRANCHES, payload: response.data });
        if (!entity) {
          dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
          dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
          dispatch({ type: SET_PAGE_NUMBER, payload: response.page });
          dispatch({ type: SET_LOADING_STATUS, payload: false });
        } else {
          dispatch({ type: SET_ANOTHER_TOTAL_COUNT, payload: response.total });
          dispatch({ type: SET_ANOTHER_PAGES_COUNT, payload: response.pageCount });
          dispatch({ type: SET_ANOTHER_PAGE_NUMBER, payload: response.page });
          dispatch({ type: SET_ANOTHER_LOADING, payload: false });
        }
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: SET_LOADING_STATUS, payload: false });
        dispatch({ type: SET_ANOTHER_LOADING, payload: false });
        dispatch({ type: GET_RESTAURANT_BRANCHES_FAILED, payload: [] });
      });
  };

export const getMergingRestaurantBranches =
  ({ pagination, filters, join }) =>
  (dispatch) => {
    dispatch({ type: SET_ANOTHER_LOADING, payload: true });
    api
      .getRestaurantBranches({ pagination, filters, join })
      .then((response) => {
        dispatch({ type: GET_MERGING_RESTAURANT_BRANCHES, payload: response.data });
        dispatch({ type: SET_ANOTHER_LOADING, payload: false });
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: SET_ANOTHER_LOADING, payload: false });
        dispatch({ type: GET_MERGING_RESTAURANT_BRANCHES_FAILED });
      });
  };

export const mergeRestaurants =
  ({ data, holdingId, MergedId }) =>
  (dispatch) => {
    dispatch({ type: SET_LOADING_STATUS, payload: true });
    api
      .mergeRestaurants(data, holdingId, MergedId)
      .then(() => {
        dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: false });
        dispatch({ type: GET_MERGED_DATA });
        dispatch({ type: SET_LOADING_STATUS, payload: false });
      })
      .catch((errors) => {
        dispatch({ type: SET_LOADING_STATUS, payload: false });
        dispatch({ type: SET_MERGE_ERRORS, payload: errors });
        dispatch({ type: GET_MERGED_DATA_FAILED });
      });
  };

export const clearErrors = () => (dispatch) => {
  dispatch({ type: SET_MERGE_ERRORS, payload: {} });
};

export const assignRestaurantAdminAction = (data, restaurantId) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: false });
  return api
    .assignResAdmin(data, restaurantId)
    .then((res) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: true });
      NotificationManager.success(
        <IntlMessages id={`restaurants.assign.success`} />,
        <IntlMessages id={`${AppConfig.successTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.resolve(res);
    })
    .catch((errors) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: SET_MERGE_ERRORS, payload: errors });
      dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: false });
      return Promise.reject(errors);
    });
};

export const updateAdminInfo = (info) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: false });
  return api
    .updateAdminInfo(info)
    .then((res) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: true });
      NotificationManager.success(
        <IntlMessages id={`restaurants.assign.update.success`} />,
        <IntlMessages id={`${AppConfig.successTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.resolve(res);
    })
    .catch((errors) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: false });
      dispatch({ type: SET_MERGE_ERRORS, payload: errors });
      return Promise.reject(errors);
    });
};

export const selectRestaurantAdminAction = (restaurantId, userId) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: false });
  return api
    .selectResAdmin(restaurantId, userId)
    .then((res) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: true });
      NotificationManager.success(
        <IntlMessages id={`restaurants.assign.success`} />,
        <IntlMessages id={`${AppConfig.successTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.resolve(res);
    })
    .catch((errors) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      dispatch({ type: SET_MERGE_ERRORS, payload: errors });
      dispatch({ type: IS_SUCCESS_ASSIGN_ADMIN, payload: false });
      return Promise.reject(errors);
    });
};

export const getRestaurantOrdersList =
  ({ pagination, filters, join }) =>
  (dispatch) => {
    dispatch({ type: SET_LOADING_STATUS, payload: true });
    api
      .getRestaurantOrdersList({ pagination, filters, join })
      .then((response) => {
        dispatch({ type: GET_RESTAURANT_ORDERS_LIST, payload: response.data });
        dispatch({ type: SET_LOADING_STATUS, payload: false });
        dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
        dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: GET_RESTAURANT_ORDERS_LIST_FAILED });
      });
  };

export const clearOrdersList = () => (dispatch) => {
  dispatch({ type: GET_RESTAURANT_ORDERS_LIST_FAILED });
};
export const clearResBranches = () => (dispatch) => {
  dispatch({ type: GET_RESTAURANT_BRANCHES_FAILED });
};
export const clearResSuppliers = () => (dispatch) => {
  dispatch({ type: GET_RESTAURANTS_SUPPLIERS, payload: [] });
};

export const getRestaurantSupplierEmails = (restaurantId, supplierId) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getRestaurantSupplierEmails(restaurantId, supplierId)
    .then((response) => {
      dispatch({ type: GET_RESTAURANT_SUPPLIER_EMAILS, payload: response.data });
      dispatch({ type: SET_LOADING_STATUS, payload: false });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_RESTAURANT_SUPPLIER_EMAILS, payload: [] });
    });
};

export const onVerifyEmailCC = (restaurant) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .verifyEmailCC(restaurant)
    .then((restaurant) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.resolve(restaurant);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      return Promise.reject(error);
    });
};

export const getBranchSuppliers = (id, pagination, filters, sort) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  api
    .getBranchSuppliers(id, { pagination, filters, sort })
    .then((response) => {
      dispatch({ type: GET_BRANCHES_SUPPLIERS, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
      dispatch({ type: SET_PAGE_NUMBER, payload: response.page });
      dispatch({ type: SET_LOADING_STATUS, payload: false });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_BRANCHES_SUPPLIERS, payload: [] });
    });
};
