import { BasePagination, formatDateTime, IconButtonWithTooltip, PlusIcon } from '@elkaso-app/web-design';
import { useGetRestaurantByIdApi } from 'apis/restaurants/use-get-restaurant-by-id-api';
import { useGetWarehouseByIdApi } from 'apis/restaurants/use-get-warehouse-by-id-api';
import { useGetWarehouseSuppliersApi } from 'apis/restaurants/warehouse-suppliers/use-get-warehouse-suppliers-api';
import { BaseSortButton, BaseTable, BaseTableContainer, NameFilterButton } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { setHiddenColumns } from 'components/base-table/utils';
import { Can } from 'configs/can';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { IsActiveCell, IsActiveCellProps } from 'pages/restaurants/components/warehouse-suppliers/is-active-cell';
import { IsPublicCell, IsPublicCellProps } from 'pages/restaurants/components/warehouse-suppliers/is-public-cell';
import {
  ISupplierActionsCell,
  SupplierActionsCell,
} from 'pages/restaurants/components/warehouse-suppliers/supplier-action-cell';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AddSupplierModal } from '../components/warehouse-suppliers/add-supplier-modal';
import { ISupplierNameCell, SupplierNameCell } from '../components/warehouse-suppliers/supplier-name-cell';

export const WarehouseSupplierListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { restaurantId, warehouseId } = useParams<{ restaurantId: string; warehouseId: string }>();

  // Get warehouse details
  const { data: warehouseData } = useGetWarehouseByIdApi(restaurantId, warehouseId);

  // Get restaurant details
  const { data: restaurantData } = useGetRestaurantByIdApi(restaurantId);

  // Get warehouse suppliers
  const { isLoading, isFetching, data } = useGetWarehouseSuppliersApi(restaurantId, warehouseId);
  const { pageCount, total, data: suppliersData } = data ?? {};

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='Supplier Name'
            sortButtonComponent={<BaseSortButton sortBy='name' />}
            filterButtonComponent={<NameFilterButton />}
          />
        ),
        accessor: 'name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['name']>) => (
          <CustomTableCell<ISupplierNameCell> as={SupplierNameCell} image={value.image} name={value.name} />
        ),
      },
      {
        Header: <TableHeader name='Is Public' />,
        accessor: 'isPublic',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['isPublic']>) => (
          <CustomTableCell<IsPublicCellProps> as={IsPublicCell} isPublic={value} />
        ),
      },
      {
        Header: <TableHeader name='Is Active' />,
        accessor: 'isActive',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['isActive']>) => (
          <CustomTableCell<IsActiveCellProps> as={IsActiveCell} isActive={value} />
        ),
      },
      {
        Header: <TableHeader name='Creation Date' />,
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['createdAt']>) => (
          <TableCell value={formatDateTime(value)} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'action',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['action']>) => (
          <CustomTableCell<ISupplierActionsCell> as={SupplierActionsCell} action={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    name: {
      image: string;
      name: string;
    };
    createdAt: string;
    isActive: boolean;
    isPublic: boolean;
    action: any;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      suppliersData?.map((supplier: (typeof suppliersData)[0]): IColumnsAccessorData => {
        return {
          id: supplier?.id,
          name: {
            image: supplier?.image,
            name: supplier?.name,
          },
          createdAt: supplier?.createdAt,
          isActive: supplier?.isActive,
          isPublic: supplier?.isPublic,
          action: supplier,
        };
      }),
    [suppliersData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title={`${restaurantData?.data?.name} > ${warehouseData?.name?.en}`} />

          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />

          <Can I='update' a='User'>
            <AddSupplierModal
              trigger={
                <IconButtonWithTooltip label='Add supplier' aria-label='add-suppliers-btn' Icon={<PlusIcon />} />
              }
            />
          </Can>
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} initialHiddenColumns={setHiddenColumns(['actions'])} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
