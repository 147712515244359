import { BasePagination, IconButtonWithTooltip, PenIcon } from '@elkaso-app/web-design';
import { useGetProductsApi } from 'apis/product-category/use-get-products-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { CodeFilterButton } from 'components/base-table/filters/code';
import { StatusFilterButton } from 'components/base-table/filters/status';
import { BaseSortButton } from 'components/base-table/sorting';
import { Can } from 'configs/can';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { filter } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { CategoryImageCell, ICategoryImageCell } from './category-image-cell';
import { IMoveCategoryModalCell, MoveCategoryModal } from './move-category-modal';
import { SearchComponent } from './search-component';
import { IStatusBadgeCell, StatusBadgeCell } from './status-badge-cell';
import { ISupplierCell, SupplierCell } from './supplier-cell';

function applyFilter(product: any[], query: string) {
  const stabilizedThis = product.map((el, index) => [el, index]);

  if (query) {
    return filter(product, (_item) => _item.nameEn?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export const ProductsListTable = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { categoryId = '' } = useParams<{ categoryId: string }>();
  const { isLoading, isFetching, data } = useGetProductsApi(Number(categoryId));
  const { pageCount, total, data: ProductsData } = data ?? {};
  const [filterName, setFilterName] = useState('');

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Image' />,
        accessor: 'imageUrl',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['imageUrl']>) => (
          <CustomTableCell<ICategoryImageCell> as={CategoryImageCell} image={value} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Name EN'
            filterButtonComponent={<NameFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='nameEn' />}
          />
        ),
        accessor: 'nameEn',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['nameEn']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Name AR' sortButtonComponent={<BaseSortButton sortBy='nameAr' />} />,
        accessor: 'nameAr',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['nameAr']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Code'
            filterButtonComponent={<CodeFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='code' />}
          />
        ),
        accessor: 'code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['code']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Unit' />,
        accessor: 'unit',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['unit']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Brand' sortButtonComponent={<BaseSortButton sortBy='brandEn' />} />,
        accessor: 'brand',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['brand']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Supplier' />,
        accessor: 'supplier',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier']>) => (
          <CustomTableCell<ISupplierCell> as={SupplierCell} name={value.name} id={value.id} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Status'
            filterButtonComponent={<StatusFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='status' />}
          />
        ),
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => (
          <CustomTableCell<IStatusBadgeCell> as={StatusBadgeCell} status={value} />
        ),
      },
      {
        Header: <TableHeader name='Creation Date' />,
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: (
          <Can I='update' a='User'>
            <TableHeader name='Actions' />
          </Can>
        ),
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <Can I='update' a='User'>
            <CustomTableCell<IMoveCategoryModalCell>
              as={MoveCategoryModal}
              variant='edit'
              value={value}
              trigger={
                <IconButtonWithTooltip
                  size='sm'
                  label='Edit'
                  aria-label='edit-category-btn'
                  Icon={<PenIcon />}
                  variant='unstyled'
                />
              }
            />
          </Can>
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    imageUrl: string;
    nameEn: string;
    nameAr: string;
    code: string;
    unit: string;
    brand: string;
    supplier: {
      id: number;
      name: string;
    };
    status: string;
    creation_date: string;
    actions: number;
  }

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.currentTarget.value);
  };

  const filteredUsers = applyFilter(ProductsData ?? [], filterName);

  const columnsAccessorData = React.useMemo(
    () =>
      filteredUsers?.map((product): IColumnsAccessorData => {
        return {
          id: product?.id,
          imageUrl: product?.imageUrl,
          nameEn: product?.nameEn,
          nameAr: product?.nameAr,
          code: product?.code,
          unit: product?.defaultUnit,
          brand: product?.brandEn,
          supplier: {
            id: product?.supplier?.id,
            name: product?.supplier?.name,
          },
          status: product?.status,
          creation_date: product?.createdAt,
          actions: product?.id,
        };
      }),
    [filteredUsers]
  );

  return (
    <BaseTableContainer border={0} isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Products' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right minHeight={'auto'}>
          <SearchComponent title={'products'} filterName={filterName} onFilterName={handleFilterByName} px='sm' />
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper minHeight={'300px'}>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
