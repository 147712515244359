/**
 * @name Store
 * @author Moaaz Ahmed
 * @summary This file will be containing all the configs for redux store.
 * @description This file contains some generic code that is responsible for running redux. Please align with your manager before updating this file.
 * @access private
 *
 * @readonly Please don't make any changes to this file.
 */

// TODO: Moaaz, we need to remove the thunk after replace all the async actions with react-query hooks
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from 'redux/rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: process.env.REACT_APP_ENV_VAR !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
