import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetAggSheetsHistoryApi } from './use-get-agg-sheets-history-api';

const endpoint = 'oms/v1/aggregated-sheets/reports';

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  id: number;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables, unknown>;

export function useRetryAggSheetJobApi(options?: Options): ReturnType {
  const { refetch: reFetchAggSheetJobs } = useGetAggSheetsHistoryApi({ enabled: false });

  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ id }: TVariables) => {
    try {
      const { data } = await http.post(endpoint + `/${id}/retry`);

      reFetchAggSheetJobs();

      toast({
        description: 'The job has been restarted successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
