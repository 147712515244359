import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { LocalStorageKeys } from 'utils/constants';
import { onLogout } from 'utils/on-logout';

export const getAccessToken = () => localStorage.getItem(LocalStorageKeys.access_token) || '';
export const getLanguage = () => localStorage.getItem(LocalStorageKeys.language) || 'en';
export const getCountry = () => {
  const country = localStorage.getItem(LocalStorageKeys.country);
  return country && country !== 'GL' ? country : '';
};

export const createAuthorizationHeader = (token?: string) => (token ? `Bearer ${token}` : '');

// Create API instance
const http = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
});

http.defaults.headers.post['Content-Type'] = 'application/json';

// Add a request interceptor
http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = {
      ['Authorization']: createAuthorizationHeader(getAccessToken()),
      ['Accept-Language']: getLanguage(),
      ['Accept-Country']: getCountry(),
      ...config.headers,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    // Unauthorized
    if (error?.response?.status === 401) {
      onLogout();
    }

    return Promise.reject(error);
  }
);

export default http;
