import { BasePagination, IconButtonWithTooltip, PenIcon, PlusIcon } from '@elkaso-app/web-design';
import { Can } from 'configs/can';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { AddCategoryModal, IAddCategoryModalCell } from 'pages/categories/components/add-category-modal';
import {
  CategoryActivationSwitch,
  ICategoryActivationSwitchCell,
} from 'pages/categories/components/category-activation-switch';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

import { useGetCategoriesApi } from 'apis/categories/use-get-categories-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { ActivationFilterButton, CreatedAtFilterButton, TitleFilterButton } from 'components/base-table/filters';
import { BaseSortButton } from 'components/base-table/sorting';
import { setHiddenColumns } from 'components/base-table/utils';
import { CellProps } from 'react-table';

export const CategoriesListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetCategoriesApi();

  const { pageCount, total, data: categoriesData } = data ?? {};

  useEffect(() => {
    dispatch(changeTitle('sidebar.categories'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='Title'
            filterButtonComponent={<TitleFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='title' />}
          />
        ),
        accessor: 'title',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['title']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Creation Date'
            filterButtonComponent={<CreatedAtFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='createdAt' />}
          />
        ),
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Activation'
            filterButtonComponent={<ActivationFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='isActive' />}
          />
        ),
        accessor: 'activation',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['activation']>) => (
          <CustomTableCell<ICategoryActivationSwitchCell>
            as={CategoryActivationSwitch}
            categoryId={value?.id}
            isActive={value?.isActive}
          />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IAddCategoryModalCell>
            as={AddCategoryModal}
            variant='edit'
            category={value}
            trigger={
              <IconButtonWithTooltip
                size='sm'
                label='Edit'
                aria-label='edit-category-btn'
                Icon={<PenIcon />}
                variant='unstyled'
              />
            }
          />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    title: string;
    creation_date: string;
    activation: any;
    actions: any;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      categoriesData?.map((category: (typeof categoriesData)[0]): IColumnsAccessorData => {
        return {
          id: category?.id,
          title: category?.title,
          creation_date: category?.createdAt,
          activation: category,
          actions: category,
        };
      }),
    [categoriesData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='sidebar.categories' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />

          <Can I='update' a='User'>
            <AddCategoryModal
              variant='add'
              trigger={<IconButtonWithTooltip label='Add category' aria-label='add-category-btn' Icon={<PlusIcon />} />}
            />
          </Can>
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable
          columns={columns}
          data={columnsAccessorData}
          initialHiddenColumns={setHiddenColumns(['activation', 'actions'])}
        />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
