import { FormControl, MenuItem, Select } from '@material-ui/core';
import GlobalLangIcon from 'assets/flag-icons/GL.svg';
import { useEffect, useState } from 'react';
import { getCountries } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { LocalStorageKeys } from 'utils/constants';

export const SelectCountry = () => {
  const [value, setValue] = useState(localStorage.getItem(LocalStorageKeys.country) || 'GL');
  const { countriesList } = useAppSelector((state: any) => state.countries);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const getCountriesCode = () => {
    type TCountriesCodes = { code: string; flag: string }[];
    const countriesCodes: TCountriesCodes = [];

    countriesCodes.push({ code: 'GL', flag: GlobalLangIcon });

    countriesList?.map((country: any) =>
      countriesCodes.push({
        code: country?.code,
        flag: country?.flag,
      })
    );

    return countriesCodes;
  };

  const onChangeCountryCode = (e: any) => {
    const selectedCountryCode = e.target.value;
    const currentCountryCode = localStorage.getItem(LocalStorageKeys.country);

    if (currentCountryCode !== selectedCountryCode) {
      localStorage.setItem(LocalStorageKeys.country, selectedCountryCode);
      setValue(selectedCountryCode);
      window.location.reload();
    }
  };

  return (
    <FormControl className='no-border-select'>
      <Select name='country' id='country' value={value} onChange={(e) => onChangeCountryCode(e)}>
        {getCountriesCode().map((country: any, index: any) => (
          <MenuItem key={index} value={country.code}>
            <img src={country.flag} width='22' height='22' alt='country-icon' />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
