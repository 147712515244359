import Button from '@material-ui/core/Button';
import { Layout, Section } from 'layout';
import { useHistory } from 'react-router-dom';

const AccessDeniedPage = () => {
  const history = useHistory();

  return (
    <Layout type='blank_page_layout'>
      <Section name='content'>
        <div className='class-404-background'>
          <div className='container flex-center h-100 expired-session scale-1'>
            <div className='flex-center expired-session'>
              <img src={require('assets/elkaso/invitation.svg').default} alt="can't help" />
              <h1 className='login-title'>Oopsss! Access Denied</h1>
              <p className='login-info text-center'>
                Looks like you have navigated too far from your Space, we can&lsquo;t help you here
              </p>
            </div>
            <Button
              className={'button-custom-width'}
              color='primary'
              variant='contained'
              onClick={() => history.push('/')}>
              Back
            </Button>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default AccessDeniedPage;
