import { Icon, IconProps } from '@elkaso-app/web-design';

export const PreferencesIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M2.783 2.66L11 1L19.217 2.66C19.4391 2.70488 19.6377 2.81724 19.78 2.97855C19.9224 3.13986 20 3.34047 20 3.54727V12.6264C19.9999 13.5243 19.756 14.4083 19.2899 15.2C18.8238 15.9917 18.1499 16.6665 17.328 17.1645L11 21L4.672 17.1645C3.85027 16.6666 3.17646 15.9919 2.71035 15.2004C2.24424 14.4089 2.00024 13.5251 2 12.6273V3.54727C2.00004 3.34047 2.07764 3.13986 2.21999 2.97855C2.36234 2.81724 2.56094 2.70488 2.783 2.66ZM11 10.0909C11.663 10.0909 12.2989 9.85146 12.7678 9.42524C13.2366 8.99902 13.5 8.42095 13.5 7.81818C13.5 7.21542 13.2366 6.63734 12.7678 6.21112C12.2989 5.7849 11.663 5.54545 11 5.54545C10.337 5.54545 9.70107 5.7849 9.23223 6.21112C8.76339 6.63734 8.5 7.21542 8.5 7.81818C8.5 8.42095 8.76339 8.99902 9.23223 9.42524C9.70107 9.85146 10.337 10.0909 11 10.0909ZM6.527 14.6364H15.473C15.3518 13.6349 14.8283 12.7093 14.0029 12.037C13.1775 11.3647 12.1082 10.9929 11 10.9929C9.89175 10.9929 8.82248 11.3647 7.99708 12.037C7.17168 12.7093 6.64822 13.6349 6.527 14.6364Z'
        fill='inherit'
      />
    </Icon>
  );
};
