import MomentUtils from '@date-io/moment';
import { jssPreset, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AppLocale from 'assets/lang';
import { theme } from 'constants/theme/generalTheme.js';
import addLightStyles from 'constants/theme/LightOverride.js';
import addLTRStyles from 'constants/theme/LTROverride.js';
import addRTLStyles from 'constants/theme/RTLOverride.js';
import { create } from 'jss';
import rtl from 'jss-rtl';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

// Kaso Theme
import { KasoThemeProvider } from '@elkaso-app/web-design';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const ThemeProviders = (props) => {
  const { children } = props;
  const { locale, rtlLayout } = useSelector((state) => state.settings);
  const currentAppLocale = AppLocale[locale.locale];

  let cashedTheme = React.useMemo(() => theme);
  cashedTheme = {
    ...cashedTheme,
    palette: {
      ...theme.palette,
      ...props.color,
    },
  };

  rtlLayout ? (cashedTheme = addRTLStyles(cashedTheme)) : (cashedTheme = addLTRStyles(cashedTheme));
  props.theme === 'light' && addLightStyles(cashedTheme);

  return (
    <MuiThemeProvider theme={cashedTheme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KasoThemeProvider>
            <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
              {children}
            </IntlProvider>
          </KasoThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
};
