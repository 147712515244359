import { BasePagination } from '@elkaso-app/web-design';
import { useGetAggSheetsRelationsApi } from 'apis/agg-sheets-relations/use-get-agg-sheets-relations-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { ActionsCell, IActionsCell } from 'pages/aggregated-sheets/components/actions-cell';
import { ILastOrderRuleCell, LastOrderRuleCell } from 'pages/aggregated-sheets/components/last-order-rule-cell';
import { ISupplierCell, SupplierCell } from 'pages/aggregated-sheets/components/supplier-cell';
import React, { useEffect } from 'react';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { AggSheetRelation } from '../types';

export const AggSheetsRelationsScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();

  const { isLoading, isFetching, data } = useGetAggSheetsRelationsApi();
  const { pageCount, total, data: aggSheetsRelationsData } = data ?? {};

  useEffect(() => {
    dispatch(changeTitle('Aggregated Sheets Relations'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Restaurant Name' />,
        accessor: 'restaurantName',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['restaurantName']>) => (
          <TableCell value={value} color='black' fontWeight='semibold' />
        ),
      },
      {
        Header: <TableHeader name='Supplier' />,
        accessor: 'supplier',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier']>) => (
          <CustomTableCell<ISupplierCell> as={SupplierCell} name={value.name} type={value.type} />
        ),
      },
      {
        Header: <TableHeader name='Buffer Time (minutes)' />,
        accessor: 'processingBufferTime',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['processingBufferTime']>) => (
          <TableCell value={value} color='black' />
        ),
      },
      {
        Header: <TableHeader name='Last Order Rule' />,
        accessor: 'hasLastOrderRule',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['hasLastOrderRule']>) => (
          <CustomTableCell<ILastOrderRuleCell> as={LastOrderRuleCell} id={value.id} value={value.value} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IActionsCell> as={ActionsCell} aggSheet={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    restaurantName: string;
    supplier: {
      name: string;
      type: string;
    };
    processingBufferTime: number;
    hasLastOrderRule: {
      id: number;
      value: boolean;
    };
    actions: AggSheetRelation;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      aggSheetsRelationsData?.map((item: AggSheetRelation): IColumnsAccessorData => {
        return {
          id: item?.id,
          restaurantName: item?.restaurant?.nameEn,
          supplier: {
            name: item?.supplier?.name,
            type: item?.supplier?.type,
          },
          processingBufferTime: item?.processingBufferTime,
          hasLastOrderRule: {
            id: item?.id,
            value: item?.hasLastOrderRule,
          },
          actions: item,
        };
      }),
    [aggSheetsRelationsData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Aggregated Sheets Relations' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
