import { Tag, Wrap, WrapItem } from '@elkaso-app/web-design';

export interface ItemSynonymsCellProps {
  value: string[];
}

export const ItemSynonymsCell = ({ value }: ItemSynonymsCellProps) => {
  return (
    <Wrap>
      {value?.map((v) => (
        <WrapItem key={v}>
          <Tag size='sm'>{v}</Tag>
        </WrapItem>
      ))}
    </Wrap>
  );
};
