import { Layout, Section } from 'layout';
import { TicketDetailsScreen } from 'pages/ticket-management/screens/ticket-details-screen';
import { TicketsListScreen } from 'pages/ticket-management/screens/tickets-list-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const TicketManagementPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Route path={`${match.url}/list`} component={TicketsListScreen} />
          <Route path={`${match.url}/details/:ticketId`} component={TicketDetailsScreen} />
          <Redirect to={`${match.url}/list`} exact />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default TicketManagementPage;
