import { Image } from '@elkaso-app/web-design';
import AltImage from 'assets/img/alt-image.png';

export interface IProductImageCell {
  imageURL: string;
}

export const ProductImageCell = ({ imageURL }: IProductImageCell) => {
  return <Image src={imageURL} fallbackSrc={AltImage} boxSize='55px' borderRadius='base' overflow='hidden' />;
};
