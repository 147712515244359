import { HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { Can } from 'configs/can';
import { useHistory, useParams } from 'react-router-dom';
import { ProductsIcon } from '../../icons/products-icon';
import { UnLinkIcon } from '../../icons/unlink-icon';
import { DetachSupplierModal } from './detach-supplier-modal';

export interface ISupplierActionsCell {
  action: any;
}

export const SupplierActionsCell = ({ action }: ISupplierActionsCell) => {
  const history = useHistory();
  const { warehouseId = '', restaurantId = '' } = useParams<{ warehouseId: string; restaurantId: string }>();

  const onProductClick = () => {
    history.push(`/app/restaurants/warehouse/suppliers/items/${restaurantId}/${warehouseId}/${action?.id}`);
  };

  return (
    <HStack spacing='sm'>
      <Can I='update' a='User'>
        <DetachSupplierModal
          name={action?.name}
          supplierId={action?.id}
          trigger={
            <IconButtonWithTooltip
              Icon={<UnLinkIcon boxSize='md' />}
              label='Detach'
              aria-label='detach-button'
              variant='ghost'
              size='sm'
            />
          }
        />
      </Can>

      <IconButtonWithTooltip
        Icon={<ProductsIcon boxSize='md' />}
        label='Products'
        aria-label='Products'
        variant='ghost'
        size='sm'
        onClick={onProductClick}
      />
    </HStack>
  );
};
