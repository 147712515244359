import { Avatar, HStack, Text, VStack } from '@elkaso-app/web-design';

export interface IBrandNameCell {
  brand: { name: string; email: string; phone: string; image: string };
}

export const BrandNameCell = ({ brand }: IBrandNameCell) => {
  return (
    <HStack spacing='md'>
      <Avatar bg='red.500' boxSize='40px' src={brand?.image} />

      <VStack align={'start'}>
        <Text textTransform={'capitalize'} fontWeight='semibold' color='red.500'>
          {brand.name}
        </Text>

        <Text color='gray.600'>{brand.email}</Text>
        <Text color='gray.600'>{brand.phone}</Text>
      </VStack>
    </HStack>
  );
};
