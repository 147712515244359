import { Icon, IconProps } from '@elkaso-app/web-design';

export const OtpIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <path
        d='M20.9954 9.7385C20.9856 9.62199 20.9357 9.5137 20.8556 9.43504C20.7754 9.35639 20.671 9.31309 20.5628 9.3137H11.4891C10.8912 6.83594 8.81003 5 6.33373 5C3.39239 5 1 7.58688 1 10.7673C1 13.9485 3.39239 16.5347 6.33373 16.5347C8.96474 16.5347 11.1505 14.4636 11.5842 11.7517H13.6049V14.863H13.6081C13.616 14.9816 13.665 15.0926 13.7455 15.1737C13.8259 15.2547 13.9317 15.2999 14.0417 15.3C14.0477 15.3 14.053 15.2985 14.0589 15.2983V15.301H15.4612V15.3003C15.5712 15.3003 15.6771 15.2552 15.7575 15.1741C15.838 15.093 15.887 14.9819 15.8948 14.8633H15.8984V11.7519H17.043V16.5625H17.0465C17.0545 16.6811 17.1036 16.7921 17.184 16.8732C17.2645 16.9543 17.3703 16.9996 17.4803 17L17.4851 16.9995H18.8901L18.895 17C19.0049 16.9996 19.1107 16.9543 19.1911 16.8732C19.2716 16.7921 19.3207 16.6811 19.3288 16.5625H19.3322V11.7517H20.5632V11.7512C20.679 11.751 20.7901 11.7011 20.872 11.6125C20.9538 11.524 20.9999 11.4039 21 11.2786H20.9998V9.73826H20.9954V9.7385ZM6.33373 14.1151C4.64685 14.1151 3.27446 12.6301 3.27446 10.8064C3.27446 8.98235 4.64685 7.49764 6.33373 7.49764C8.02038 7.49764 9.39209 8.98235 9.39209 10.8064C9.39232 12.6304 8.02038 14.1151 6.33373 14.1151Z'
        fill='inherit'
      />
    </Icon>
  );
};
