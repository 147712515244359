import { HStack, Td, Tr } from '@elkaso-app/web-design';
import { DragHandle } from 'components/base-table/components';

interface IStaticTableRow {
  row: any;
}

export const StaticTableRow = ({ row }: IStaticTableRow) => {
  return (
    <Tr boxShadow='xl' outline='1px solid #a5a5a5' {...row.getRowProps()}>
      {row.cells?.map((cell: any, i: any) => {
        if (i === 0) {
          return (
            <Td key={i} {...cell.getCellProps()}>
              <HStack spacing='md' align='center'>
                <DragHandle isDragging={true} />
                {cell.render('Cell')}
              </HStack>
            </Td>
          );
        }

        return (
          <Td key={i} {...cell.getCellProps()}>
            {cell.render('Cell')}
          </Td>
        );
      })}
    </Tr>
  );
};
