/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import Loadable from 'react-loadable';
import IntlMessages from 'utils/intl-messages';

// Restaurants List
export const AsyncRestaurantsListComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantsList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

export const RestaurantBranchesComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantBranchesList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Restaurants Form
export const AsyncRestaurantsFormComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantsForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Restaurant Suppliers List
export const AsyncRestaurantSuppliersListComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantSuppliersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});
// Restaurant Branches Suppliers List
export const RestaurantBranchesSuppliersList = Loadable({
  loader: () => import('pages/restaurants/restaurantBranchesSuppliersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Restaurant Orders List
export const RestaurantOrdersList = Loadable({
  loader: () => import('pages/restaurants/restaurantOrdersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Restaurant Details
export const RestaurantDetails = Loadable({
  loader: () => import('pages/restaurants/restaurantDetails'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// branch Form
export const AsyncBranchFormComponent = Loadable({
  loader: () => import('pages/restaurants/branchForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// restaurant Users List
export const AsyncRestaurantUsersListComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantUsersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// restaurant Users Form
export const AsyncRestaurantUsersFormComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantUsersForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});
export const RestaurantManagersListComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantManagersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// restaurant Users Form
export const AsyncRestaurantKpiComponent = Loadable({
  loader: () => import('pages/restaurants/restaurantKpi'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Suppliers List
export const AsyncSuppliersListComponent = Loadable({
  loader: () => import('pages/suppliers/suppliersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Suppliers Form
export const AsyncSuppliersFormComponent = Loadable({
  loader: () => import('pages/suppliers/suppliersForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Suppliers Add
export const AsyncSuppliersAddComponent = Loadable({
  loader: () => import('pages/suppliers/supplierAdd'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Suppliers users
export const AsyncSupplierUsersListComponent = Loadable({
  loader: () => import('pages/suppliers/supplierUsersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Suppliers users form
export const AsyncSupplierUsersFormComponent = Loadable({
  loader: () => import('pages/suppliers/supplierUsersForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Suppliers users form
export const AsyncSupplierKpiComponent = Loadable({
  loader: () => import('pages/suppliers/supplierKpi'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Elkaso users list
export const AsyncElkasoUsersListComponent = Loadable({
  loader: () => import('pages/elkasoUsers/elkasoUsersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Elkaso users form
export const AsyncElkasoUsersFormComponent = Loadable({
  loader: () => import('pages/elkasoUsers/elkasoUsersForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Invitation Requests
export const AsyncInvitationRequestListComponent = Loadable({
  loader: () => import('pages/invitationRequest/invitationRequestList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Invitation Requests Form
export const AsyncInvitationRequestFormComponent = Loadable({
  loader: () => import('pages/invitationRequest/invitationForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Units List
export const AsyncUnitsListComponent = Loadable({
  loader: () => import('pages/units/unitsList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Units Form
export const AsyncUnitsFormComponent = Loadable({
  loader: () => import('pages/units/unitsForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Statistics
export const AsyncStatisticsComponent = Loadable({
  loader: () => import('pages/statistics/statistics'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Promotions List
export const PromotionsListComponent = Loadable({
  loader: () => import('pages/promotions/promotionsList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Promotion Form
export const PromotionFormComponent = Loadable({
  loader: () => import('pages/promotions/promotionForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Permissions
export const PermissionsComponent = Loadable({
  loader: () => import('pages/permissions/permissionForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Restaurant merge
export const RestaurantMergePage = Loadable({
  loader: () => import('pages/restaurants/restaurantMergePage'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Restaurant Supplier Emails
export const RestaurantSupplierEmails = Loadable({
  loader: () => import('pages/restaurants/restaurantSupplierEmails'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// OTP List
export const OTPList = Loadable({
  loader: () => import('pages/sms-otp/otpList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Whats Numbers List
export const WhatsNumbersList = Loadable({
  loader: () => import('pages/whatsNumbers/whatsNumbersList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Countries List
export const CountriesList = Loadable({
  loader: () => import('pages/countries/countriesList'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});

// Country Form
export const CountryForm = Loadable({
  loader: () => import('pages/countries/countryForm'),
  loading: () => (
    <div className='init-loading'>
      <IntlMessages id={'global.loading'} />
    </div>
  ),
});
