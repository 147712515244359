import { Button, ChakraSelect, FormControl, FormLabel, HStack, Stack, useDisclosure } from '@elkaso-app/web-design';
import { useGetCountriesApi } from 'apis/users/use-get-countries-api';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
};

type TInputs = {
  countryCode: TOption | undefined;
};

const defaultValues: TInputs = {
  countryCode: undefined,
};

export const CountryCodeFilterButton = () => {
  const [countryCode, setIsCountryCode] = useState<string | undefined>(undefined);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();
  const { data: countries = [] } = useGetCountriesApi();

  const countryOptions = countries?.map((country) => {
    return { value: country.code.toString(), label: country.name };
  });

  useEffect(() => {
    setIsCountryCode((params?.countryCode as string) ?? '');
  }, [params.countryCode]);

  const { control, handleSubmit, formState, reset, setValue } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  const updateValues = () => {
    setValue(
      'countryCode',
      countryOptions.find((o) => {
        if (params?.countryCode) {
          return o.value === params?.countryCode;
        }

        return undefined;
      })
    );
  };

  useEffect(() => {
    updateValues();
  }, [params.countryCode]);

  const onSubmit = (data: TInputs) => {
    params.countryCode = data?.countryCode?.value as string;

    setPageParams(params);
    onClose();
    reset(data);
  };

  const handleClose = () => {
    onClose();
    updateValues();
  };

  const handleDisableFilterButton = () => {
    const isDisabled = Object.keys(formState.dirtyFields).length ? false : true;
    return isDisabled;
  };

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={!!countryCode}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='countryCode'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Country</FormLabel>
                <ChakraSelect
                  id='countryCode'
                  options={countryOptions}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                />
              </FormControl>
            )}
          />

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' disabled={handleDisableFilterButton()}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
