import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

const endpoint = 'catalog/v1/restaurants';
export const downloadItemsCSVFileEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = string;

type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

function constructEndpoint(restaurantId: string, supplierId: string) {
  return `${endpoint}/${restaurantId}/suppliers/${supplierId}/products/download`;
}

export function useDownloadItemsCSVFileApi(options?: Options): ReturnType {
  const toast = useCustomToast();

  const { restaurantId, supplierId } = useParams<{
    supplierId: string;
    restaurantId: string;
  }>();

  const fullEndpoint = constructEndpoint(restaurantId, supplierId);

  const queryFn = async () => {
    try {
      await http
        .get(fullEndpoint, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          fileDownload(response.data, `Restaurant_catalog_CSV_template.csv`);
        });
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper(fullEndpoint, queryFn, {
    enabled: false,
    ...options,
  });
}
