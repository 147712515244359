import { Badge, useDisclosure } from '@elkaso-app/web-design';
import { statusObj } from '../utils/restaurant-transfer-status';
import { RestaurantTransferLifetimeModal } from './restaurant-transfer-lifetime-modal';

interface IStatusBadgeStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBadgeStyleProps: IStatusBadgeStyleProps = {
  approved: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  modified: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  canceled: {
    color: '#110000',
    bgColor: 'rgba(17, 0, 0, 0.05)',
  },
  created: {
    color: '#160C45',
    bgColor: 'rgba(22, 12, 69, 0.08)',
  },
  rejected: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
  received: {
    color: '#5BC0DE',
    bgColor: 'rgba(91, 192, 222, 0.08)',
  },
};

export interface IStatusBadgeCell {
  status: string;
  history: any;
}

export const StatusBadgeCell = ({ status, history }: IStatusBadgeCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Badge
        color={statusBadgeStyleProps[status].color}
        bgColor={statusBadgeStyleProps[status].bgColor}
        px='md'
        py='sm'
        borderRadius='full'
        fontSize='xs'
        cursor='pointer'
        textTransform={'capitalize'}
        _hover={{ shadow: 'sm' }}
        onClick={onOpen}>
        {statusObj?.[status]?.name}
      </Badge>

      <RestaurantTransferLifetimeModal isOpen={isOpen} onClose={onClose} history={history} />
    </>
  );
};
