import { Box, HStack, IconButton, Tooltip } from '@elkaso-app/web-design';
import { BreadcrumbBar } from 'components/breadcrumb-bar';
import PageTitleBar from 'components/breadcrumb-bar/PageTitleBar';
import { FeatureFlags } from 'feature-flags';
import { MenuIcon } from 'layout/components/header/icons/menu-icon';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleSidebar } from 'redux/sidebar/reducer';

export function HeaderLeft(): JSX.Element {
  const isSidebarOpen = useAppSelector((state) => state.sidebar.isOpen);
  const { title } = useAppSelector((state: any) => state.settings);
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Box me='auto'>
      <HStack spacing='0.5rem'>
        <Box onClick={handleToggleSidebar}>
          <Tooltip label={isSidebarOpen ? 'Close' : 'Open'}>
            <IconButton aria-label='sidebar' icon={<MenuIcon />} isRound variant={'ghost'} size={'lg'} />
          </Tooltip>
        </Box>

        {title && (
          <Box>
            <FeatureFlags feature='enable_breadcrumb_v2'>
              <BreadcrumbBar />
            </FeatureFlags>

            <FeatureFlags feature='enable_breadcrumb_v2' reverse>
              <PageTitleBar title={title} match={match} location={location} />
            </FeatureFlags>
          </Box>
        )}
      </HStack>
    </Box>
  );
}
