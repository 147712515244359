import { HStack, Text, TextProps } from '@elkaso-app/web-design';

export interface ITableHeader extends TextProps {
  name: string;
  filterButtonComponent?: JSX.Element;
  sortButtonComponent?: JSX.Element;
}

export const TableHeader = ({ name, filterButtonComponent, sortButtonComponent, ...rest }: ITableHeader) => {
  return (
    <HStack spacing='xs'>
      <Text {...rest}>{name}</Text>

      {/* Filter Button */}
      {filterButtonComponent && <>{filterButtonComponent}</>}

      {/* Sort Button */}
      {sortButtonComponent && <>{sortButtonComponent}</>}
    </HStack>
  );
};
