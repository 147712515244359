import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useUnassignWarehouseUserApi } from 'apis/restaurants/use-unassign-warehouse-user-api';
import React from 'react';
import { useParams } from 'react-router-dom';

interface IDeleteWarehouseUserModal {
  userData: any;
  trigger: JSX.Element;
}

export const DeleteWarehouseUserModal = ({ trigger, userData }: IDeleteWarehouseUserModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { restaurantId, warehouseId } = useParams<{ restaurantId: string; warehouseId: string }>();

  const { isLoading: isUnassignWarehouseUserLoading, mutate: unassignWarehouseUserApi } = useUnassignWarehouseUserApi(
    restaurantId,
    warehouseId
  );

  const onDelete = () => {
    unassignWarehouseUserApi(
      { userId: userData?.id },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Un-assign User</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text color='gray.500' fontSize='md'>
              Are you sure you want to un-assign this user? This will not delete the user, it will only un-assign this
              user from the warehouse
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' variant='ghost' me='sm' onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='red' variant='solid' onClick={onDelete} isLoading={isUnassignWarehouseUserLoading}>
              Un-assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
