type StatusObj = {
  [key: string]: { slug: string; name: string };
};

export const statusObj: StatusObj = {
  approved: {
    slug: 'approved',
    name: 'Confirmed',
  },
  modified: {
    slug: 'modified',
    name: 'Modified & Confirmed',
  },
  draft: {
    slug: 'draft',
    name: 'Draft',
  },
  canceled: {
    slug: 'canceled',
    name: 'Canceled',
  },
  restaurant_placed: {
    slug: 'restaurant_placed',
    name: 'New Order',
  },
  supplier_placed: {
    slug: 'supplier_placed',
    name: 'Supplier Placed',
  },
  rejected: {
    slug: 'rejected',
    name: 'Rejected',
  },
  received: {
    slug: 'received',
    name: 'Received',
  },
  restaurant_placed_approval: {
    slug: 'restaurant_placed_approval',
    name: 'Need Approval',
  },
  draft_expire: {
    slug: 'draft_expire',
    name: 'Expired Draft',
  },
};
