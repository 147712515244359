import { HStack, Stack, Text, VStack, WhiteCard } from '@elkaso-app/web-design';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { CategoryDetailsSection } from '../components/product-category/category-details-section';
import { TreeViewSection } from '../components/product-category/tree-view-section';
import { SelectCategoryIcon } from '../icons/select-category-icon';

export const ProductCategoriesScreen = () => {
  const params = useParams<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeTitle('Product Categories'));
  }, []);

  return (
    <HStack maxWidth={'full'} overflow={'hidden'} spacing='md' align='stretch' h='full'>
      <WhiteCard w='350px' minW='350px'>
        <VStack h='full' spacing='md' alignItems='stretch' py='md'>
          <Text fontSize='xl' fontWeight='bold' px='md'>
            All Categories
          </Text>
          <TreeViewSection />
        </VStack>
      </WhiteCard>

      <WhiteCard display={'flex'} flexDirection={'column'} width={'full'} overflow={'hidden'}>
        {!params.categoryId ? (
          <Stack align='center' justifyContent={'center'} h='full'>
            <SelectCategoryIcon />
          </Stack>
        ) : (
          <CategoryDetailsSection />
        )}
      </WhiteCard>
    </HStack>
  );
};
