import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import suppliersApi from 'apis/suppliers';
import usersApi from 'apis/users';
import AutoCompleteComponent from 'components/AutoComplete/AutoComplete';
import AppConfig from 'constants/AppConfig';
import { SUPPLIER_MANAGER } from 'constants/users';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConfirmationToggleDialog, MergeSupplierUserDialog } from 'redux/actions';
import { useDebounce } from 'use-lodash-debounce';
import IntlMessages from 'utils/intl-messages';

const MergeSupplierDialog = (props) => {
  const close = () => {
    props.MergeSupplierUserDialog({
      openMergeDialog: false,
      confirmButton: 'merge.yes',
      cancelButton: '',
    });
  };

  const closeConfirmation = () => {
    props.MergeSupplierUserDialog({
      openMergeDialog: true,
      confirmButton: 'merge.yes',
    });
    props.ConfirmationToggleDialog({
      openConfirmation: false,
    });
  };

  const {
    dialog: {
      colorClass = 'primary',
      title,
      openMergeDialog,
      confirmButton,
      cancelButton,
      supplierId,
      supplierName,
      fetchData,
      countryCode,
    },
  } = props;

  const [errors, setErrors] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [usersNames, setUsersNames] = useState([]);
  const [supplierUserObj, setSupplierUserObj] = useState({});
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 500);

  const { supplierUserError } = errors;

  useEffect(() => {
    setInputValue('');
    setUsersNames([]);
    setErrors({});
    setSupplierUserObj({});
  }, [openMergeDialog]);

  const getUsersNames = () => {
    if (inputValue) {
      setSearchLoading(true);
      let filters = {
        filters: {
          name: { $contL: inputValue },
          supplierId: { ne: supplierId },
          countryCode: countryCode !== 'GL' ? { eq: countryCode } : {},
          type: { eq: SUPPLIER_MANAGER },
        },
        sort: 'name,ASC',
        join: ['supplier'],
      };
      usersApi.getUsers(filters).then((res) => {
        setUsersNames(res.data);
        setSearchLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      setErrors({});
    }
  }, [debouncedValue]);

  useEffect(() => {
    getUsersNames();
  }, [debouncedValue]);

  const onConfirm = () => {
    props.ConfirmationToggleDialog({
      onConfirm: () => updateValue(),
      title: 'suppliers.merge',
      name: 'suppliers.merge',
      confirmButton: 'merge.yes',
      cancelButton: 'cancelButton',
      values: { employee: supplierUserObj && supplierUserObj.name, supplierComp: supplierName },
      openConfirmation: true,
    });
  };

  const updateValue = () => {
    if (supplierId && supplierUserObj && supplierUserObj.id) {
      suppliersApi
        .mergeSupplier(supplierId, supplierUserObj.id)
        .then(() => {
          NotificationManager.success(
            <IntlMessages id={`suppliers.merger.success`} />,
            <IntlMessages id={`${AppConfig.successTitle}`} />,
            AppConfig.notificationTimeout
          );
          close();
          fetchData();
        })
        .catch((errors) => {
          let { code } = errors;
          if (code === 106) {
            setErrors({ ...errors, supplierUserError: true });
          }
        });
      closeConfirmation();
    }
  };

  const changeAutoCompleteTextField = (value) => {
    setInputValue(value);
    setSupplierUserObj({ name: value });
  };

  return (
    <Fragment>
      <Dialog open={openMergeDialog || false} onClose={() => close()}>
        <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title'>
          <IntlMessages id={`dialog.${title}`} />
        </DialogTitle>
        <DialogContent style={{ padding: '8px 60px', width: '550px' }}>
          <DialogContentText id='alert-dialog-description' className={supplierUserError ? 'error' : ''}>
            {!supplierUserError ? (
              <IntlMessages id='dialog.mergeSupplier.note' />
            ) : (
              <IntlMessages id='suppliers.error.merge.country' />
            )}
          </DialogContentText>
          <AutoCompleteComponent
            disableClearable={true}
            customClass={'min-width-300 pr-20'}
            getOptionSelected={(option, value) => option.name === value.name}
            loading={!!searchLoading}
            value={!_.isEmpty(supplierUserObj) && supplierUserObj}
            disabled={!!searchLoading}
            options={!_.isEmpty(usersNames) ? usersNames : []}
            getOptionLabel={(option) => option.name || ''}
            onChangeTextField={(e) => changeAutoCompleteTextField(e.target.value)}
            onChange={(e, value) => setSupplierUserObj(value)}
            label={'supplier.user.name'}
            inputValue={!_.isEmpty(supplierUserObj) ? supplierUserObj.name : inputValue}
            renderOption={(option) => (
              <Fragment>
                {`${option.name}(${option.supplier && option.supplier.name ? option.supplier.name : '---'})`}
              </Fragment>
            )}
            noOptionsText={<IntlMessages id={'global.options.noMatched'} />}
          />
        </DialogContent>
        <DialogActions className={colorClass ? 'red' : colorClass}>
          <Button onClick={close} color='primary'>
            {<IntlMessages id={cancelButton ? `button.${cancelButton}` : 'button.cancel'} />}
          </Button>
          <Button disabled={_.isEmpty(supplierUserObj)} variant='contained' onClick={onConfirm} color='primary'>
            {<IntlMessages id={confirmButton ? `button.${confirmButton}` : 'button.yes'} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(
  connect(mapStateToProps, { MergeSupplierUserDialog, ConfirmationToggleDialog })(MergeSupplierDialog)
);
