import { Icon, IconProps } from '@elkaso-app/web-design';

export const SuppliersIcon = (props: IconProps) => {
  return (
    <Icon width='22px' height='22px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <g clipPath='url(#clip0_1987_2049)'>
        <path
          d='M3.575 4C2.70547 4 2 4.71387 2 5.59375V16.2188C2 17.0986 2.70547 17.8125 3.575 17.8125H4.1C4.1 19.5723 5.51094 21 7.25 21C8.98906 21 10.4 19.5723 10.4 17.8125H14.6C14.6 19.5723 16.0109 21 17.75 21C19.4891 21 20.9 19.5723 20.9 17.8125H21.95C22.5308 17.8125 23 17.3377 23 16.75C23 16.1623 22.5308 15.6875 21.95 15.6875V11.8791C21.95 11.3146 21.7302 10.7734 21.3364 10.375L18.8 7.8084C18.4062 7.40996 17.8714 7.1875 17.3136 7.1875H15.65V5.59375C15.65 4.71387 14.9445 4 14.075 4H3.575ZM15.65 9.3125H17.3136L19.85 11.8791V12.5H15.65V9.3125ZM8.825 17.8125C8.825 18.6924 8.11953 19.4062 7.25 19.4062C6.38047 19.4062 5.675 18.6924 5.675 17.8125C5.675 16.9326 6.38047 16.2188 7.25 16.2188C8.11953 16.2188 8.825 16.9326 8.825 17.8125ZM17.75 19.4062C16.8805 19.4062 16.175 18.6924 16.175 17.8125C16.175 16.9326 16.8805 16.2188 17.75 16.2188C18.6195 16.2188 19.325 16.9326 19.325 17.8125C19.325 18.6924 18.6195 19.4062 17.75 19.4062Z'
          fill='inherit'
        />
      </g>
      <defs>
        <clipPath id='clip0_1987_2049'>
          <rect width='21' height='17' fill='white' transform='translate(2 4)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
