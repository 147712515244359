import { Box, BrandLogo, Flex, Stack } from '@elkaso-app/web-design';
import { SidebarItem } from 'layout/components/sidebar/sidebar-item';
import { useSidebarItems } from 'layout/components/sidebar/utils/sidebar-items';

export const Sidebar = () => {
  const sidebarItems = useSidebarItems();

  return (
    <Box>
      <Flex align={'center'} justify='center' h='68px' borderBottom={'2px solid'} borderColor='gray.200'>
        <BrandLogo />
      </Flex>

      <Stack spacing={'xs'} p={'md'}>
        {sidebarItems.map((item, index) => (
          <SidebarItem item={item} key={index} />
        ))}
      </Stack>
    </Box>
  );
};
