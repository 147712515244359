import { useCustomToast } from '@elkaso-app/web-design';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'https://elkaso-media-production.s3.eu-west-1.amazonaws.com/ItemsTemplate.csv';
export const downloadDemoFileEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [typeof endpoint];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useDownloadDemoFileApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const fullEndpoint = `${endpoint}`;

  const queryFn = async () => {
    try {
      await axios
        .get(fullEndpoint, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          fileDownload(response.data, `WH_catalog_CSV_template.csv`);
        });
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: false,
    ...options,
  });
}
