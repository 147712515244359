/**
 * Auth User Reducers
 */
import {
  FORCE_UPDATE,
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  RE_BUILD_PERMISSIONS,
  SIGNUP_USER,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_SUCCESS,
} from 'redux/types';
import { LocalStorageKeys } from 'utils/constants';

/**
 * initial auth user
 */
const INIT_STATE = {
  user: JSON.parse(localStorage.getItem(LocalStorageKeys.cms_user)),
  loading: false,
  forceUpdate: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload };

    case LOGIN_USER_FAILURE:
      return { ...state, loading: false };

    case LOGOUT_USER:
      return { ...state, user: null };

    case SIGNUP_USER:
      return { ...state, loading: true };

    case SIGNUP_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };

    case SIGNUP_USER_FAILURE:
      return { ...state, loading: false };

    case RE_BUILD_PERMISSIONS:
      return { ...state, ...action.payload, loading: false };

    case FORCE_UPDATE:
      return { ...state, forceUpdate: action.payload };
    case 'TEST':
      return {
        ...state,
        user: action.payload,
      };
    default:
      return { ...state };
  }
};
