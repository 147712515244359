import { IconButtonWithTooltip, PlusIcon } from '@elkaso-app/web-design';
import { Can } from 'configs/can';
import React, { ChangeEvent, useState } from 'react';

import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { filter } from 'lodash';
import { CellProps } from 'react-table';
import { ActivationSwitch, IActivationSwitchCell } from './activation-switch';
import { AddCategoryModal } from './add-category-modal';
import { CategoryCountries, ICategoryCountriesCell } from './category-countries';
import { CategoryImageCell, ICategoryImageCell } from './category-image-cell';
import { SearchComponent } from './search-component';
import { language } from './tree-view-section';

function applyFilter(category: any[], query: string) {
  const stabilizedThis = category.map((el, index) => [el, index]);

  if (query) {
    return filter(category, (_item) => language(_item, 'en')?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

interface ISubCategoriesListTable {
  isLoading: boolean;
  categoriesData?: Record<string, any>;
}

export const SubCategoriesListTable = ({ categoriesData, isLoading }: ISubCategoriesListTable) => {
  const [filterName, setFilterName] = useState('');

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Image' />,
        accessor: 'image',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['image']>) => (
          <CustomTableCell<ICategoryImageCell> as={CategoryImageCell} image={value} />
        ),
      },
      {
        Header: <TableHeader name='Name EN' />,
        accessor: 'titleEN',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['titleEN']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Name AR' />,
        accessor: 'titleAR',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['titleAR']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Kaso ID' />,
        accessor: 'kasoId',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kasoId']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Countries' />,
        accessor: 'countries',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['countries']>) => (
          <CustomTableCell<ICategoryCountriesCell> as={CategoryCountries} countries={value} />
        ),
      },
      {
        Header: <TableHeader name='status' />,
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => (
          <CustomTableCell<IActivationSwitchCell>
            as={ActivationSwitch}
            categoryId={value.id}
            isActive={value.isActive}
          />
        ),
      },
      {
        Header: <TableHeader name='Creation Date' />,
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    image: string;
    titleAR: string;
    titleEN: string;
    kasoId: string;
    countries: {
      id: number;
      name: string;
    }[];
    status: {
      id: number;
      isActive: boolean;
    };
    creation_date: string;
  }

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.currentTarget.value);
  };

  const filteredUsers = applyFilter(categoriesData?.children ?? [], filterName);

  const columnsAccessorData = React.useMemo(
    () =>
      filteredUsers?.map((category): IColumnsAccessorData => {
        return {
          id: category?.id,
          image: category?.image,
          kasoId: category?.kasoId,
          titleAR: language(category, 'ar'),
          titleEN: language(category, 'en'),
          countries: category?.countries,
          status: {
            id: category?.id,
            isActive: category?.status == 'active' ? true : false,
          },
          creation_date: category?.createdAt,
        };
      }),
    [filteredUsers]
  );

  return (
    <BaseTableContainer border={0} isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Sub Categories' />
          <BaseTableSpinner isFetching={isLoading} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right minHeight={'auto'}>
          <SearchComponent title={'sub-categories'} filterName={filterName} onFilterName={handleFilterByName} px='sm' />

          {categoriesData?.depth < 3 && (
            <Can I='manage' a='CatalogCategory'>
              <AddCategoryModal
                title={language(categoriesData, 'en')}
                variant='add'
                trigger={
                  <IconButtonWithTooltip
                    _hover={{
                      background: '#e03d52cf',
                    }}
                    background={'#e03d52'}
                    label='Add Sub-Categories'
                    aria-label='add-category-btn'
                    Icon={<PlusIcon fill={'white'} />}
                  />
                }
              />
            </Can>
          )}
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper minHeight={'300px'}>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>
    </BaseTableContainer>
  );
};
