import { Icon, IconProps } from '@elkaso-app/web-design';

export const NotFoundIcon = (props: IconProps) => {
  return (
    <Icon width='737px' height='392px' viewBox='0 0 737 392' fill='none' {...props}>
      <path
        d='M569.344 374.731C564.069 381.588 557.866 386.673 559.216 388.972C559.216 388.972 562.022 391.019 569.787 391.019C583.079 391.019 596.16 390.006 594.008 388.656C591.033 386.799 582.425 386.103 574.196 383.276C572.171 382.58 578.648 378.634 576.876 377.98C573.205 376.651 570.356 375.49 569.344 374.731Z'
        fill='#002E4F'
      />
      <path
        d='M706.357 388.592C706.357 388.592 708.109 391.04 714.818 391.04C726.317 391.04 738.301 389.479 736.444 388.318C733.87 386.715 713.721 382.495 713.721 382.495L709.206 376.883L701.547 381.124L706.378 388.592H706.357Z'
        fill='#002E4F'
      />
      <path
        d='M669.942 219.108C661.692 219.108 655.447 217.463 654.941 217.315C650.636 216.133 648.105 211.703 649.286 207.398C650.447 203.094 654.877 200.563 659.181 201.723C659.456 201.807 677.621 206.449 688.213 197.356C695.302 191.258 698.003 179.802 696.23 163.26L695.661 157.986L711.991 148.724C715.873 146.529 720.81 147.88 723.004 151.762C725.199 155.644 723.848 160.56 719.966 162.775L712.75 166.868C713.89 186.342 709.164 200.731 698.657 209.677C689.795 217.23 678.761 219.087 669.984 219.087L669.942 219.108Z'
        fill='#E53E51'
      />
      <path
        d='M697.876 174.295C697.56 186.047 694.458 194.528 688.53 199.613C676.904 209.593 656.987 204.508 656.65 204.424C651.924 203.158 647.071 205.943 645.784 210.648C644.497 215.374 647.282 220.248 652.008 221.535C652.578 221.682 659.435 225.1 668.486 224.784C702.834 223.56 723.574 193.938 723.954 176.658L697.876 174.316V174.295Z'
        fill='#E1F3F6'
      />
      <path
        d='M650.636 133.216C647.556 131.845 644.539 130.347 641.564 128.764C613.102 144.968 576.728 165.75 576.011 166.467C576.011 166.467 594.092 195.246 595.253 196.406C595.991 197.145 640.825 164.99 673.064 141.698C665.616 138.807 657.957 136.486 650.657 133.216H650.636Z'
        fill='#E53E51'
      />
      <path
        d='M651.1 132.372C658.548 135.663 666.397 138.048 673.971 141.022C691.736 128.195 705.45 118.215 705.45 118.215L675.11 109.691C675.11 109.691 660.51 117.983 642.513 128.216C645.34 129.693 648.189 131.085 651.1 132.372Z'
        fill='#E53E51'
      />
      <path d='M598.797 94.5846L616.668 142.964L651.586 155.77L607.342 74.963L598.797 94.5846Z' fill='#CC697C' />
      <path d='M653.485 167.649H627.618L650.637 207.884L685.745 222.695L653.485 167.649Z' fill='#CC697C' />
      <path
        d='M685.744 222.695L665.152 187.545L577.023 239.215L559.701 209.656L570.588 33.9053L610.19 10.697L681.482 132.288L705.471 118.236L725.409 152.268L701.42 166.32L722.012 201.449L685.765 222.695H685.744ZM605.802 86.314L603.565 87.6221L597.51 181.489L645.193 153.534L605.781 86.314H605.802Z'
        fill='#AF3748'
      />
      <path d='M530.396 201.617L559.702 209.656L577.024 239.215L546.072 231.387L530.396 201.617Z' fill='#E53E51' />
      <path d='M530.396 201.618L559.702 209.656L570.589 33.9054L543.688 27.4703L530.396 201.618Z' fill='#E53E51' />
      <path d='M543.688 27.4703L570.589 33.9054L610.191 10.6759L582.952 2.76392L543.688 27.4703Z' fill='#E53E51' />
      <path
        d='M602.637 271.644C602.637 271.644 606.941 318.081 606.941 334.348C606.941 334.348 573.12 360.7 564.85 374.499C568.416 377.199 575.441 380.807 580.041 380.66C602.616 359.118 627.365 343.737 627.365 343.737C627.365 343.737 638.041 312.743 641.374 286.497L602.637 271.644Z'
        fill='#002E4F'
      />
      <path
        d='M626.753 273.838C634.369 273.901 642.155 272.572 648.695 269.027C646.501 259.744 651.185 232.379 657.05 217.758C657.05 217.758 661.692 207.567 658.443 204.445C646.416 192.883 636.184 193.811 632.998 193.684C621.963 203.01 601.413 230.522 602.236 266.875C607.912 272.846 619.431 273.774 626.753 273.817V273.838Z'
        fill='#E1F3F6'
      />
      <path
        d='M701.336 382.39C705.746 381.166 709.966 379.14 712.666 375.533C699.606 354.35 651.713 276.559 649.117 270.462C649.054 270.314 648.991 270.124 648.928 269.955C641.902 273.753 633.505 275.082 625.361 274.766C618.271 274.492 608.018 273.5 602.237 268.247C602.237 268.626 602.237 268.985 602.279 269.365C603.27 289.662 670.638 357.388 697.159 383.276C698.615 383.065 700.028 382.748 701.336 382.39Z'
        fill='#00396A'
      />
      <path
        d='M562.297 252.781C560.419 252.781 558.626 252.507 556.917 251.937C553.351 250.756 548.646 247.739 546.41 240.249C545.144 235.966 547.57 231.472 551.853 230.206C556.136 228.94 560.63 231.387 561.896 235.649C562.044 236.156 562.191 236.472 562.276 236.641C563.141 236.683 566.327 236.388 574.112 231.83C581.075 227.737 589.303 221.661 597.257 215.774C610.697 205.837 623.398 196.427 633.884 193.115C638.125 191.764 642.661 194.127 644.011 198.368C645.362 202.63 642.999 207.166 638.758 208.495C630.846 211.006 618.651 220.015 606.857 228.729C589.598 241.494 574.386 252.76 562.297 252.76V252.781Z'
        fill='#E53E51'
      />
      <path
        d='M577.403 246.747C576.412 242 575.378 236.746 572.361 232.843C565.841 236.451 563.077 236.683 562.276 236.662C562.17 236.472 562.044 236.156 561.896 235.67C560.63 231.387 556.136 228.961 551.853 230.227C547.57 231.493 545.144 235.987 546.41 240.27C548.625 247.781 553.351 250.777 556.917 251.958C558.626 252.528 560.419 252.802 562.297 252.802C566.981 252.802 572.15 251.115 577.72 248.287C577.614 247.781 577.509 247.275 577.403 246.768V246.747Z'
        fill='#E53E51'
      />
      <path
        d='M644.012 198.389C642.661 194.149 638.125 191.785 633.884 193.136C623.398 196.448 610.697 205.837 597.257 215.796C589.303 221.682 581.075 227.758 574.112 231.852C573.796 232.041 573.5 232.21 573.184 232.379C576.201 236.367 581.433 256.03 581.518 256.495C590.084 252.022 631.141 220.754 640.045 211.323C643.104 208.074 645.341 202.672 643.99 198.41L644.012 198.389Z'
        fill='#E1F3F6'
      />
      <path
        d='M550.861 246.262C550.376 246.262 549.912 246.114 549.49 245.819C543.16 241.283 539.848 232.569 539.721 232.21C539.257 230.987 539.89 229.615 541.114 229.151C542.338 228.708 543.709 229.32 544.173 230.544C544.173 230.544 547.127 238.287 552.254 241.958C553.33 242.718 553.562 244.194 552.802 245.27C552.338 245.924 551.6 246.262 550.861 246.262Z'
        fill='#E53E51'
      />
      <path
        d='M550.882 240.481C550.144 240.481 549.405 240.122 548.941 239.468C545.903 235.164 543.477 224.974 543.224 223.834C542.928 222.547 543.73 221.281 544.996 220.986C546.283 220.691 547.549 221.492 547.844 222.758C548.519 225.628 550.714 233.751 552.824 236.725C553.583 237.802 553.33 239.278 552.254 240.038C551.832 240.333 551.368 240.481 550.882 240.481Z'
        fill='#E53E51'
      />
      <path
        d='M554.153 239.131C553.224 239.131 552.317 238.582 551.959 237.654C549.785 232.316 548.941 221.134 548.899 220.669C548.794 219.361 549.785 218.222 551.094 218.116C552.402 218.011 553.541 219.003 553.646 220.311C553.879 223.328 554.786 232.02 556.368 235.86C556.875 237.084 556.284 238.455 555.06 238.962C554.765 239.088 554.469 239.131 554.174 239.131H554.153Z'
        fill='#E53E51'
      />
      <path
        d='M557.718 237.991C556.663 237.991 555.693 237.274 555.419 236.219C553.921 230.417 555.292 224.509 555.355 224.256C555.651 222.969 556.938 222.189 558.204 222.484C559.47 222.779 560.271 224.066 559.976 225.332C559.955 225.438 558.837 230.417 560.018 235.017C560.356 236.282 559.575 237.591 558.309 237.907C558.119 237.97 557.908 237.991 557.718 237.991Z'
        fill='#E53E51'
      />
      <path
        d='M653.189 211.302C652.092 211.302 650.995 211.07 649.94 210.563C646.142 208.77 644.518 204.234 646.311 200.457C648.189 196.491 651.565 192.419 651.944 191.976C654.645 188.769 659.434 188.368 662.662 191.068C665.869 193.769 666.27 198.558 663.57 201.765C662.62 202.905 660.827 205.31 660.046 206.956C658.738 209.698 656.016 211.302 653.168 211.302H653.189Z'
        fill='#E53E51'
      />
      <path
        d='M654.286 207.377C655.193 208.369 656.122 209.297 657.113 210.205C658.358 209.466 659.413 208.348 660.067 206.956C660.848 205.31 662.641 202.905 663.591 201.765C666.291 198.558 665.89 193.769 662.683 191.068C659.476 188.368 654.687 188.769 651.965 191.976C651.818 192.165 651.206 192.904 650.383 193.98C650.446 198.769 650.952 203.749 654.286 207.399V207.377Z'
        fill='#E53E51'
      />
      <path
        d='M679.246 199.001C676.229 206.344 667.831 209.825 660.489 206.808C653.147 203.791 649.666 195.393 652.683 188.051L656.48 178.831C659.497 171.489 667.916 168.008 675.237 171.025C682.579 174.042 686.06 182.439 683.043 189.781L679.246 199.001Z'
        fill='#E53E51'
      />
      <path
        d='M654.286 186.975C653.126 191.912 646.016 190.33 643.357 187.756C639.686 184.211 637.492 174.168 645.636 174.696C639.771 165.856 659.645 156.277 661.734 166.489C663.295 163.851 664.878 161.488 668.317 161.256C670.807 161.087 675.153 163.028 674.794 166.067C678.486 163.155 683.867 163.366 687.517 166.32C688.677 167.269 690.787 169.358 689.732 170.898C689.162 171.742 686.166 171.995 685.154 172.312C686.884 171.531 693.952 173.113 692.559 176.046C691.905 177.418 688.445 176.194 687.432 175.899V176.215C691.209 178.283 692.77 180.392 692.327 185.097C692.116 187.271 691.103 191.343 688.529 191.659C687.221 191.807 685.681 190.984 685.702 189.613C688.867 192.756 684.394 201.196 680.406 197.039C678.845 195.415 679.605 192.672 681.081 191.258C678.718 189.908 673.613 182.734 677.242 180.814C675.533 180.857 667.663 178.177 670.174 175.92C670.828 178.557 667.578 181.954 664.983 180.603C666.587 184.106 661.861 186.722 658.949 186.849C660.721 189.085 655.236 191.744 653.569 188.494L654.286 186.996V186.975Z'
        fill='#00396A'
      />
      <path
        d='M384.331 158.302C384.331 158.302 379.71 140.643 402.602 136.465L400.851 157.669L384.331 158.302Z'
        fill='#E53E51'
      />
      <path
        d='M292.679 202.862C287.678 202.862 283.016 201.85 278.711 199.824C257.929 190.013 255.63 160.496 255.524 159.252C255.208 154.8 258.562 150.939 263.014 150.622C267.445 150.306 271.306 153.66 271.622 158.091C272.065 164.02 275.462 180.456 285.632 185.224C294.029 189.169 307.026 185.161 323.272 173.62C326.901 171.046 331.943 171.89 334.538 175.519C337.112 179.147 336.268 184.19 332.618 186.785C317.554 197.482 304.177 202.841 292.658 202.841L292.679 202.862Z'
        fill='#CDE5E8'
      />
      <path
        d='M335.635 218.032C337.872 212.146 339.96 206.154 342.387 200.33C340.762 200.204 339.095 199.993 337.429 199.676C329.601 198.178 322.955 194.992 317.533 190.119C312.089 185.245 307.912 179.063 304.937 171.594C301.983 164.125 300.253 155.623 299.768 146.086C299.282 136.571 300.042 126.507 302.067 115.915C304.135 105.113 307.131 95.4285 311.077 86.8625C315.022 78.2965 319.748 71.0808 325.255 65.2154C330.761 59.35 336.943 55.1514 343.779 52.6196C350.615 50.0878 357.957 49.5603 365.806 51.0583C372.009 52.2398 377.474 54.5818 382.221 58.0419C384.499 53.4847 386.968 48.9907 389.31 44.5178C392.074 39.2221 394.648 33.8419 397.116 28.3985C398.171 26.0777 399.078 23.6724 400.028 21.3094C392.095 16.7732 383.191 13.5663 373.338 11.6885C359.371 9.03007 346.206 9.51534 333.884 13.2076C321.541 16.8787 310.444 23.0395 300.569 31.6899C290.695 40.3403 282.298 51.1849 275.42 64.2449C268.542 77.3049 263.563 91.8839 260.482 107.961C257.402 124.059 256.663 139.44 258.246 154.104C259.828 168.767 263.626 181.954 269.618 193.621C275.61 205.31 283.67 215.12 293.776 223.075C303.186 230.501 314.284 235.607 327.048 238.434C330.065 231.683 332.998 224.91 335.614 218.011L335.635 218.032Z'
        fill='#E53E51'
      />
      <path
        d='M444.989 96.6311C443.406 81.9676 439.609 68.7811 433.617 57.1136C427.625 45.425 419.565 35.6142 409.459 27.66C406.737 25.508 403.868 23.5669 400.872 21.8157C399.922 24.1788 399.015 26.584 397.96 28.8838C395.45 34.3061 392.939 39.7073 390.154 45.003C387.791 49.497 385.301 54.0121 383.001 58.6116C383.824 59.2445 384.626 59.8775 385.386 60.5737C390.808 65.4475 395.07 71.6294 398.129 79.1404C401.188 86.6304 402.982 95.0909 403.488 104.522C403.994 113.953 403.214 124.059 401.146 134.841C399.121 145.432 396.125 155.053 392.179 163.745C388.213 172.417 383.423 179.675 377.811 185.519C372.199 191.363 365.975 195.541 359.118 198.073C354.075 199.929 348.822 200.71 343.357 200.415C340.678 206.786 338.399 213.348 335.952 219.804C333.525 226.197 330.804 232.442 327.998 238.666C328.631 238.793 329.263 238.92 329.896 239.046C343.864 241.726 357.008 241.219 369.351 237.527C381.672 233.856 392.791 227.695 402.665 219.045C412.539 210.394 420.915 199.55 427.815 186.49C434.693 173.43 439.672 158.872 442.752 142.774C445.833 126.696 446.571 111.295 444.989 96.6311Z'
        fill='#CC697C'
      />
      <path
        d='M354.582 247.422C340.614 244.743 328.567 239.426 318.461 231.472C308.355 223.518 300.295 213.686 294.303 202.018C288.311 190.33 284.513 177.164 282.931 162.501C281.349 147.837 282.087 132.456 285.167 116.358C288.248 100.281 293.206 85.7021 300.105 72.6421C306.983 59.5822 315.359 48.7375 325.255 40.0871C335.129 31.4367 346.227 25.276 358.569 21.6048C370.891 17.9337 384.056 17.4273 398.023 20.0857C411.991 22.7441 424.038 28.0821 434.144 36.0362C444.25 44.0114 452.31 53.8223 458.302 65.4897C464.294 77.1783 468.092 90.3438 469.674 105.028C471.256 119.713 470.518 135.094 467.438 151.171C464.357 167.269 459.378 181.827 452.5 194.887C445.622 207.947 437.246 218.792 427.35 227.442C417.476 236.092 406.357 242.253 394.036 245.924C381.693 249.595 368.549 250.102 354.582 247.443V247.422ZM362.114 208.052C369.73 209.508 376.967 208.981 383.824 206.428C390.66 203.896 396.905 199.719 402.517 193.874C408.13 188.03 412.919 180.772 416.886 172.101C420.852 163.429 423.848 153.787 425.852 143.196C427.92 132.393 428.701 122.287 428.194 112.877C427.688 103.467 425.895 94.9855 422.835 87.4955C419.776 80.0055 415.514 73.8025 410.092 68.9288C404.648 64.055 398.129 60.8902 390.512 59.4344C382.685 57.9365 375.343 58.4639 368.486 60.9958C361.629 63.5276 355.468 67.7262 349.961 73.5916C344.454 79.457 339.728 86.6726 335.783 95.2386C331.837 103.805 328.841 113.489 326.774 124.291C324.748 134.883 323.989 144.926 324.474 154.462C324.959 163.999 326.668 172.48 329.643 179.97C332.597 187.439 336.796 193.621 342.239 198.495C347.661 203.369 354.307 206.554 362.135 208.052H362.114Z'
        fill='#AF3748'
      />
      <path
        d='M497.018 128.849L497.63 158.534C497.63 158.534 489.148 166.13 481.553 158.534L484.359 125.114L497.018 128.87V128.849Z'
        fill='#00396A'
      />
      <path
        d='M462.058 365.637C461.572 365.257 458.26 363.717 457.817 363.337C456.045 365.996 454.8 367.473 452.669 369.667C457.901 374.224 456.551 379.773 456.551 379.773C456.551 379.773 452.859 382.959 451.403 383.676C446.782 385.955 438.259 389.12 438.259 389.12L459.716 390.808L475.624 374.52C469.991 371.376 465.666 368.507 462.058 365.658V365.637Z'
        fill='#002E4F'
      />
      <path
        d='M378.022 366.798C374.604 366.207 370.3 368.823 367.515 366.798C366.861 372.768 366.988 376.018 366.988 376.018L335.256 389.099H375.786C376.145 384.774 375.385 380.638 375.955 376.629C376.25 374.583 377.643 368.76 378.022 366.777V366.798Z'
        fill='#002E4F'
      />
      <path
        d='M414.966 222.758L379.099 290.906C379.099 290.906 393.635 303.207 407.94 307.3L434.82 227.02L414.966 222.779V222.758Z'
        fill='#CDE5E8'
      />
      <path
        d='M454.23 371.376C456.382 369.182 458.155 366.544 459.927 363.886C450.643 355.932 446.107 347.746 433.511 333.652C428.3 327.808 442.12 294.409 445.474 288.796C451.509 278.732 452.437 271.432 457.142 254.195L422.118 267.276C413.784 297.13 404.079 327.006 413.805 341.247C424.102 356.312 442.352 368.443 451.171 374.499C451.508 374.731 453.956 371.165 454.251 371.376H454.23Z'
        fill='#002E4F'
      />
      <path
        d='M395.26 154.779C394.775 154.779 394.289 154.631 393.867 154.336C392.812 153.576 392.559 152.078 393.319 151.023C396.146 147.099 396.758 143.85 396.758 143.828C396.99 142.541 398.214 141.676 399.522 141.909C400.809 142.141 401.674 143.364 401.442 144.651C401.421 144.82 400.682 148.913 397.18 153.787C396.716 154.441 395.977 154.779 395.239 154.779H395.26Z'
        fill='#E53E51'
      />
      <path
        d='M454.652 261.41C465.075 232.78 473.979 187.861 477.312 169.948C475.455 169.168 473.62 168.598 472.164 168.197C469.063 167.353 465.37 166.34 462.184 167.332C460.581 167.838 459.146 168.661 457.733 169.547C456.425 173.43 455.897 176.721 455.897 176.721C455.897 176.721 433.512 219.53 418.911 248.709C431.296 251.494 443.428 255.566 454.631 261.432L454.652 261.41Z'
        fill='#F6EE9E'
      />
      <path
        d='M418.51 249.574C417.223 252.169 416 254.638 414.86 256.959C411.991 262.044 408.889 266.749 405.893 271.264C377.073 314.705 366.207 349.328 364.266 365.722C367.009 367.874 377.432 369.076 380.892 369.688C386.209 342.513 400.049 325.191 424.819 304.705C433.111 297.848 439.314 289.134 444.778 281.897C448.027 277.593 451.255 270.673 454.357 262.297C443.132 256.41 430.959 252.338 418.553 249.574H418.51Z'
        fill='#00396A'
      />
      <path
        d='M390.555 261.516C387.2 261.516 384.078 259.427 382.917 256.094C381.461 251.874 383.698 247.275 387.896 245.819C397.686 242.422 412.708 229.024 422.857 214.656C443.407 185.498 457.838 169.337 458.45 168.661C461.425 165.349 466.531 165.075 469.843 168.05C473.156 171.025 473.43 176.13 470.455 179.443C470.307 179.612 456.002 195.646 436.043 223.961C426.043 238.139 408.362 255.798 393.192 261.073C392.327 261.368 391.42 261.516 390.555 261.516Z'
        fill='#E53E51'
      />
      <path
        d='M471.636 172.628C471.447 172.628 471.257 172.628 471.067 172.628C466.889 172.312 463.746 168.683 464.041 164.484C464.357 160.117 466.066 155.095 466.256 154.547C467.628 150.58 471.953 148.47 475.919 149.842C479.886 151.213 481.996 155.538 480.624 159.505C480.139 160.897 479.337 163.788 479.211 165.602C478.915 169.59 475.582 172.649 471.636 172.649V172.628Z'
        fill='#E53E51'
      />
      <path
        d='M473.852 164.611C475.202 166.151 476.658 167.522 478.219 168.788C478.768 167.839 479.105 166.742 479.211 165.581C479.337 163.767 480.16 160.876 480.625 159.484C481.996 155.517 479.886 151.192 475.92 149.821C472.628 148.681 469.105 149.947 467.206 152.669C467.902 156.952 471.404 161.804 473.852 164.611Z'
        fill='#E53E51'
      />
      <path
        d='M493.431 148.154C493.263 156.087 486.68 162.374 478.747 162.184C470.814 161.994 464.526 155.433 464.716 147.5L464.948 137.52C465.117 129.587 471.7 123.3 479.633 123.468C487.566 123.637 493.853 130.22 493.685 138.153L493.453 148.133L493.431 148.154Z'
        fill='#E53E51'
      />
      <path
        d='M379.141 259.301C375.828 259.301 372.03 258.415 367.789 256.621C366.587 256.115 366.017 254.722 366.524 253.52C367.03 252.317 368.422 251.748 369.625 252.254C375.87 254.891 380.849 255.25 384.014 253.351C385.132 252.676 386.588 253.035 387.285 254.153C387.96 255.271 387.601 256.727 386.483 257.423C384.394 258.689 381.926 259.322 379.141 259.322V259.301Z'
        fill='#E53E51'
      />
      <path
        d='M381.989 262.74C378.212 262.74 375.174 262.001 371.735 261.178L370.659 260.925C369.393 260.63 368.591 259.343 368.908 258.056C369.203 256.79 370.49 255.988 371.777 256.305L372.853 256.558C378.613 257.95 382.411 258.879 390.85 256.706C392.137 256.389 393.424 257.149 393.741 258.415C394.078 259.68 393.298 260.989 392.032 261.305C387.981 262.36 384.795 262.74 382.01 262.74H381.989Z'
        fill='#E53E51'
      />
      <path
        d='M378.972 254.617C371.524 254.617 366.418 250.038 366.144 249.785C365.173 248.899 365.11 247.401 365.996 246.431C366.882 245.46 368.38 245.397 369.351 246.283C369.583 246.494 375.048 251.304 382.39 249.427C383.677 249.089 384.964 249.87 385.28 251.136C385.618 252.402 384.837 253.71 383.571 254.026C381.968 254.448 380.407 254.617 378.951 254.617H378.972Z'
        fill='#E53E51'
      />
      <path
        d='M383.951 252.064C383.951 252.064 383.761 252.064 383.677 252.064C375.596 251.114 372.304 245.291 372.157 245.038C371.524 243.899 371.946 242.443 373.106 241.81C374.246 241.177 375.68 241.599 376.313 242.738C376.419 242.928 378.676 246.684 384.225 247.338C385.533 247.485 386.462 248.667 386.314 249.975C386.166 251.178 385.153 252.085 383.951 252.085V252.064Z'
        fill='#E53E51'
      />
      <path
        d='M412.328 158.218C412.202 158.218 412.075 158.218 411.949 158.175C408.024 157.521 397.264 155.833 394.775 155.833C393.466 155.833 392.39 154.778 392.39 153.449C392.39 152.12 393.445 151.065 394.775 151.065C398.108 151.065 411.252 153.217 412.729 153.47C414.016 153.681 414.903 154.905 414.692 156.192C414.502 157.353 413.489 158.175 412.35 158.175L412.328 158.218Z'
        fill='#E53E51'
      />
      <path
        d='M410.809 163.197C410.598 163.197 410.387 163.176 410.176 163.113C410.071 163.091 398.762 160.011 393.509 159.716C392.2 159.631 391.209 158.513 391.272 157.205C391.357 155.897 392.475 154.863 393.783 154.969C399.543 155.306 410.936 158.408 411.421 158.534C412.687 158.872 413.426 160.18 413.088 161.446C412.793 162.501 411.843 163.197 410.809 163.197Z'
        fill='#E53E51'
      />
      <path
        d='M409.67 168.429C409.417 168.429 409.185 168.387 408.932 168.324C398.235 164.864 395.597 164.653 394.184 164.547C393.572 164.505 393.002 164.442 392.201 164.252C390.935 163.914 390.175 162.627 390.513 161.34C390.85 160.074 392.158 159.315 393.424 159.652C393.804 159.758 394.078 159.779 394.564 159.821C396.294 159.969 399.163 160.201 410.388 163.809C411.632 164.21 412.329 165.56 411.928 166.805C411.611 167.817 410.662 168.45 409.67 168.45V168.429Z'
        fill='#E53E51'
      />
      <path
        d='M404.986 172.227C404.986 172.227 404.881 172.227 404.818 172.227C394.1 171.468 393.656 165.644 393.656 164.99L398.404 164.885C398.404 164.885 398.404 164.822 398.404 164.737C398.404 164.758 398.889 167.058 405.176 167.501C406.484 167.586 407.476 168.725 407.371 170.033C407.286 171.278 406.231 172.248 405.008 172.248L404.986 172.227Z'
        fill='#E53E51'
      />
      <path
        d='M395.998 169.379C395.998 169.379 393.931 168.429 390.724 168.429C388.023 168.429 384.352 163.851 384.352 159.083C384.352 159.083 383.044 145.052 397.834 151.403L395.998 169.379Z'
        fill='#E53E51'
      />
      <path
        d='M442.626 211.048L421.865 313.228C421.865 313.228 453.998 320.972 473.725 309.621L478.705 167.649C478.705 167.649 456.636 159.927 442.626 211.048Z'
        fill='#CDE5E8'
      />
      <path
        d='M447.437 267.276L467.311 266.2C467.311 266.2 467.797 296.603 455.918 295.421C455.918 295.421 439.672 298.966 447.437 267.276Z'
        fill='#E1F3F6'
      />
      <path
        d='M472.396 165.222C470.223 163.26 467.396 162.184 464.463 162.184C461.087 162.184 457.88 163.619 455.623 166.13C455.011 166.805 440.495 183.051 419.755 212.462C418.405 214.382 416.907 216.344 415.303 218.306L413.194 220.901L415.493 223.328C417.941 225.923 420.515 228.433 422.983 230.881C424.334 232.21 425.684 233.518 427.013 234.869L429.946 237.801L432.604 234.615C434.988 231.767 437.183 228.94 439.145 226.176C458.977 198.052 473.156 182.164 473.282 182.017C477.65 177.143 477.249 169.611 472.375 165.243L472.396 165.222Z'
        fill='#E1F3F6'
      />
      <path d='M417.867 211.793L409.844 220.723L430.733 239.492L438.756 230.562L417.867 211.793Z' fill='#F8FDFF' />
      <path
        d='M450.285 153.85L468.535 161.003C468.535 161.003 476.299 123.215 470.624 117.561C470.624 117.561 450.264 115.641 450.264 153.85H450.285Z'
        fill='#00396A'
      />
      <path
        d='M471.7 124.144C472.354 125.114 486.511 134.566 497.63 131.95C497.63 131.95 495.731 115.261 469.759 117.666L471.7 124.165V124.144Z'
        fill='#00396A'
      />
      <path
        d='M482.65 141.951C482.65 146.339 479.084 149.884 474.717 149.884C470.349 149.884 466.784 146.339 466.784 141.951C466.784 137.562 470.328 134.018 474.717 134.018C479.105 134.018 482.65 137.562 482.65 141.951Z'
        stroke='#DDDD67'
        strokeWidth='0.87'
        strokeMiterlimit='10'
      />
      <path
        d='M500.563 143.512C500.563 147.331 497.482 150.411 493.663 150.411C489.845 150.411 486.764 147.31 486.764 143.512C486.764 139.714 489.866 136.613 493.663 136.613C497.461 136.613 500.563 139.714 500.563 143.512Z'
        stroke='#DDDD67'
        strokeWidth='0.75'
        strokeMiterlimit='10'
      />
      <path
        d='M482.65 141.951C482.65 141.951 485.287 140.917 486.848 142.499'
        stroke='#DDDD67'
        strokeWidth='0.87'
        strokeMiterlimit='10'
      />
      <path
        d='M251.642 155.855C251.452 155.855 251.262 155.855 251.072 155.834C246.895 155.538 243.751 151.888 244.047 147.711C244.363 143.343 246.072 138.322 246.262 137.773C247.633 133.807 251.958 131.697 255.925 133.068C259.892 134.44 262.001 138.765 260.63 142.731C260.145 144.124 259.343 147.014 259.216 148.829C258.921 152.817 255.587 155.876 251.642 155.876V155.855Z'
        fill='#E53E51'
      />
      <path
        d='M247.127 366.756C259.723 372.558 270.04 372.452 270.884 372.558C279.091 357.957 303.819 318.398 281.328 285.948L249.511 268.858C249.511 268.858 281.328 302.046 247.127 366.756Z'
        fill='#002E4F'
      />
      <path
        d='M263.584 379.689C263.584 379.689 266.517 373.718 268.816 369.73C267.086 369.519 259.385 369.203 256.221 368C253.246 374.077 246.768 382.959 242.211 389.057H284.957L263.584 379.71V379.689Z'
        fill='#002E4F'
      />
      <path
        d='M316.457 287.868C311.14 277.551 272.53 246.473 269.027 228.623C268.711 227.041 268.458 225.417 268.226 223.771C262.023 227.041 253.984 227.948 247.591 228.265C240.354 228.624 236.029 229.088 229.025 225.67C229.615 236.746 227.822 247.443 234.278 258.668C247.106 280.927 289.704 300 294.472 307.342C303.84 321.858 307.089 353.358 310.275 372.579C315.296 372.579 327.196 372.368 332.07 370.617C330.424 360.764 333.104 320.191 316.436 287.868H316.457Z'
        fill='#00396A'
      />
      <path
        d='M329.77 377.959C329.77 377.959 328.377 372.347 327.977 369.709C323.082 371.418 320.233 369.709 315.212 369.709C315.971 374.478 315.402 383.381 315.845 388.614H356.375L329.77 377.959Z'
        fill='#002E4F'
      />
      <path
        d='M247.908 234.088C254.089 233.814 267.044 232.084 272.255 226.893C271.2 218.412 267.403 204.782 268.732 193.811C273.247 156.614 255.102 145.474 255.102 145.474C255.102 145.474 246.747 139.63 239.975 146.614C229.763 157.142 219.783 186.891 220.796 230.881C227.843 234.51 240.544 234.426 247.929 234.088H247.908Z'
        fill='#CDE5E8'
      />
      <path
        d='M341.036 245.819C340.425 245.819 339.813 245.755 339.201 245.608C309.937 238.814 285.146 221.281 267.529 194.887C254.321 175.118 249.764 157.374 249.574 156.635C248.477 152.31 251.115 147.922 255.44 146.846C259.765 145.749 264.153 148.365 265.229 152.69C265.314 153.006 269.512 168.978 281.264 186.384C296.751 209.361 317.49 223.982 342.851 229.889C347.197 230.902 349.898 235.227 348.885 239.574C348.02 243.308 344.708 245.819 341.036 245.819Z'
        fill='#E53E51'
      />
      <path
        d='M323.251 221.577C307.131 213.939 293.396 201.828 282.446 185.604C270.842 168.408 266.643 152.5 266.622 152.352C265.546 148.133 261.769 145.179 257.423 145.179C256.642 145.179 255.862 145.284 255.102 145.474C252.634 146.086 250.566 147.626 249.279 149.821C247.971 151.994 247.612 154.547 248.224 157.015L248.266 157.184C249.511 162.627 254.512 181.743 266.39 199.508C280.61 220.796 299.894 236.704 322.153 245.566C322.322 245.629 322.491 245.671 322.681 245.671C322.955 245.671 323.251 245.587 323.483 245.418C323.862 245.165 324.116 244.722 324.116 244.236V222.864C324.116 222.315 323.799 221.809 323.293 221.577H323.251Z'
        fill='#E1F3F6'
      />
      <path
        d='M347.029 246.41C344.433 246.41 341.691 246.114 338.8 245.545C337.513 245.291 336.69 244.047 336.943 242.76C337.197 241.473 338.441 240.629 339.75 240.903C346.818 242.317 352.725 241.81 357.282 239.363C358.443 238.751 359.878 239.194 360.489 240.333C361.101 241.494 360.658 242.928 359.519 243.54C355.932 245.46 351.755 246.41 347.05 246.41H347.029Z'
        fill='#E53E51'
      />
      <path
        d='M347.366 241.093C346.227 241.093 345.066 241.008 343.885 240.84C342.577 240.65 341.69 239.447 341.88 238.139C342.07 236.831 343.273 235.945 344.581 236.135C352.176 237.253 359.202 233.307 359.287 233.265C360.426 232.611 361.882 233.012 362.536 234.151C363.19 235.291 362.789 236.725 361.671 237.401C361.375 237.569 355.13 241.093 347.408 241.093H347.366Z'
        fill='#E53E51'
      />
      <path
        d='M345.256 236.409C344.898 236.409 344.518 236.409 344.117 236.367C342.809 236.282 341.817 235.164 341.901 233.856C341.986 232.548 343.104 231.556 344.412 231.641C350.024 231.978 352.978 228.581 352.999 228.539C353.822 227.526 355.32 227.358 356.333 228.202C357.346 229.024 357.514 230.522 356.67 231.535C356.502 231.746 352.62 236.409 345.214 236.409H345.256Z'
        fill='#E53E51'
      />
      <path
        d='M337.049 232.421C335.741 232.421 334.665 231.366 334.665 230.058C334.665 228.75 335.72 227.695 337.028 227.695C337.239 227.695 341.923 227.59 344.602 223.729C345.341 222.653 346.839 222.378 347.915 223.138C348.991 223.876 349.265 225.353 348.505 226.45C344.37 232.4 337.323 232.464 337.028 232.464L337.049 232.421Z'
        fill='#E53E51'
      />
      <path
        d='M329.284 178.81C328.546 178.81 327.808 178.472 327.343 177.818C323.841 172.944 323.103 168.851 323.082 168.661C322.849 167.374 323.736 166.151 325.023 165.919C326.31 165.687 327.533 166.552 327.765 167.86C327.765 167.86 328.398 171.109 331.204 175.033C331.964 176.109 331.732 177.586 330.656 178.346C330.234 178.641 329.749 178.789 329.284 178.789V178.81Z'
        fill='#E53E51'
      />
      <path
        d='M329.791 179.865C329.643 179.865 329.495 179.865 329.348 179.823C324.158 178.831 313.84 178.81 313.735 178.81C312.427 178.81 311.372 177.755 311.372 176.447C311.372 175.139 312.427 174.084 313.735 174.084C314.178 174.084 324.601 174.084 330.234 175.181C331.521 175.434 332.365 176.658 332.112 177.966C331.901 179.105 330.888 179.886 329.791 179.886V179.865Z'
        fill='#E53E51'
      />
      <path
        d='M312.427 184.802C311.245 184.802 310.233 183.916 310.064 182.713C309.895 181.405 310.823 180.224 312.131 180.055C313.629 179.865 326.985 178.24 331.289 179C332.576 179.232 333.441 180.456 333.209 181.743C332.977 183.03 331.753 183.895 330.466 183.663C327.744 183.177 318.144 184.085 312.722 184.739C312.617 184.739 312.532 184.739 312.427 184.739V184.802Z'
        fill='#E53E51'
      />
      <path
        d='M315.507 190.182C314.389 190.182 313.397 189.401 313.186 188.262C312.933 186.975 313.777 185.73 315.064 185.477C320.824 184.359 322.343 183.874 325.128 182.988C326.436 182.566 328.061 182.038 330.487 181.342C331.753 180.983 333.061 181.701 333.42 182.966C333.779 184.232 333.061 185.54 331.795 185.899C329.432 186.574 327.913 187.081 326.584 187.503C323.715 188.431 321.984 188.98 315.95 190.14C315.803 190.161 315.655 190.182 315.507 190.182Z'
        fill='#E53E51'
      />
      <path
        d='M320.634 193.959C319.495 193.959 318.503 193.136 318.292 191.975C318.081 190.688 318.946 189.444 320.233 189.233C327.934 187.924 329.453 185.055 329.475 185.013C329.896 183.768 331.226 183.093 332.47 183.494C333.715 183.916 334.39 185.245 333.99 186.49C333.694 187.418 331.627 192.081 321.056 193.895C320.93 193.895 320.782 193.937 320.655 193.937L320.634 193.959Z'
        fill='#E53E51'
      />
      <path
        d='M329.791 191.089L327.977 171.742C327.977 171.742 336.458 172.354 336.458 181.257C336.458 184.232 335.593 187.545 329.791 191.089Z'
        fill='#E53E51'
      />
      <path
        d='M276.348 128.047C278.501 136.149 273.669 144.461 265.546 146.592C257.444 148.744 249.131 143.913 246.979 135.811L244.279 125.621C242.127 117.519 246.958 109.206 255.081 107.075C263.183 104.923 271.496 109.754 273.648 117.856L276.348 128.047Z'
        fill='#E53E51'
      />
      <path
        d='M274.914 122.646C273.753 125.853 272.129 129.039 269.618 131.212C263.5 136.486 254.132 137.9 246.705 134.735L246.979 135.79C249.131 143.892 257.423 148.723 265.546 146.571C273.648 144.419 278.479 136.128 276.349 128.026L274.935 122.646H274.914Z'
        fill='#00396A'
      />
      <path
        d='M246.937 135.242C241.241 131.591 235.08 117.666 244.089 114.628C249.659 112.75 247.422 129.925 247.422 134.777L246.937 135.242Z'
        fill='#00396A'
      />
      <path
        d='M242.359 115.768C235.459 110.767 244.004 96.6523 261.031 96.6523C271.559 96.6523 271.475 104.712 264.196 109.058C258.161 112.666 248.794 110.556 244.258 116.717L242.359 115.768Z'
        fill='#00396A'
      />
      <path
        d='M274.914 125.768C272.509 122.118 267.74 114.691 265.905 110.535C264.723 107.877 263.12 103.362 268.437 106.569C272.888 109.248 275.294 118.721 274.872 123.532L274.914 125.768Z'
        fill='#00396A'
      />
      <path
        d='M154.61 159.526C151.994 161.952 149.103 164.21 146.74 166.91L112.455 238.519L137.984 205.584L165.096 149.546C161.636 152.901 158.155 156.256 154.631 159.526H154.61Z'
        fill='#CC697C'
      />
      <path
        d='M134.271 147.774C136.761 144.377 139.145 140.368 141.972 136.866L81.9677 107.56L25.0439 113.257L124.798 159.252C128.068 155.517 131.359 151.741 134.271 147.753V147.774Z'
        fill='#CC697C'
      />
      <path
        d='M142.837 137.288C139.651 141.276 137.056 145.981 133.997 149.694C131.254 153.049 128.49 156.382 125.663 159.674L171.004 180.582L187.461 159.083L142.816 137.288H142.837Z'
        fill='#E53E51'
      />
      <path
        d='M74.5411 220.395L92.1372 183.663L0 139.546L14.7901 108.636L166.531 19.2841L207.926 39.1167L147.057 166.256L172.143 178.261L155.116 213.834L130.03 201.828L112.434 238.561L74.52 220.416L74.5411 220.395ZM142.795 77.8114L140.453 76.6932L59.2868 124.207L109.143 148.069L142.774 77.7903L142.795 77.8114Z'
        fill='#AF3748'
      />
      <path d='M172.143 178.219L187.481 159.083L175.455 185.772L154.547 214.952L172.143 178.219Z' fill='#CC697C' />
      <path
        d='M166.277 148.491L226.113 17.3219L207.926 39.0745L147.057 166.214C148.745 164.569 165.286 149.462 166.256 148.491H166.277Z'
        fill='#CC697C'
      />
      <path d='M14.79 108.636L41.8172 85.0903L190.098 0L166.531 19.263L14.79 108.636Z' fill='#E53E51' />
      <path d='M166.531 19.263L207.926 39.0956L226.113 17.3219L190.098 0L166.531 19.263Z' fill='#E53E51' />
      <path
        d='M115.704 115.071C110.62 111.442 107.096 113.046 104.649 113.405C101.969 113.826 99.986 112.35 99.986 112.35C98.8889 111.632 97.412 111.949 96.6946 113.046C95.9773 114.143 96.2938 115.62 97.3909 116.337C97.4964 116.401 99.5851 117.73 103.193 118.679C99.2475 122.287 95.2177 126.633 94.9856 126.865C94.0995 127.836 94.1628 129.334 95.1122 130.22C95.5764 130.642 96.1461 130.853 96.7157 130.853C97.3487 130.853 98.0027 130.6 98.4669 130.093C98.5091 130.051 101.315 127.013 104.459 123.996C101.547 127.646 98.8256 131.423 98.3192 132.119C97.5597 133.195 97.7918 134.672 98.8678 135.431C99.2898 135.727 99.775 135.874 100.26 135.874C100.999 135.874 101.737 135.537 102.18 134.883C103.742 132.71 105.936 129.777 108.025 127.161C107.244 128.701 106.252 130.452 104.269 133.638C103.573 134.756 103.91 136.212 105.029 136.908C105.408 137.14 105.851 137.267 106.273 137.267C107.075 137.267 107.835 136.866 108.299 136.149C109.122 134.841 109.776 133.743 110.324 132.815C109.902 133.849 110.261 135.052 111.231 135.685C111.632 135.938 112.075 136.064 112.519 136.064C113.299 136.064 114.059 135.685 114.502 134.988C116.717 131.57 117.814 128.764 118.278 126.57L118.384 126.633C119.713 119.966 118.089 116.78 115.683 115.071H115.704ZM110.957 131.781C111.738 130.41 112.286 129.355 112.793 128.321C112.371 129.334 111.78 130.494 110.957 131.781Z'
        fill='#E53E51'
      />
      <path
        d='M42.9778 142.014C35.4667 141.592 24.5588 156.298 35.7199 160.307C25.6137 160.074 23.1452 174.358 32.4074 178.304C16.1827 175.139 18.0815 196.174 25.7403 203.77C20.55 202.947 15.2754 207.905 16.0772 213.327C16.8789 218.771 19.031 226.324 32.5129 230.375C45.9949 234.426 54.2444 226.851 60.9326 219.699C65.2367 228.476 86.4407 216.619 84.0988 206.112C92.1162 208.96 99.2897 199.866 99.4374 192.608C99.5851 185.519 94.627 180.118 89.9009 175.498C93.2345 172.459 93.8674 166.805 92.9813 162.501C91.5255 155.327 82.5586 154.61 82.5586 148.913C82.5586 146.909 82.9384 140.706 81.2505 137.984C78.5499 133.659 73.8238 129.355 67.8951 131.486C42.9778 119.671 42.9778 142.014 42.9778 142.014Z'
        fill='#00396A'
      />
      <path
        d='M57.4934 372.579C50.6364 371.376 49.5393 371.735 42.7667 370.068L27.0905 389.225H87.8753L52.5775 379.731L57.4934 372.579Z'
        fill='#002E4F'
      />
      <path
        d='M170.708 378.064L167.121 373.338C163.872 375.427 160.496 377.221 156.846 378.508L166.446 389.226H202.06L170.708 378.064Z'
        fill='#002E4F'
      />
      <path
        d='M36.3315 372.642C43.1464 374.309 49.9612 376.039 56.8393 377.22L107.033 315.465L98.4878 290.927L63.3376 250.397L73.4649 307.827L36.3315 372.642Z'
        fill='#002E4F'
      />
      <path
        d='M104.606 233.371C90.829 229.721 72.1568 234.341 58.7593 239.405C58.7593 239.405 66.9877 272.129 72.3678 281.897C88.0862 310.317 156.045 381.25 156.045 381.25C159.779 379.984 166.974 374.71 170.265 372.579L104.606 233.371Z'
        fill='#00396A'
      />
      <path
        d='M56.945 252.191C70.2371 247.19 96.9267 238.772 110.62 242.19C110.62 242.19 92.496 199.592 84.2676 190.203C78.7187 183.874 48.379 166.024 48.379 189.359C48.379 221.324 56.945 252.191 56.945 252.191Z'
        fill='#CDE5E8'
      />
      <path
        d='M68.76 187.376C68.5912 187.439 68.4013 187.481 68.2114 187.545C64.1605 188.642 59.983 186.237 58.8858 182.186C57.7465 177.945 57.6832 172.649 57.6832 172.08C57.6832 167.881 61.059 164.463 65.2576 164.442C69.4562 164.442 72.8742 167.818 72.8953 171.995C72.8953 173.472 73.1063 176.468 73.5704 178.219C74.6254 182.101 72.4733 186.068 68.76 187.376Z'
        fill='#E53E51'
      />
      <path
        d='M73.8448 179.865C73.8237 179.316 73.7393 178.768 73.5916 178.219C73.1275 176.468 72.9376 173.472 72.9165 171.995C72.8954 167.797 69.4774 164.421 65.2788 164.442C61.0802 164.442 57.6833 167.881 57.7044 172.08C57.7044 172.164 57.7044 172.396 57.7044 172.713C61.46 176.932 67.6207 178.937 73.8237 179.865H73.8448Z'
        fill='#E53E51'
      />
      <path
        d='M81.2715 157.078C83.7189 164.632 79.5836 172.734 72.0514 175.181C64.4982 177.628 56.3963 173.493 53.9489 165.961L50.8685 156.467C48.4211 148.913 52.5564 140.812 60.0886 138.364C67.6418 135.917 75.7437 140.031 78.1911 147.584L81.2715 157.078Z'
        fill='#E53E51'
      />
      <path
        d='M165.898 149.441C165.602 149.188 165.244 149.04 164.885 148.956C165.223 148.576 165.602 148.175 166.024 147.711C166.911 146.74 166.847 145.242 165.877 144.356C165.75 144.251 165.624 144.145 165.497 144.082C166.151 143.132 166.045 141.824 165.18 141.001C164.505 140.368 163.556 140.221 162.754 140.516C163.83 139.419 164.505 138.66 164.59 138.575C165.455 137.584 165.37 136.085 164.379 135.22C163.387 134.355 161.889 134.44 161.024 135.431C160.961 135.495 155.813 141.318 150.496 144.335C148.196 140.854 145.095 139.63 144.926 139.567C143.702 139.103 142.373 139.714 141.888 140.917C141.424 142.12 142.014 143.491 143.217 143.997C143.322 144.04 145.137 144.799 146.55 146.972C146.361 147.5 146.129 148.28 146.002 149.167C111.063 196.469 64.7093 179.675 62.6205 178.873C58.4641 177.291 53.8014 179.337 52.1979 183.494C50.5944 187.65 52.6409 192.334 56.7973 193.938C57.367 194.149 68.6969 198.474 84.8795 198.474C88.1287 198.474 91.5888 198.305 95.1756 197.883C112.624 195.858 138.428 185.815 159.041 159.231C159.378 159.041 159.864 158.745 160.497 158.281C160.919 158.007 161.341 157.711 161.784 157.353L161.72 157.311C162.944 156.277 164.442 154.842 166.151 152.796C166.995 151.783 166.868 150.285 165.856 149.462L165.898 149.441Z'
        fill='#E53E51'
      />
      <path
        d='M101.547 179L99.585 179.422C94.7956 180.456 89.8164 180.983 84.816 180.983C72.5578 180.983 64.0551 177.903 63.1267 177.544C62.0296 177.122 60.8903 176.911 59.7299 176.911C55.7844 176.911 52.3032 179.295 50.8685 182.988C49.9613 185.351 49.7925 188.051 51.0373 190.245C55.679 198.474 68.4014 201.807 84.9004 201.807C88.4028 201.807 91.9262 201.596 95.3653 201.217C97.4751 200.964 99.6694 200.605 101.927 200.141L103.319 199.782C103.319 199.782 101.336 193.79 100.914 187.693C100.745 185.372 101.568 179.021 101.568 179.021L101.547 179Z'
        fill='#E1F3F6'
      />
      <path
        d='M50.531 164.104C55.5103 160.939 57.2404 154.99 56.6918 149.061C61.0592 151.994 64.5194 150.2 67.2622 145.158C75.5539 156.762 88.1708 139.503 70.9544 133.237C57.1771 128.237 43.674 141.866 47.1975 155.559L50.531 164.104Z'
        fill='#00396A'
      />
    </Icon>
  );
};
