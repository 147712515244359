import {
  formatPrice,
  HStack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@elkaso-app/web-design';
import { useMemo } from 'react';

interface IOrderItemsTable {
  totalItems: number;
  subtotal: number;
  vat: number;
  vatPercent: number;
  total: number;
  currencyCode: string;
  orderStatus: string;
  items: {
    name: string;
    price: number;
    quantity: number;
    unit: string;
    receivedQuantity: number;
    confirmedQuantity: number;
    totalPrice: number;
  }[];
}

export const OrderItemsTable = ({
  items,
  totalItems,
  subtotal,
  vat,
  vatPercent,
  total,
  currencyCode,
  orderStatus,
}: IOrderItemsTable) => {
  const isReceived = useMemo(() => {
    return orderStatus === 'received';
  }, []);

  return (
    <VStack
      spacing='lg'
      flex={1}
      alignItems='stretch'
      divider={<StackDivider borderColor='gray.200' />}
      overflowY='auto'>
      <TableContainer flex={1} overflowY='auto'>
        <Table size='sm' variant='simple'>
          <Thead>
            <Tr>
              <Th>Items</Th>
              <Th>Unit</Th>
              <Th>QTY</Th>
              <Th>{isReceived ? 'Received QTY' : 'Confirmed QTY'}</Th>
              <Th>Ind.Price</Th>
              <Th>Tot.Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items?.map((item, i) => (
              <Tr key={i}>
                <Td>{item?.name}</Td>
                <Td>{item?.unit}</Td>
                <Td>{item?.quantity}</Td>
                <Td>{isReceived ? item?.receivedQuantity : item?.confirmedQuantity}</Td>
                <Td>{formatPrice(item?.price)}</Td>
                <Td>{formatPrice(item?.totalPrice)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <VStack spacing='md' alignItems='stretch' fontWeight={'bold'}>
        <HStack justify='space-between'>
          <Text>Total (Items)</Text>
          <Text>{totalItems}</Text>
        </HStack>
        <HStack justify='space-between'>
          <Text>Subtotal</Text>
          <Text>{formatPrice(subtotal, currencyCode)}</Text>
        </HStack>
        <HStack justify='space-between'>
          <Text>VAT ({vatPercent}%)</Text>
          <Text>{formatPrice(vat, currencyCode)}</Text>
        </HStack>
        <HStack justify='space-between' color='red.500'>
          <Text>Total(Inc.VAT)</Text>
          <Text>{formatPrice(total, currencyCode)}</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};
