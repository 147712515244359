import { HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { EditAggSheetModal } from 'pages/aggregated-sheets/components/edit-agg-sheet-modal';
import { EditProcessingDaysModal } from 'pages/aggregated-sheets/components/edit-processing-days-modal';
import { AggSheetRelation } from 'pages/aggregated-sheets/types';
import { FaHistory } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

export interface IActionsCell {
  aggSheet: AggSheetRelation;
}

export const ActionsCell = ({ aggSheet }: IActionsCell) => {
  const history = useHistory();

  const onReportClick = () => {
    history.push({
      pathname: '/app/agg-sheet-relations/history',
      search: `restaurantId=${aggSheet.restaurant.id}&supplierId=${aggSheet.supplier.id}&supplierType=${aggSheet.supplier.type}`,
    });
  };

  return (
    <HStack spacing='xs'>
      <EditAggSheetModal aggSheet={aggSheet} variant='edit' />
      <EditAggSheetModal aggSheet={aggSheet} variant='test' />
      <EditProcessingDaysModal aggSheet={aggSheet} />
      <IconButtonWithTooltip
        Icon={<FaHistory fontSize='18px' color='gray' />}
        label='History'
        aria-label='History'
        size='sm'
        variant='unstyled'
        onClick={onReportClick}
      />
    </HStack>
  );
};
