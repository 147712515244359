import { Layout, Section } from 'layout';
import { FoodUniverseScreen } from 'pages/catalog/screens/food-universe-screen';
import { ProductCategoriesScreen } from 'pages/catalog/screens/product-details-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const CatalogPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Route path={`${match.url}/food-universe`} component={FoodUniverseScreen} />
          <Route path={`${match.url}/product-categories/:categoryId?`} component={ProductCategoriesScreen} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default CatalogPage;
