import { Box, Text, TextProps, Tooltip } from '@elkaso-app/web-design';
import React from 'react';

export interface TruncateTextProps extends TextProps {
  text: string;
  width?: string;
}

export const TruncateText: React.FC<TruncateTextProps> = ({ text, width = '200px', ...props }) => {
  return (
    <Tooltip label={text}>
      <Box width={width} isTruncated>
        <Text {...props} isTruncated>
          {text}
        </Text>
      </Box>
    </Tooltip>
  );
};
