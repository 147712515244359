import {
  BoxProps,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useUpdateCategoryApi } from 'apis/product-category/use-update-category-api';
import { useUpdateProductApi } from 'apis/product-category/use-update-product-api';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TreeViewSection } from './tree-view-section';

export interface IMoveCategoryModalCell extends BoxProps {
  variant: 'move' | 'edit';
  trigger: JSX.Element;
  value?: any;
}

export const MoveCategoryModal = ({ variant, trigger, value }: IMoveCategoryModalCell) => {
  const params = useParams<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isUpdateCategoryLoading, mutate: updateCategory } = useUpdateCategoryApi(params?.categoryId);
  const { isLoading: isUpdateProductLoading, mutate: updateProduct } = useUpdateProductApi(params?.categoryId);
  const [activeId, setActiveId] = useState(0);

  const handleClick = () => {
    if (variant === 'move') {
      const variables = {
        categoryId: params?.categoryId,
        body: {
          parentId: activeId,
        },
      };

      updateCategory(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }

    if (variant === 'edit') {
      const variables = {
        categoryId: activeId,
        body: {
          products: [value],
        },
      };
      updateProduct(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='sm'>
        <ModalOverlay />
        <ModalContent maxHeight='600px' minWidth='720px'>
          <ModalHeader>
            {variant == 'move' ? 'Move Category' : 'Edit Product’s Category'}
            <Text color='gray.500' fontWeight='semibold' fontSize='md'>
              Please select from below the category you want to move this item to.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow={'auto'}>
            <VStack spacing='md' alignItems='stretch' py='md'>
              <TreeViewSection setActiveIdd={setActiveId} isModal={true} variant={variant} />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                isLoading={isUpdateCategoryLoading || isUpdateProductLoading}
                colorScheme='red'
                type='submit'
                onClick={handleClick}
                form='AddCategoryModal'>
                Confirm
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
