import { Icon, IconProps } from '@elkaso-app/web-design';

export const ClosedArrowIcon = (props: IconProps) => {
  return (
    <Icon width='10px' height='6px' viewBox='0 0 10 6' fill='none' {...props}>
      <g clipPath='url(#clip0_853_1165)'>
        <path d='M0 0.5L5 5.5L10 0.5H0Z' fill='#565656' />
      </g>
      <defs>
        <clipPath id='clip0_853_1165'>
          <rect width='10' height='5' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
