import {
  BasePagination,
  Checkbox,
  formatPrice,
  Heading,
  HStack,
  IconButtonWithTooltip,
  PlusIcon,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useGetSupplierItemsApi } from 'apis/restaurants/branch-supplier-items/use-get-supplier-items-api';
import { useGetBranchByIdApi } from 'apis/restaurants/use-get-branch-by-id-api';
import { useGetRestaurantByIdApi } from 'apis/restaurants/use-get-restaurant-by-id-api';
import { useGetSupplierByIdApi } from 'apis/restaurants/use-get-supplier-by-id-api';
import { useGetCatalogItemUnitsApi } from 'apis/restaurants/warehouse-items/use-get-catalog-item-units';
import { BaseTable, BaseTableContainer, useCustomTableCheckboxes } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { BrandFilterButton } from 'components/base-table/filters/brand';
import { CodeFilterButton } from 'components/base-table/filters/code';
import { PriceFilterButton } from 'components/base-table/filters/price';
import { UnitNameFilterButton } from 'components/base-table/filters/unit-name';
import { BaseSortButton } from 'components/base-table/sorting';
import { TruncateText, TruncateTextProps } from 'components/truncate-text';
import { ability } from 'configs/ability';
import { Can } from 'configs/can';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { AddItemModalCell } from 'pages/restaurants/components/branch-supplier-items/add-item-modal';
import { DownloadCSVDemoFile } from 'pages/restaurants/components/branch-supplier-items/download-csv-demo-file';
import { DownloadProductCSVFile } from 'pages/restaurants/components/branch-supplier-items/download-product-csv-file';
import {
  ItemSynonymsCell,
  ItemSynonymsCellProps,
} from 'pages/restaurants/components/branch-supplier-items/items-synonyms-cell';
import { OpenMappingSheet } from 'pages/restaurants/components/branch-supplier-items/open-mapping-sheet';
import {
  IProductListActionsCell,
  ProductListActionsCell,
} from 'pages/restaurants/components/branch-supplier-items/product-actions-cell';
import { RemoveItemsButton } from 'pages/restaurants/components/branch-supplier-items/remove-items-button';
import { UploadCSVFiles } from 'pages/restaurants/components/branch-supplier-items/upload-csv-files';
import { ItemLevelCell, ItemLevelCellProps } from 'pages/restaurants/components/item-level-cell';
import { IProductCategoryCell, ProductCategoryCell } from 'pages/restaurants/components/product-category-cell';
import { IProductImageCell, ProductImageCell } from 'pages/restaurants/components/product-image-cell';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';

export const BranchSupplierItemsScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();

  const { restaurantId, branchId, supplierId } = useParams<{
    restaurantId: string;
    branchId: string;
    supplierId: string;
  }>();

  // Get branch details
  const { data: branchData } = useGetBranchByIdApi(branchId);

  // Get restaurant details
  const { data: restaurantData } = useGetRestaurantByIdApi(restaurantId);

  // Get supplier details
  const { data: supplierData } = useGetSupplierByIdApi(supplierId);

  // Get supplier items
  const { isLoading, isFetching, data } = useGetSupplierItemsApi();
  const { pageCount, total, data: itemsData } = data ?? {};

  // Get Units *Used in units filter & edit/add new item modal
  useGetCatalogItemUnitsApi();

  // Handle select table rows
  const { checkedItems, isIndeterminate, allChecked, setCheckboxState, handleParentChange, handleChildChange } =
    useCustomTableCheckboxes(itemsData);

  const columns = useMemo(
    () => [
      {
        accessor: 'itemLevel',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['itemLevel']>) => (
          <CustomTableCell<ItemLevelCellProps> as={ItemLevelCell} branchId={value} subEntityType='branch' />
        ),
      },
      {
        Header: <TableHeader name='Image' />,
        accessor: 'image',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['image']>) => (
          <CustomTableCell<IProductImageCell> as={ProductImageCell} imageURL={value} />
        ),
      },
      {
        Header: <TableHeader name='Name(EN)' filterButtonComponent={<NameFilterButton />} />,
        accessor: 'nameEn',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['nameEn']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Name(AR)' filterButtonComponent={<NameFilterButton />} />,
        accessor: 'nameAr',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['nameAr']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Brand' filterButtonComponent={<BrandFilterButton />} />,
        accessor: 'brand',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['brand']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Categories' />,
        accessor: 'categories',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['categories']>) => (
          <CustomTableCell<IProductCategoryCell> as={ProductCategoryCell} categoryName={value} />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Code'
            sortButtonComponent={<BaseSortButton sortBy='code' />}
            filterButtonComponent={<CodeFilterButton />}
          />
        ),
        accessor: 'code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['code']>) => <TableCell value={value} />,
      },
      {
        Header: (
          <TableHeader
            name='Price'
            sortButtonComponent={<BaseSortButton sortBy='price' />}
            filterButtonComponent={<PriceFilterButton />}
          />
        ),
        accessor: 'price',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['price']>) => <TableCell value={formatPrice(value)} />,
      },
      {
        Header: (
          <TableHeader
            name='Unit'
            filterButtonComponent={<UnitNameFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='unit' />}
          />
        ),
        accessor: 'unit',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['unit']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Description' />,
        accessor: 'description',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['description']>) => (
          <CustomTableCell<TruncateTextProps> as={TruncateText} text={value} />
        ),
      },
      {
        Header: <TableHeader name='Synonyms' />,
        accessor: 'synonyms',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['synonyms']>) => (
          <CustomTableCell<ItemSynonymsCellProps> as={ItemSynonymsCell} value={value} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IProductListActionsCell>
            as={ProductListActionsCell}
            row={row}
            item={value}
            isSelected={checkedItems[row.index]?.isChecked}
            onSelectChange={handleChildChange}
          />
        ),
      },
    ],
    [checkedItems]
  );

  interface IColumnsAccessorData {
    id: number;
    itemLevel: number;
    image: string;
    nameEn: string;
    nameAr: string;
    brand: string;
    categories: string;
    code: string;
    price: number;
    unit: string;
    description: string;
    synonyms: string[];
    actions: { [key: string]: any };
  }

  const columnsAccessorData = useMemo(
    () =>
      itemsData?.map((item: (typeof itemsData)[0]): IColumnsAccessorData => {
        return {
          id: item?.id,
          itemLevel: item?.branchId,
          image: item?.images?.[0],
          nameEn: item?.name?.en,
          nameAr: item?.name?.ar,
          brand: item?.brand?.en,
          categories: item?.category?.tree?.find((item: any) => item.depth === 1)?.title,
          code: item?.code,
          price: item?.price,
          unit: item?.unit?.abbreviation,
          description: item?.description,
          synonyms: item?.synonyms,
          actions: item,
        };
      }),
    [itemsData]
  );

  const setInitialHiddenColumns = () => {
    const hiddenColumns = [];

    if (ability.cannot('update', 'User')) hiddenColumns.push('actions');

    return hiddenColumns;
  };

  return (
    <VStack spacing='sm' align='stretch'>
      <WhiteCard p='md'>
        <VStack spacing='md' align='start'>
          <Heading as='h3' fontSize='lg'>
            Upload Branch Supplier Products From CSV File
          </Heading>

          <DownloadCSVDemoFile />
          <OpenMappingSheet />
          <UploadCSVFiles />
        </VStack>
      </WhiteCard>

      <BaseTableContainer isLoading={isLoading}>
        <BaseTableContainer.Header>
          <BaseTableContainer.Header.Left>
            <VStack spacing='sm' align='start'>
              <HStack>
                <BaseTableTitle
                  title={`${restaurantData?.data?.name} > ${branchData?.data?.name} >  ${supplierData?.data?.name}`}
                />

                <BaseTableSpinner isFetching={isFetching} />
              </HStack>

              <DownloadProductCSVFile />
            </VStack>
          </BaseTableContainer.Header.Left>

          <BaseTableContainer.Header.Right>
            <ClearFilterButton />

            <Can I='update' a='User'>
              <AddItemModalCell
                variant='add'
                trigger={<IconButtonWithTooltip label='Add item' aria-label='add-item-btn' Icon={<PlusIcon />} />}
              />
            </Can>

            <Can I='update' a='User'>
              <RemoveItemsButton checkedItems={checkedItems} />
            </Can>

            <Can I='update' a='User'>
              <IconButtonWithTooltip
                label='Select all'
                aria-label='select-btn'
                isDisabled={!itemsData?.length}
                onClick={() => {
                  handleParentChange(!allChecked);
                  setCheckboxState(!allChecked);
                }}
                Icon={
                  <Checkbox
                    mb='unset'
                    colorScheme='gray'
                    borderColor='#757575'
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    pointerEvents='none'
                  />
                }
              />
            </Can>
          </BaseTableContainer.Header.Right>
        </BaseTableContainer.Header>

        <BaseTableContainer.TableWrapper>
          <BaseTable columns={columns} data={columnsAccessorData} initialHiddenColumns={setInitialHiddenColumns()} />
        </BaseTableContainer.TableWrapper>

        <BaseTableContainer.Footer>
          <BaseTableContainer.Footer.Left />
          <BaseTableContainer.Footer.Right>
            <BasePagination>
              <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
              <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
            </BasePagination>
          </BaseTableContainer.Footer.Right>
        </BaseTableContainer.Footer>
      </BaseTableContainer>
    </VStack>
  );
};
