import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import { useGetWarehouseUsersApi } from 'apis/restaurants/use-get-warehouse-users-api';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'ums/v1/restaurants/warehouses';
export const assignWarehouseUserEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  userId: string;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useAssignWarehouseUserApi(restaurantId: string, warehouseId: string, options?: Options): ReturnType {
  const { refetch: refetchWarehousesUsers } = useGetWarehouseUsersApi(restaurantId, warehouseId, { enabled: false });
  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ userId }: TVariables) => {
    try {
      fullEndpoint = `${endpoint}/${restaurantId}/${warehouseId}/users/${userId}/assign`;

      const { data } = await http.post(fullEndpoint);

      // Refetch warehouses users
      refetchWarehousesUsers();

      toast({
        description: 'Warehouse user have been assigned successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
