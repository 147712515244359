import {
  Alert,
  AlertIcon,
  FileUploader,
  Highlight,
  IconProps,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@elkaso-app/web-design';
import { getCampaignDetailsEndpointIdentifier } from 'apis/campaigns/use-get-campaign-details-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { MediaButton } from 'pages/promotions/components/media-button';
import { SkeletonMediaSection } from 'pages/promotions/components/skeleton-media-section';
import { CampaignFormValues, CampaignImagesObj } from 'pages/promotions/hooks/use-campaign-form-handler';
import { MobileIcon, WebIcon } from 'pages/promotions/icons';
import { UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type MediaVariant = {
  label: string;
  size: string;
  value: string;
  tip: string;

  icon: (props: IconProps) => JSX.Element;
};

const mediaVariants: MediaVariant[] = [
  {
    label: 'Large',
    size: '330x200',
    value: 'mobile_large',
    icon: MobileIcon,
    tip: 'Banner will display in the marketplace banners section on mobile devices.',
  },
  {
    label: 'Medium',
    size: '343x130',
    value: 'mobile_medium',
    icon: MobileIcon,
    tip: 'Banner will feature on the home page banner section on mobile.',
  },
  {
    label: 'Small',
    size: '343x85',
    value: 'mobile_small',
    icon: MobileIcon,
    tip: 'Banner may appear in various places with a dimension of 343x85 on mobile.',
  },
  {
    label: 'Large',
    size: '1000x215',
    value: 'web_large',
    icon: WebIcon,
    tip: 'Banner will appear in the web app marketplace banners section.',
  },
  {
    label: 'Medium',
    size: '1000x160',
    value: 'web_medium',
    icon: WebIcon,
    tip: 'Banner will feature on the home page banner section on web.',
  },
  {
    label: 'Small',
    size: '1000x100',
    value: 'web_small',
    icon: WebIcon,
    tip: 'Banner may appear in various places with a dimension of 1000x100 on web.',
  },
];

interface MediaSectionProps {
  setValue: UseFormSetValue<CampaignFormValues>;
  imagesObj: CampaignImagesObj;
  isFetching: boolean;
}

export const MediaSection = ({ imagesObj, setValue, isFetching }: MediaSectionProps) => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { imagesBySizes } = useGetQueryClientData(`${getCampaignDetailsEndpointIdentifier}/${campaignId}`) ?? {};

  if (isFetching) return <SkeletonMediaSection />;

  return (
    <VStack align='stretch' spacing='lg'>
      <VStack align='stretch' spacing='2xs'>
        <Text fontSize={'xl'} fontWeight='bold'>
          Media
        </Text>

        <Text color='gray.500' fontSize='sm' fontWeight='normal'>
          Upload banners for both mobile and web platforms.
        </Text>
      </VStack>

      <Tabs>
        <TabList overflowX={'scroll'} overflowY={'hidden'}>
          {mediaVariants.map((i) => (
            <MediaButton key={i.value} label={i.label} size={i.size} icon={i.icon} />
          ))}
        </TabList>

        <TabPanels>
          {mediaVariants.map((i) => (
            <TabPanel key={i.value}>
              <VStack spacing='lg'>
                <Alert status='info' variant='left-accent' colorScheme='orange' bg='gray.50'>
                  <AlertIcon />

                  <Text>
                    <Highlight query={['Tip:', 'marketplace', 'home page']} styles={{ fontWeight: 'bold' }}>
                      {`Tip: ${i.tip}`}
                    </Highlight>
                  </Text>
                </Alert>

                <FileUploader
                  defaultUrlList={[imagesBySizes?.[i.value] ?? null]}
                  multiple={false}
                  acceptedTypes={['image/jpeg', 'image/png', 'image/webp']}
                  onRemove={() => setValue('imagesObj', { ...imagesObj, [i.value]: undefined })}
                  onRemoveAll={() => setValue('imagesObj', { ...imagesObj, [i.value]: undefined })}
                  onUpload={(files) => setValue('imagesObj', { ...imagesObj, [i.value]: files[0] })}
                />
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </VStack>
  );
};
