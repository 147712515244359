import {
  Button,
  ChakraAsyncSelect,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  useDisclosure,
} from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Option = {
  label: string;
  value: string;
  type?: string; // type is optional for the restaurant
};

type TInputs = {
  restaurant?: Option | null;
  subEntity?: Option | null;
};

const defaultValues: TInputs = {
  restaurant: null,
  subEntity: null,
};

const getRestaurantDetails = async (restaurantId: string) => {
  const { data } = await http.get(`restaurants/${restaurantId}`);
  return data;
};

type SubEntityDetailsProps = {
  restaurantId: string;
  subEntityId?: string;
  subEntityType?: string;
  name?: string;
};

const getSubEntityDetails = async ({ restaurantId, subEntityId, subEntityType, name }: SubEntityDetailsProps) => {
  const { data } = await http.get(`/ums/v1/restaurants/${restaurantId}/locations/all`, {
    params: {
      name,
      subEntityId,
      subEntityType,
    },
  });

  return data;
};

export const RestaurantBranchIdFilterButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const { control, handleSubmit, watch, reset, getValues } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  const isFilterActive = useMemo(() => Boolean(params.restaurantId), [params.restaurantId]);

  const updateValues = async () => {
    const values: TInputs = {
      restaurant: null,
      subEntity: null,
    };

    // Update restaurant value
    if (params.restaurantId) {
      const { data } = await getRestaurantDetails(params.restaurantId as string);

      values.restaurant = {
        label: data?.nameEn,
        value: data?.id,
      };
    }

    if (!params.restaurantId) {
      values.restaurant = null;
      values.subEntity = null;
    }

    // Update branch value
    if (params.restaurantId && params.subEntityId && params.subEntityType) {
      const { data } = await getSubEntityDetails({
        restaurantId: params.restaurantId as string,
        subEntityId: params.subEntityId as string,
        subEntityType: params.subEntityType as string,
      });

      values.subEntity = {
        label: data[0]?.name,
        value: data[0]?.id,
        type: data[0]?.type,
      };
    }

    if (!params.restaurantId || !params.subEntityId || !params.subEntityType) {
      values.subEntity = null;
    }

    reset(values);
  };

  useEffect(() => {
    updateValues();
  }, [params.restaurantId, params.subEntityId, params.subEntityType]);

  const onSubmit = (data: TInputs) => {
    setPageParams({
      ...params,
      restaurantId: data.restaurant?.value,
      subEntityId: data.subEntity?.value,
      subEntityType: data.subEntity?.type,
    });

    onClose();
    reset(data);
  };

  const handleClose = () => {
    updateValues();
    onClose();
  };

  const allFields = watch();
  const isFilterButtonDisabled = useMemo(() => {
    return Object.values(allFields).every((value) => !value);
  }, [allFields]);

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={isFilterActive}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='restaurant'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Restaurant</FormLabel>
                <ChakraAsyncSelect
                  id='restaurant'
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  loadOptions={(inputValue, callback) => {
                    http
                      .get('restaurants', {
                        params: { filter: `nameEn||$contL||${inputValue}` },
                      })
                      .then((res) => {
                        const values: Option[] = [];

                        res?.data?.data?.map((item: any) => {
                          values.push({ label: item?.name, value: item?.id });
                        });

                        callback(values);
                      });
                  }}
                />
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name='subEntity'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Branch</FormLabel>
                <ChakraAsyncSelect
                  id='subEntity'
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  isDisabled={!getValues('restaurant.value')}
                  loadOptions={(inputValue, callback) => {
                    getSubEntityDetails({ restaurantId: getValues('restaurant.value'), name: inputValue }).then(
                      ({ data }) => {
                        const values: Option[] = [];

                        data?.map((item: any) => {
                          values.push({ label: item?.name, value: item?.id, type: item?.type });
                        });

                        callback(values);
                      }
                    );
                  }}
                />
              </FormControl>
            )}
          />

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' isDisabled={isFilterButtonDisabled}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
