export default (theme) =>
  (theme = {
    ...theme,
    direction: 'ltr',
    overrides: {
      ...theme.overrides,
      MuiInputLabel: {
        ...theme.overrides.MuiInputLabel,
        '&$shrink': { transformOrigin: 'top left !important' },
      },
      MuiListItemSecondaryAction: {
        root: {
          right: 16,
          left: 'unset',
        },
      },
      MuiListItem: {
        root: {
          textAlign: 'left',
          direction: 'ltr',
        },
      },
    },
  });
