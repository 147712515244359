import {
  Box,
  Flex,
  formatDateTime,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  TextProps,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useGetWarehouseByIdApi } from 'apis/restaurants/use-get-warehouse-by-id-api';
import { EmailIcon } from 'pages/restaurants/icons/email-icon';
import { useParams } from 'react-router-dom';

const Title = (props: TextProps) => {
  return <Text fontWeight='semibold' color='gray.600' {...props} />;
};

const Info = (props: TextProps) => {
  return <Text {...props} />;
};

export interface IWarehouseNameCell {
  warehouseId: string;
  warehouseName: string;
}

export const WarehouseNameCell = ({ warehouseName, warehouseId }: IWarehouseNameCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { data: warehouse } = useGetWarehouseByIdApi(restaurantId, warehouseId);

  return (
    <>
      <Text fontWeight='semibold' color='red.500' cursor='pointer' onClick={onOpen}>
        {warehouseName}
      </Text>

      <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='xs'>
              <Heading as='h2' size='lg'>
                Warehouse Details
              </Heading>
              <Text color='gray.500' fontWeight='normal' fontSize='md'>
                You are viewing warehouse details.
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack spacing='md'>
              <HStack align='start'>
                <Box flex={1}>
                  <Title>Warehouse Name (En)</Title>
                  <Info>{warehouse?.name?.en}</Info>
                </Box>

                <Box flex={1}>
                  <Title>Warehouse Name (Ar)</Title>
                  <Info>{warehouse?.name?.ar}</Info>
                </Box>
              </HStack>

              <HStack align='start'>
                <Box flex={1}>
                  <Title>Address (En)</Title>
                  <Info>{warehouse?.address?.en}</Info>
                </Box>

                <Box flex={1}>
                  <Title>Address (Ar)</Title>
                  <Info>{warehouse?.address?.ar ?? '...'}</Info>
                </Box>
              </HStack>

              <HStack align='start'>
                <Box flex={1}>
                  <Title>Cut-off Time</Title>
                  <Info>{formatDateTime(warehouse?.cutOffTime)}</Info>
                </Box>

                <Box flex={1}>
                  <Title>Landline Number</Title>
                  <Info>{warehouse?.landlineNumber}</Info>
                </Box>
              </HStack>

              {warehouse?.emails?.length && (
                <VStack spacing='sm' align='stretch'>
                  {warehouse?.emails?.map((email: string) => (
                    <Flex key={email} justify='space-between' align='center'>
                      <EmailIcon me='md' />
                      <Text flex={1}>{email}</Text>
                    </Flex>
                  ))}
                </VStack>
              )}

              <Flex justify='space-between' align='center'>
                <Box>
                  <Title>Auto-approval</Title>
                  <Info>{warehouse?.hasAutoApproval ? 'Enabled' : 'Disabled'}</Info>
                </Box>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};
