import { IconButtonWithTooltip, PenIcon } from '@elkaso-app/web-design';
import { ActionsModal } from './actions-modal';

export interface AllDocumentActionsCellProps {
  actions: any;
}
export const AllDocumentActionsCell = ({ actions }: AllDocumentActionsCellProps) => {
  return (
    <ActionsModal
      actions={actions}
      variant={'edit'}
      trigger={
        <IconButtonWithTooltip size='sm' label='Edit' aria-label='edit-btn' Icon={<PenIcon />} variant='unstyled' />
      }
    />
  );
};
