import { yupResolver } from '@hookform/resolvers/yup';
import { useAddCampaignApi } from 'apis/campaigns/use-add-campaign-api';
import { useEditCampaignApi } from 'apis/campaigns/use-edit-campaign-api';
import { useGetCampaignDetailsApi } from 'apis/campaigns/use-get-campaign-details-api';
import { useGetDownloadImageApi } from 'apis/campaigns/use-get-download-images-api';
import { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup.object().shape({
  compaignName: yup.string().required('Compaign name is required'),
  country: yup.object({
    value: yup.string().required('Country is required.'),
    label: yup.string().required(),
  }),
  publishers: yup
    .array()
    .of(
      yup.object({
        value: yup.string().required('Publisher is required.'),
        label: yup.string().required(),
      })
    )
    .min(1, 'Publisher is required.'),
  target: yup.object({
    value: yup.string().required('Target is required.'),
    label: yup.string().required(),
  }),
  targetId: yup.string().required('Target ID is required'),
  images: yup.object().shape({
    web_small: yup.mixed(),
    web_medium: yup.mixed(),
    web_large: yup.mixed(),
    mobile_small: yup.mixed(),
    mobile_medium: yup.mixed(),
    mobile_large: yup.mixed(),
  }),
  keywords: yup.string(),
});

type TOption = {
  value: string;
  label: string;
};

type ImagesSizeMap = 'web_small' | 'web_medium' | 'web_large' | 'mobile_small' | 'mobile_medium' | 'mobile_large';

export type CampaignImagesObj = Record<ImagesSizeMap, any>;

type FormValues = {
  catalogCategories: TOption[];
  compaignName: string;
  country?: TOption;
  publishers?: TOption[];
  target?: TOption;
  targetId: string;
  imagesObj?: CampaignImagesObj;
  keywords: string;
};

export type CampaignFormValues = FormValues;

const defaultValues: FormValues = {
  compaignName: '',
  country: undefined,
  publishers: [],
  target: undefined,
  targetId: '',
  imagesObj: undefined,
  keywords: '',
  catalogCategories: [],
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const useCampaignFormHandler = () => {
  const history = useHistory();

  const { campaignId, internalName } = useParams<{ campaignId: string; internalName: string }>();

  const { mutate: addCampaign, isLoading: isLoadingAddCampaign } = useAddCampaignApi();
  const { mutate: editCampaign, isLoading: isLoadingEditCampaign } = useEditCampaignApi(campaignId);

  const {
    data: campaignData,
    isLoading: isLoadingCampaignDetails,
    isFetching: isFetchingCampaignDetails,
  } = useGetCampaignDetailsApi(campaignId, {
    enabled: !!campaignId,
  });

  const {
    data: downloadImages,
    isLoading: isLoadingDownloadImages,
    isFetching: isFetchingDownloadImages,
  } = useGetDownloadImageApi(campaignData?.imagesBySizes, {
    enabled: !!Object.keys(campaignData?.imagesBySizes ?? {})?.length,
  });

  const isLoading =
    isLoadingAddCampaign || isLoadingEditCampaign || isLoadingDownloadImages || isLoadingCampaignDetails;
  const isFetching = isFetchingCampaignDetails || isFetchingDownloadImages;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const imagesObj = watch('imagesObj');

  const isEdit = useMemo(() => {
    return !!campaignId;
  }, [campaignId]);

  const getInternalName = useMemo<string>(() => {
    return isEdit ? campaignData?.internalName : internalName;
  }, [internalName, isEdit, campaignData?.internalName, campaignId]);

  const { publishers, country, type, typeTarget, keywords, catalogCategories } = campaignData || {};

  useEffect(() => {
    reset({
      imagesObj: { ...imagesObj, ...(downloadImages as CampaignImagesObj) },
      compaignName: getInternalName,
      country: country
        ? {
            value: country?.id,
            label: country?.name,
          }
        : undefined,
      publishers: publishers
        ? publishers.map((publisher: Record<string, any>) => ({
            value: publisher?.id,
            label: publisher?.name,
          }))
        : [],
      catalogCategories: catalogCategories
        ? catalogCategories.map((catalogCategory: Record<string, any>) => ({
            value: catalogCategory?.id,
            label: catalogCategory?.title,
          }))
        : [],
      target: type
        ? {
            value: type,
            label: capitalizeFirstLetter(type),
          }
        : undefined,
      targetId: typeTarget ?? '',
      keywords: keywords?.join(', ') ?? '',
    });
  }, [campaignData, downloadImages]);

  const isDisabled = useMemo(() => {
    const {
      compaignName,
      country: countryValue,
      publishers: publishersValue,
      catalogCategories: catalogCategoriesValue,
      target,
      targetId,
      keywords: keywordsValue,
    } = watch() || {};

    // Handle keywords
    const keywordsArray = keywordsValue ? keywordsValue?.split(',').map((kw) => kw?.trim()) : [];

    const isKeywordsValid =
      keywordsArray.length === (keywords?.length || 0) && keywordsArray.every((kw) => keywords?.includes(kw));

    const isKeywordsInValidFormat = keywordsValue?.trim().startsWith(',') || keywordsValue?.trim().endsWith(',');

    // Handle images
    for (const key in imagesObj) {
      if (!imagesObj[key as ImagesSizeMap]) {
        delete imagesObj[key as ImagesSizeMap];
      }
    }

    const isFile = (obj: any) => obj instanceof File;

    const isImageValueAsFile =
      imagesObj &&
      Object.keys(imagesObj)
        ?.map((key) => isFile(imagesObj[key as ImagesSizeMap]))
        ?.includes(true);

    const imagesBySizesObj = Object.keys(campaignData?.imagesBySizes ?? {});
    const imagesByObj = Object.keys(imagesObj ?? {});
    downloadImages;
    const downloadImagesObj = Object.keys(downloadImages ?? {});

    const isImagesChanges =
      imagesBySizesObj?.every((item) => imagesByObj?.includes(item)) &&
      imagesBySizesObj?.length == imagesByObj?.length &&
      imagesBySizesObj?.every((item) => downloadImagesObj?.includes(item)) &&
      imagesBySizesObj?.length == downloadImagesObj?.length &&
      !isImageValueAsFile;

    // Handle publishers
    const isPublishersValid =
      publishersValue?.length == publishers?.length &&
      publishersValue?.some((pub) => {
        return publishers?.some((defaultPub: any) => pub?.value?.includes(defaultPub?.id));
      });

    const isCatalogCategoriesValid =
      catalogCategoriesValue?.length == catalogCategories?.length &&
      catalogCategoriesValue?.every((cc) => catalogCategories.some((existingCC: any) => existingCC.id === cc.value));

    // Handle Final Validation
    const isFinalValidationPassed =
      compaignName == getInternalName &&
      countryValue?.value == country?.id &&
      target?.value == type &&
      targetId == typeTarget &&
      isPublishersValid &&
      isImagesChanges &&
      isKeywordsValid &&
      isCatalogCategoriesValid;

    return (
      !compaignName ||
      !countryValue ||
      !publishersValue ||
      !target ||
      !targetId ||
      isKeywordsInValidFormat ||
      isFinalValidationPassed ||
      isLoading ||
      isFetching
    );
  }, [watch(), campaignData, downloadImages]);

  const onSubmit: SubmitHandler<FormValues> = ({
    compaignName,
    publishers,
    country,
    target,
    targetId,
    keywords,
    catalogCategories,
  }) => {
    const formData = new FormData();

    formData.append('internalName', compaignName);
    formData.append('countryId', country?.value as string);
    formData.append('type', target?.value as string);
    formData.append('typeTarget', targetId);
    formData.append('isActive', campaignData?.isActive);
    formData.append('keywords', keywords);

    publishers?.forEach((publisher) => {
      formData.append('publisherIds', publisher?.value);
    });

    catalogCategories.forEach((catalogCategory) => {
      formData.append('catalogCategoryIds', catalogCategory?.value);
    });

    for (const key in imagesObj) {
      if (Object.prototype.hasOwnProperty.call(imagesObj, key)) {
        formData.append(key, imagesObj[key as ImagesSizeMap]);
      }
    }

    const variables = {
      body: formData,
    };

    if (isEdit) {
      editCampaign(variables, {
        onSuccess: () => {
          history.push('/app/promotions/campaigns');
        },
      });
    }

    if (!isEdit) {
      addCampaign(variables, {
        onSuccess: () => {
          history.push('/app/promotions/campaigns');
        },
      });
    }
  };

  return {
    control,
    errors,
    handleSubmit,
    register,
    onSubmit,
    setValue,
    imagesObj,
    isLoading,
    isEdit,
    getInternalName,
    isDisabled,
    isFetching,
  };
};
