import { FeatureFlagsProvider } from 'feature-flags';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// React query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Redux
import { Provider } from 'react-redux';
import store from 'redux/store';

// CASL
import { ability } from 'configs/ability';
import { AbilityContext } from 'configs/can';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <FeatureFlagsProvider>
        <Provider store={store}>
          <AbilityContext.Provider value={ability}>
            <App />
          </AbilityContext.Provider>
        </Provider>
      </FeatureFlagsProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
