import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'ums/v1/zoho/agents';
export const getAssigneeListEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [typeof endpoint];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetAssigneeListApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const isLoggedIn = useIsLoggedIn();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: { page: 1, limit: 40, search: 'kaso' },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: isLoggedIn,
    ...options,
  });
}
