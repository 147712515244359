import { Box, BrandLogo300, Flex, Text, VStack } from '@elkaso-app/web-design';
import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';

export const PreLoginPageLayout = ({ sections }: LayoutProps) => {
  return (
    <Box as='main'>
      <Box as='section' minH={'100vh'}>
        <Flex h='100vh'>
          <VStack display={['none', 'flex']} justify='center' align='start' px='100px' bgColor='white'>
            <BrandLogo300 />

            <Text color='gray.500' fontWeight='light' letterSpacing='wider'>
              Dashboard
            </Text>
          </VStack>

          {/* Start Content */}
          <Flex p={['md', 'lg']} justify='center' align='center' flexGrow={1} bgColor='gray.50' pos='relative'>
            <Section sections={sections} name='content' />
          </Flex>
          {/* End Content */}
        </Flex>
      </Box>
    </Box>
  );
};
