import { Icon, IconProps } from '@elkaso-app/web-design';

export const TruckIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <g clipPath='url(#clip0_2856_12753)'>
        <path
          d='M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8ZM6 18.5C5.17 18.5 4.5 17.83 4.5 17C4.5 16.17 5.17 15.5 6 15.5C6.83 15.5 7.5 16.17 7.5 17C7.5 17.83 6.83 18.5 6 18.5ZM19.5 9.5L21.46 12H17V9.5H19.5ZM18 18.5C17.17 18.5 16.5 17.83 16.5 17C16.5 16.17 17.17 15.5 18 15.5C18.83 15.5 19.5 16.17 19.5 17C19.5 17.83 18.83 18.5 18 18.5Z'
          fill='inherit'
        />
      </g>
      <defs>
        <clipPath id='clip0_2856_12753'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
