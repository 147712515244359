import { Td, Tr } from '@elkaso-app/web-design';

export const EmptyTableState = () => {
  return (
    <Tr bgColor='white'>
      <Td colSpan={100} textAlign='center' fontWeight='bold' fontSize='lg' h='400px'>
        No results found
      </Td>
    </Tr>
  );
};
