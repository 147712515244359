import { combineReducers } from '@reduxjs/toolkit';

// Old
import authUserReducer from 'redux/reducers/authUserReducer';
import catalogReducer from 'redux/reducers/catalogReducer';
import categories from 'redux/reducers/categoriesReducer';
import Countries from 'redux/reducers/countriesReducer';
import dialogReducer from 'redux/reducers/dialogReducer';
import elkasoUsersReducer from 'redux/reducers/elkasoUsersReducer';
import invitationRequestsReducer from 'redux/reducers/InvitationRequestReducer';
import ordersReducer from 'redux/reducers/ordersReducer';
import OTPReducer from 'redux/reducers/otpReducer';
import PaginationAndFilters from 'redux/reducers/paginAndFiltersReducer';
import promotions from 'redux/reducers/promotionReducer';
import RestaurantsReducer from 'redux/reducers/restaurantsReducer';
import settings from 'redux/reducers/settings';
import SuppliersReducer from 'redux/reducers/suppliersReducer';
import unitsReducer from 'redux/reducers/unitsReducer';
import usersReducer from 'redux/reducers/usersReducer';

// New
import config from 'redux/config/reducer';
import sidebar from 'redux/sidebar/reducer';

export default combineReducers({
  settings,
  authUser: authUserReducer,
  dialog: dialogReducer,
  users: usersReducer,
  PaginationAndFilters,
  restaurants: RestaurantsReducer,
  suppliers: SuppliersReducer,
  elkasoUsers: elkasoUsersReducer,
  invitationRequests: invitationRequestsReducer,
  units: unitsReducer,
  orders: ordersReducer,
  catalogs: catalogReducer,
  promotions: promotions,
  categories: categories,
  otp: OTPReducer,
  countries: Countries,

  // New
  sidebar: sidebar,
  config: config,
});
