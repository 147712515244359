import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const ProtectedRoute = ({ path, component: Component, ...rest }: RouteProps) => {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )}
        {...rest}
      />
    );
  }

  return <Route path={path} component={Component} {...rest} />;
};
