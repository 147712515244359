import {
  AsyncSupplierKpiComponent,
  AsyncSuppliersAddComponent,
  AsyncSuppliersFormComponent,
  AsyncSuppliersListComponent,
  AsyncSupplierUsersFormComponent,
  AsyncSupplierUsersListComponent,
} from 'components/AsyncComponent';
import { Layout, Section } from 'layout';
import { SupplierProductsScreen } from 'pages/suppliers/screens/supplier-products-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AddSupplierUser } from './add-supplier-user';
import { SupplierBrandsList } from './supplier-brands-list';

const SuppliersPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
          <Route path={`${match.url}/list`} component={AsyncSuppliersListComponent} />
          <Route exact path={`${match.url}/brands/:supplierId`} component={SupplierBrandsList} />
          <Route path={`${match.url}/add`} component={AsyncSuppliersAddComponent} />

          <Route path={`${match.url}/products/:supplierId`} component={SupplierProductsScreen} />

          <Route path={`${match.url}/edit/:id`} component={AsyncSuppliersFormComponent} />
          <Route exact path={`${match.url}/employees/add/:supplierId`} component={AddSupplierUser} />
          <Route exact path={`${match.url}/employees/:id`} component={AsyncSupplierUsersListComponent} />
          <Route exact path={`${match.url}/employees/edit/:id`} component={AsyncSupplierUsersFormComponent} />
          <Route path={`${match.url}/kpi/:id`} component={AsyncSupplierKpiComponent} />

          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default SuppliersPage;
