import {
  BoxProps,
  Button,
  ChakraSelect,
  formatOptionsArray,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useCreateUserApi } from 'apis/users/use-create-user-api';
import { useGetCountriesApi } from 'apis/users/use-get-countries-api';
import { useGetRolesApi } from 'apis/users/use-get-roles-api';
import { useUpdateUserApi } from 'apis/users/use-update-user-api';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: number;
  label: string;
};

type TInputs = {
  name: string;
  email: string;
  phoneNumber: string;
  countries: TOption[] | undefined;
  roles: TOption | undefined;
};

const defaultValues: TInputs = {
  name: '',
  email: '',
  phoneNumber: '',
  countries: undefined,
  roles: undefined,
};

export interface IAddUserModalCell extends BoxProps {
  variant: 'add' | 'edit';
  trigger: JSX.Element;
  user?: any;
}

export const AddUserModal = ({ variant, user, trigger }: IAddUserModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isCountriesLoading, data: countries = [] } = useGetCountriesApi();
  const { isLoading: isRolesLoading, data: roles = [] } = useGetRolesApi();
  const { isLoading: isCreateUserLoading, mutate: createUser } = useCreateUserApi();
  const { isLoading: isUpdateUserLoading, mutate: updateUser } = useUpdateUserApi();
  const isEdit = variant === 'edit';

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = () => {
    setValue('name', user?.name ?? '');
    setValue('email', user?.email ?? '');
    setValue('phoneNumber', user?.phoneNumber ?? '');
    setValue('countries', formatOptionsArray(user?.permittedCountries, 'id', 'name') ?? undefined);
    setValue('roles', formatOptionsArray(user?.roles, 'id', 'name')?.[0] ?? undefined);
  };

  useEffect(() => {
    updateValues();
  }, [isOpen]);

  const onSubmit = (data: TInputs) => {
    // Create new user
    if (variant === 'add') {
      const variables = {
        body: {
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          permittedCountries: data?.countries?.map((i) => i.value) as number[],
          roles: [data?.roles?.value as number],
        },
      };

      createUser(variables, {
        onSuccess: () => {
          onCloseModal();
        },
      });
    }

    // Edit existing user
    if (variant === 'edit') {
      const variables = {
        userId: user?.id,
        body: {
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          permittedCountries: data?.countries?.map((i) => i.value) as number[],
          roles: [data?.roles?.value as number],
        },
      };

      updateUser(variables, {
        onSuccess: () => {
          onCloseModal();
        },
      });
    }

    // Update form data
    reset(data);
  };

  const onCloseModal = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onCloseModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? 'Edit' : 'Add new'} user</ModalHeader>

          <ModalBody>
            <Stack as='form' id='addUserModal' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl isRequired isInvalid={errors.name ? true : false}>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register('name', {
                    required: 'Name is required.',
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={errors.email ? true : false}>
                <FormLabel>E-mail</FormLabel>
                <Input
                  type='email'
                  {...register('email', {
                    required: 'Email is required.',
                    pattern: {
                      value: /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: 'Email address is incorrect.',
                    },
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={errors.phoneNumber ? true : false}>
                <FormLabel>Phone number</FormLabel>
                <Input
                  type='tel'
                  {...register('phoneNumber', {
                    required: 'Phone number is required.',
                    pattern: {
                      value: /^[+][0-9]{12}$/,
                      message: 'Phone number is incorrect.',
                    },
                  })}
                />

                <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
              </FormControl>

              <Controller
                control={control}
                name='countries'
                rules={{ required: 'Countries are required.' }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                  <FormControl isRequired isInvalid={error ? true : false}>
                    <FormLabel>Countries</FormLabel>
                    <ChakraSelect
                      options={formatOptionsArray(countries, 'id', 'name')}
                      id='countries'
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                      isMulti
                      isLoading={isCountriesLoading}
                    />
                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name='roles'
                rules={{ required: 'Roles are required.' }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                  <FormControl isRequired isInvalid={error ? true : false}>
                    <FormLabel>Roles</FormLabel>
                    <ChakraSelect
                      options={formatOptionsArray(roles, 'id', 'name')}
                      id='roles'
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      ref={ref}
                      isLoading={isRolesLoading}
                    />
                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onCloseModal}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addUserModal'
                isLoading={isCreateUserLoading || isUpdateUserLoading}>
                {isEdit ? 'Edit User' : 'Create User'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
