import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import { useGetWarehouseListApi } from 'apis/restaurants/use-get-warehouse-list-api';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'ums/v1/restaurants/warehouses';
export const updateWarehouseEndpointIdentifier = endpoint;

interface BodyType {
  name: {
    en: string;
    ar: string;
  };
  address: {
    en: string;
    ar: string;
  };
  emails: string[];
  cutOffTime: string;
  landlineNumber: string | null;
  hasAutoApproval: boolean;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  warehouseId: string;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUpdateWarehouseApi(restaurantId: string, options?: Options): ReturnType {
  const { refetch: refetchWarehouses } = useGetWarehouseListApi(restaurantId, { enabled: false });
  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ warehouseId, body }: TVariables) => {
    try {
      fullEndpoint = `${endpoint}/${restaurantId}/${warehouseId}`;
      const { data } = await http.put(fullEndpoint, body);

      // Refetch warehouses list
      refetchWarehouses();

      toast({
        description: 'Warehouse have been updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
