import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useRetryPosJobApi } from 'apis/pos-integrations/use-retry-pos-job-api';

export interface RetryActionPosIssueCellProps {
  id: number;
}

export const RetryPosIssueButton = ({ id }: RetryActionPosIssueCellProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isLoading, mutate: retryPosJob } = useRetryPosJobApi();

  const handleRetryPosJob = () => {
    const variables = {
      id: id,
    };

    retryPosJob(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <Button variant='outline' onClick={onOpen} size='sm' colorScheme='red'>
        Retry
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Restart Job!</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text>Are you sure you want to restart this Job?</Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='sm'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='solid' colorScheme='red' onClick={handleRetryPosJob} isLoading={isLoading}>
                Restart
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
