import { Icon, IconProps } from '@elkaso-app/web-design';

export const AddIcon = (props: IconProps) => {
  return (
    <Icon width='25px' height='25px' viewBox='0 0 25 25' fill='#E03E52' {...props}>
      <g clipPath='url(#clip0_1851_8660)'>
        <path
          d='M12.5 2.5C6.98 2.5 2.5 6.98 2.5 12.5C2.5 18.02 6.98 22.5 12.5 22.5C18.02 22.5 22.5 18.02 22.5 12.5C22.5 6.98 18.02 2.5 12.5 2.5ZM17.5 13.5H13.5V17.5H11.5V13.5H7.5V11.5H11.5V7.5H13.5V11.5H17.5V13.5Z'
          fill='inherit'
        />
      </g>
      <defs>
        <clipPath id='clip0_1851_8660'>
          <rect width='24' height='24' fill='white' transform='translate(0.5 0.5)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
