import api from 'apis/config/api-service';

export default {
  getRestaurants: (params) => api.get('restaurants', { ...params }),
  getRestaurantById: (id, params) => api.get(`restaurants/${id}`, { ...params }),
  updateRestaurant: (restaurant) => api.patch(`restaurants/${restaurant.id}`, restaurant),
  getBranchById: (id) => api.get(`branches/${id}`),
  updateBranch: (branch) => api.patch(`branches/${branch.id}`, branch),
  getRestaurantUsers: (id, params) => api.get(`users?join=branches&filter=restaurantId||$eq||${id}&`, params),
  getRestaurantSuppliers: (resId, params) => api.get(`suppliers/restaurant/${resId}`, params),
  detachSupplier: (supplierId, restaurantId) => api.patch(`suppliers/detach/${supplierId}/${restaurantId}`),
  attachSupplier: (supplierId, restaurantId) => api.patch(`suppliers/attach/${supplierId}/${restaurantId}`),
  deleteRestaurantUser: (userId) => api.delete(`ums/v1/users/${userId}`),
  getRestaurantKpi: (restaurantId, params) => api.get(`reports/restaurants-kpis/${restaurantId}`, { ...params }),
  getRestaurantAdminManagersUsers: (id, params) => api.get(`branches/users/${id}`, params),
  getRestaurantBranches: (params) => api.get(`branches`, { ...params }),
  getBranchSuppliers: (branchId, params) => api.get(`branches/${branchId}/suppliers`, { ...params }),
  changePreference: (data, params) => api.patch(`users/admin/update-preferences`, data, { ...params }),
  mergeRestaurants: (data, holdingId, MergedId, params) =>
    api.post(`restaurants/merge/${holdingId}/${MergedId}`, data, { ...params }),
  assignResAdmin: (data, restaurantId, params) =>
    api.post(`restaurants/restaurant-new-admin/${restaurantId}`, data, { ...params }),
  updateAdminInfo: (info) => info.id && api.patch(`users/${info.id}`, info),
  selectResAdmin: (restaurantId, userId, params) =>
    api.post(`restaurants/restaurant-new-admin/${restaurantId}/${userId}`, {}, { ...params }),
  getRestaurantOrdersList: (params) => api.get('products', { ...params }),
  getRestaurantSupplierEmails: (resId, suppId, params) => api.get(`users/emails/cms/${resId}/${suppId}`, params),
  verifyEmailCC: (data) => api.patch(`users/emails/cms/status/${data.id}`, data),
  attachDetachSupplierToBranch: (branchId, supplierId, data) =>
    api.patch(`branches/${branchId}/link/${supplierId}`, data),
};
