import { HStack, Icon, StackProps, Text, Tooltip, VStack } from '@elkaso-app/web-design';
import { useClipboard } from 'hooks/use-clipboard';
import { FaWhatsapp } from 'react-icons/fa6';
import { IoCheckmarkSharp, IoCopyOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { onWhatsAppClick } from 'utils/on-whatsapp-click';

export interface ISupplierInfoCell extends StackProps {
  restaurantId: string;
  supplierId: string;
  name: string;
  supplierUserName: string;
  phoneNumber: string;
  isAutoApproved: boolean;
}

export const SupplierInfoCell = ({
  restaurantId,
  name,
  supplierUserName,
  isAutoApproved,
  phoneNumber,
  ...rest
}: ISupplierInfoCell) => {
  const { onCopy, hasCopied } = useClipboard();

  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/restaurants/warehouse/list/${restaurantId}?limit=45&page=1&name=${name}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>

      {isAutoApproved && (
        <Text whiteSpace='pre-line' fontSize='sm' color='gray.400'>
          Auto Approved
        </Text>
      )}

      {!isAutoApproved && (
        <Text whiteSpace='pre-line' fontSize='md' color='gray.500'>
          {supplierUserName}
        </Text>
      )}

      {!isAutoApproved && (
        <HStack spacing='xs' align='center' position='relative'>
          <Text fontSize='md' color='gray.500'>
            {phoneNumber}
          </Text>

          <Tooltip label='WhatsApp' aria-label='whatsapp' placement='bottom' hasArrow>
            <span>
              <Icon as={FaWhatsapp} fill='whatsapp.500' cursor='pointer' onClick={() => onWhatsAppClick(phoneNumber)} />
            </span>
          </Tooltip>

          <Tooltip label='Copy' aria-label='copy' placement='bottom' hasArrow>
            <span>
              {!hasCopied && (
                <Icon as={IoCopyOutline} fill='gray.500' cursor='pointer' onClick={() => onCopy(phoneNumber)} />
              )}

              {hasCopied && (
                <Icon as={IoCheckmarkSharp} fill='gray.500' cursor='pointer' onClick={() => onCopy(phoneNumber)} />
              )}
            </span>
          </Tooltip>
        </HStack>
      )}
    </VStack>
  );
};
