import { Box, Center, Divider, Spinner, Text, VStack } from '@elkaso-app/web-design';
import { useGetOrderCommentsApi } from 'apis/orders/use-get-order-comments-api';
import _orderBy from 'lodash/orderBy';
import { AddOrderCommentInput } from 'pages/orders/components/add-order-comment-input';
import { OrderComment } from 'pages/orders/components/order-comment';

interface IOrderComments {
  orderId: string;
}

export const OrderComments = ({ orderId }: IOrderComments) => {
  const { isLoading, isFetching, data: comments } = useGetOrderCommentsApi(orderId);
  const sortedComments = _orderBy(comments, ['createdAt'], ['asc']);

  return (
    <Box>
      {!isLoading && !isFetching && !comments?.length && (
        <Box textAlign='center' color='gray.500' py='md'>
          <Text>Comments box is empty!</Text>
          <Text>Start adding your fist comment from below</Text>
        </Box>
      )}

      {!isLoading && !isFetching && !!comments?.length && (
        <VStack spacing='md' align='start' maxH='191px' overflow='auto'>
          {sortedComments?.map((comment: any) => (
            <OrderComment
              key={comment?.id}
              createdBy={comment?.author?.name}
              createdAt={comment?.createdAt}
              message={comment?.comment}
            />
          ))}
        </VStack>
      )}

      {(isLoading || isFetching) && (
        <Center mt='md'>
          <Spinner color='red.500' />
        </Center>
      )}

      <Divider my='md' borderWidth='2px' />

      <AddOrderCommentInput orderId={orderId} />
    </Box>
  );
};
