import { Button } from '@elkaso-app/web-design';
import { useGetDownloadRestaurantSAMsFileApi } from 'apis/restaurants/restaurant-sams/use-download-restaurant-sams-file-api';
import { useParams } from 'react-router-dom';

interface ExportSAMsProps {
  restaurantName: string;
}

export const ExportSAMs = ({ restaurantName }: ExportSAMsProps) => {
  const { restaurantId, supplierId } = useParams<{ restaurantId: string; supplierId: string }>();
  const { isLoading, isFetching, refetch } = useGetDownloadRestaurantSAMsFileApi(
    restaurantId,
    supplierId,
    restaurantName
  );

  return (
    <Button colorScheme='red' variant='solid' onClick={() => refetch()} isLoading={isLoading || isFetching}>
      Export
    </Button>
  );
};
