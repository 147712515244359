import { Box, useRadio } from '@chakra-ui/react';
import React from 'react';

interface RadioCardProps {
  value: string;
  children: React.ReactNode;
}

export function RadioCard(props: RadioCardProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as='label'>
      <input {...input} />

      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderColor='gray.700'
        borderRadius='4px'
        fontWeight='400'
        textColor='gray'
        userSelect='none'
        _checked={{
          textColor: '#E53E51',
          fontSize: 'md',
          borderColor: '#E53E51',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}>
        {props.children}
      </Box>
    </Box>
  );
}
