import { GET_COUNTRIES, SET_LOADING_STATUS } from 'redux/types';

const INIT_STATE = {
  countriesList: [],
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return { ...state, isLoading: action.payload };
    case GET_COUNTRIES:
      return { ...state, countriesList: action.payload, isLoading: false };
    default:
      return { ...state };
  }
};
