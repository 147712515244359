import api from 'apis/catalog';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import { GET_CATALOG, GET_CATALOG_FAILED, SET_LOADING_STATUS3, SET_PAGES_COUNT, SET_TOTAL_COUNT } from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getProducts = (supplierId, pagination, filters, customParams) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS3, payload: true });
  api
    .getProducts(supplierId, { pagination, filters, customParams })
    .then((response) => {
      dispatch({ type: GET_CATALOG, payload: response.data });
      dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
      dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
    })
    .catch(() => {
      NotificationManager.error(
        <IntlMessages id={`global.error.somethingWentWrong`} />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
      dispatch({ type: GET_CATALOG_FAILED });
    });
};

export const upsertProduct = (product) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS3, payload: true });
  return api
    .upsertProduct(product)
    .then(() => {
      dispatch({ type: SET_LOADING_STATUS3, payload: false });
      return Promise.resolve(product);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS3, payload: false });
      let { code, message } = error;
      message
        ? NotificationManager.error(
            <IntlMessages id={Array.isArray(message) ? message[0] : message} />,
            <IntlMessages id={`${AppConfig.errorTitle}`} />,
            AppConfig.errorNotificationTimeout
          )
        : !(code === 851) &&
          NotificationManager.error(
            <IntlMessages id={`global.error.somethingWentWrong`} />,
            <IntlMessages id={`${AppConfig.errorTitle}`} />,
            AppConfig.errorNotificationTimeout
          );
      return Promise.reject(error);
    });
};
