import { Flex, Text, VStack } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { UploadFilesIcon } from 'pages/ticket-management/icons/upload-files';
import { DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';

interface IUploadTicketFile {
  value: Record<string, never>[];
  onUpload: (data: any) => void;
}

export const UploadTicketFile = ({ value, onUpload }: IUploadTicketFile) => {
  const dropZoneOptions: DropzoneOptions = {
    maxFiles: 10,
    multiple: true,
    validator: (file) => {
      if (file.size > 5000000) {
        return {
          code: ErrorCode.FileTooLarge,
          message: 'File is larger than 5mb',
        };
      }

      return null;
    },
    onDropAccepted: (acceptedFiles) => {
      const collector = acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        const { data } = await http.post('ums/v1/zoho/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        return data;
      });

      Promise.all(collector).then((value) => {
        onUpload(value);
      });
    },
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone(dropZoneOptions);

  return (
    <VStack spacing='lg'>
      <VStack spacing='sm' _hover={{ cursor: 'pointer' }} {...getRootProps()}>
        <input {...getInputProps()} />

        {/* Drag & drop */}
        {!value?.length && <UploadFilesIcon maxW='100%' />}

        {/* Preview */}
        {!!value?.length && (
          <VStack>
            {value.map((item) => (
              <Flex key={item?.name}>
                <Text color='red.500' fontWeight='500'>
                  {item?.name}
                </Text>
              </Flex>
            ))}
          </VStack>
        )}

        {/* Errors */}
        {!!fileRejections.length && <Text color='red.500'>{fileRejections[0]?.errors[0]?.message}</Text>}
      </VStack>
    </VStack>
  );
};
