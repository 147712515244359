import {
  Button,
  FileUploader,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UploadedFile,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useUploadRegionsCsvFileApi } from 'apis/supplier/use-upload-regions-csv-file-api';
import { cloneElement, useState } from 'react';
import { useParams } from 'react-router-dom';

interface IUploadCsvModal {
  trigger: JSX.Element;
  restaurantId: string;
}

export const UploadCsvModal = ({ trigger, restaurantId }: IUploadCsvModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState<UploadedFile>();

  const { supplierId } = useParams<{ supplierId: string }>();

  const { mutate: RegionsUpload, isLoading } = useUploadRegionsCsvFileApi(restaurantId as string, supplierId);

  const onUploadButton = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file as File);

    const variables = {
      body: formData,
    };

    RegionsUpload(variables, {
      onSuccess: () => {
        setFile(undefined);
        onClose();
      },
    });
  };

  return (
    <>
      {cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack spacing='xs' align='start'>
              <Heading as='h3' size='lg'>
                Service Regions
              </Heading>
              <Text color='gray.500' fontSize='md' fontWeight='normal'>
                Upload a CSV file to define the service region for this brand.
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <FileUploader
              style='style_01'
              message='Drag & Drop or click
              here to upload.'
              acceptedTypes={[
                'text/csv',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              multiple={false}
              onRemove={() => {
                setFile(undefined);
              }}
              onRemoveAll={() => {
                setFile(undefined);
              }}
              onUpload={(files) => {
                setFile(files[0]);
              }}
            />
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                CANCEL
              </Button>
              <Button
                isLoading={isLoading}
                onClick={onUploadButton}
                isDisabled={!file}
                variant='solid'
                colorScheme='red'>
                UPLOAD
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
