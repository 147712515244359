import api from 'apis/promotions';
import AppConfig from 'constants/AppConfig';
import { NotificationManager } from 'react-notifications';
import {
  GET_PROMOTIONS,
  GET_PROMOTIONS_FAILED,
  SET_LOADING_STATUS,
  SET_PAGES_COUNT,
  SET_PROMOTION,
  SET_PROMOTION_ERRORS,
  SET_PROMOTION_FAILED,
  SET_SUPPLIER_LOADER,
  SET_TOTAL_COUNT,
} from 'redux/types';
import IntlMessages from 'utils/intl-messages';

export const getPromotions =
  ({ pagination, filters, sort, join }) =>
  (dispatch) => {
    dispatch({ type: SET_LOADING_STATUS, payload: true });
    api
      .getPromotions({ pagination, filters, sort, join })
      .then((response) => {
        dispatch({ type: GET_PROMOTIONS, payload: response.data });
        dispatch({ type: SET_TOTAL_COUNT, payload: response.total });
        dispatch({ type: SET_PAGES_COUNT, payload: response.pageCount });
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: GET_PROMOTIONS_FAILED });
      });
  };

export const getPromotionById =
  ({ id, params }) =>
  (dispatch) => {
    dispatch({ type: SET_LOADING_STATUS, payload: true });
    api
      .getPromotionById(id, params)
      .then((response) => {
        dispatch({ type: SET_LOADING_STATUS, payload: false });
        dispatch({ type: SET_PROMOTION, payload: response.data });
      })
      .catch(() => {
        NotificationManager.error(
          <IntlMessages id={`global.error.somethingWentWrong`} />,
          <IntlMessages id={`${AppConfig.errorTitle}`} />,
          AppConfig.errorNotificationTimeout
        );
        dispatch({ type: SET_PROMOTION_FAILED });
      });
  };

export const addOrUpdatePromotion = (data) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: true });
  return api
    .addOrUpdatePromotions(data)
    .then((response) => {
      dispatch({ type: SET_PROMOTION, payload: response.data });
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch({ type: SET_LOADING_STATUS, payload: false });
      return Promise.reject(error);
    });
};

export const setSupplierLoading = (status) => (dispatch) => {
  dispatch({ type: SET_SUPPLIER_LOADER, payload: status });
};

export const setPromotionsErrors = (errors) => (dispatch) => {
  dispatch({ type: SET_PROMOTION_ERRORS, payload: errors });
};

export const setPromotionLoading = (status) => (dispatch) => {
  dispatch({ type: SET_LOADING_STATUS, payload: status });
};
