import { Spinner, SpinnerProps } from '@elkaso-app/web-design';

interface IBaseTableSpinner extends SpinnerProps {
  isFetching: boolean;
}

export const BaseTableSpinner = ({ isFetching, ...rest }: IBaseTableSpinner) => {
  if (!isFetching) return null;
  return <Spinner size='sm' borderColor='red.500' {...rest} />;
};
