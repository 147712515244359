import {
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

type TInputs = {
  name: string;
};

const defaultValues: TInputs = {
  name: '',
};

export interface IAddCampaignModal extends BoxProps {
  trigger: JSX.Element;
}

export const AddCampaignModal = ({ trigger }: IAddCampaignModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    setValue('name', '');
  }, [isOpen]);

  const onSubmit = (data: TInputs) => {
    history.push(`/app/promotions/campaigns/add/${data.name}`);
  };

  const onCloseModal = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal
        isOpen={isOpen}
        onClose={onCloseModal}
        onCloseComplete={() => reset({})}
        closeOnOverlayClick={false}
        size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='2xs'>
              <Text fontSize={'2xl'} fontWeight='bold'>
                Create New Campaign
              </Text>

              <Text color='gray.500' fontSize='sm' fontWeight='normal'>
                Enter campaign name (won&apos;t be visible to users).
              </Text>
            </VStack>
          </ModalHeader>
          <ModalBody>
            <Stack as='form' id='addCampaignModal' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl isRequired isInvalid={errors.name ? true : false}>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register('name', {
                    required: 'Name is required.',
                  })}
                />

                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='black' onClick={onCloseModal}>
                Cancel
              </Button>

              <Button
                isDisabled={!watch('name')}
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addCampaignModal'>
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
