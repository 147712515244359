import { Icon, IconProps } from '@elkaso-app/web-design';

export const CopyIcon = (props: IconProps) => {
  return (
    <Icon width='21px' height='24px' viewBox='0 0 21 24' fill='#323232' {...props}>
      <path
        d='M15 1H3C1.9 1 1 1.9 1 3V17H3V3H15V1ZM18 5H7C5.9 5 5 5.9 5 7V21C5 22.1 5.9 23 7 23H18C19.1 23 20 22.1 20 21V7C20 5.9 19.1 5 18 5ZM18 21H7V7H18V21Z'
        fill='inherit'
      />
    </Icon>
  );
};
