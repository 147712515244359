import { Dialog, DialogContent, IconButton, Zoom } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RctSectionLoader from 'components/RctSectionLoader/RctSectionLoader';
import React, { Fragment, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { imageToggleDialog } from 'redux/actions';
import { LocalStorageKeys } from 'utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom in ref={ref} {...props} />;
});

const ImageDialog = (props) => {
  const close = () => {
    props.imageToggleDialog({
      openImageDialog: false,
    });
  };

  const accessToken = localStorage.getItem(LocalStorageKeys.access_token);

  const {
    dialog: { openImageDialog, file },
    rtlLayout,
  } = props;

  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPages(null);
    setPageNumber(1);
  }, [openImageDialog]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages);
  };

  const prev = () => {
    if (pageNumber === 1) return;
    else setPageNumber(pageNumber - 1);
  };
  const next = () => {
    if (pageNumber === pages) return;
    else setPageNumber(pageNumber + 1);
  };

  return (
    <Fragment>
      <Dialog
        TransitionComponent={Transition}
        open={openImageDialog || false}
        onClose={() => close()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'>
        <DialogContent style={{ padding: '0', minWidth: '100px', minHeight: '75px' }}>
          {file &&
            (file.name.toString().includes('.pdf') ? (
              <Document
                file={`${file.url}?access_token=${accessToken}`}
                loading={<RctSectionLoader />}
                onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} loading={<RctSectionLoader />} />
                <div
                  className={`d-flex justify-content-center align-items-center mb-5 ${
                    rtlLayout && 'flex-row-reverse'
                  }`}>
                  <IconButton aria-label='Left' onClick={prev} disabled={pageNumber === 1}>
                    <ChevronLeftIcon />
                  </IconButton>
                  <p className='float-right mx-2 my-0'>Page {pageNumber}</p>
                  <p className='float-right mx-2 my-0'>of</p>
                  <p className='float-right mx-2 my-0'>{pages}</p>
                  <IconButton aria-label='Right' onClick={next} disabled={pageNumber === pages}>
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              </Document>
            ) : (
              <img src={`${file.url}?access_token=${accessToken}`} alt='Invoice' width='100%' height='100%' />
            ))}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog, settings: { rtlLayout } }) => {
  return {
    dialog,
    rtlLayout,
  };
};

export default withRouter(connect(mapStateToProps, { imageToggleDialog })(ImageDialog));
