import { Text, TextProps } from '@elkaso-app/web-design';

interface ITableCell extends TextProps {
  value: React.ReactNode;
}

export const TableCell = ({ value, ...rest }: ITableCell) => {
  return <Text {...rest}>{value}</Text>;
};

interface ICustomTableCell {
  as: React.ElementType;
}

export const CustomTableCell = <T,>({ as: As, ...rest }: ICustomTableCell & T) => {
  return <As {...rest} />;
};
