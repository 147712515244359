import { BreadcrumbItem } from '@elkaso-app/web-design';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import IntlMessages from 'utils/intl-messages';

type BreadcrumbItem = {
  title: string;
  link?: string;
};

export const BreadcrumbBar: FC = () => {
  const { push, goBack } = useHistory();
  const { pathname } = useLocation();
  const { title } = useAppSelector((state: any) => state.settings);

  const breadcrumbHandler = (link?: string) => {
    link === 'goBack' ? goBack() : push(link || '');
  };

  const path = pathname.substr(5);
  const res = path.split('/');

  const pathIncludes = (key: string): boolean => res.includes(key);
  const pathIncludesAll = (keys: string[]): boolean => keys.every((key) => res.includes(key));

  const isProfile = pathIncludes('profile');
  const isTicket = pathIncludes('ticket');
  const isList = pathIncludes('list');
  const isAdd = pathIncludes('add');
  const isEdit = pathIncludes('edit');
  const isApprove = pathIncludes('approve');
  const isUsers = pathIncludes('users');
  const isBranch = pathIncludes('branch');
  const isEmployees = pathIncludes('employees') && !res.includes('adminEmployees');
  const restaurantSuppliers = pathIncludesAll(['restaurants', 'branch', 'suppliers']);
  const restaurantWarehouse = pathIncludesAll(['restaurants', 'warehouse']);
  const restaurantWarehouseUsers = pathIncludesAll(['restaurants', 'warehouse', 'users']);
  const restaurantWarehouseProducts = pathIncludesAll(['restaurants', 'warehouse', 'products']);
  const restaurantWarehouseSuppliers = pathIncludesAll(['restaurants', 'warehouse', 'suppliers']);
  const isProducts = pathIncludesAll(['suppliers', 'products']);
  const isBranches = pathIncludes('branch');
  const isBranchUsers = pathIncludesAll(['branch', 'users']) && res.length > 3;
  const isResBranches = pathIncludes('branches');
  const isResOrdersList = pathIncludes('orders-list');
  const isKpi = pathIncludes('kpi');
  const isMerge = pathIncludes('merge');
  const isOrderDetails = pathIncludesAll(['orders', 'details']);
  const isRestaurantTransferDetails = pathIncludesAll(['restaurant-transfers', 'details']);
  const isRestaurantDetails = pathIncludesAll(['restaurants', 'details']);
  const isUsersEdit = isUsers && isEdit && res.length > 3;
  const isEmployeesEdit = isEmployees && isEdit && res.length > 3;
  const isEmployeesAdd = isEmployees && isAdd && res.length >= 3;
  const isBranchEdit = isBranches && isEdit && res.length > 3;

  const breadcrumbArray = isProfile
    ? [{ title: res[0] }]
    : isBranchEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'branches', link: 'goBack' }, { title: res[2] }]
    : isUsersEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'user', link: 'goBack' }, { title: res[2] }]
    : isBranchUsers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'branches', link: 'goBack' }, { title: res[2] }]
    : isEmployeesEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'employee', link: 'goBack' }, { title: res[2] }]
    : isEmployeesAdd
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'employee', link: 'goBack' }, { title: res[2] }]
    : restaurantSuppliers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouseUsers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouseProducts
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouseSuppliers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouse
    ? [
        { title: res[0], link: `/app/${res[0]}` },
        { title: res[1], link: 'goBack' },
      ]
    : isList
    ? [{ title: res[0] }]
    : isAdd
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isApprove
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isBranch
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isResBranches
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isResOrdersList
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isMerge
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isUsers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isEmployees
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isProducts
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isKpi
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isRestaurantDetails
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: `${res[0]}.${res[1]}` }]
    : isTicket
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'ticket-' + res[1] }]
    : isOrderDetails
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isRestaurantTransferDetails
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'transfer-' + res[1] }]
    : [];

  return (
    <div className={`breadcrumbTitle d-flex justify-content-between align-items-center`}>
      {title ? (
        <div className='page-title-wrap'>
          {breadcrumbArray.length > 1 ? (
            <Breadcrumbs separator='/' aria-label='breadcrumb'>
              {breadcrumbArray.map((item: BreadcrumbItem, index: number) => {
                return index !== breadcrumbArray.length - 1 ? (
                  <Link onClick={() => breadcrumbHandler(item.link)} key={index}>
                    <Typography className='secondItem'>
                      <IntlMessages id={`breadcrumb.${item.title}`} />
                    </Typography>
                  </Link>
                ) : (
                  <Typography className='firstItem' key={index}>
                    <IntlMessages id={`${item.title}.breadcrumb`} />
                  </Typography>
                );
              })}
            </Breadcrumbs>
          ) : (
            <Typography color='primary' className='firstItem'>
              {<IntlMessages id={title} />}
            </Typography>
          )}
        </div>
      ) : (
        <div className='page-title-wrap'>
          <h2 className=''>
            <IntlMessages id={title} />
          </h2>
        </div>
      )}
    </div>
  );
};
