import { Center } from '@elkaso-app/web-design';

interface IStatusBadgeStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBadgeStyleProps: IStatusBadgeStyleProps = {
  active: {
    color: '#258750',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  inactive: {
    color: '#000000',
    bgColor: 'rgba(237, 237, 237, 1)',
  },
};

export interface IUserStatusCell {
  status: 'active' | 'inactive';
}

export const UserStatusCell = ({ status }: IUserStatusCell) => {
  return (
    <Center
      bgColor={statusBadgeStyleProps[status]?.bgColor}
      borderRadius={'full'}
      fontWeight='semibold'
      my='sm'
      color={statusBadgeStyleProps[status]?.color}
      p='sm'
      textTransform='capitalize'
      maxW='100px'>
      {status}
    </Center>
  );
};
