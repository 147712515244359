import { Icon, IconProps } from '@elkaso-app/web-design';

export const AddSubCategoryIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='none' {...props}>
      <g clipPath='url(#clip0_876_903)'>
        <path
          d='M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z'
          fill='#939393'
        />
      </g>
      <defs>
        <clipPath id='clip0_876_903'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
