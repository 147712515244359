import { Icon, IconProps } from '@elkaso-app/web-design';

export const CloseIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#E03E52' {...props}>
      <g clipPath='url(#clip0_1851_8668)'>
        <path
          d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
          fill='inherits'
        />
      </g>
      <defs>
        <clipPath id='clip0_1851_8668'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
