import {
  Button,
  ChakraSelect,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  useBoolean,
  useDisclosure,
} from '@elkaso-app/web-design';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { statusObj } from 'pages/restaurant-transfers/utils/restaurant-transfer-status';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
};

type TInputs = {
  status: TOption | undefined;
};

const statusOptions: TOption[] = [
  {
    value: statusObj['approved']?.slug,
    label: statusObj['approved']?.name,
  },
  {
    value: statusObj['canceled']?.slug,
    label: statusObj['canceled']?.name,
  },
  {
    value: statusObj['created']?.slug,
    label: statusObj['created']?.name,
  },
  {
    value: statusObj['rejected']?.slug,
    label: statusObj['rejected']?.name,
  },
  {
    value: statusObj['received']?.slug,
    label: statusObj['received']?.name,
  },
];

const defaultValues: TInputs = {
  status: undefined,
};

export const RestaurantTransferStatusFilterButton = () => {
  const [isActive, setIsActive] = useBoolean(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  // Update active filter button
  useEffect(() => {
    if (params.status) setIsActive.on();
    else setIsActive.off();
  }, [params.status]);

  const { control, handleSubmit, formState, reset, setValue } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = () => {
    setValue(
      'status',
      statusOptions.find((o) => {
        if (params?.status) {
          return o.value === params?.status;
        }

        return undefined;
      })
    );
  };

  useEffect(() => {
    updateValues();
  }, [params.status]);

  const onSubmit = (data: TInputs) => {
    params.status = data.status?.value as string;

    setPageParams(params);
    onClose();
    reset(data);
  };

  const handleClose = () => {
    onClose();
    updateValues();
  };

  const handleDisableFilterButton = () => {
    return !Object.keys(formState.dirtyFields).length;
  };

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={isActive}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='status'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Status</FormLabel>
                <ChakraSelect
                  id='status'
                  options={statusOptions}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                />
              </FormControl>
            )}
          />

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' disabled={handleDisableFilterButton()}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
