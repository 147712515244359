import { Icon, IconProps } from '@elkaso-app/web-design';

export const WhatsAppIcon = (props: IconProps) => {
  return (
    <Icon width='22px' height='22px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <g clipPath='url(#clip0_1987_2103)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0001 3.66675C7.39758 3.66675 3.66675 7.39758 3.66675 12.0001C3.66675 13.5751 4.10425 15.0501 4.86508 16.3067L4.12175 18.8334C4.07907 18.9785 4.07626 19.1324 4.11362 19.2789C4.15098 19.4254 4.22712 19.5592 4.33405 19.6661C4.44097 19.773 4.57473 19.8492 4.72126 19.8865C4.86779 19.9239 5.02168 19.9211 5.16675 19.8784L7.69341 19.1351C8.99231 19.921 10.4819 20.3355 12.0001 20.3334C16.6026 20.3334 20.3334 16.6026 20.3334 12.0001C20.3334 7.39758 16.6026 3.66675 12.0001 3.66675ZM10.1151 13.8859C11.8009 15.5709 13.4101 15.7934 13.9784 15.8142C14.8426 15.8459 15.6842 15.1859 16.0117 14.4201C16.0531 14.3248 16.0681 14.2202 16.0552 14.1171C16.0423 14.0141 16.002 13.9164 15.9384 13.8342C15.4817 13.2509 14.8642 12.8317 14.2609 12.4151C14.1349 12.328 13.9801 12.293 13.829 12.3176C13.6778 12.3421 13.542 12.4243 13.4501 12.5467L12.9501 13.3092C12.9238 13.3502 12.8828 13.3796 12.8356 13.3914C12.7883 13.4032 12.7384 13.3964 12.6959 13.3726C12.3567 13.1784 11.8626 12.8484 11.5076 12.4934C11.1526 12.1384 10.8426 11.6667 10.6684 11.3492C10.6468 11.3089 10.6406 11.2621 10.6508 11.2175C10.6611 11.1729 10.6872 11.1336 10.7242 11.1067L11.4942 10.5351C11.6041 10.4395 11.6751 10.3069 11.6935 10.1625C11.7119 10.018 11.6766 9.87184 11.5942 9.75175C11.2209 9.20508 10.7859 8.51008 10.1551 8.04925C10.0737 7.99025 9.9783 7.95346 9.87836 7.94251C9.77842 7.93155 9.67734 7.94679 9.58508 7.98675C8.81841 8.31508 8.15508 9.15675 8.18675 10.0226C8.20758 10.5909 8.43008 12.2001 10.1151 13.8859Z'
          fill='inherits'
        />
      </g>
      <defs>
        <clipPath id='clip0_1987_2103'>
          <rect width='20' height='20' fill='white' transform='translate(2 2)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
