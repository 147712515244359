import {
  BoxProps,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useAssignTicketApi } from 'apis/orders/use-assign-ticket-api';
import { useGetUnassignedTicketsApi } from 'apis/orders/use-get-unassigned-tickets';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
};

interface FormValues {
  assigneeId: string;
}

const defaultValues: FormValues = {
  assigneeId: '118481000001643661',
};
const agentsOptions: TOption[] = [
  { value: '118481000001643661', label: 'Demecris Bragat' },
  { value: '118481000001030632', label: 'Wilson Francisco' },
  { value: '118481000001030524', label: 'Hamda' },
];

export interface IAssignTicketModal extends BoxProps {
  trigger: JSX.Element;
}

export const AssignTicketsModal: React.FC<IAssignTicketModal> = ({ trigger }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isLoadingAssign, mutate: assignTicketsApi } = useAssignTicketApi();
  const { isLoading: isLoadingTickets, isFetching, data: tickets } = useGetUnassignedTicketsApi();

  const { control, handleSubmit, reset } = useForm<FormValues>({ defaultValues, reValidateMode: 'onChange' });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    assignTicketsApi(data, {
      onSuccess: () => {
        onClose();
        reset();
      },
    });
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}
      <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={reset} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='xs'>
              <Heading fontSize='24px' fontWeight='semibold'>
                Assign Ticket
              </Heading>
              <Text color='gray.600' fontWeight='normal' fontSize='md'>
                You have {tickets?.data?.length ?? 0} unassigned tickets
              </Text>
              <Text color='gray.500' fontWeight='normal' fontSize='md'>
                Please choose the agent that you want to assign the unassigned tickets to
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form id='assignTicketForm' onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name='assigneeId'
                control={control}
                defaultValue={agentsOptions[0].value}
                render={({ field }) => (
                  <RadioGroup {...field} colorScheme='red'>
                    <VStack align='flex-start'>
                      {agentsOptions.map((option) => (
                        <Radio key={option.value} value={option.value} color='black'>
                          {option.label}
                        </Radio>
                      ))}
                    </VStack>
                  </RadioGroup>
                )}
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <HStack spacing='sm'>
              <Button colorScheme='red' variant='outline' onClick={onClose}>
                Cancel
              </Button>
              <Button
                type='submit'
                form='assignTicketForm'
                colorScheme='red'
                variant='solid'
                isDisabled={(tickets?.data?.length ?? 0) === 0}
                isLoading={isLoadingAssign || isLoadingTickets || isFetching}>
                Assign
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
