import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { ProcessingDayPayload } from 'pages/aggregated-sheets/types';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'oms/v1/aggregated-sheets';
export const editProcessingDaysEndpointIdentifier = endpoint;

interface BodyType {
  processingDays: ProcessingDayPayload[];
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  aggSheetId: number;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useEditProcessingDaysApi(options?: Options): ReturnType {
  const toast = useCustomToast();

  const mutationFn = async ({ aggSheetId, body }: TVariables) => {
    const fullEndpoint = `${endpoint}/${aggSheetId}/processing-days`;

    try {
      const { data } = await http.patch(fullEndpoint, body);

      toast({
        description: 'Days have been updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
