import { Icon, IconProps } from '@elkaso-app/web-design';

export const ProductsIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='none' {...props}>
      <g clipPath='url(#clip0_1838_8236)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.41 3.71621C19.584 3.78566 19.7332 3.90566 19.8384 4.06074C19.9436 4.21581 19.9999 4.39884 20 4.58621V15.4137C19.9999 15.6011 19.9436 15.7841 19.8384 15.9392C19.7332 16.0943 19.584 16.2143 19.41 16.2837L10.3475 19.9087C10.124 19.9981 9.87473 19.9981 9.65125 19.9087L0.58875 16.2837C0.414961 16.2141 0.266007 16.094 0.161076 15.9389C0.0561445 15.7839 4.35749e-05 15.6009 0 15.4137L0 4.58621C4.35749e-05 4.39898 0.0561445 4.21606 0.161076 4.061C0.266007 3.90594 0.414961 3.78586 0.58875 3.71621L9.30375 0.229958L9.30875 0.228708L9.65125 0.0912076C9.8751 0.00149851 10.1249 0.00149851 10.3488 0.0912076L10.6913 0.228708L10.6962 0.229958L19.41 3.71621ZM17.6925 4.37496L10 7.45246L2.3075 4.37496L1.25 4.79871V5.29871L9.375 8.54871V18.4512L10 18.7012L10.625 18.4512V8.54996L18.75 5.29996V4.79996L17.6925 4.37496Z'
          fill='#757575'
        />
      </g>
      <defs>
        <clipPath id='clip0_1838_8236'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
