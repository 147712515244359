import { Text, VStack } from '@elkaso-app/web-design';

export interface UserInfoCellProps {
  name: string;
  email: string;
  phoneNumber: string;
}

export const UserInfoCell = ({ name, email, phoneNumber }: UserInfoCellProps) => {
  return (
    <VStack align='start' spacing='sm' py='sm'>
      <Text fontWeight='bold' fontSize={'sm'} color={'red.500'}>
        {name}
      </Text>
      <Text color={'grey'}>{email}</Text>
      <Text color={'grey'}>{phoneNumber}</Text>
    </VStack>
  );
};
