import { HStack, Spinner, Text } from '@elkaso-app/web-design';
import { useDownloadProductsFileApi } from 'apis/restaurants/warehouse-items/use-download-products-file';
import { DownArrowIcon } from 'pages/restaurants/icons/down-arrow-icon';

interface IDownloadProductCSVFile {
  warehouseName: string;
  warehouseId: string;
  restaurantName: string;
  restaurantId: string;
}

export const DownloadProductCSVFile = ({
  warehouseName,
  warehouseId,
  restaurantName,
  restaurantId,
}: IDownloadProductCSVFile) => {
  const {
    refetch: downloadProductsFile,
    isLoading,
    isFetching,
  } = useDownloadProductsFileApi(warehouseName, warehouseId, restaurantName, restaurantId);

  return (
    <HStack spacing='sm' cursor='pointer' w='fit-content' onClick={() => downloadProductsFile()}>
      <Text userSelect='none'>Download products as CSV file</Text>
      <DownArrowIcon />
      {(isLoading || isFetching) && <Spinner size='sm' borderColor='red.500' />}
    </HStack>
  );
};
