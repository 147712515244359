import { Box, Center, Skeleton, Stack, Text } from '@elkaso-app/web-design';
import { useGetCategoriesApi } from 'apis/product-category/use-get-categories-api';
import { TData } from 'apis/product-category/use-get-category-details-api';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LevelComponent } from './level-component';
import { SearchComponent } from './search-component';

export interface ICategoryTree {
  id: number;
  lft: number;
  rgt: number;
  depth: number;
  parentId: number | null;
  rootId: number;
  image: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  open?: boolean;
  translations: {
    id: number;
    catalogCategoryId: number;
    language: string;
    title: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
  }[];
  countries: {
    id: number;
    name: string;
  }[];
  children: ICategoryTree[];
}

export const language = (category: any, type: string) => {
  return category?.translations[0]?.language == type
    ? category?.translations[0]?.title
    : category?.translations[1]?.title;
};

export function filter(category: TData | ICategoryTree[], query: string, type: string) {
  const getNodes = (result: ICategoryTree[], object: ICategoryTree) => {
    if (type === 'filterByTitle')
      if (language(object, 'en')?.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        result.push(object);
        return result;
      }

    if (type === 'filterById')
      if (object.id.toString() === query) {
        result.push(object);
        return result;
      }

    if (Array.isArray(object.children)) {
      const children = object.children.reduce(getNodes, []);
      if (children.length) result.push({ ...object, open: true, children });
    }

    return result;
  };

  return category.reduce(getNodes, []);
}

interface ITreeView {
  isModal?: boolean;
  variant?: string;
  setActiveIdd?: (id: number) => void;
}

export const TreeViewSection = ({ variant, isModal, setActiveIdd }: ITreeView) => {
  const params = useParams<any>();
  const { isLoading, data } = useGetCategoriesApi();
  const [filterName, setFilterName] = useState('');
  const { data: ProductCategoriesData } = data ?? {};
  const [activeId, setActiveId] = useState(0);

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.currentTarget.value);
  };

  useEffect(() => {
    if (setActiveIdd) setActiveIdd(activeId);
  }, [activeId]);

  const isNotFound = !filter(ProductCategoriesData ?? [], filterName, 'filterByTitle').length && !!filterName;

  const opened = () => {
    const resultFilter = filter(
      ProductCategoriesData ?? [],
      params.categoryId ? params.categoryId.toString() : '',
      'filterById'
    )[0];

    if (params.categoryId == resultFilter?.id) return [{ id: null }];

    if (params.categoryId == resultFilter?.children[0]?.id) return [{ id: resultFilter?.id }, { id: null }];

    if (params.categoryId == resultFilter?.children[0]?.children[0]?.id)
      return [{ id: resultFilter?.id }, { id: resultFilter?.children[0]?.id }, { id: null }];

    return [
      { id: resultFilter?.id },
      { id: resultFilter?.children[0]?.id },
      {
        id: resultFilter?.children[0]?.children[0]?.id,
      },
      {
        id: null,
      },
    ];
  };

  return (
    <>
      <SearchComponent title={'Categories'} filterName={filterName} onFilterName={handleFilterByName} px='md' />

      <Box overflow='auto'>
        {isLoading ? (
          <Stack p={'md'}>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
        ) : (
          <Box ms={'-lg'}>
            {filter(ProductCategoriesData ?? [], filterName, 'filterByTitle').map((level1: ICategoryTree) => {
              return (
                <LevelComponent
                  activeId={activeId}
                  setActiveId={setActiveId}
                  isModal={isModal}
                  key={level1.id}
                  open={level1?.open || opened().some((item) => item.id == level1.id)}
                  id={level1.id}
                  name={language(level1, 'en')}>
                  {(level1.children || []).map((level2: ICategoryTree) => {
                    return (
                      <LevelComponent
                        activeId={activeId}
                        setActiveId={setActiveId}
                        isModal={isModal}
                        key={level2.id}
                        open={level2?.open || opened().some((item) => item.id == level2.id)}
                        id={level2.id}
                        name={language(level2, 'en')}>
                        {(level2.children || []).map((level3: ICategoryTree) => {
                          return (
                            <LevelComponent
                              activeId={activeId}
                              variant={variant}
                              setActiveId={setActiveId}
                              isModal={isModal}
                              depth={level3.depth}
                              key={level3.id}
                              open={level3?.open || opened().some((item) => item.id == level3.id)}
                              id={level3.id}
                              name={language(level3, 'en')}>
                              {(!isModal || variant == 'edit') &&
                                (level3.children || []).map((level4: ICategoryTree) => {
                                  return (
                                    <LevelComponent
                                      activeId={activeId}
                                      setActiveId={setActiveId}
                                      isModal={isModal}
                                      key={level4.id}
                                      depth={level4.depth}
                                      id={level4.id}
                                      name={language(level4, 'en')}></LevelComponent>
                                  );
                                })}
                            </LevelComponent>
                          );
                        })}
                      </LevelComponent>
                    );
                  })}
                </LevelComponent>
              );
            })}
          </Box>
        )}

        {isNotFound && !isLoading && (
          <Text ps='md'>
            <Center>No results found</Center>
          </Text>
        )}
      </Box>
    </>
  );
};
