import { BasePagination, formatDateTime } from '@elkaso-app/web-design';
import { useGetLicensesApi } from 'apis/restaurants/restaurants-licenses/use-get-licenses-api';
import { BaseTable, BaseTableContainer, RestaurantIdFilterButton } from 'components/base-table';
import { CustomTableCell, TableCell, TableHeader } from 'components/base-table/components';
import { LicenseNumberFilterButton } from 'components/base-table/filters/license-number';
import { StatusLicenseFilterButton } from 'components/base-table/filters/license-status';
import { ability } from 'configs/ability';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { DateTimeCell, IDateTimeCell } from 'pages/restaurants/components/restaurant-licenses/date-time-cell';
import { IStatusBadgeCell, StatusBadgeCell } from 'pages/restaurants/components/restaurant-licenses/status-badge-cell';
import React from 'react';
import { CellProps } from 'react-table';
import { AllDocumentActionsCell, AllDocumentActionsCellProps } from './all-document-actions-cell';

export const AllDocumentList = () => {
  const setPaginationParams = useSetPaginationParamsV1();

  const { isLoading, isFetching, data } = useGetLicensesApi(['approved', 'rejected']);
  const { pageCount, total, data: licensesData } = data ?? {};

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Restaurant Name' filterButtonComponent={<RestaurantIdFilterButton />} />,
        accessor: 'restaurantName',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['restaurantName']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Submission Date' />,
        accessor: 'submissionDate',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['submissionDate']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: <TableHeader name='Collected From' />,
        accessor: 'collectedFrom',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['collectedFrom']>) => (
          <TableCell value={value === 'in_app' ? 'Marketplace' : 'Register'} />
        ),
      },
      {
        Header: <TableHeader name='Country' />,
        accessor: 'countryCode',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['countryCode']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Type' />,
        accessor: 'type',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['type']>) => (
          <TableCell value={value.toUpperCase()} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Trade Licence Number' filterButtonComponent={<LicenseNumberFilterButton />} />,
        accessor: 'licenseNumber',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['licenseNumber']>) => (
          <TableCell value={value} color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Expiry Date' />,
        accessor: 'expiryDate',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['expiryDate']>) => (
          <TableCell value={formatDateTime(value, 'DD-MM-YYYY')} color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Status' filterButtonComponent={<StatusLicenseFilterButton />} />,
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => (
          <CustomTableCell<IStatusBadgeCell> as={StatusBadgeCell} status={value} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<AllDocumentActionsCellProps> as={AllDocumentActionsCell} actions={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    restaurantName: string;
    submissionDate: string;
    collectedFrom: string;
    licenseNumber: string;
    expiryDate: string;
    status: string;
    actions: any;
    countryCode: string;
    type: string;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      licensesData?.map((license: Record<string, any>): IColumnsAccessorData => {
        return {
          id: license?.id,
          restaurantName: license?.restaurant?.name,
          submissionDate: license?.createdAt,
          collectedFrom: license?.collectedFrom,
          licenseNumber: license?.licenseNumber,
          expiryDate: license?.expiryDate,
          status: license?.status,
          actions: license,
          countryCode: license?.restaurant?.countryCode,
          type: license?.type,
        };
      }),
    [licensesData]
  );

  const setInitialHiddenColumns = () => {
    const hiddenColumns = [];

    if (ability.cannot('update', 'User')) {
      hiddenColumns.push('activation');
      hiddenColumns.push('actions');
    }

    return hiddenColumns;
  };

  return (
    <BaseTableContainer isLoading={isLoading || isFetching} pt={'md'} border={0}>
      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} initialHiddenColumns={setInitialHiddenColumns()} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
