import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Slide,
  Typography,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import api from 'apis/elkasoUsers';
import useForm from 'components/general-functions/forms';
import RctSectionLoader from 'components/RctSectionLoader/RctSectionLoader';
import AppConfig from 'constants/AppConfig';
import React, { Fragment, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mergeSupplierToggleDialog } from 'redux/actions';
import { LocalStorageKeys } from 'utils/constants';
import IntlMessages from 'utils/intl-messages';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MasterKeyDialog = (props) => {
  const close = () => {
    props.mergeSupplierToggleDialog({
      openMasterKeyDialog: false,
    });
  };

  const [pin, setPin] = useState(null);
  const [loading, setloading] = useState(false);

  const {
    dialog: { openMasterKeyDialog },
  } = props;

  let statePassword = useForm({ password: '' });
  let { onChange, setValue } = statePassword;
  let user = statePassword.value;

  const getMasterKey = (e) => {
    e.preventDefault();
    setloading(true);
    api
      .getMasterKey(user)
      .then((res) => {
        res.pin && setPin(res.pin);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        err.code === 311
          ? NotificationManager.error(
              <IntlMessages id='wrong.password' />,
              <IntlMessages id={`${AppConfig.errorTitle}`} />,
              AppConfig.errorNotificationTimeout
            )
          : NotificationManager.error(
              <IntlMessages id={`global.error.somethingWentWrong`} />,
              <IntlMessages id={`${AppConfig.errorTitle}`} />,
              AppConfig.errorNotificationTimeout
            );
      });
  };

  useEffect(() => {
    !openMasterKeyDialog && setPin(null);
    setValue('');
  }, [openMasterKeyDialog]);

  return (
    <Fragment>
      <Dialog
        open={openMasterKeyDialog || false}
        onClose={() => close()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        TransitionComponent={Transition}>
        <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title'>
          <div className='page-title'>
            <h2 style={{ marginBottom: '30px' }}>
              <IntlMessages id={`enter.password`} />
            </h2>
          </div>
        </DialogTitle>

        {loading && <RctSectionLoader />}
        {pin && (
          <Fragment>
            <DialogContent style={{ padding: '8px 60px', width: '700px' }}>
              <Typography variant='h6' color='textSecondary'>
                <IntlMessages id='master.key' />
                <strong>{pin}</strong>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color='primary' variant='outlined'>
                <IntlMessages id={`button.cancel`} />
              </Button>
            </DialogActions>
          </Fragment>
        )}
        {!pin && (
          <ValidatorForm
            noValidate
            onSubmit={(e) => getMasterKey(e)}
            onError={() =>
              NotificationManager.error(
                <IntlMessages id='error.required' />,
                <IntlMessages id={`${AppConfig.errorTitle}`} />,
                AppConfig.errorNotificationTimeout
              )
            }>
            <DialogContent style={{ padding: '8px 60px', width: '700px' }}>
              <TextValidator
                fullWidth
                required
                placeholder={localStorage.getItem(LocalStorageKeys.language) === 'ar' ? 'كلمة المرور' : 'Password'}
                value={user.password || ''}
                type={user.showPassword ? 'text' : 'password'}
                name='password'
                variant='filled'
                onChange={onChange}
                validators={['required']}
                errorMessages={[]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        tabIndex='-1'
                        className='icon-dark'
                        onClick={() => statePassword.toggleValue('showPassword')}>
                        {user.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </DialogContent>
            <DialogActions style={{ padding: '8px 60px 30px' }}>
              <Button onClick={close} color='primary' variant='outlined'>
                <IntlMessages id={`button.cancel`} />
              </Button>
              <Button variant='contained' color='primary' type='submit'>
                <IntlMessages id='components.submit' />
              </Button>
            </DialogActions>
          </ValidatorForm>
        )}
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(connect(mapStateToProps, { mergeSupplierToggleDialog })(MasterKeyDialog));
