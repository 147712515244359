/**
 *  Image Cropper
 */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { uploadFileApi } from 'apis/config/upload-file-api';
import RctSectionLoader from 'components/RctSectionLoader/RctSectionLoader';
import 'cropperjs/dist/cropper.css';
import { Fragment, useState } from 'react';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ImageCropperDialog } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const CropperDialog = (props) => {
  //library docs
  //https://www.npmjs.com/package/react-cropper
  const {
    dialog: { openCropper, title, image, updateCroppedImage },
  } = props;

  let imageExt = image && image.split('?')[0].split('.').pop().toLowerCase();

  const [cropper, setCropper] = useState();
  const [uploading, setUploading] = useState(false);

  const close = () => props.ImageCropperDialog({ openCropper: false });

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas({
        width: 160,
        height: 90,
        minWidth: 256,
        minHeight: 256,
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: '#fff',
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',
        borderRadius: '50%',
      });
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob, `croppedImage.${imageExt}`);
        setUploading(true);
        uploadFileApi(formData, props.url, props.token).then((response) => {
          updateCroppedImage(response.data.url);
          setUploading(false);
          close();
        });
      }, `image/${imageExt}`);
    }
  };

  if (!openCropper) return <Fragment />;
  return (
    <Dialog open={openCropper || false} onClose={() => close()}>
      <DialogTitle id='simple-dialog-title' className={`confirmation-dialog`}>
        <IntlMessages id={title} />
      </DialogTitle>
      <DialogContent>
        {!!uploading && <RctSectionLoader />}
        <Cropper
          style={{ height: 400, width: '100%' }}
          zoomTo={1}
          zoomable={false}
          initialAspectRatio={1}
          aspectRatio={1}
          src={image}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </DialogContent>
      <DialogActions className={'dialog-actions'}>
        <Button onClick={close} color='primary' variant='outlined' className={'p-0'}>
          {
            <p className={'button-dialog-text'}>
              <IntlMessages id='button.cancel' />
            </p>
          }
        </Button>
        <Button onClick={getCropData} color='primary' variant='contained' className={'p-0'}>
          {
            <p className={'button-dialog-text'}>
              <IntlMessages id='global.crop' />
            </p>
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};
export default withRouter(connect(mapStateToProps, { ImageCropperDialog })(CropperDialog));
