import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutocompleteLoader from 'components/AutoComplete/AutoCompleteLoader';
import { Fragment } from 'react';
import IntlMessages from 'utils/intl-messages';

const AutoCompleteComponent = (props) => {
  const {
    options,
    loading,
    disabled,
    getOptionLabel,
    filterSelectedOptions,
    label,
    placeholder,
    onChange,
    required,
    multiple,
    errors,
    helperText,
    value,
    renderOption,
    getOptionSelected,
    customClass,
    disableClearable,
    customStyle,
    inputValue,
    onChangeTextField,
    autoComplete,
    autoSelect,
    onInputChange,
    noOptionsText,
    ...rest
  } = props;
  return (
    <Autocomplete
      onInputChange={onInputChange}
      inputValue={inputValue}
      autoSelect={autoSelect}
      autoComplete={autoComplete}
      style={customStyle}
      className={customClass}
      disableClearable={disableClearable ? disableClearable : false}
      renderOption={renderOption}
      loading={loading}
      disabled={disabled}
      onChange={onChange}
      multiple={multiple}
      fullWidth
      options={options ? options : []}
      value={value ? value : []}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterSelectedOptions={filterSelectedOptions} // true = hide selected item from options
      getOptionLabel={getOptionLabel}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onChangeTextField}
          error={errors ? errors : false}
          helperText={errors ? <IntlMessages id={`${helperText && helperText}`} /> : ''}
          variant='standard'
          required={required ? required : false}
          label={label ? <IntlMessages id={`${label}`} /> : ''}
          placeholder={placeholder ? placeholder : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {!!loading && <AutocompleteLoader />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default AutoCompleteComponent;
