import api from 'apis/config/api-service';

export default {
  getSuppliers: (params) => api.get('suppliers', { ...params }),
  getSupplierById: (id, params) => api.get(`suppliers/${id}`, { ...params }),
  updateSupplier: (supplier) => api.patch(`suppliers/${supplier.id}`, supplier),
  getSupplierUsers: (id, params) => api.get(`users?filter=supplierId||$eq||${id}&`, params),
  getSupplierAdminUsers: (id, params) => api.get(`users/${id}/staff`, params),
  getSupplierKpi: (supplierId, params) => api.get(`reports/supplier-kpis/${supplierId}`, { ...params }),
  mergeSupplier: (supplierId, mergedId) => api.post(`suppliers/merge/${supplierId}/${mergedId}`),
  getWhatsNumbers: (params) => api.get(`whatsapp-numbers`, { ...params }),
  updateWhatsNumbers: (data) => api.patch(`whatsapp-numbers/${data.id}`, data),
};
