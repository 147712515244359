import { useEffect } from 'react';
import { LocalStorageKeys } from 'utils/constants';

export const useSyncLogout = () => {
  const onStorageUpdate = ({ key }: StorageEvent) => {
    if (key === LocalStorageKeys.access_token) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);

    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);
};
