/**
 * App Settings Reducers
 */
import api from 'apis/config/api-service';

import AppConfig from 'constants/AppConfig';
import {
  CHANGE_TITLE,
  RTL_LAYOUT,
  SET_COUNTRY_CODE,
  SET_COUNTRY_DISPLAY,
  SET_IMAGE_LOADING,
  SET_LANGUAGE,
  SET_REDIRECTED_URL,
  SET_ROOT_OBJECT,
  UPDATE_TOUR,
} from 'redux/types';

/**
 * initial app settings
 */
const INIT_STATE = {
  locale: AppConfig.locale,
  rtlLayout: AppConfig.rtlLayout,
  type: 'light',
  isImageLoading: false,
  redirectedUrl: '',
  languages: [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'en',
    },
    {
      languageId: 'arabic',
      locale: 'ar',
      name: 'العربية',
      icon: 'ar',
    },
  ],
  tour: { steps: [], run: true },
  rootObject: {},
  color: {
    primary: {
      main: '#E53E51',
      light: '#4BDEDE',
      dark: '#E53E51',
      contrastText: '#fff',
    },
  },
  countryDisplay: true,
  countryCode: AppConfig.countryCode,
  countries: [
    {
      country: 'GL',
      icon: 'GL',
    },
    {
      country: 'SA',
      icon: 'SA',
    },
    {
      country: 'AE',
      icon: 'AE',
    },
    {
      country: 'QA',
      icon: 'QA',
    },
    {
      country: 'KW',
      icon: 'KW',
    },
    {
      country: 'BE',
      icon: 'BE',
    },
  ],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //change page title
    case CHANGE_TITLE:
      return { ...state, title: action.payload };
    // rtl layout
    case RTL_LAYOUT:
      return { ...state, rtlLayout: action.payload };
    // set language
    case SET_LANGUAGE:
      api.defaults.headers.common['Accept-Language'] = action.payload;
      return { ...state, locale: action.payload };

    case UPDATE_TOUR:
      return { ...state, tour: action.payload };
    case SET_ROOT_OBJECT:
      return { ...state, rootObject: action.payload };
    case SET_COUNTRY_CODE:
      return { ...state, countryCode: action.payload };
    case SET_REDIRECTED_URL:
      return { ...state, redirectedUrl: action.payload };
    case SET_COUNTRY_DISPLAY:
      return { ...state, countryDisplay: action.payload };
    case SET_IMAGE_LOADING:
      return { ...state, isImageLoading: action.payload };
    default:
      return { ...state };
  }
};
