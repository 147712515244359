import { Box, HStack, IconButtonWithTooltip, StackDivider, Text, VStack } from '@elkaso-app/web-design';
import dayjs from 'dayjs';
import { CopyIcon } from '../icons/copy-icon';
import { statusObj } from '../utils/order-status';
import { RestaurantInfoCell } from './restaurant-info-cell';
import { SupplierInfoCell } from './supplier-info-cell';

interface IStatusBannerStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBannerStyleProps: IStatusBannerStyleProps = {
  approved: {
    color: '#71BD4D',
    bgColor: 'rgba(113, 189, 77, 0.08)',
  },
  modified: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  draft: {
    color: '#C2C2C2',
    bgColor: 'rgba(194, 194, 194, 0.08)',
  },
  canceled: {
    color: '#110000',
    bgColor: 'rgba(17, 0, 0, 0.05)',
  },
  restaurant_placed: {
    color: '#160C45',
    bgColor: 'rgba(22, 12, 69, 0.08)',
  },
  supplier_placed: {
    color: '#F0AD4E',
    bgColor: 'rgba(240, 173, 78, 0.08)',
  },
  rejected: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
  received: {
    color: '#5BC0DE',
    bgColor: 'rgba(91, 192, 222, 0.08)',
  },
  restaurant_placed_approval: {
    color: '#F0AD4E',
    bgColor: 'rgba(240, 173, 78, 0.08)',
  },
  draft_expire: {
    color: '#C2C2C2',
    bgColor: 'rgba(194, 194, 194, 0.08)',
  },
};

interface IOrderStatusBanner {
  code: string;
  createdAt: string;
  status: string;
}

const OrderStatusBanner = ({ code, createdAt, status }: IOrderStatusBanner) => {
  return (
    <HStack justify='space-between' p='md' borderRadius='base' bgColor={statusBannerStyleProps[status].bgColor}>
      <Box>
        <Text fontWeight='500'>Order #{code}</Text>
        <Text>Creation Date: {dayjs(createdAt).format('DD-MM-YYYY')}</Text>
      </Box>
      <Text color={statusBannerStyleProps[status].color} fontWeight='500' fontSize='md'>
        {statusObj[status].name}
      </Text>
    </HStack>
  );
};

interface IInfoRow {
  title: string;
  body: string;
  enableBodyCopyButton?: boolean;
  titleColor?: string;
}

const InfoRow = ({ title, body, titleColor = 'gray.500', enableBodyCopyButton = false }: IInfoRow) => {
  const onCopy = () => {
    return navigator.clipboard.writeText(body);
  };

  return (
    <HStack spacing='sm'>
      <VStack spacing='2xs' align='start' flex={1}>
        <Text textTransform='capitalize' color={titleColor} fontSize='sm'>
          {title}
        </Text>
        <Text color='gray.800' fontSize='md' fontWeight='500'>
          {body ?? '---'}
        </Text>
      </VStack>

      {!!enableBodyCopyButton && (
        <IconButtonWithTooltip
          label='Copy'
          aria-label='copy'
          Icon={<CopyIcon />}
          onClick={onCopy}
          variant='ghost'
          disabled={!body}
        />
      )}
    </HStack>
  );
};

interface IRestaurantDetails {
  restaurantId: string;
  name: string;
  subEntity: string;
  phoneNumber: string;
}

const RestaurantDetails = ({ restaurantId, name, subEntity, phoneNumber }: IRestaurantDetails) => {
  return (
    <Box>
      <Text mb='xs' color='gray.600' fontWeight='bold'>
        Restaurant Details
      </Text>

      <RestaurantInfoCell
        restaurantId={restaurantId}
        name={name}
        subEntityName={subEntity}
        phoneNumber={phoneNumber}
        spacing='0'
      />
    </Box>
  );
};

interface ISupplierDetails {
  supplierId: string;
  name: string;
  supplierUserName: string;
  phoneNumber: string;
}

const SupplierDetails = ({ supplierId, name, supplierUserName, phoneNumber }: ISupplierDetails) => {
  return (
    <Box>
      <Text mb='xs' color='gray.600' fontWeight='bold'>
        Supplier Details
      </Text>

      <SupplierInfoCell
        supplierId={supplierId}
        name={name}
        supplierUserName={supplierUserName}
        phoneNumber={phoneNumber}
        spacing='0'
      />
    </Box>
  );
};

interface IOrderDetailsSection {
  order: any;
}

export const OrderDetailsSection = ({ order }: IOrderDetailsSection) => {
  return (
    <HStack spacing='0' divider={<StackDivider borderWidth='4px' borderColor='gray.100' />} align='start' flex={1}>
      <VStack spacing='md' flex={1} align='stretch' pt='md' pe='md' minW='400px'>
        <OrderStatusBanner code={order?.code} createdAt={order?.createdAt} status={order?.status} />
        <InfoRow title='Invoice Number' body={order?.invoiceNumber} enableBodyCopyButton />
        <InfoRow title='Delivery Details' body={order?.restaurant?.subEntity?.address} enableBodyCopyButton />
        <InfoRow title='Expected Delivery Date' body={dayjs(order?.deliveryDate).format('DD-MM-YYYY')} />
        <InfoRow title='Delivery Instructions' body={order?.note} />
        {order?.comment && <InfoRow title='Note' body={order?.comment} />}
      </VStack>

      <VStack spacing='lg' pt='md' ps='md' minW='250px' align='stretch'>
        <RestaurantDetails
          restaurantId={order?.restaurant?.id}
          name={order?.restaurant?.name}
          subEntity={order?.restaurant?.subEntity?.name}
          phoneNumber={order?.restaurant?.user?.phoneNumber}
        />

        <SupplierDetails
          supplierId={order?.supplier?.id}
          name={order?.supplier?.name}
          supplierUserName={order?.supplier?.user?.name}
          phoneNumber={order?.supplier?.user?.phoneNumber}
        />

        <HStack spacing='lg'>
          <InfoRow
            title='Supplier Cutoff Time'
            body={order?.supplier?.cutOffTime ? dayjs(order?.supplier?.cutOffTime).format('hh:mm A') : '---'}
          />
          <InfoRow title='Is Public' body={order?.supplier?.isPublic ? 'Yes' : 'No'} />
        </HStack>
      </VStack>
    </HStack>
  );
};
