import { Badge } from '@elkaso-app/web-design';

export interface IProductCategoryCell {
  categoryName: string;
}

export const ProductCategoryCell = ({ categoryName }: IProductCategoryCell) => {
  return (
    <Badge px='md' py='sm' borderRadius='full' fontSize='xs' textTransform='initial'>
      {categoryName}
    </Badge>
  );
};
