import { getValidatedLimitParam, getValidatedPageParam, useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { TParam, usePageParams } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'ums/v1/zoho/tickets';
export const getTicketsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [
  typeof endpoint,
  {
    page: TParam;
    limit: TParam;
    sort: TParam;
    status: TParam;
    assignee: TParam;
  }
];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetTicketsApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const page = getValidatedPageParam();
  const limit = getValidatedLimitParam();
  const isLoggedIn = useIsLoggedIn();
  const { getPageParams } = usePageParams();
  const { status, assignee, sort } = getPageParams();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: { page, limit, sort, status, assignee },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([endpoint, { page, limit, sort, status, assignee }], queryFn, {
    enabled: isLoggedIn,
    ...options,
  });
}
