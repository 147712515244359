import { Box, Flex, Image, InfoIcon, Text, VStack } from '@elkaso-app/web-design';
import { UploadFilesIcon } from 'pages/categories/icons/upload-files';
import { useEffect, useState } from 'react';
import { DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';

interface IUploadCategoryImage {
  uploadImageData: any;
  uploadFileApi: any;
  isSuccess: boolean;
  fieldName: 'imageAE' | 'imageSA';
  image: string;
  error?: FieldError;
  setValue: (
    name: 'imageAE' | 'imageSA',
    value: string,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
}

interface TPreviewFile extends File {
  preview: string;
}

export const UploadCategoryImageV2 = ({
  uploadImageData,
  uploadFileApi,
  isSuccess,
  fieldName,
  image,
  error,
  setValue,
}: IUploadCategoryImage) => {
  const [file, setFile] = useState<TPreviewFile>();

  const dropZoneOptions: DropzoneOptions = {
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/*': ['.jpg', '.png', '.svg'],
    },
    validator: (file) => {
      if (file.size > 1000000) {
        return {
          code: ErrorCode.FileTooLarge,
          message: 'File is larger than 1mb',
        };
      }

      return null;
    },
    onDropAccepted: (acceptedFiles) => {
      const _file: File = acceptedFiles[0];

      Object.assign(_file, {
        preview: URL.createObjectURL(_file),
      });

      setFile(_file as TPreviewFile);

      const formData = new FormData();
      formData.append('file', _file);

      uploadFileApi({
        body: formData,
      });
    },
  };

  useEffect(() => {
    if (isSuccess && uploadImageData.url) {
      setValue(fieldName, uploadImageData?.url, { shouldValidate: true });
    }
  }, [isSuccess]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone(dropZoneOptions);

  // Make sure to revoke the data uri to avoid memory leaks, will run on unmount
  useEffect(() => {
    return () => {
      if (file && file.preview) URL.revokeObjectURL(file.preview);
    };
  }, []);

  return (
    <VStack spacing='lg'>
      <VStack spacing='sm' justify='center' _hover={{ cursor: 'pointer' }} {...getRootProps()}>
        <input {...getInputProps()} />

        {/* Drag & drop */}
        {!image && !file?.preview && <UploadFilesIcon />}

        {/* Current */}
        {image && !file?.preview && (
          <Image src={image} alt='Category Image' boxSize='146px' objectFit='contain' loading={'lazy'} />
        )}

        {/* Preview */}
        {!!file?.preview && (
          <Image src={file?.preview} alt='Preview Image' boxSize='146px' objectFit='contain' loading={'lazy'} />
        )}

        {/* Errors */}
        {(!!fileRejections.length || error) && (
          <Text color='red.500'>{fileRejections[0]?.errors[0]?.message || error?.message}</Text>
        )}
      </VStack>

      {fieldName == 'imageSA' && (
        <Flex justify='center'>
          <Box maxW='90%'>
            <InfoIcon me='xs' />
            <Text color='red.500' me='xs' display='inline'>
              Important Requirements:
            </Text>
            <Text color='gray.400' display='inline'>
              Image size should be less than 1mb and dimension should be square size
            </Text>
          </Box>
        </Flex>
      )}
    </VStack>
  );
};
