import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

import { useGetRestaurantPosIssuesListApi } from './use-get-restaurant-pos-issues-list-api';

const endpoint = 'pos/v2/foodics/failed/transaction';

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  id: number;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables, unknown>;

export function useRetryPosJobApi(options?: Options): ReturnType {
  const { refetch: reFetchPosIssues } = useGetRestaurantPosIssuesListApi({ enabled: false });

  const toast = useCustomToast();
  let fullEndpoint;

  const mutationFn = async ({ id }: TVariables) => {
    try {
      const { data } = await http.post(endpoint + `/${id}/retry`);

      reFetchPosIssues();

      toast({
        description: 'The job has been restarted successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
