import { HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { Can } from 'configs/can';
import { RemoveIcon } from '../icons/remove-icon';
import { DeleteWarehouseUserModal } from './delete-warehouse-user-modal';

export interface IUsersActionsCell {
  userData: Record<string, any>;
}

export const UsersActionsCell = ({ userData }: IUsersActionsCell) => {
  return (
    <HStack spacing='xs'>
      <Can I='update' a='User'>
        <DeleteWarehouseUserModal
          userData={userData}
          trigger={
            <IconButtonWithTooltip
              Icon={<RemoveIcon boxSize='20px' />}
              label='Unassign'
              aria-label='unassign'
              variant='ghost'
              size='sm'
              disabled={userData?.roles?.[0]?.slug === 'restaurant_admin'}
            />
          }
        />
      </Can>
    </HStack>
  );
};
