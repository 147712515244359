/**
 * Users Routes
 */
/* eslint-disable */
import { Layout, Section } from 'layout';
import { Redirect, Route, Switch } from 'react-router-dom';

// async components
import { AsyncUnitsFormComponent, AsyncUnitsListComponent } from 'components/AsyncComponent';
// import { ACTIONS, USER } from 'constants/Permissions';
// import { HasAccess } from 'components/HasAccess';

const Forms = ({ match }) => {
  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
          <Route path={`${match.url}/list`} component={AsyncUnitsListComponent} />
          <Route path={`${match.url}/add`} component={AsyncUnitsFormComponent} />
          <Route path={`${match.url}/edit/:id`} component={AsyncUnitsFormComponent} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default Forms;
