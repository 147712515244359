const newColor = 'rgba(255, 255, 255, 0.5)';
const black = 'rgba(0, 0, 0, 0.87)';
export default (theme) =>
  (theme = {
    ...theme,
    palette: {
      ...theme.palette,
      type: 'light',
    },
    overrides: {
      ...theme.overrides,
      MuiInputBase: {
        root: {
          color: newColor,
        },
      },
      MuiButtonBase: {
        root: {
          fill: black,
        },
      },
      MuiIconButton: {
        root: {
          color: black,
        },
      },
      MuiSelect: {
        icon: {
          color: black,
        },
      },
      MuiFormLabel: {
        ...theme.overrides.MuiFormLabel,
        root: {
          color: black,
        },
      },
      MuiPaper: {
        root: {
          color: black,
          backgroundColor: '#555',
        },
      },
      MUIDataTableHeadCell: {
        data: {
          ...theme.overrides.data,
          color: '#000',
        },
      },
      MuiTableCell: {
        ...theme.overrides.MuiTableCell,
        head: {
          ...theme.overrides.MuiTableCell.head,
          color: '#FAFAFA',
          backgroundColor: '#CFCFCF',
          border: 'solid #424242 1px',
        },
        body: {
          ...theme.overrides.MuiTableCell.body,
          border: 'solid #CFCFCF 1px',
          color: black,
        },
        root: {
          ...theme.overrides.MuiTableCell.root,
          border: 'solid #CFCFCF 1px',
          // borderBottom: 'solid #CFCFCF 1px',
        },
        footer: {
          border: 'solid #CFCFCF 1px',
        },
      },
      MuiTableRow: {
        head: {
          height: 30,
        },
      },
      MuiTablePagination: {
        input: {
          color: black,
        },
        actions: {
          color: black,
        },
      },
      toolbar: {
        borderBottom: '1px solid #CFCFCF',
      },
      MuiTypography: {
        colorTextSecondary: {
          color: black,
        },
        colorInherit: {
          color: black,
        },
      },
    },
  });
