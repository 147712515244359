import { GET_USERS, GET_USER_FAILED, SET_LOADING_STATUS, SET_USER } from 'redux/types';

const INIT_STATE = {
  usersList: [],
  user: {},
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS: {
      return { ...state, isLoading: action.payload };
    }
    case GET_USERS:
      return { ...state, usersList: action.payload, isLoading: false };
    case GET_USER_FAILED:
      return { ...state, usersList: [], isLoading: false };
    case SET_USER:
      return { ...state, user: action.payload };
    default:
      return { ...state };
  }
};
