import { Icon, IconProps } from '@elkaso-app/web-design';

export const DownArrowIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' {...props}>
      <g clipPath='url(#clip0_4050_1113)'>
        <path d='M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z' fill='#160C45' />
      </g>
      <defs>
        <clipPath id='clip0_4050_1113'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
