import { Button, ChakraSelect, FormControl, FormLabel, HStack, Stack, useDisclosure } from '@elkaso-app/web-design';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
};

type TInputs = {
  type: TOption | undefined;
};

const typeOptions: TOption[] = [
  {
    value: 'supplier',
    label: 'Supplier',
  },
  {
    value: 'product',
    label: 'Product',
  },
];

const defaultValues: TInputs = {
  type: undefined,
};

export const TypeFilterButton = () => {
  const [type, setIsType] = useState<string | undefined>(undefined);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  useEffect(() => {
    setIsType((params?.type as string) ?? '');
  }, [params.type]);

  const { control, handleSubmit, formState, reset, setValue } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  const updateValues = () => {
    setValue(
      'type',
      typeOptions.find((o) => {
        if (params?.type) {
          return o.value === params?.type;
        }

        return undefined;
      })
    );
  };

  useEffect(() => {
    updateValues();
  }, [params.type]);

  const onSubmit = (data: TInputs) => {
    params.type = data?.type?.value as string;

    setPageParams(params);
    onClose();
    reset(data);
  };

  const handleClose = () => {
    onClose();
    updateValues();
  };

  const handleDisableFilterButton = () => {
    const isDisabled = Object.keys(formState.dirtyFields).length ? false : true;
    return isDisabled;
  };

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={!!type}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='type'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Type</FormLabel>
                <ChakraSelect
                  id='type'
                  options={typeOptions}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                />
              </FormControl>
            )}
          />

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' disabled={handleDisableFilterButton()}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
