import { HStack, Skeleton, SkeletonText, VStack } from '@elkaso-app/web-design';

export const SkeletonMediaSection = () => {
  const mediaQuery = new Array(5).fill(null);

  return (
    <VStack align='stretch' spacing='lg'>
      <VStack align='stretch' spacing={'md'}>
        <Skeleton height='25px' width='80px' />

        <SkeletonText noOfLines={1} width='200px' />
      </VStack>

      <HStack spacing={'lg'}>
        {mediaQuery.map((i) => (
          <HStack key={i}>
            <Skeleton height='40px' width='40px' />

            <SkeletonText noOfLines={2} skeletonHeight='2' width={'50px'} />
          </HStack>
        ))}
      </HStack>

      <Skeleton height='40px' />

      <Skeleton height='150px' width='full' />
    </VStack>
  );
};
