import api from 'apis/config/api-service';
import http, { createAuthorizationHeader, getAccessToken } from 'apis/config/http-service';
import { usePageParams } from 'hooks/use-page-params';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { LocalStorageKeys } from 'utils/constants';

export function useGoogleLogin() {
  const { getPageParams } = usePageParams();
  const params = getPageParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const token = params?.access_token;
  if (!token || typeof token !== 'string') return;
  const isOTPEnabled = Number(params?.totpEnabled);

  // OTP Enabled
  if (isOTPEnabled) {
    history.replace(`/validation-code?access_token=${token}`);
  }

  // OTP NOT Enabled
  if (!isOTPEnabled) {
    // Set access-token in localStorage
    localStorage.setItem(LocalStorageKeys.access_token, token);

    // Update default configs for axios instance
    http.defaults.headers.common['Authorization'] = createAuthorizationHeader(getAccessToken());
    api.defaults.headers.common['Authorization'] = createAuthorizationHeader(getAccessToken());

    // TEMP: update redux with authUser data and store the old cms-user object in localStorage
    dispatch(getCurrentUser());

    // Redirect to home page
    history.replace('/');
  }
}
