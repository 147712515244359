import {
  Container,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  WhiteCard,
} from '@elkaso-app/web-design';
import { Layout, Section } from 'layout';
import { ProfileTab } from 'pages/profile/components/profile-tab';
import { SecurityTab } from 'pages/profile/components/security-tab';
import { useEffect } from 'react';
import { AiOutlineSecurityScan, AiOutlineUser } from 'react-icons/ai';
import { changeTitle, setCountryDisplay } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

const UserProfile = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeTitle('sidebar.userProfile'));
    dispatch(setCountryDisplay(true));
  }, []);

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <Container maxW='container.lg'>
          <WhiteCard p='lg'>
            <Tabs>
              <TabList>
                <Tab>
                  <Flex>
                    <Icon as={AiOutlineUser} me='md' />
                    <Text>Profile</Text>
                  </Flex>
                </Tab>
                <Tab>
                  <Flex>
                    <Icon as={AiOutlineSecurityScan} me='md' />
                    <Text>Security</Text>
                  </Flex>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <ProfileTab />
                </TabPanel>

                <TabPanel>
                  <SecurityTab />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </WhiteCard>
        </Container>
      </Section>
    </Layout>
  );
};

export default UserProfile;
