export const primary = {
  main: '#E53E51',
  light: '#4BDEDE',
  dark: '#E53E51',
  contrastText: '#fff',
};
export const secondary = {
  main: '#E32726',
  light: 'rgb(277,89,88)',
  dark: 'rgb(177,10,10)',
  contrastText: '#fff',
};
export const secondaryFontColor = '#6D6F7D';
export const iconHoverColor = '#E6E7EF';
export const cardBorderColor = '#E6E7EF';
// export const headerIconsColors = '#a7a7a7';
export const headerIconsColors = '#BCC0D1';
