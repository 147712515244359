import { getValidatedLimitParam, getValidatedPageParam, useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { TParam, usePageParams } from 'hooks/use-page-params';
import fileDownload from 'js-file-download';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'catalog/v1/restaurants';
export const downloadProductsFileEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;
type TQueryKey = [
  string,
  {
    page: TParam;
    limit: TParam;
    sort: TParam;
    name: TParam;
    sku: TParam;
    price: TParam;
    unitId: TParam;
    categoryId: TParam;
    isActive: TParam;
  }
];
export function useDownloadProductsFileApi(
  warehouseName: string,
  warehouseId: string,
  restaurantName: string,
  restaurantId: string,
  options?: Options
): ReturnType {
  const toast = useCustomToast();
  const fullEndpoint = `${endpoint}/${restaurantId}/warehouses/${warehouseId}/items/download-csv`;
  const page = getValidatedPageParam();
  const limit = getValidatedLimitParam();
  const { getPageParams } = usePageParams();
  const { name, sort, sku, price, unitId, categoryId, isActive } = getPageParams();

  const queryFn = async () => {
    try {
      await http
        .get(fullEndpoint, {
          params: { page, limit, sort, name, sku, price, unitId, categoryId, isActive },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          fileDownload(response.data, `${restaurantName} - ${warehouseName} item list.csv`);
        });
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper(
    [fullEndpoint, { page, limit, sort, name, sku, price, unitId, categoryId, isActive }],
    queryFn,
    {
      enabled: false,
      ...options,
    }
  );
}
