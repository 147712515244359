// TODO: Moaaz, remove the layout content height after refactoring the tables
import { Box, Flex } from '@elkaso-app/web-design';
import { HeaderContainer } from 'layout/components/header/header-container';
import { HeaderLeft } from 'layout/components/header/header-left';
import { HeaderRight } from 'layout/components/header/header-right';
import { Sidebar } from 'layout/components/sidebar/sidebar';
import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';
import { useAppSelector } from 'redux/hooks';

export const SidebarPageLayout = ({ sections }: LayoutProps) => {
  const isSidebarOpen = useAppSelector((state) => state.sidebar.isOpen);
  const sidebarWidth = 268;
  const headerHight = 68;

  return (
    <Flex as='main' minH={'100vh'} direction={'row'}>
      {/* Start Sider */}
      {isSidebarOpen && (
        <Box
          as='aside'
          minW={sidebarWidth}
          borderEnd={'2px solid'}
          borderColor={'#e6e7ef'}
          h={'100vh'}
          overflowY={'auto'}>
          <Section sections={sections} name='sider'>
            <Sidebar />
          </Section>
        </Box>
      )}
      {/* End Sider */}

      <Flex as='main' minH={'100vh'} direction={'column'} flexGrow={1}>
        <Box as='header'>
          {/* Start Header */}
          <Section sections={sections} name='header'>
            <HeaderContainer>
              <HeaderLeft />
              <HeaderRight />
            </HeaderContainer>
          </Section>
          {/* End Header */}
        </Box>

        {/* Start Content */}
        <Box
          as='section'
          bgColor='gray.50'
          p='md'
          h={`calc(100vh - ${headerHight}px)`}
          w={!isSidebarOpen ? '100vw' : `calc(100vw - ${sidebarWidth}px)`}
          overflowY={'auto'}>
          <Section sections={sections} name='content' />
        </Box>
        {/* End Content */}
      </Flex>
    </Flex>
  );
};
