import { BasePagination } from '@elkaso-app/web-design';
import { useGetRestaurantsBySupplierIdApi } from 'apis/supplier/use-get-restaurants-by-supplier-id-api';
import { BaseSortButton, BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameEmailPhoneFiltersButton } from 'components/base-table/filters/name-email-phone';
import { usePageParams } from 'hooks/use-page-params';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { BrandActionsCell, IBrandActionsCell } from './components/brand-action-cell';
import { BrandNameCell, IBrandNameCell } from './components/brand-name-cell';

interface IColumnsAccessorData {
  brand_name: {
    name: string;
    email: string;
    phone: string;
    image: string;
  };
  actions: any;
}

export const SupplierBrandsList = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { getPageParams } = usePageParams();
  const { supplierName } = getPageParams();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { data, isLoading, isFetching } = useGetRestaurantsBySupplierIdApi(supplierId);
  const restaurants = data?.data ?? [];

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='Brand Name'
            filterButtonComponent={<NameEmailPhoneFiltersButton />}
            sortButtonComponent={<BaseSortButton sortBy='name' />}
          />
        ),
        accessor: 'brand_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['brand_name']>) => (
          <CustomTableCell<IBrandNameCell> as={BrandNameCell} brand={value} />
        ),
      },
      {
        Header: <TableHeader name='action' />,
        accessor: 'action',
        Cell: ({ value }: CellProps<any>) => (
          <CustomTableCell<IBrandActionsCell> as={BrandActionsCell} action={value} />
        ),
      },
    ],
    []
  );

  const columnsAccessorData = React.useMemo(
    () =>
      restaurants?.map((restaurant: any) => {
        return {
          id: restaurant?.id,
          brand_name: {
            name: restaurant?.name,
            email: restaurant?.email,
            phone: restaurant?.phoneNumber,
            image: restaurant?.imageUrl,
          },
          action: restaurant,
        };
      }),
    [restaurants]
  );

  return (
    <BaseTableContainer border={0} isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title={`Suppliers > ${supplierName} > List of Brands `} />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>
        <BaseTableContainer.Header.Right>
          <ClearFilterButton whitelist={['supplierName']} />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper minHeight={'300px'}>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={data?.pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={data?.total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
