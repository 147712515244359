import { Button } from '@elkaso-app/web-design';
import { useUploadRestaurantSAMsFileApi } from 'apis/restaurants/restaurant-sams/use-upload-restaurant-sams-file-api';
import { ChangeEvent, useRef } from 'react';
import { useParams } from 'react-router-dom';

export const ImportSAMs = () => {
  const { restaurantId, supplierId } = useParams<{ restaurantId: string; supplierId: string }>();
  const { isLoading, mutate } = useUploadRestaurantSAMsFileApi(restaurantId, supplierId);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onImport = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file as File);

      const variables = {
        body: formData,
      };

      mutate(variables);
    }
  };

  return (
    <>
      <input type='file' style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
      <Button colorScheme='red' variant='outline' onClick={onImport} isLoading={isLoading}>
        Import
      </Button>
    </>
  );
};
