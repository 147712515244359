import {
  GET_PROMOTIONS,
  GET_PROMOTIONS_FAILED,
  SET_LOADING_STATUS,
  SET_PROMOTION,
  SET_PROMOTION_ERRORS,
  SET_SUPPLIER_LOADER,
} from 'redux/types';

const INIT_STATE = {
  promotionsList: [],
  promotion: {},
  promotionErrors: {},
  isLoading: false,
  supplierLoader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS: {
      return { ...state, isLoading: action.payload };
    }
    case GET_PROMOTIONS:
      return { ...state, promotionsList: action.payload, isLoading: false };
    case GET_PROMOTIONS_FAILED:
      return { ...state, promotionsList: [], isLoading: false };
    case SET_PROMOTION:
      return { ...state, promotion: action.payload };
    case SET_PROMOTION_ERRORS:
      return { ...state, promotionErrors: action.payload };
    case SET_SUPPLIER_LOADER:
      return { ...state, supplierLoader: action.payload };
    default:
      return { ...state };
  }
};
