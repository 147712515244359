import { Icon } from '@elkaso-app/web-design';
import { MdDragIndicator } from 'react-icons/md';

interface IDragHandle {
  isDragging: boolean;
}

export const DragHandle = ({ isDragging, ...rest }: IDragHandle) => {
  return <Icon as={MdDragIndicator} boxSize='20px' cursor={isDragging ? 'grabbing' : 'grab'} {...rest} />;
};
