import { Image, Text, VStack } from '@elkaso-app/web-design';
import { useUploadFileApi } from 'apis/config/use-upload-file-api';
import { useEffect, useState } from 'react';
import { DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { UploadFilesIcon } from '../../icons/upload-files';

interface IUploadCategoryImage {
  image: string;
  error?: FieldError;
  setValue: (
    name: 'image',
    value: string,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
}

interface TPreviewFile extends File {
  preview: string;
}

export const UploadCategoryImage = ({ image, error, setValue }: IUploadCategoryImage) => {
  const [file, setFile] = useState<TPreviewFile>();
  const { data: uploadImageData, mutate: uploadFileApi, isSuccess } = useUploadFileApi();

  const dropZoneOptions: DropzoneOptions = {
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/*': ['.jpg', '.png', '.svg'],
    },
    validator: (file) => {
      if (file.size > 1000000) {
        return {
          code: ErrorCode.FileTooLarge,
          message: 'File is larger than 1mb',
        };
      }

      return null;
    },
    onDropAccepted: (acceptedFiles) => {
      const _file: File = acceptedFiles[0];

      Object.assign(_file, {
        preview: URL.createObjectURL(_file),
      });

      setFile(_file as TPreviewFile);

      const formData = new FormData();
      formData.append('file', _file);

      uploadFileApi({
        body: formData,
      });
    },
  };

  useEffect(() => {
    if (isSuccess && uploadImageData.url) {
      setValue('image', uploadImageData?.url, { shouldValidate: true });
    }
  }, [isSuccess]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone(dropZoneOptions);

  // Make sure to revoke the data uri to avoid memory leaks, will run on unmount
  useEffect(() => {
    return () => {
      if (file && file.preview) URL.revokeObjectURL(file.preview);
    };
  }, []);

  return (
    <VStack spacing='lg'>
      <VStack mb='lg' width={'100%'} spacing='sm' justify='center' _hover={{ cursor: 'pointer' }} {...getRootProps()}>
        <input {...getInputProps()} />

        {/* Drag & drop */}
        {!image && !file?.preview && (
          <VStack borderRadius='md' p='md' width={'100%'} border='2px dashed #B1B1B1'>
            <UploadFilesIcon />
            <Text as='b' color=' #939393' fontSize={'lg'} align={'center'}>
              Drag & Drop or click <br /> here to upload
            </Text>
            <Text color={'#B1B1B1'}>Image size should be less than 5mb</Text>
          </VStack>
        )}

        {/* Current */}
        {image && !file?.preview && (
          <Image src={image} alt='Category Image' boxSize='146px' objectFit='contain' loading={'lazy'} />
        )}

        {/* Preview */}
        {!!file?.preview && (
          <Image src={file?.preview} alt='Preview Image' boxSize='146px' objectFit='contain' loading={'lazy'} />
        )}

        {/* Errors */}
        {(!!fileRejections.length || error) && (
          <Text color='red.500'>{fileRejections[0]?.errors[0]?.message || error?.message}</Text>
        )}
      </VStack>
    </VStack>
  );
};
