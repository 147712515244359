import api from 'apis/config/files-api';

export const uploadFileApi = (file, url, token, params, authorization) => {
  return api.post(`${url ? url : !token ? `files/upload/public` : `files?access_token=${token}`}`, file, {
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authorization && `Bearer ${authorization}`,
    },
  });
};
