import { HStack, Icon, StackProps, Text, Tooltip, VStack } from '@elkaso-app/web-design';
import { useClipboard } from 'hooks/use-clipboard';
import { FaWhatsapp } from 'react-icons/fa6';
import { IoCheckmarkSharp, IoCopyOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { onWhatsAppClick } from 'utils/on-whatsapp-click';

export interface UserInfoCellData extends StackProps {
  userId: number;
  name: string;
  email: string;
  phoneNumber: string;
}

export const UserInfoCell = ({ name, email, phoneNumber, ...rest }: UserInfoCellData) => {
  const { onCopy, hasCopied } = useClipboard();

  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/elkasoUsers/list?limit=45&page=1&phoneNumber=${encodeURIComponent(phoneNumber)}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>

      <Text whiteSpace='pre-line' fontSize='md' color='gray.500'>
        {email}
      </Text>

      <HStack spacing='xs' align='center' position='relative'>
        <Text fontSize='md' color='gray.500'>
          {phoneNumber}
        </Text>

        <Tooltip label='WhatsApp' aria-label='whatsapp' placement='bottom' hasArrow>
          <span>
            <Icon as={FaWhatsapp} fill='whatsapp.500' cursor='pointer' onClick={() => onWhatsAppClick(phoneNumber)} />
          </span>
        </Tooltip>

        <Tooltip label='Copy' aria-label='copy' placement='bottom' hasArrow>
          <span>
            {!hasCopied && (
              <Icon as={IoCopyOutline} fill='gray.500' cursor='pointer' onClick={() => onCopy(phoneNumber)} />
            )}

            {hasCopied && <Icon as={IoCheckmarkSharp} fill='gray.500' cursor='pointer' />}
          </span>
        </Tooltip>
      </HStack>
    </VStack>
  );
};
