import { BasePagination } from '@elkaso-app/web-design';
import { useGetAggSheetsHistoryApi } from 'apis/agg-sheets-relations/use-get-agg-sheets-history-api';
import {
  BaseTable,
  BaseTableContainer,
  BaseTableSpinner,
  BaseTableTitle,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import {
  RetryAggSheetButtonCellProps,
  RetryAggSheetCell,
} from 'pages/aggregated-sheets/components/retry-agg-sheet-cell';
import { ISupplierCell, SupplierCell } from 'pages/aggregated-sheets/components/supplier-cell';
import { AggSheetHistory } from 'pages/aggregated-sheets/types';
import React, { useEffect } from 'react';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

export const AggSheetsHistoryScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();

  const { isLoading, isFetching, data } = useGetAggSheetsHistoryApi();
  const { pageCount, total, data: aggSheetsHistoryData } = data ?? {};

  useEffect(() => {
    dispatch(changeTitle('Aggregated Sheets Relations'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Restaurant Name' />,
        accessor: 'restaurantName',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['restaurantName']>) => (
          <TableCell value={value} color='black' fontWeight='semibold' />
        ),
      },
      {
        Header: <TableHeader name='Supplier' />,
        accessor: 'supplier',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier']>) => (
          <CustomTableCell<ISupplierCell> as={SupplierCell} name={value.name} type={value.type} />
        ),
      },
      {
        Header: <TableHeader name='Buffer Time (minutes)' />,
        accessor: 'processingBufferTime',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['processingBufferTime']>) => (
          <TableCell value={value} color='black' />
        ),
      },
      {
        Header: <TableHeader name='Cut Off Time' />,
        accessor: 'cutOffTime',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['cutOffTime']>) => (
          <TableCell value={value} color='black' />
        ),
      },
      {
        Header: <TableHeader name='Report Date' />,
        accessor: 'reportDatetime',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['reportDatetime']>) => (
          <TableCell value={value} color='black' />
        ),
      },
      {
        Header: <TableHeader name='Status' />,
        accessor: 'status',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['status']>) => <TableCell value={value} color='black' />,
      },
      {
        Header: <TableHeader name='Action' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<RetryAggSheetButtonCellProps> as={RetryAggSheetCell} id={value.id} status={value.status} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    restaurantName: string;
    supplier: {
      name: string;
      type: string;
    };
    cutOffTime: string;
    reportDatetime: string;
    processingBufferTime: number;
    status: string;
    hasLastOrderRule: {
      id: number;
      value: boolean;
    };
    actions: AggSheetHistory;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      aggSheetsHistoryData?.map((item: AggSheetHistory): IColumnsAccessorData => {
        return {
          id: item?.id,
          restaurantName: item?.restaurant?.nameEn,
          supplier: {
            name: item?.supplier?.name,
            type: item?.supplier?.type,
          },
          cutOffTime: item?.cutOffTime,
          reportDatetime: item?.reportDatetime,
          processingBufferTime: item?.processingBufferTime,
          status: item?.status,
          hasLastOrderRule: {
            id: item?.id,
            value: item?.hasLastOrderRule,
          },
          actions: item,
        };
      }),
    [aggSheetsHistoryData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='Aggregated Sheets Relations' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
