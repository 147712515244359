/**
 * Rct Section Loader
 */
import { LinearProgress } from '@material-ui/core';

const RctSectionLoader = () => (
  <div className='d-flex justify-content-center loader-overlay'>
    <LinearProgress />
  </div>
);

export default RctSectionLoader;
