import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  useBoolean,
  useDisclosure,
} from '@elkaso-app/web-design';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type TInputs = {
  code: string;
};

const defaultValues: TInputs = {
  code: '',
};

export const CodeFilterButton = () => {
  const [isActive, setIsActive] = useBoolean(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  // Update active filter button
  useEffect(() => {
    if (params.code) setIsActive.on();
    else setIsActive.off();
  }, [params.code]);

  const { register, handleSubmit, formState, reset, setValue } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = () => {
    setValue('code', (params.code as string) ?? '');
  };

  useEffect(() => {
    updateValues();
  }, [params.code]);

  const onSubmit = (data: TInputs) => {
    params.code = data.code;

    setPageParams(params);
    onClose();
    reset(data);
  };

  const handleClose = () => {
    onClose();
    updateValues();
  };

  const handleDisableFilterButton = () => {
    const isDisabled = Object.keys(formState.dirtyFields).length ? false : true;
    return isDisabled;
  };

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={isActive}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <FormLabel>Code</FormLabel>
            <Input size='sm' {...register('code')} />
          </FormControl>

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' disabled={handleDisableFilterButton()}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
