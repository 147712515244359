import { Icon, IconProps } from '@elkaso-app/web-design';

export const UserIcon = (props: IconProps) => {
  return (
    <Icon width='39px' height='41px' viewBox='0 0 39 41' fill='#E53E51' {...props}>
      <path
        d='M14.75 15.2368C14.75 17.8484 16.8812 19.9737 19.5 19.9737C22.1188 19.9737 24.25 17.8484 24.25 15.2368C24.25 12.6253 22.1188 10.5 19.5 10.5C16.8812 10.5 14.75 12.6253 14.75 15.2368ZM27.9444 30.5H29V29.4474C29 25.3853 25.6845 22.0789 21.6111 22.0789H17.3889C13.3144 22.0789 10 25.3853 10 29.4474V30.5H27.9444Z'
        fill='inherit'
      />
    </Icon>
  );
};
