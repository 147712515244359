import {
  formatPrice,
  HStack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@elkaso-app/web-design';
import { useMemo } from 'react';

interface RestaurantTransferItem {
  name: string;
  price: number;
  quantity: number;
  unit: string;
  totalPrice: number;
  history: {
    status: string;
    price: number;
    quantity: number;
  }[];
}

interface IRestaurantTransferItemsTable {
  subtotal: number;
  vatPercent: number;
  vatValue: number;
  total: number;
  currencyCode: string;
  status: string;
  totalItems: number;
  items: RestaurantTransferItem[];
}

export const RestaurantTransferItemsTable = ({
  items,
  totalItems,
  subtotal,
  vatPercent,
  vatValue,
  total,
  currencyCode,
  status,
}: IRestaurantTransferItemsTable) => {
  const isReceived = useMemo(() => {
    return status === 'received';
  }, []);

  const isApproved = useMemo(() => {
    return status === 'approved';
  }, []);

  const getHistoricalQuantity = (item: RestaurantTransferItem) => {
    if (isReceived) {
      const receivedHistory = item?.history?.find((item) => item.status === 'received');
      return receivedHistory?.quantity || null;
    }

    if (isApproved) {
      const receivedHistory = item?.history?.find((item) => item.status === 'approved');
      return receivedHistory?.quantity || null;
    }

    return null;
  };

  return (
    <VStack
      spacing='lg'
      flex={1}
      alignItems='stretch'
      divider={<StackDivider borderColor='gray.200' />}
      overflowY='auto'>
      <TableContainer flex={1} overflowY='auto'>
        <Table size='sm' variant='simple'>
          <Thead>
            <Tr>
              <Th>Items</Th>
              <Th>Unit</Th>
              <Th>QTY</Th>
              <Th>{isReceived ? 'Received QTY' : 'Confirmed QTY'}</Th>
              <Th>Ind.Price</Th>
              <Th>Tot.Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items?.map((item, i) => (
              <Tr key={i}>
                <Td>{item?.name}</Td>
                <Td>{item?.unit}</Td>
                <Td>{item?.quantity}</Td>
                <Td>{getHistoricalQuantity(item)}</Td>
                <Td>{formatPrice(item?.price)}</Td>
                <Td>{formatPrice(item?.totalPrice)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <VStack spacing='md' alignItems='stretch' fontWeight={'bold'}>
        <HStack justify='space-between'>
          <Text>Total (Items)</Text>
          <Text>{totalItems}</Text>
        </HStack>
        <HStack justify='space-between'>
          <Text>Subtotal</Text>
          <Text>{formatPrice(subtotal, currencyCode)}</Text>
        </HStack>
        <HStack justify='space-between'>
          <Text>VAT ({vatPercent}%)</Text>
          <Text>{formatPrice(vatValue, currencyCode) || 0}</Text>
        </HStack>
        <HStack justify='space-between' color='red.500'>
          <Text>Total(Inc.VAT)</Text>
          <Text>{formatPrice(total, currencyCode)}</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};
