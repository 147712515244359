type StatusObj = {
  [key: string]: { slug: string; name: string };
};

export const statusObj: StatusObj = {
  approved: {
    slug: 'approved',
    name: 'Confirmed',
  },
  modified: {
    slug: 'modified',
    name: 'Modified & Confirmed',
  },
  canceled: {
    slug: 'canceled',
    name: 'Canceled',
  },
  created: {
    slug: 'created',
    name: 'New Transfer',
  },
  rejected: {
    slug: 'rejected',
    name: 'Rejected',
  },
  received: {
    slug: 'received',
    name: 'Received',
  },
};
