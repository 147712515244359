import {
  Button,
  HStack,
  IconButtonWithTooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { useDeleteSupplierItemsApi } from 'apis/restaurants/warehouse-supplier-items/use-delete-supplier-items-api';
import { CheckedItemType } from 'components/base-table';
import { RemoveIcon } from 'pages/restaurants/icons/remove-icon';

interface RemoveItemsButtonProps {
  checkedItems: CheckedItemType[];
}

export const RemoveItemsButton = ({ checkedItems }: RemoveItemsButtonProps) => {
  const { isLoading: isDeleteSupplierItemsLoading, mutate: deleteSupplierItems } = useDeleteSupplierItemsApi();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDeleteBulkItems = () => {
    const variables = {
      query: { ids: checkedItems?.filter((i) => i.isChecked)?.map((i) => i.id) },
    };

    deleteSupplierItems(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <IconButtonWithTooltip
        label='Remove'
        aria-label='remove-btn'
        isDisabled={!checkedItems.find((i) => i.isChecked)}
        onClick={onOpen}
        Icon={<RemoveIcon boxSize={5} />}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Items!</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text>Are you sure you want to delete these items?</Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                onClick={handleDeleteBulkItems}
                isLoading={isDeleteSupplierItemsLoading}>
                Delete
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
