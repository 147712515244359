import { red } from '@material-ui/core/colors';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { iconHoverColor, primary, secondary } from 'constants/theme/MainColors';

let theme = createTheme({
  palette: {
    primary,
    secondary,
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiTable: {
      root: {
        width: '100%',
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
        // background:'rgba(226, 226, 226, 0.3)'
      },
      input: {
        padding: '10px 14px',
      },
    },
    MuiSelect: {
      outlined: {
        border: '2px solid #E6E7EF !important',
        borderRadius: '4px !important',
      },
      selectMenu: {
        paddingRight: '35px !important',
      },
    },
    MuiDataTableHeadCell: {
      data: {
        fontSize: 14,
        textAlign: 'left',
        fontWeight: '600',
      },
      sortAction: {
        display: 'none',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 500,
        fontSize: '1rem',
        textAlign: 'left',
        // minWidth:150,
        minWidth: 50,
        maxWidth: 150,
        color: '#9699A9',
        letterSpacing: -0.5,
        paddingBottom: 15,
        paddingTop: 15,
      },
      body: {
        textAlign: 'left',
        textWrap: 'normal',
        wordWrap: 'break-word',
        color: 'rgba(66, 68, 77, 0.65)',
      },
      root: {
        padding: 0,
        textAlign: 'left',
        borderBottom: 'none',
      },
      stickyHeader: {
        backgroundColor: '#FFFFFF !important',
      },
    },
    MuiTableRow: {
      head: {
        height: 30,
      },
    },
    MuiTablePagination: {
      actions: {
        marginLeft: '0 !important',
      },
    },
    MuiChip: {
      root: {
        margin: '3px !important',
        height: '25px !important',
        borderRadius: '31px !important',
      },
    },

    MuiFormLabel: {
      asterisk: {
        color: red[500],
      },
    },
    MuiInput: {
      formControl: {
        minWidth: 120,
      },
    },
    MuiButton: {
      contained: {
        '&:hover': {
          boxShadow: '-8px 16px 16px rgba(0, 0, 0, 0.05) !important',
          background: 'red',
        },
      },
      root: {
        color: 'none',
        borderRadius: 8,
        textTransform: 'capitalize',
        '&:hover': {
          boxShadow: 'none !important',
        },
      },
      text: {
        fontWeight: 600,
      },
      containedPrimary: {
        boxShadow: 'none',
        color: '#FFFFFF !important',
      },
      containedSecondary: {
        boxShadow: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    Textarea: {
      root: {
        minHeight: 70,
      },
    },
    MuiButtonBase: {
      root: {
        fill: 'rgba(0, 0, 0, 0.54)',
      },
      '&:hover': {
        backgroundColor: 'red',
      },
      '&$focused': {
        backgroundColor: 'red',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'none',
        '&:hover': {
          backgroundColor: 'none',
        },
        '&$focused': {
          backgroundColor: 'none',
        },
      },
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0px 100px #FFF inset',
          ' -webkit-text-fill-color': '#000',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 28,
      },
    },
    MuiListItemText: {
      multiline: {
        textAlign: 'left',
      },
    },
    MuiDropzoneArea: {
      root: {
        borderColor: primary.main,
        borderRadius: '15px',
        minHeight: 0,
        padding: 50,
        margin: 15,
      },
      textContainer: {
        color: primary.main,
      },
      icon: {
        color: primary.main,
      },
      // active:{
      //   backgroundColor:'pink'
      // },
      // invalid:{
      //   backgroundColor: 'green'
      // }
    },
    MuiDropzoneSnackbar: {
      successAlert: {
        backgroundColor: '#1C1C1C',
        opacity: 0.95,
      },
      errorAlert: {
        backgroundColor: '#1C1C1C',
        opacity: 0.95,
      },
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: '12px',
        backgroundColor: '#1C1C1C',
        opacity: 0.95,
      },
      action: {
        display: 'none',
      },
      message: {
        svg: {
          color: '#11B719',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#313135',
      },
    },
    MuiAvatar: {
      root: {
        fontSize: 15,
      },
      colorDefault: {
        backgroundColor: primary.main,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 10,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08), 0px 8px 36px rgba(0, 0, 0, 0.12)',
      },
    },
    MuiIconButton: {
      root: {
        marginLeft: 3,
        marginRight: 3,
        color: '#a7a7a7',
        padding: 10,
        '&:hover': {
          boxShadow: 'none !important',
          backgroundColor: iconHoverColor,
          marginLeft: 3,
          marginRight: 3,
        },
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.16) !important',
        // padding: '15px 34px 15px 5px'
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 12,
      },
    },
    MuiTouchRipple: {
      //remove effect on click list item
      child: {
        backgroundColor: 'none !important',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: 'none !important',
      },
    },
    MuiInputBase: {
      input: {
        padding: 10,
      },
      multiline: {
        padding: '0 10px 0 10px',
      },
    },
    MuiInputLabel: {
      formControl: {
        padding: '0 10px 0 10px',
      },
    },
    MuiAutocomplete: {
      /* inputRoot:{
        paddingTop: '5px !important'
      },*/
      input: { padding: '10px !important' },
    },
    MuiDialogContent: {
      root: {
        textAlign: 'left',
      },
    },
  },
});

theme = responsiveFontSizes(theme);
export { theme };
