import { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

export const FoodUniverseScreen = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeTitle('Food Universe'));
  }, []);

  return <>Food Universe Screen</>;
};
