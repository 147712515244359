import {
  Button,
  ChakraSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAssignSupplierApi } from 'apis/restaurants/warehouse-suppliers/use-assign-supplier-api';
import { useGetWarehouseUnassignedSuppliersApi } from 'apis/restaurants/warehouse-suppliers/use-get-warehouse-unassigned-suppliers-api';
import { cloneElement, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

export interface IAddSupplierModal {
  trigger: JSX.Element;
}

type TOption = {
  value: string;
  label: string;
};

type FormValues = {
  supplierId?: TOption;
};

const defaultValues = {
  supplierId: undefined,
};

const schema = yup.object().shape({
  supplierId: yup
    .object({
      value: yup.string().required().required('Supplier is required'),
      label: yup.string().required(),
    })
    .nullable(),
});

export const AddSupplierModal = ({ trigger }: IAddSupplierModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { restaurantId, warehouseId } = useParams<{ restaurantId: string; warehouseId: string }>();
  const { isLoading, isFetching, refetch, data } = useGetWarehouseUnassignedSuppliersApi(restaurantId, warehouseId);
  const { data: suppliersData } = data ?? {};

  const { isLoading: isAssignSupplierLoading, mutate: assignSupplierApi } = useAssignSupplierApi(
    restaurantId,
    warehouseId
  );

  const suppliersOptions = useMemo<TOption[]>(() => {
    return suppliersData?.map((supplier: (typeof suppliersData)[0]) => {
      return {
        value: supplier?.id,
        label: supplier?.name,
      };
    });
  }, [suppliersData]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const variables = {
      supplierId: data?.supplierId?.value || '',
    };

    assignSupplierApi(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const onOpenModal = () => {
    refetch();
    onOpen();
  };

  return (
    <>
      {cloneElement(trigger, { onClick: onOpenModal })}

      <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={() => reset(defaultValues)} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='2xs'>
              <Text fontSize='2xl' fontWeight='bold'>
                Select supplier to be attached
              </Text>

              <Text color='gray.500' fontSize='sm' fontWeight='normal'>
                Note: Suppliers in the same country of the restaurant will be listed
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody color='blue.500'>
            <VStack as='form' id='editSupplierDetailsModal' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                control={control}
                name='supplierId'
                render={({ field: { value, onChange, onBlur, name } }) => (
                  <FormControl isInvalid={!!errors.supplierId} isRequired>
                    <FormLabel color='gray.500'>Supplier Name</FormLabel>
                    <ChakraSelect
                      isLoading={isLoading || isFetching}
                      variant='outline'
                      size='lg'
                      value={value}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      options={suppliersOptions}
                    />
                    <FormErrorMessage>{errors.supplierId?.value?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button onClick={onClose} variant='ghost' colorScheme='gray'>
                Cancel
              </Button>

              <Button
                form='editSupplierDetailsModal'
                type='submit'
                colorScheme='red'
                isLoading={isAssignSupplierLoading}>
                Attach
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
