/**
 * App Redux Action Types
 */
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_ROOT_OBJECT = 'SET_ROOT_OBJECT';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_COUNTRY_DISPLAY = 'SET_COUNTRY_DISPLAY';
export const SET_REDIRECTED_URL = 'SET_REDIRECTED_URL';
export const SET_IMAGE_LOADING = 'SET_IMAGE_LOADING';

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const RE_BUILD_PERMISSIONS = 'RE_BUILD_PERMISSIONS';

//refresh
export const FORCE_UPDATE = 'FORCE_UPDATE';
export const CHANGE_TITLE = 'CHANGE_TITLE';

//dialog
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const LOGIN_DIALOG = 'LOGIN_DIALOG';
export const CATALOG_DIALOG = 'CATALOG_DIALOG';
export const MERGE_DIALOG = 'MERGE_DIALOG';
export const MAP_DIALOG = 'MAP_DIALOG';
export const IMAGE_DIALOG = 'IMAGE_DIALOG';
export const INFO_DIALOG = 'INFO_DIALOG';
export const ATTACH_SUPPLIER_DIALOG = 'ATTACH_SUPPLIER_DIALOG';
export const COMMENT_DIALOG = 'COMMENT_DIALOG';
export const IMAGE_CROPPER_DIALOG = 'IMAGE_CROPPER_DIALOG';
export const ASSIGN_RESTAURANT = 'ASSIGN_RESTAURANT';
export const SELECT_ADMIN = 'SELECT_ADMIN';
export const SELECT_ASSIGN_METHOD = 'SELECT_ASSIGN_METHOD';

//users
export const GET_USERS = 'GET_USERS';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_USER = 'SET_USER';

//roles
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLE_FAILED = 'GET_ROLE_FAILED';
export const SET_ROLE = 'SET_ROLE';

//pagination and filters
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_PAGES_COUNT = 'SET_PAGES_COUNT';
export const SET_ANOTHER_TOTAL_COUNT = 'SET_ANOTHER_TOTAL_COUNT';
export const SET_ANOTHER_PAGES_COUNT = 'SET_ANOTHER_PAGES_COUNT';
export const SET_ANOTHER_PAGE_NUMBER = 'SET_ANOTHER_PAGE_NUMBER';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';

//user profile
export const SET_ANOTHER_LOADING = 'SET_ANOTHER_LOADING';
export const UPDATE_TOUR = 'UPDATE_TOUR';

export const GET_OTP_REQUESTS = 'GET_OTP_REQUESTS';
export const GET_OTP_REQUESTS_FAILED = 'GET_OTP_REQUESTS_FAILED';
export const GET_OTP = 'GET_OTP';
export const GET_OTP_FAILED = 'GET_OTP_FAILED';

// restaurants
export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const GET_RESTAURANTS_FAILED = 'GET_RESTAURANTS_FAILED';
export const SET_RESTAURANT = 'SET_RESTAURANT';
export const SET_RESTAURANT_FAILED = 'SET_RESTAURANT_FAILED';
export const GET_RESTAURANT_ADMIN_MANAGERS = 'GET_RESTAURANT_ADMIN_MANAGERS';
export const GET_RESTAURANT_ADMIN_MANAGERS_FAILED = 'GET_RESTAURANT_ADMIN_MANAGERS_FAILED';
export const GET_RESTAURANT_ORDERS_LIST = 'GET_RESTAURANT_ORDERS_LIST';
export const GET_RESTAURANT_ORDERS_LIST_FAILED = 'GET_RESTAURANT_ORDERS_LIST_FAILED';
export const GET_RESTAURANT_SUPPLIER_EMAILS = 'GET_RESTAURANT_SUPPLIER_EMAILS';
export const GET_BRANCHES_SUPPLIERS = 'GET_BRANCHES_SUPPLIERS';
export const GET_BRANCHES_SUPPLIERS_FAILED = 'GET_BRANCHES_SUPPLIERS_FAILED';

// suppliers
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIERS_FAILED = 'GET_SUPPLIERS_FAILED';
export const SET_SUPPLIER = 'SET_SUPPLIER';
export const SET_SUPPLIER_FAILED = 'SET_SUPPLIER_FAILED';
export const GET_SUPPLIER_KPI = 'GET_SUPPLIER_KPI';
export const SET_SUPPLIER_LOADER = 'SET_SUPPLIER_LOADER';
export const SET_CATEGORIES_LOADER = 'SET_CATEGORIES_LOADER';
export const GET_SUPPLIER_ADMIN_USERS = 'GET_SUPPLIER_ADMIN_USERS';
export const GET_SUPPLIER_ADMIN_USERS_FAILED = 'GET_SUPPLIER_ADMIN_USERS_FAILED';
export const GET_SUPPLIER_MANAGER_USERS = 'GET_SUPPLIER_MANAGER_USERS';
export const GET_SUPPLIER_MANAGER_USERS_FAILED = 'GET_SUPPLIER_MANAGER_USERS_FAILED';
export const MERGE_SUPPLIER_USER_DIALOG = 'MERGE_SUPPLIER_USER_DIALOG';

// supplier users
export const GET_SUPPLIER_USERS = 'GET_SUPPLIER_USERS';
export const GET_SUPPLIER_USERS_FAILED = 'GET_SUPPLIER_USERS_FAILED';

// branches
export const SET_BRANCH = 'SET_BRANCH';
export const SET_BRANCH_FAILED = 'SET_BRANCH_FAILED';

// restaurant users
export const GET_RESTAURANT_USERS = 'GET_BRANCH_USERS';
export const GET_RESTAURANT_USERS_FAILED = 'GET_BRANCH_USERS_FAILED';

// restaurant suppliers
export const GET_RESTAURANTS_SUPPLIERS = 'GET_RESTAURANTS_SUPPLIERS';
export const GET_RESTAURANTS_KPI = 'GET_RESTAURANTS_KPI';

// elkaso users
export const GET_ELKASO_USERS = 'GET_ELKASO_USERS';
export const GET_ELKASO_USERS_FAILED = 'GET_ELKASO_USERS_FAILED';

// invitation requests
export const GET_INVITATION_REQUESTS = 'GET_INVITATION_REQUESTS';
export const GET_INVITATION_REQUESTS_FAILED = 'GET_INVITATION_REQUESTS_FAILED';
export const GET_INVITATION = 'GET_INVITATION';
export const GET_INVITATION_FAILED = 'GET_INVITATION_FAILED';

// Units
export const GET_UNITS = 'GET_UNITS';
export const GET_UNITS_FAILED = 'GET_UNITS_FAILED';
export const SET_UNIT = 'SET_UNIT';

// Orders
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';

// Catalog
export const GET_CATALOG = 'GET_CATALOG';
export const GET_CATALOG_FAILED = 'GET_CATALOG_FAILED';
export const SET_LOADING_STATUS2 = 'SET_LOADING_STATUS2';
export const SET_LOADING_STATUS3 = 'SET_LOADING_STATUS3';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

//Promotions
export const GET_PROMOTIONS = 'GET_PROMOTIONS';
export const GET_PROMOTIONS_FAILED = 'GET_PROMOTIONS_FAILED';
export const SET_PROMOTION = 'SET_PROMOTION';
export const SET_PROMOTION_FAILED = 'SET_PROMOTION_FAILED';
export const SET_PROMOTION_ERRORS = 'SET_PROMOTION_ERRORS';

//Categories
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_CATEGORY_FAILED = 'SET_CATEGORY_FAILED';
export const SET_CATEGORY_ERRORS = 'SET_CATEGORY_ERRORS';

//Restaurant Merge
export const GET_RESTAURANT_BRANCHES = 'GET_RESTAURANT_BRANCHES';
export const GET_RESTAURANT_BRANCHES_FAILED = 'GET_RESTAURANT_BRANCHES_FAILED';
export const MERGE_RESTAURANT_DIALOG = 'MERGE_RESTAURANT_DIALOG';
export const GET_MERGING_RESTAURANT_BRANCHES = 'GET_MERGING_RESTAURANT_BRANCHES';
export const GET_MERGING_RESTAURANT_BRANCHES_FAILED = 'GET_MERGING_RESTAURANT_BRANCHES_FAILED';
export const GET_MERGED_DATA_FAILED = 'GET_MERGED_DATA_FAILED';
export const GET_MERGED_DATA = 'GET_MERGED_DATA';
export const SET_MERGE_ERRORS = 'SET_MERGE_ERRORS';
export const IS_SUCCESS_ASSIGN_ADMIN = 'IS_SUCCESS_ASSIGN_ADMIN';

//OTP
export const GET_OTP_LIST = 'GET_OTP_LIST';
export const GET_OTP_LIST_FAILED = 'GET_OTP_LIST_FAILED';
export const SET_OTP = 'SET_OTP';

//Whats Numbers
export const GET_WHATS_NUMBERS = 'GET_WHATS_NUMBERS';
export const GET_WHATS_NUMBERS_FAILED = 'GET_WHATS_NUMBERS_FAILED';

//Countries
export const GET_COUNTRIES = 'GET_COUNTRIES';
