import { Center, Flex, Text } from '@elkaso-app/web-design';
import { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';

export const GoogleLoginButton = () => {
  const [_window, setWindow] = useState<Window & typeof globalThis>();

  useEffect(() => {
    setWindow(window);
  }, []);

  const handleClick = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/ums/v1/auth/google?redirect=${_window?.location?.origin}`;
    const target: '_self' | '_blank' | '_parent' | '_top' = '_self';
    const features = '';

    _window?.open(url, target, features);
  };

  return (
    <Flex
      onClick={handleClick}
      align='center'
      justify='flex-start'
      cursor='pointer'
      p='2px'
      bgColor='white'
      borderRadius='lg'
      shadow='xl'>
      <Center bgColor='white' p='sm' borderRadius='sm'>
        <FcGoogle size='30px' />
      </Center>

      <Text px='md' color='gray.500' fontWeight='500' fontSize='lg'>
        Continue with Google
      </Text>
    </Flex>
  );
};
