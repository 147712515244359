import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rtlLayoutAction } from 'redux/actions';

export const useSetLangHtmlTags = () => {
  const { rtlLayout } = useSelector((state: any) => state.settings);
  const [_document, setDocument] = useState<Document | null>(null);
  const htmlElement = _document?.querySelector('html');
  const dispatch = useDispatch();

  // Set default value for [lang, dir] attribute in html tag
  const setHTMLTags = () => {
    if (rtlLayout) {
      htmlElement?.setAttribute('lang', 'ar');
      htmlElement?.setAttribute('dir', 'rtl');
    }

    if (!rtlLayout) {
      htmlElement?.setAttribute('lang', 'en');
      htmlElement?.setAttribute('dir', 'ltr');
    }

    dispatch(rtlLayoutAction(rtlLayout));
  };

  // Get document object & set default language
  useEffect(() => {
    setDocument(document);
    setHTMLTags();
  }, [_document]);
};
