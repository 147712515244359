/**
 * Language Provider Helper Component
 * Used to Display Localised Strings
 */
import { FormattedMessage, injectIntl } from 'react-intl';

// Injected message
const InjectMassage = ({ id = '.', ...rest }) => {
  return <FormattedMessage id={id} {...rest} />;
};

export default injectIntl(InjectMassage, {
  withRef: false,
  pure: true,
});
