import { Text } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';

export interface ISupplierCell {
  id: number;
  name: string;
}

export const SupplierCell = ({ id, name }: ISupplierCell) => {
  return (
    <Link to={`/app/suppliers/products/${id}`}>
      <Text color={'red.500'} fontWeight='500' _hover={{ color: 'red.500' }} _focus={{ color: 'red.500' }}>
        {name}
      </Text>
    </Link>
  );
};
