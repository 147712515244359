/*
 * [1] - Check if params have sort param
 * [2] - Validate sort param
 * [3] - Store sort param status in useState
 * [4] - Activate/Deactivate sort button ASC/DESC according to the param postfix
 * [5] - When click the sort button change the sort param
 */

import { Icon, IconButtonWithTooltip, useBoolean } from '@elkaso-app/web-design';
import { SortIcon } from 'components/base-table/icons/sort-icon';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect, useState } from 'react';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';

interface IGetSortIcon {
  sortStatus: 'ASC' | 'DESC' | null;
}

const GetSortIcon = ({ sortStatus }: IGetSortIcon) => {
  switch (sortStatus) {
    case 'ASC':
      return <Icon as={FaSortAlphaDown} boxSize='18px' color='red.500' />;

    case 'DESC':
      return <Icon as={FaSortAlphaUp} boxSize='18px' color='red.500' />;

    default:
      return <SortIcon />;
  }
};

interface ISortButton {
  sortBy: string;
}

export const BaseSortButton = ({ sortBy }: ISortButton) => {
  const [isLoading, setIsLoading] = useBoolean(true);
  const [sortStatus, setSortStatus] = useState<IGetSortIcon['sortStatus']>(null);
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const validateSortParam = (sortParam: string) => {
    if (!sortParam) {
      return setSortStatus(null);
    }

    if (typeof sortParam !== 'string') {
      console.warn('Sort param is invalid, It should be string.');
      return setSortStatus(null);
    }

    if (!sortParam.includes(sortBy)) {
      return setSortStatus(null);
    }

    if (sortParam.includes('.asc')) {
      return setSortStatus('ASC');
    }

    if (sortParam.includes('.desc')) {
      return setSortStatus('DESC');
    }

    console.warn('Sort param is invalid, It should be postfixed with one of these ".asc" or ".desc"');
    return setSortStatus(null);
  };

  useEffect(() => {
    setIsLoading.on();
    validateSortParam(params?.sort as string);
    setIsLoading.off();
  }, [params?.sort]);

  const handleSortByButton = () => {
    if (sortStatus === null) params.sort = sortBy + '.asc';
    if (sortStatus === 'ASC') params.sort = sortBy + '.desc';
    if (sortStatus === 'DESC') params.sort = '';
    setPageParams(params);
  };

  return (
    <IconButtonWithTooltip
      label='Sort'
      aria-label='sort-btn'
      display='flex'
      alignItems='center'
      justifyContent='center'
      Icon={<GetSortIcon sortStatus={sortStatus} />}
      variant='unstyled'
      size='xs'
      onClick={handleSortByButton}
      isLoading={isLoading}
    />
  );
};
