import { Icon, IconProps } from '@elkaso-app/web-design';

export const SortIcon = (props: IconProps) => {
  return (
    <Icon width='14px' height='14px' viewBox='0 0 16 16' fill='none' stroke='#757575' {...props}>
      <path
        d='M9.41176 15L14 10.3591M5.58824 1.03867V14.9613V1.03867ZM1 5.64088L5.58824 1L1 5.64088ZM9.41176 15V1.07735V15Z'
        stroke='inherit'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
