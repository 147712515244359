import { Icon, IconProps } from '@elkaso-app/web-design';

export const FileIcon = (props: IconProps) => {
  return (
    <Icon width='25px' height='25px' viewBox='0 0 25 25' fill='#E03E52' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.3846 3.5H3.61538C2.72323 3.5 2 4.03726 2 4.7V20.3C2 20.9627 2.72323 21.5 3.61538 21.5H17C17.8922 21.5 23 17.1627 23 16.5L23 4.7C23 4.03726 22.2768 3.5 21.3846 3.5ZM6.5 10.25C6.5 9.83579 6.83579 9.5 7.25 9.5H17.75C18.1642 9.5 18.5 9.83579 18.5 10.25C18.5 10.6642 18.1642 11 17.75 11H7.25C6.83579 11 6.5 10.6642 6.5 10.25ZM7.25 14C6.83579 14 6.5 14.3358 6.5 14.75C6.5 15.1642 6.83579 15.5 7.25 15.5H11.75C12.1642 15.5 12.5 15.1642 12.5 14.75C12.5 14.3358 12.1642 14 11.75 14H7.25Z'
        fill='#565656'
      />
    </Icon>
  );
};
