import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import IntlMessages from 'utils/intl-messages';

const PageTitleBar = (props) => {
  const { push, goBack } = useHistory();

  const breadcrumbHandler = (link) => {
    link === 'goBack' ? goBack() : push(link);
  };

  const { title, location } = props;
  const currentPath = location.pathname;
  let path = currentPath.substr(5);
  let res = path.split('/');

  let isProfile = res.includes('profile');
  let isTicket = res.includes('ticket');
  let isList = res.includes('list');
  let isAdd = res.includes('add');
  let isEdit = res.includes('edit');
  let isApprove = res.includes('approve');
  let isUsers = res.includes('users');
  let isBranch = res.includes('branch');
  let isEmployees = res.includes('employees') && !res.includes('adminEmployees');
  let restaurantSuppliers = res.includes('restaurants') && res.includes('branch') && res.includes('suppliers');
  let restaurantWarehouse = res.includes('restaurants') && res.includes('warehouse');
  let restaurantWarehouseUsers = res.includes('restaurants') && res.includes('warehouse') && res.includes('users');
  let restaurantWarehouseProducts =
    res.includes('restaurants') && res.includes('warehouse') && res.includes('products');
  let restaurantWarehouseSuppliers =
    res.includes('restaurants') && res.includes('warehouse') && res.includes('suppliers');
  let isProducts = res.includes('suppliers') && res.includes('products');
  let isBranches = res.includes('branch');
  let isBranchUsers = res.includes('branch') && res.includes('users') && res.length > 3;
  let isResBranches = res.includes('branches');
  let isResOrdersList = res.includes('orders-list');
  let isKpi = res.includes('kpi');
  let isMerge = res.includes('merge');
  let isOrderDetails = res.includes('details');
  let isRestaurantDetails = res.includes('restaurants') && res.includes('details');
  let isUsersEdit = isUsers && isEdit && res.length > 3;
  let isEmployeesEdit = isEmployees && isEdit && res.length > 3;
  let isEmployeesAdd = isEmployees && isAdd && res.length >= 3;
  let isBranchEdit = isBranches && isEdit && res.length > 3;

  let breadcrumbArray = isProfile
    ? [{ title: res[0] }]
    : isBranchEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'branches', link: 'goBack' }, { title: res[2] }]
    : isUsersEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'user', link: 'goBack' }, { title: res[2] }]
    : isBranchUsers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'branches', link: 'goBack' }, { title: res[2] }]
    : isEmployeesEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'employee', link: 'goBack' }, { title: res[2] }]
    : isEmployeesAdd
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'employee', link: 'goBack' }, { title: res[2] }]
    : restaurantSuppliers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouseUsers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouseProducts
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouseSuppliers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1], link: 'goBack' }, { title: res[2] }]
    : restaurantWarehouse
    ? [
        { title: res[0], link: `/app/${res[0]}` },
        { title: res[1], link: 'goBack' },
      ]
    : isList
    ? [{ title: res[0] }]
    : isAdd
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isEdit
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isApprove
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isBranch
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isResBranches
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isResOrdersList
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isMerge
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isUsers
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isEmployees
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isProducts
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isKpi
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : isRestaurantDetails
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: `${res[0]}.${res[1]}` }]
    : isTicket
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: 'ticket-' + res[1] }]
    : isOrderDetails
    ? [{ title: res[0], link: `/app/${res[0]}` }, { title: res[1] }]
    : [];

  return (
    <div className={`breadcrumbTitle d-flex justify-content-between align-items-center`}>
      {title ? (
        <div className='page-title-wrap'>
          {breadcrumbArray.length > 1 ? (
            <Breadcrumbs separator='/' aria-label='breadcrumb'>
              {breadcrumbArray.map((item, index) => {
                return index !== breadcrumbArray.length - 1 ? (
                  <Link onClick={() => breadcrumbHandler(item.link)} key={index}>
                    <Typography className='secondItem'>
                      <IntlMessages id={`breadcrumb.${item.title}`} />
                    </Typography>
                  </Link>
                ) : (
                  <Typography className='firstItem' key={index}>
                    <IntlMessages id={`${item.title}.breadcrumb`} />
                  </Typography>
                );
              })}
            </Breadcrumbs>
          ) : (
            <Typography color='primary' className='firstItem'>
              {<IntlMessages id={title} />}
            </Typography>
          )}
        </div>
      ) : (
        <div className='page-title-wrap'>
          <h2 className=''>
            <IntlMessages id={title} />
          </h2>
        </div>
      )}
    </div>
  );
};

export default PageTitleBar;
