import { Text, TextProps } from '@elkaso-app/web-design';
import IntlMessages from 'utils/intl-messages';

interface IBaseTableTitle extends TextProps {
  title: string;
}

export const BaseTableTitle = ({ title, ...rest }: IBaseTableTitle) => {
  return (
    <Text fontWeight='bold' fontSize='xl' {...rest}>
      <IntlMessages id={title} />
    </Text>
  );
};
