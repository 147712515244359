import { Text } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';

export interface IOrderCodeCell {
  code: string;
}

export const OrderCodeCell = ({ code }: IOrderCodeCell) => {
  return (
    <Link to={`/app/orders/details/${code}`}>
      <Text color={'red.500'} fontWeight='500' _hover={{ color: 'red.500' }} _focus={{ color: 'red.500' }}>
        #{code}
      </Text>
    </Link>
  );
};
