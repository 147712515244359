import { GET_CATALOG, GET_CATALOG_FAILED, SET_LOADING_STATUS3 } from 'redux/types';

const INIT_STATE = {
  catalogList: [],
  isLoading3: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS3:
      return { ...state, isLoading3: action.payload };
    case GET_CATALOG:
      return { ...state, catalogList: action.payload, isLoading3: false };
    case GET_CATALOG_FAILED:
      return { ...state, catalogList: [], isLoading3: false };
    default:
      return { ...state };
  }
};
