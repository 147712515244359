import { Badge } from '@elkaso-app/web-design';
import { statusObj } from 'pages/catalog/utils/product-status';

interface IStatusBadgeStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBadgeStyleProps: IStatusBadgeStyleProps = {
  suggested: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  active: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  inactive: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
  merged: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
  restaurant_catalog: {
    color: '#110000',
    bgColor: 'rgba(17, 0, 0, 0.05)',
  },
};

export interface IStatusBadgeCell {
  status: string;
}

export const StatusBadgeCell = ({ status }: IStatusBadgeCell) => {
  return (
    <>
      <Badge
        color={statusBadgeStyleProps[status]?.color}
        bgColor={statusBadgeStyleProps[status]?.bgColor}
        px='md'
        py='sm'
        borderRadius='full'
        fontSize='xs'
        textTransform={'capitalize'}
        _hover={{ shadow: 'sm' }}>
        {statusObj?.[status]?.name}
      </Badge>
    </>
  );
};
