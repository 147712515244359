import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGetSupplierItemsApi } from './use-get-supplier-items-api';

const endpoint = 'catalog/v1/restaurants';
export const uploadItemsCSVFilesEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: FormData;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

function constructEndpoint(restaurantId: string, supplierId: string) {
  return `${endpoint}/${restaurantId}/suppliers/${supplierId}/products/upload`;
}

export function useUploadSupplierItemsCSVFileApi(options?: Options): ReturnType {
  const toast = useCustomToast();

  const { restaurantId, supplierId } = useParams<{
    restaurantId: string;
    supplierId: string;
  }>();

  const { refetch: refetchSupplierItems } = useGetSupplierItemsApi({ enabled: false });

  const fullEndpoint = constructEndpoint(restaurantId, supplierId);

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(fullEndpoint, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (data?.status) {
        toast({
          description: 'the file is waiting in queue, will be uploaded shortly.',
          status: 'error',
        });
      }

      if (data?.aborted) {
        toast({
          description: data?.unsuccessful?.errors[0]?.value?.message ?? 'unexpected error',
          status: 'error',
        });
      }

      if (!data?.aborted && !data?.status) {
        toast({
          description: 'CSV file has been uploaded successfully.',
          status: 'success',
        });
      }

      // Refetch supplier items
      refetchSupplierItems();

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: [endpoint], ...options });
}
