import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@elkaso-app/web-design';
import { useResendEmailApi } from 'apis/orders/use-resend-email-api';

interface IResendEmailModal {
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
}

export const ResendEmailModal = ({ orderId, isOpen, onClose }: IResendEmailModal) => {
  const { isLoading, mutate: resendEmailApi } = useResendEmailApi();

  const onResendEmail = () => {
    const variables = {
      orderId: orderId,
    };

    resendEmailApi(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='sm'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resend Email</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize='md' color='gray.500'>
            Are you sure you want to resend the email to supplier?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant='outline' colorScheme='red' me='sm' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='solid' colorScheme='red' onClick={onResendEmail} isLoading={isLoading}>
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
