type StatusObj = {
  [key: string]: { slug: string; name: string };
};

export const statusObj: StatusObj = {
  suggested: {
    slug: 'suggested',
    name: 'Public-Active',
  },
  active: {
    slug: 'active',
    name: 'Public-Active',
  },
  inactive: {
    slug: 'inactive',
    name: 'Public-Inactive',
  },
  merged: {
    slug: 'merged',
    name: 'Public-Inactive',
  },
  restaurant_catalog: {
    slug: 'restaurant_catalog',
    name: 'Private',
  },
};
