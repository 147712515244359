import { useCustomToast } from '@elkaso-app/web-design';
import api from 'apis/config/api-service';
import http, { createAuthorizationHeader, getAccessToken } from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { usePageParams } from 'hooks/use-page-params';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { LocalStorageKeys } from 'utils/constants';

const endpoint = 'ums/v1/auth/totp-validate';
export const send2FAValidationEndpointIdentifier = endpoint;

interface BodyType {
  token: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function use2FASendValidationApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { getPageParams } = usePageParams();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const params = getPageParams();
      const token = params?.access_token;
      if (!token || typeof token !== 'string') return;

      const { data } = await axios.post(endpoint, body, {
        baseURL: process.env.REACT_APP_BASE_URL + '/',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      // Set access-token in localStorage
      localStorage.setItem(LocalStorageKeys.access_token, data?.token);

      // Update default configs for axios instance
      http.defaults.headers.common['Authorization'] = createAuthorizationHeader(getAccessToken());
      api.defaults.headers.common['Authorization'] = createAuthorizationHeader(getAccessToken());

      // TEMP: update redux with authUser data and store the old cms-user object in localStorage
      dispatch(getCurrentUser());

      // Redirect to home page
      history.replace('/');

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
