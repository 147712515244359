import { Icon, IconProps } from '@elkaso-app/web-design';

export const OpenedSectionIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='none' {...props}>
      <g clipPath='url(#clip0_762_18001)'>
        <path
          d='M10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z'
          fill='#565656'
        />
      </g>
      <defs>
        <clipPath id='clip0_762_18001'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
