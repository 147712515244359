import { Box, Heading, Text } from '@elkaso-app/web-design';
import { useGoogleLogin } from 'apis/auth/use-google-login';
import { Layout, Section } from 'layout';
import { GoogleLoginButton } from 'pages/login/components/google-login-button';

const LoginPage = () => {
  useGoogleLogin();

  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <Box w='100%' maxW='500px'>
          <Heading as='h3' mb='sm'>
            Login
          </Heading>

          <Text fontSize='md' color='gray.500' mb='lg'>
            Please ask your line manager for invitation
          </Text>

          <GoogleLoginButton />
        </Box>
      </Section>
    </Layout>
  );
};

export default LoginPage;
