import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetWarehouseSuppliersApi } from './use-get-warehouse-suppliers-api';

const endpoint = 'ums/v1/restaurants/warehouses';
export const unassignSupplierEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  supplierId: string;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

function constructEndpoint(restaurantId: string, warehouseId: string, supplierId: string): string {
  return `${endpoint}/${restaurantId}/${warehouseId}/suppliers/${supplierId}/unassign`;
}

export function useUnassignSupplierApi(restaurantId: string, warehouseId: string, options?: Options): ReturnType {
  const { refetch: refetchWarehouseSuppliers } = useGetWarehouseSuppliersApi(restaurantId, warehouseId, {
    enabled: false,
  });

  const toast = useCustomToast();
  let fullEndpoint = '';

  const mutationFn = async ({ supplierId }: TVariables) => {
    fullEndpoint = constructEndpoint(restaurantId, warehouseId, supplierId);

    try {
      const { data } = await http.delete(fullEndpoint);

      // Refetch warehouse suppliers
      refetchWarehouseSuppliers();

      toast({
        description: 'Supplier has unassigned successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
