import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Textarea,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { FormSection } from 'pages/promotions/components/form-section';
import { MediaSection } from 'pages/promotions/components/media-section';
import { SkeletonMediaSection } from 'pages/promotions/components/skeleton-media-section';
import { CampaignImagesObj, useCampaignFormHandler } from 'pages/promotions/hooks/use-campaign-form-handler';

const SkeletonStack = () => {
  return (
    <Stack spacing={'lg'}>
      <Skeleton height='50px' />
      <Skeleton height='50px' />
      <Skeleton height='50px' />
      <Skeleton height='50px' />
      <Skeleton height='50px' />
      <Skeleton height='40px' width='100px' alignSelf='end' />
    </Stack>
  );
};

export const AddCampaignScreen = () => {
  const {
    control,
    errors,
    handleSubmit,
    register,
    onSubmit,
    setValue,
    imagesObj,
    isLoading,
    isEdit,
    getInternalName,
    isDisabled,
    isFetching,
  } = useCampaignFormHandler();

  if (isLoading) {
    return (
      <HStack maxWidth={'full'} w={'full'} overflow={'auto'} align='stretch' h='full'>
        <WhiteCard p={'md'} minW='450px' overflow={'auto'}>
          <VStack spacing='lg' alignItems='stretch'>
            <SkeletonStack />
          </VStack>
        </WhiteCard>

        <WhiteCard w={'full'} overflow={'visible'} p={'md'}>
          <SkeletonMediaSection />
        </WhiteCard>
      </HStack>
    );
  }

  return (
    <VStack>
      <WhiteCard p={'md'} w={'full'}>
        <HStack justifyContent={'space-between'}>
          <Text fontSize='xl' fontWeight='bold'>
            {getInternalName}
          </Text>

          <Button
            isDisabled={isDisabled}
            alignSelf={'end'}
            variant='solid'
            colorScheme='red'
            type='submit'
            isLoading={isLoading || isFetching}
            form='addCampaignForm'>
            Publish
          </Button>
        </HStack>
      </WhiteCard>

      <SimpleGrid
        w={'full'}
        spacing={'1%'}
        columns={2}
        h='full'
        as='form'
        id='addCampaignForm'
        onSubmit={handleSubmit(onSubmit)}
        templateColumns={['33% 66%']}
        noValidate>
        <VStack align={'stretch'}>
          <WhiteCard p={'md'}>
            <VStack spacing='lg' alignItems='stretch'>
              <FormSection control={control} errors={errors} register={register} isEdit={isEdit} />
            </VStack>
          </WhiteCard>
          <WhiteCard p={'md'}>
            <FormControl isInvalid={errors.keywords ? true : false}>
              <FormLabel>Add Keywords</FormLabel>

              <Textarea rows={5} {...register('keywords')} />

              <FormErrorMessage>{errors.keywords?.message}</FormErrorMessage>
            </FormControl>
          </WhiteCard>
        </VStack>
        <WhiteCard p={'md'}>
          <MediaSection setValue={setValue} imagesObj={imagesObj as CampaignImagesObj} isFetching={isFetching} />
        </WhiteCard>
      </SimpleGrid>
    </VStack>
  );
};
