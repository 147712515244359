import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@material-ui/core';
import useForm from 'components/general-functions/forms.js';
import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectAssignMethodAction } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const SelectAssignMethod = (props) => {
  let state = useForm({ assignType: '' });
  let stateValues = state.value;

  const {
    dialog: { openSelectMethod },
  } = props;

  const close = () => {
    state.setValue({});
    props.SelectAssignMethodAction({
      openSelectMethod: false,
    });
  };

  useEffect(() => {
    if (stateValues.assignType) {
      props.dialog.onConfirm(stateValues.assignType);
    }
  }, [stateValues]);

  useEffect(() => {
    if (stateValues.assignType) {
      state.setValue({});
    }
  }, [openSelectMethod]);

  return (
    <Fragment>
      <Dialog
        open={openSelectMethod || false}
        onClose={() => close()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'>
        <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title'>
          <div className='page-title'>
            <h2>
              <IntlMessages id={`restaurant.selectAdmin.method`} />
            </h2>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: '8px 60px', width: '700px' }}>
          <div className={'form-group mr-20 ml-20'}>
            <Select fullWidth name={'assignType'} value={stateValues.assignType || ''} onChange={state.onChange}>
              {['addNew', 'exist'].map((type, index) => (
                <MenuItem key={index} value={type}>
                  <IntlMessages id={`restaurant.merge.${type}`} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 60px 30px' }}>
          <Button onClick={close} color='primary' variant='outlined'>
            <IntlMessages id={`button.cancel`} />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(connect(mapStateToProps, { SelectAssignMethodAction })(SelectAssignMethod));
