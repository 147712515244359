import { StackProps, Text, VStack } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';

export interface RestaurantInfoCellData extends StackProps {
  restaurantId: number;
  name: string;
}

export const RestaurantInfoCell = ({ restaurantId, name, ...rest }: RestaurantInfoCellData) => {
  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/restaurants/details/${restaurantId}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>
    </VStack>
  );
};
