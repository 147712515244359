const SUPER_ADMIN = 'admin';
const CMS_USER = 'cms_user';
const CUSTOMER = 'customer';
const SUPPLIER_ADMIN = 'supplier_admin';
const SUPPLIER_MANAGER = 'supplier_manager';
const SUPPLIER_USER = 'supplier_user';
const RESTAURANT_ADMIN = 'restaurant_admin';
const RESTAURANT_WAREHOUSE_USER = 'restaurant_warehouse_user';
const RESTAURANT_MANAGER = 'restaurant_manager';
const RESTAURANT_USER = 'restaurant_user';
const RESTAURANT_CATALOG = 'restaurant_catalog';

const permissionTypes = {
  any: 'any',
};

export {
  SUPER_ADMIN,
  permissionTypes,
  CMS_USER,
  CUSTOMER,
  SUPPLIER_ADMIN,
  SUPPLIER_MANAGER,
  SUPPLIER_USER,
  RESTAURANT_ADMIN,
  RESTAURANT_MANAGER,
  RESTAURANT_USER,
  RESTAURANT_CATALOG,
  RESTAURANT_WAREHOUSE_USER,
};
