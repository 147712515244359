import { PromotionFormComponent, PromotionsListComponent } from 'components/AsyncComponent';
import { Layout, Section } from 'layout';
import { AddCampaignScreen } from 'pages/promotions/screens/add-campaign-screen';
import { CampaignsScreen } from 'pages/promotions/screens/campaigns-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const Forms = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
          <Route path={`${match.url}/list`} component={PromotionsListComponent} />
          <Route exact path={`${match.url}/campaigns`} component={CampaignsScreen} />
          <Route path={`${match.url}/campaigns/add/:internalName`} component={AddCampaignScreen} />
          <Route path={`${match.url}/campaigns/edit/:campaignId`} component={AddCampaignScreen} />
          <Route path={`${match.url}/add`} component={PromotionFormComponent} />
          <Route path={`${match.url}/edit/:id`} component={PromotionFormComponent} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default Forms;
