import {
  BoxProps,
  Button,
  ChakraAsyncSelect,
  ChakraSelect,
  formatOptionsArray,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { CreateTicketVariables, useCreateTicketApi, useGetDepartmentsApi } from 'apis/ticket-management';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TicketCategory } from '../utils/ticket-categories';
import { UploadTicketFile } from './upload-ticket-file';

type TOption = {
  value: string;
  label: string;
};

const categoriesOptions: TOption[] = [
  {
    value: TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING,
    label: TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING,
  },
  {
    value: TicketCategory.CATALOG_RELATED,
    label: TicketCategory.CATALOG_RELATED,
  },
  {
    value: TicketCategory.CMS_UPDATE,
    label: TicketCategory.CMS_UPDATE,
  },
  {
    value: TicketCategory.LOGISTIC_FULFILLMENT_REQUEST,
    label: TicketCategory.LOGISTIC_FULFILLMENT_REQUEST,
  },
  {
    value: TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING,
    label: TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING,
  },
  {
    value: TicketCategory.ORDER_RELATED,
    label: TicketCategory.ORDER_RELATED,
  },
  {
    value: TicketCategory.OTHER_REQUESTS_INQUIRIES,
    label: TicketCategory.OTHER_REQUESTS_INQUIRIES,
  },
];

type TInputs = {
  ticketCategory?: TOption;
  departmentId?: TOption;
  onboardingType?: TOption;
  brandOrSupplierId?: TOption;
  catalogRelated?: TOption;
  additionalInfo: string;
  orderCode: string;
  deliveryReason?: TOption;
  paymentMode?: TOption;
  supplierAllocation: string;
  additionalSuppliersOrBranches: string;
  cmsUpdatePurpose?: TOption;
  orderIssueType?: TOption;
  attachBrandTradeLicense: Record<string, never>[];
  attachSuppliersMasterList: Record<string, never>[];
  attachInvoices: Record<string, never>[];
  attachments: Record<string, never>[];
};

export type TicketModalInput = TInputs;

const defaultValues: TInputs = {
  ticketCategory: undefined,
  departmentId: undefined,
  onboardingType: undefined,
  brandOrSupplierId: undefined,
  catalogRelated: undefined,
  additionalInfo: '',
  orderCode: '',
  deliveryReason: undefined,
  paymentMode: undefined,
  orderIssueType: undefined,
  supplierAllocation: '',
  additionalSuppliersOrBranches: '',
  cmsUpdatePurpose: undefined,
  attachBrandTradeLicense: [],
  attachSuppliersMasterList: [],
  attachInvoices: [],
  attachments: [],
};

export interface ICreateTicketModal extends BoxProps {
  trigger: JSX.Element;
}

export const CreateTicketModal = ({ trigger }: ICreateTicketModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isCreateTicketLoading, mutate: createTicket } = useCreateTicketApi();
  const { isLoading: isGetDepartmentsLoading, data: departmentsApi } = useGetDepartmentsApi();

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const onSubmit = (data: TInputs) => {
    const getIdsOfUploadsObject = (data: Record<string, never>[]): string[] => {
      const ids: string[] = [];

      data.map((item) => {
        ids.push(item?.id);
      });

      return ids;
    };

    const variables: CreateTicketVariables = {
      body: {
        ticketCategory: data?.ticketCategory?.value,
        departmentId: data?.departmentId?.value,
        onboardingType: data?.onboardingType?.value,
        entityId: data?.brandOrSupplierId?.value,
        additionalInfo: data?.additionalInfo,
        paymentMode: data?.paymentMode?.value,
        orderCode: data?.orderCode,
        deliveryReason: data?.deliveryReason?.value,
        supplierAllocation: data?.supplierAllocation,
        cmsUpdatePurpose: data?.cmsUpdatePurpose?.value,
        orderIssueType: data?.orderIssueType?.value,
        additionalSuppliersOrBranches: data?.additionalSuppliersOrBranches,
        catalogRelated: data?.catalogRelated?.value,
        uploads: [
          ...getIdsOfUploadsObject(data.attachBrandTradeLicense),
          ...getIdsOfUploadsObject(data.attachSuppliersMasterList),
          ...getIdsOfUploadsObject(data.attachInvoices),
          ...getIdsOfUploadsObject(data.attachments),
        ],
      },
    };

    createTicket(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  const selectedCategory = watch('ticketCategory')?.value;
  const selectedOnboardingType = watch('onboardingType')?.value;

  // Clear the form errors every time we select new ticket category
  useEffect(() => {
    clearErrors();
  }, [selectedCategory]);

  const DepartmentIdFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
      case TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING:
      case TicketCategory.CMS_UPDATE:
      case TicketCategory.CATALOG_RELATED:
      case TicketCategory.LOGISTIC_FULFILLMENT_REQUEST:
      case TicketCategory.ORDER_RELATED:
      case TicketCategory.OTHER_REQUESTS_INQUIRIES:
        return (
          <Controller
            control={control}
            name='departmentId'
            rules={{ required: 'Department is required' }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired>
                <FormLabel>Department</FormLabel>
                <ChakraSelect
                  isLoading={isGetDepartmentsLoading}
                  options={formatOptionsArray(departmentsApi?.data, 'id', 'name')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const OnboardingTypeFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
      case TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING:
      case TicketCategory.CMS_UPDATE:
      case TicketCategory.CATALOG_RELATED:
      case TicketCategory.OTHER_REQUESTS_INQUIRIES:
      case TicketCategory.ORDER_RELATED:
      case TicketCategory.LOGISTIC_FULFILLMENT_REQUEST:
        return (
          <Controller
            control={control}
            name='onboardingType'
            rules={{ required: 'Onboarding type is required' }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired>
                <FormLabel>Onboarding Type</FormLabel>
                <ChakraSelect
                  options={[
                    { value: 'restaurant', label: 'Restaurant' },
                    { value: 'supplier', label: 'Supplier' },
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const SelectBrandOrSupplierNameFormControl = () => {
    // TODO: add this to each input
    const checkIsRequired = () => {
      switch (selectedCategory) {
        case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
          return false;

        default:
          return true;
      }
    };

    switch (selectedCategory) {
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
      case TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING:
      case TicketCategory.CMS_UPDATE:
      case TicketCategory.CATALOG_RELATED:
      case TicketCategory.OTHER_REQUESTS_INQUIRIES:
      case TicketCategory.ORDER_RELATED:
      case TicketCategory.LOGISTIC_FULFILLMENT_REQUEST:
        return (
          <Controller
            control={control}
            name='brandOrSupplierId'
            rules={{ required: checkIsRequired() ? 'Brand or supplier is required' : false }}
            render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired={checkIsRequired()}>
                <FormLabel>Brand/Supplier</FormLabel>

                <ChakraAsyncSelect
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  isDisabled={!selectedOnboardingType}
                  loadOptions={(inputValue, callback) => {
                    if (selectedOnboardingType === 'restaurant') {
                      http
                        .get('restaurants', {
                          params: { filter: `nameEn||$contL||${inputValue}` },
                        })
                        .then((res) => {
                          const values: TOption[] = [];

                          res?.data?.data?.map((item: any) => {
                            values.push({ value: item?.id, label: item?.name });
                          });

                          callback(values);
                        });
                    }

                    if (selectedOnboardingType === 'supplier') {
                      http
                        .get('suppliers', {
                          params: { filter: `name||$contL||${inputValue}` },
                        })
                        .then((res) => {
                          const values: TOption[] = [];

                          res?.data?.data?.map((item: any) => {
                            values.push({ value: item?.id, label: item?.name });
                          });

                          callback(values);
                        });
                    }
                  }}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const AttachBrandTradeLicenseFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
        return (
          <Controller
            control={control}
            name='attachBrandTradeLicense'
            render={({ field: { value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false}>
                <FormLabel>Brand trade license</FormLabel>
                <UploadTicketFile
                  onUpload={(data) => {
                    setValue('attachBrandTradeLicense', data, { shouldValidate: true });
                  }}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const AttachSuppliersMasterListFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
        return (
          <Controller
            control={control}
            name='attachSuppliersMasterList'
            render={({ field: { value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false}>
                <FormLabel>Supplier master list</FormLabel>
                <UploadTicketFile
                  onUpload={(data) => {
                    setValue('attachSuppliersMasterList', data, { shouldValidate: true });
                  }}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const AttachInvoicesFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
      case TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING:
        return (
          <Controller
            control={control}
            name='attachInvoices'
            render={({ field: { value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false}>
                <FormLabel>Invoices</FormLabel>
                <UploadTicketFile
                  onUpload={(data) => {
                    setValue('attachInvoices', data, { shouldValidate: true });
                  }}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const AttachmentsFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.CATALOG_RELATED:
      case TicketCategory.ORDER_RELATED:
        return (
          <Controller
            control={control}
            name='attachments'
            render={({ field: { value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false}>
                <FormLabel>Attachments</FormLabel>
                <UploadTicketFile
                  onUpload={(data) => {
                    setValue('attachments', data, { shouldValidate: true });
                  }}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const SupplierAllocationFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
      case TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING:
        return (
          <FormControl isInvalid={errors.supplierAllocation ? true : false}>
            <FormLabel>Supplier Allocation</FormLabel>
            <Input {...register('supplierAllocation')} />

            <FormErrorMessage>{errors.supplierAllocation?.message}</FormErrorMessage>
          </FormControl>
        );

      default:
        return null;
    }
  };

  const AdditionalSuppliersOrBranchesFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.ADDITIONAL_SUPPLIER_BRANCH_ONBOARDING:
        return (
          <FormControl isInvalid={errors.additionalSuppliersOrBranches ? true : false}>
            <FormLabel>Additional Suppliers/Branches</FormLabel>
            <Input {...register('additionalSuppliersOrBranches')} />

            <FormErrorMessage>{errors.additionalSuppliersOrBranches?.message}</FormErrorMessage>
          </FormControl>
        );

      default:
        return null;
    }
  };

  const SelectPurposeForCMSUpdateFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.CMS_UPDATE:
        return (
          <Controller
            control={control}
            name='cmsUpdatePurpose'
            rules={{ required: 'Update purpose is required' }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired>
                <FormLabel>Update Purpose</FormLabel>
                <ChakraSelect
                  options={[
                    { value: 'additional user', label: 'Additional user' },
                    { value: 'transfer', label: 'Transfer' },
                    { value: 'deletion', label: 'Deletion' },
                    { value: 'others', label: 'Others' },
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const AdditionalInfoFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.CMS_UPDATE:
      case TicketCategory.CATALOG_RELATED:
      case TicketCategory.LOGISTIC_FULFILLMENT_REQUEST:
      case TicketCategory.ORDER_RELATED:
      case TicketCategory.NEW_BRAND_SUPPLIER_ONBOARDING:
      case TicketCategory.OTHER_REQUESTS_INQUIRIES:
        return (
          <FormControl isInvalid={errors.additionalInfo ? true : false} isRequired>
            <FormLabel>Additional Info</FormLabel>

            <Textarea
              rows={4}
              {...register('additionalInfo', {
                required: 'Additional info is required',
              })}
            />

            <FormErrorMessage>{errors.additionalInfo?.message}</FormErrorMessage>
          </FormControl>
        );

      default:
        return null;
    }
  };

  const SelectTypeForCatalogRelatedTaskFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.CATALOG_RELATED:
        return (
          <Controller
            control={control}
            name='catalogRelated'
            rules={{ required: 'Catalog related is required' }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired>
                <FormLabel>Catalog Related</FormLabel>
                <ChakraSelect
                  options={[
                    { value: 'additional items', label: 'Additional Items' },
                    { value: 'revise existing items', label: 'Revise existing items' },
                    { value: 'delete items', label: 'Delete Items' },
                    { value: 'others', label: 'Others' },
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const OrderCodeFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.LOGISTIC_FULFILLMENT_REQUEST:
      case TicketCategory.ORDER_RELATED:
        return (
          <FormControl isInvalid={errors.orderCode ? true : false} isRequired>
            <FormLabel>Order Code</FormLabel>
            <Input
              {...register('orderCode', {
                required: 'Order code is required',
              })}
            />

            <FormErrorMessage>{errors.orderCode?.message}</FormErrorMessage>
          </FormControl>
        );

      default:
        return null;
    }
  };

  const SelectReasonForDeliveryFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.LOGISTIC_FULFILLMENT_REQUEST:
        return (
          <Controller
            control={control}
            name='deliveryReason'
            rules={{ required: 'Delivery reason is required' }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired>
                <FormLabel>Delivery Reason</FormLabel>
                <ChakraSelect
                  options={[
                    { value: 'express delivery', label: 'Express delivery' },
                    { value: 'order delay', label: 'Order delay' },
                    { value: 'app issue', label: 'App issue' },
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const SelectPaymentModeFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.LOGISTIC_FULFILLMENT_REQUEST:
        return (
          <Controller
            control={control}
            name='paymentMode'
            rules={{ required: 'Payment mode is required' }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired>
                <FormLabel>Payment Mode</FormLabel>
                <ChakraSelect
                  options={[
                    { value: 'free delivery', label: 'Free delivery' },
                    { value: 'paid delivery / cod', label: 'Paid delivery / COD' },
                    { value: 'paid delivery / credit', label: 'Paid delivery / Credit' },
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  const SelectIssueFormControl = () => {
    switch (selectedCategory) {
      case TicketCategory.ORDER_RELATED:
        return (
          <Controller
            control={control}
            name='orderIssueType'
            rules={{ required: 'Issue type is required' }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl isInvalid={error ? true : false} isRequired>
                <FormLabel>Issue Type</FormLabel>
                <ChakraSelect
                  options={[
                    { value: 'order delay', label: 'Order Delay' },
                    { value: 'missing details', label: 'Missing Details' },
                    { value: 'item return / exchange', label: 'Item Return / Exchange' },
                    { value: 'cancellation', label: 'Cancellation' },
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => reset(defaultValues)}
        closeOnOverlayClick={false}
        size='3xl'
        scrollBehavior={!selectedCategory ? 'outside' : 'inside'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as='h2' size='lg'>
              {!selectedCategory ? 'Ticket Category' : 'New Ticket'}
            </Heading>
            <Text fontWeight='normal' color='gray.500'>
              {!selectedCategory
                ? 'Please select from below your ticket category'
                : 'Please fill the form below to create new ticket'}
            </Text>
          </ModalHeader>
          <ModalBody>
            <Stack as='form' id='createTicketModal' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                control={control}
                name='ticketCategory'
                rules={{ required: 'Ticket category is required' }}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <FormControl isInvalid={error ? true : false} isRequired>
                    <FormLabel>Ticket category</FormLabel>
                    <ChakraSelect
                      options={categoriesOptions}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      value={value}
                    />

                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                  </FormControl>
                )}
              />

              <DepartmentIdFormControl />
              <OnboardingTypeFormControl />
              <SelectBrandOrSupplierNameFormControl />
              <SupplierAllocationFormControl />
              <AdditionalSuppliersOrBranchesFormControl />
              <SelectPurposeForCMSUpdateFormControl />
              <AdditionalInfoFormControl />
              <SelectTypeForCatalogRelatedTaskFormControl />
              <OrderCodeFormControl />
              <SelectReasonForDeliveryFormControl />
              <SelectPaymentModeFormControl />
              <SelectIssueFormControl />
              <AttachSuppliersMasterListFormControl />
              <AttachBrandTradeLicenseFormControl />
              <AttachInvoicesFormControl />
              <AttachmentsFormControl />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='createTicketModal'
                isLoading={isCreateTicketLoading}>
                {!selectedCategory ? 'Continue' : 'Create Ticket'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
