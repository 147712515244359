import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  SwitchProps,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { usePatchSupplierItemApi } from 'apis/supplier/supplier-items/use-patch-supplier-item-api';

export interface IItemStatusCell extends SwitchProps {
  itemId: string;
  isActive: boolean;
}

export const ItemStatusCell = ({ itemId, isActive, ...rest }: IItemStatusCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: patchProduct, isLoading: isPatchProductLoading } = usePatchSupplierItemApi();

  const handleActivateProduct = () => {
    const variables = {
      itemId,
      body: {
        isActive: !isActive,
      },
    };

    patchProduct(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <FormControl display='flex' alignItems='center'>
        <Switch id={`active-user-${itemId}`} onChange={onOpen} isChecked={isActive} {...rest} />
        <FormLabel htmlFor={`active-user-${itemId}`} mb='0' ms='sm' userSelect='none' fontWeight='normal' fontSize='sm'>
          {isActive ? 'Active' : 'Inactive'}
        </FormLabel>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isActive ? 'Deactivate' : 'Activate'} product!</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {isActive && (
              <>
                <Text>Are you sure you want to deactivate this product?</Text>
              </>
            )}

            {!isActive && (
              <>
                <Text>Are you sure you want to activate this product?</Text>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                onClick={handleActivateProduct}
                isLoading={isPatchProductLoading}>
                {isActive ? 'Deactivate' : 'Activate'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
