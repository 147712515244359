import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { AggSheetRelation } from 'pages/aggregated-sheets/types';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'oms/v1/aggregated-sheets';
export const getAggSheetsRelationByIdEndpointIdentifier = endpoint;

type TData = AggSheetRelation;
type TError = AxiosError;
type TQueryKey = [typeof endpoint];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetAggSheetsRelationByIdApi(aggSheetId: number, options?: Options): ReturnType {
  const toast = useCustomToast();
  const fullEndpoint = `${endpoint}/${aggSheetId}`;

  const queryFn = async () => {
    try {
      const { data } = await http.get(fullEndpoint);

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: false,
    ...options,
  });
}
