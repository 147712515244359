import features from './features.json';

const currentEnvironment = process.env.REACT_APP_ENV_VAR as string;

type Feature = {
  [key: string]: boolean;
};

type Features = {
  [key: string]: Feature;
};

// I cloned this object to save the old object key types for the IntelliSense
const _features: Features = { ...features };
const environmentFeatures: Feature = {};

Object.keys(_features).forEach((featureName) => {
  environmentFeatures[featureName] = _features[featureName][currentEnvironment];
});

type FeatureKey = keyof typeof features;

export type EnvironmentFeatures = {
  [key in FeatureKey]: boolean;
};

export default environmentFeatures as EnvironmentFeatures;
