import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetOrderCommentsApi } from './use-get-order-comments-api';

const endpoint = 'oms/v1/orders';
export const createOrderCommentEndpointIdentifier = endpoint;

interface BodyType {
  text: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  orderId: string;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useCreateOrderCommentApi(orderId: string, options?: Options): ReturnType {
  const { refetch: refetchOrderCommentsApi } = useGetOrderCommentsApi(orderId, { enabled: false });

  const toast = useCustomToast();

  const mutationFn = async ({ orderId, body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint + `/${orderId}/comments`, body);

      // Refetch order comments list
      refetchOrderCommentsApi();

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
