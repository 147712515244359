import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButtonWithTooltip,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BsWhatsapp } from 'react-icons/bs';
import * as Yup from 'yup';

type TInputs = {
  phoneNumber: string;
};

const defaultValues: TInputs = {
  phoneNumber: '',
};

const phoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .notRequired()
    .matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, 'Phone number is not valid'),
});

export const WhatsAppChatInitiative = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: yupResolver(phoneSchema),
  });

  const phoneNumber = watch('phoneNumber');
  const isPhoneNumberEmpty = phoneNumber === undefined || phoneNumber.trim() === '';

  const formattedPhoneNumber = (value: string) => {
    // Removes spaces, hyphens, commas, and parentheses
    return value.replace(/[\s,()-]/g, '');
  };

  const onSubmit = (data: TInputs) => {
    const formattedNumber = formattedPhoneNumber(data.phoneNumber);
    setValue('phoneNumber', formattedNumber, { shouldValidate: true });

    const url = `https://wa.me/${formattedNumber}`;
    window.open(url, '_blank');
  };

  const onReset = () => {
    reset(defaultValues);
  };

  const onPaste = async () => {
    try {
      const copiedValue = await navigator.clipboard.readText();
      const formattedNumber = formattedPhoneNumber(copiedValue);

      setValue('phoneNumber', formattedNumber, { shouldValidate: true });
    } catch (error) {
      console.error('Failed to read clipboard contents: ', error);
    }
  };

  return (
    <>
      <IconButtonWithTooltip
        label='Start a WhatsApp chat'
        aria-label='chat'
        Icon={<Icon as={BsWhatsapp} aria-label='chat' fill='#757575' />}
        onClick={onOpen}
        variant='ghost'
        size='lg'
        isRound
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered={false} onCloseComplete={onReset}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Start a WhatsApp chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form id='initiateWhatsAppChatFormId' onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl isInvalid={errors.phoneNumber ? true : false}>
                <InputGroup variant='outline' size='lg'>
                  <InputLeftAddon pointerEvents='none'>
                    <HStack spacing='xs' alignItems='center'>
                      <BsWhatsapp color='#757575' size='14px' />
                      <Text color='#757575'>WhatsApp</Text>
                    </HStack>
                  </InputLeftAddon>

                  <Input
                    pr='75px'
                    type='tel'
                    placeholder='ex.: 507738852'
                    autoFocus
                    onPaste={onPaste}
                    {...register('phoneNumber')}
                    isInvalid={errors.phoneNumber ? true : false}
                  />

                  <InputRightElement width='75px'>
                    <Button size='sm' variant='ghost' width='65px' onClick={onPaste}>
                      Paste
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button onClick={onClose} variant='ghost' colorScheme='gray'>
                Cancel
              </Button>

              <Button
                leftIcon={<BsWhatsapp />}
                variant='solid'
                colorScheme='red'
                type='submit'
                form='initiateWhatsAppChatFormId'
                isDisabled={!!errors.phoneNumber || isPhoneNumberEmpty}>
                Chat
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
