import { Layout, Section } from 'layout';
import { RestaurantTransfersDetailsScreen } from 'pages/restaurant-transfers/screens/restaurant-transfers-details-screen';
import { RestaurantTransfersListScreen } from 'pages/restaurant-transfers/screens/restaurant-transfers-list-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const RestaurantTransfersPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
          <Route path={`${match.url}/list`} component={RestaurantTransfersListScreen} />
          <Route path={`${match.url}/details/:code`} component={RestaurantTransfersDetailsScreen} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default RestaurantTransfersPage;
