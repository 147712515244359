import { HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { Can } from 'configs/can';
import { AddWarehouseModal } from 'pages/restaurants/components/add-warehouse-modal';
import { useHistory, useParams } from 'react-router-dom';
import { EditIcon } from '../icons/edit-icon';
import { ProductsIcon } from '../icons/products-icon';
import { TruckIcon } from '../icons/truck-icon';
import { UsersIcon } from '../icons/users-icon';

export interface IWarehouseActionsCell {
  warehouseId: any;
}

export const WarehouseActionsCell = ({ warehouseId }: IWarehouseActionsCell) => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const history = useHistory();

  const onUsersClick = () => {
    history.push(`/app/restaurants/warehouse/users/${restaurantId}/${warehouseId}`);
  };

  const onProductsClick = () => {
    history.push(`/app/restaurants/warehouse/items/${restaurantId}/${warehouseId}`);
  };

  const onSuppliersClick = () => {
    history.push(`/app/restaurants/warehouse/suppliers/${restaurantId}/${warehouseId}`);
  };

  return (
    <HStack spacing='xs'>
      <IconButtonWithTooltip
        Icon={<UsersIcon boxSize='20px' />}
        label='Users'
        aria-label='Users'
        variant='ghost'
        size='sm'
        onClick={onUsersClick}
      />

      <IconButtonWithTooltip
        Icon={<ProductsIcon boxSize='18px' />}
        label='Products'
        aria-label='Products'
        variant='ghost'
        size='sm'
        onClick={onProductsClick}
      />

      <IconButtonWithTooltip
        Icon={<TruckIcon boxSize='18px' />}
        label='Suppliers'
        aria-label='Suppliers'
        variant='ghost'
        size='sm'
        onClick={onSuppliersClick}
      />

      <Can I='update' a='User'>
        <AddWarehouseModal
          variant='edit'
          warehouseId={warehouseId}
          trigger={
            <IconButtonWithTooltip
              Icon={<EditIcon boxSize='15px' />}
              label='Edit'
              aria-label='Edit'
              variant='ghost'
              size='sm'
            />
          }
        />
      </Can>
    </HStack>
  );
};
