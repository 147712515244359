import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetQueryClientData } from '../../hooks/use-get-query-client-data';
import { getMeEndpointIdentifier } from '../users/use-me-api';

const endpoint = 'ums/v1/zoho/tickets/unassigned-tickets';

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  assigneeId: string;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useAssignTicketApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const me = useGetQueryClientData(getMeEndpointIdentifier);

  const mutationFn = async ({ assigneeId }: TVariables) => {
    try {
      const countryCode = me?.countryCode ?? '';

      const { data } = await http.patch(`${endpoint}/${assigneeId}`, null, {
        headers: {
          'Accept-Country': countryCode,
        },
      });

      toast({
        description: 'The tickets have been assigned successfully.',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message ?? 'An error occurred while assigning tickets.';

      toast({
        description: errorMessage,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
