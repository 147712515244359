import { Box } from '@elkaso-app/web-design';
import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';

export const BlankPageLayout = ({ sections }: LayoutProps) => {
  return (
    <Box as='main'>
      {/* Start Content */}
      <Box as='section' minH={'100vh'}>
        <Section sections={sections} name='content' />
      </Box>
      {/* End Content */}
    </Box>
  );
};
