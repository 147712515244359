import { HStack, Text } from '@elkaso-app/web-design';

export interface IPublishersCell {
  publishers: {
    id: string;
    name: string;
  }[];
}

export const PublishersCell = ({ publishers }: IPublishersCell) => {
  const publisherNames = publishers.map((publisher) => publisher.name).join(', ');

  return (
    <HStack>
      <Text>{publisherNames}</Text>
    </HStack>
  );
};
