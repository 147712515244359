import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetProductsApi } from './use-get-products-api';

const endpoint = 'catalog/v1/catalog-categories';
export const updateProductEndpointIdentifier = endpoint;

interface BodyType {
  products: any;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  categoryId: number;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUpdateProductApi(categoryId: number, options?: Options): ReturnType {
  const { refetch: refetchProductDetails } = useGetProductsApi(categoryId, { enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ categoryId, body }: TVariables) => {
    const fullEndpoint = `${endpoint}/${categoryId}/legacy-products`;

    try {
      const { data } = await http.post(fullEndpoint, body);
      // Refetch categories list
      refetchProductDetails();

      toast({
        description: 'Product has been updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
