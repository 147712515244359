import { Icon, IconProps } from '@elkaso-app/web-design';

export const EditModeIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='none' {...props}>
      <g clipPath='url(#clip0_1037_11939)'>
        <path
          d='M3 17.75V21.5H6.75L17.81 10.44L14.06 6.69L3 17.75ZM20.71 7.54C21.1 7.15 21.1 6.52 20.71 6.13L18.37 3.79C17.98 3.4 17.35 3.4 16.96 3.79L15.13 5.62L18.88 9.37L20.71 7.54V7.54Z'
          fill='#E03E52'
        />
      </g>
      <defs>
        <clipPath id='clip0_1037_11939'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
