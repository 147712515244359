import { IconButton, Menu, MenuButton, MenuList, Tooltip } from '@elkaso-app/web-design';
import { FilterIcon } from 'components/base-table/icons';

interface IBaseFilterButton {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  isActive: boolean;
  formComponent: JSX.Element;
}

export const BaseFilterButton = ({ isOpen, onOpen, onClose, isActive, formComponent }: IBaseFilterButton) => {
  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <Tooltip label='Filter'>
        <MenuButton
          as={IconButton}
          aria-label='filter-btn'
          icon={<FilterIcon fill={isActive ? 'red.500' : 'gray.500'} />}
          variant='unstyled'
          size='xs'
        />
      </Tooltip>
      <MenuList>{formComponent}</MenuList>
    </Menu>
  );
};
