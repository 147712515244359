<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'></svg>;

import { Icon, IconProps } from '@elkaso-app/web-design';

export const RemoveIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#757575' {...props}>
      <g clipPath='url(#clip0_1856_9047)'>
        <path
          d='M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z'
          fill='inherit'
        />
      </g>
      <defs>
        <clipPath id='clip0_1856_9047'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};
