import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import useForm from 'components/general-functions/forms.js';
import ImageComponent from 'components/ImageComponent';
import numberMask from 'components/numberComponent';
import TwoItemsRow from 'components/TwoItemsRow';
import AppConfig from 'constants/AppConfig';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CatalogToggleDialog } from 'redux/actions';
import IntlMessages from 'utils/intl-messages';

const CatalogDialog = (props) => {
  const close = () => {
    props.CatalogToggleDialog({
      openCatalog: false,
    });
  };

  //for normal fields
  let stateProduct = useForm({}, {});
  let { setValue, setFile } = stateProduct;
  let productValues = stateProduct.value;
  let fileValues = stateProduct.file;

  const {
    dialog: {
      openCatalog,
      data: { item },
    },
  } = props;

  useEffect(() => {
    setValue(item ? item : {});
    setFile(!!item && item.imageUrl ? { imageUrl: item.imageUrl } : {});
    setSyns(item && item.synonyms ? item.synonyms : []);
  }, [openCatalog]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!_.isEmpty(productValues) && productValues.id && !_.isEmpty(fileValues) && !fileValues.imageUrl) {
      fileValues.imageUrl = null;
    }
    let submitedData = {
      ...productValues,
      defaultPrice: parseFloat(productValues.defaultPrice),
      imageUrl: fileValues.imageUrl,
    };
    submitedData = syns && { ...submitedData, synonyms: syns };
    if (!!submitedData.nameEn || !!submitedData.nameAr) {
      props.dialog.onConfirm(submitedData);
      close();
    } else {
      NotificationManager.error(
        <IntlMessages id='error.message.catalog.form' />,
        <IntlMessages id={`${AppConfig.errorTitle}`} />,
        AppConfig.errorNotificationTimeout
      );
    }
  };

  const classes = () => ({
    chipContainer: {
      backgroundColor: 'transparent',
      display: 'inline-block',
      marginBottom: 10,
      marginLeft: -5,
    },
    chip: {
      marginTop: 10,
      marginRight: 5,
    },
  });

  const [synValue, setSynValue] = useState('');
  const [syns, setSyns] = useState([]);
  const onChange = (e) => setSynValue(e.target.value);
  const addHandler = () => {
    synValue && setSyns([...syns, synValue]);
    setSynValue('');
  };
  const onRemoveItem = (itemIndex) => setSyns(syns.filter((value, index) => index !== itemIndex));

  const ChipsComponent = (data) => {
    return (
      <div className={'dark-theme flex-col'} style={{ flexGrow: '1', marginTop: '5px' }}>
        <div className={classes.chipContainer}>
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <Chip
                key={index}
                className={classes.chip}
                label={item}
                deleteIcon={<CancelIcon />}
                onDelete={() => onRemoveItem(index)}
              />
            ))}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Dialog
        open={openCatalog || false}
        onClose={() => close()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'>
        <ValidatorForm
          noValidate
          onSubmit={handleSubmit}
          onError={() => {
            NotificationManager.error(
              <IntlMessages id='global.errors.fill' />,
              <IntlMessages id={`${AppConfig.errorTitle}`} />,
              AppConfig.errorNotificationTimeout
            );
          }}>
          <DialogTitle style={{ padding: '30px 60px 8px' }} id='alert-dialog-title'>
            <div className='page-title'>
              <h2>
                <IntlMessages id={item ? `product.edit` : `product.add`} />
              </h2>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: '8px 60px', width: '700px' }}>
            <p>
              <strong>
                <IntlMessages id='note' />
              </strong>{' '}
              <IntlMessages id='note.body2' />
            </p>
            <TwoItemsRow
              firstComponent={
                <div className='form-group'>
                  <TextValidator
                    label={<IntlMessages id='catalog.nameEn' />}
                    onChange={stateProduct.onChange}
                    name='nameEn'
                    fullWidth
                    value={productValues.nameEn || ''}
                    required
                    validators={['required']}
                    errorMessages={[<IntlMessages id={`global.errors.required`} key='key-3' />]}
                  />
                </div>
              }
              secondComponent={
                <div className='form-group'>
                  <TextValidator
                    label={<IntlMessages id='catalog.nameAr' />}
                    onChange={stateProduct.onChange}
                    name='nameAr'
                    fullWidth
                    value={productValues.nameAr || ''}
                  />
                </div>
              }
            />
            <TwoItemsRow
              firstComponent={
                <div className='form-group'>
                  <TextValidator
                    label={<IntlMessages id='catalog.brandEn' />}
                    onChange={stateProduct.onChange}
                    name='brandEn'
                    fullWidth
                    value={productValues.brandEn || ''}
                  />
                </div>
              }
              secondComponent={
                <div className='form-group'>
                  <TextValidator
                    label={<IntlMessages id='catalog.brandAr' />}
                    onChange={stateProduct.onChange}
                    name='brandAr'
                    fullWidth
                    value={productValues.brandAr || ''}
                  />
                </div>
              }
            />
            <TwoItemsRow
              firstComponent={
                <div className='form-group'>
                  <TextValidator
                    label={<IntlMessages id='catalog.code' />}
                    onChange={stateProduct.onChange}
                    name='code'
                    fullWidth
                    value={productValues.code || ''}
                    required
                    validators={['required']}
                    errorMessages={[<IntlMessages id={`global.errors.required`} key='key-0' />]}
                  />
                </div>
              }
              secondComponent={
                <div className='form-group'>
                  <TextValidator
                    disabled={item && `true`}
                    label={<IntlMessages id='Kaso ID' />}
                    onChange={stateProduct.onChange}
                    name='catalogCategoryKasoId'
                    fullWidth
                    value={productValues.catalogCategoryKasoId || ''}
                  />
                </div>
              }
            />

            <TwoItemsRow
              firstComponent={
                <div className='form-group'>
                  <TextValidator
                    label={<IntlMessages id='catalog.defaultPrice' />}
                    value={productValues.defaultPrice === '0' ? '' : productValues.defaultPrice}
                    onChange={stateProduct.onChange}
                    name='defaultPrice'
                    fullWidth
                    InputProps={{
                      inputComponent: numberMask,
                    }}
                  />
                </div>
              }
              secondComponent={
                <div className='form-group'>
                  <TextValidator
                    label={<IntlMessages id='catalog.defaultUnit' />}
                    onChange={stateProduct.onChange}
                    name='defaultUnit'
                    fullWidth
                    value={productValues.defaultUnit || ''}
                    required
                    validators={['required']}
                    errorMessages={[<IntlMessages id={`global.errors.required`} key='key-1' />]}
                  />
                </div>
              }
            />
            <div className='form-group'>
              <TextValidator
                label={<IntlMessages id='catalog.specs' />}
                onChange={stateProduct.onChange}
                name='specs'
                fullWidth
                value={productValues.specs || ''}
              />
            </div>
            <TwoItemsRow
              firstComponent={
                <div className='form-group'>
                  <TextField
                    label={<IntlMessages id='catalog.synonyms' />}
                    name='synValue'
                    onChange={onChange}
                    fullWidth
                    value={synValue}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton style={{ marginBottom: '5px' }} onClick={addHandler}>
                            <AddCircle />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              secondComponent={ChipsComponent(syns)}
            />
            <TwoItemsRow
              firstComponent={
                <div className={'form-group'}>
                  <ImageComponent state={stateProduct} name={'imageUrl'} customClass={'custom-img'} hasAccess={true} />
                </div>
              }
            />
          </DialogContent>
          <DialogActions style={{ padding: '8px 60px 30px' }}>
            <Button onClick={close} color='primary' variant='outlined'>
              <IntlMessages id={`button.cancel`} />
            </Button>
            <Button variant='contained' type='submit' color='primary'>
              <IntlMessages id={item ? `button.edit` : `button.add`} />
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ dialog }) => {
  return { dialog };
};

export default withRouter(connect(mapStateToProps, { CatalogToggleDialog })(CatalogDialog));
