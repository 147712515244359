import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import { useGetTicketsApi } from 'apis/ticket-management/use-get-tickets-api';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'ums/v1/zoho/tickets';
export const createTicketEndpointIdentifier = endpoint;

interface BodyType {
  onboardingType?: string;
  entityId?: string;
  additionalInfo?: string;
  orderCode?: string;
  ticketCategory?: string;
  uploads?: any[];
  deliveryReason?: string;
  paymentMode?: string;
  additionalSuppliersOrBranches?: string;
  cmsUpdatePurpose?: string;
  orderIssueType?: string;
  departmentId?: string;
  supplierAllocation?: string;
  catalogRelated?: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: BodyType;
};

export type CreateTicketVariables = TVariables;

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useCreateTicketApi(options?: Options): ReturnType {
  const { refetch: refetchTickets } = useGetTicketsApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint, body);

      // Refetch tickets list
      refetchTickets();

      toast({
        description: 'New ticket has been created successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
