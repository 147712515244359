import { Badge } from '@elkaso-app/web-design';

interface IStatusBadgeStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

const statusBadgeStyleProps: IStatusBadgeStyleProps = {
  true: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  false: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
};

export interface IsActiveCellProps {
  isActive: boolean;
}

export const IsActiveCell = ({ isActive }: IsActiveCellProps) => {
  const index = isActive?.toString() || 'false';

  return (
    <Badge
      color={statusBadgeStyleProps[index].color}
      bgColor={statusBadgeStyleProps[index].bgColor}
      px='md'
      py='sm'
      borderRadius='full'
      fontSize='xs'
      textTransform={'capitalize'}
      _hover={{ shadow: 'sm' }}>
      {isActive ? 'Yes' : 'No'}
    </Badge>
  );
};
