import {
  Box,
  Center,
  HStack,
  IconButtonWithTooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@elkaso-app/web-design';
import { OrderComments } from 'pages/orders/components/order-comments';
import { OrderCommentsIcon } from '../icons/order-comments-icon';

interface IOrderCommentButton {
  order: Record<string, never>;
}

const OrderCommentButton = ({ order }: IOrderCommentButton) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box pos='relative'>
      {!!order?.orderCommentsCount && (
        <Center
          pos='absolute'
          top={0}
          right={0}
          bgColor='red.500'
          h='15px'
          minW='15px'
          fontSize='2xs'
          color='white'
          borderRadius='full'
          zIndex={1}
          px='4px'
          pt='1px'
          shadow='md'>
          {order?.orderCommentsCount > 99 ? '+99' : order?.orderCommentsCount}
        </Center>
      )}

      <IconButtonWithTooltip
        label='Comments'
        aria-label='order-comments'
        Icon={<OrderCommentsIcon />}
        variant='unstyled'
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Comments</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OrderComments orderId={order?.id} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export interface IOrderActionsCell {
  order: any;
}

export const OrderActionsCell = ({ order }: IOrderActionsCell) => {
  return (
    <HStack>
      <OrderCommentButton order={order} />
    </HStack>
  );
};
