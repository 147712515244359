import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useAssignWarehouseUserApi } from 'apis/restaurants/use-assign-warehouse-user-api';
import { useGetUnassignedUsersListApi } from 'apis/restaurants/use-get-unassigned-users-list-api';
import { useGetWarehouseByIdApi } from 'apis/restaurants/use-get-warehouse-by-id-api';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { UserIcon } from '../icons/user-icon';

const UserSkeleton = () => {
  return (
    <HStack align='center' spacing='md'>
      <Skeleton boxSize='18px' borderRadius='full' />
      <UserIcon fill='gray.300' />

      <VStack align='start' spacing='xs'>
        <Skeleton h='12px' w='200px' />
        <Skeleton h='10px' w='100px' />
      </VStack>
    </HStack>
  );
};

type TInputs = {
  userId: string;
};

interface IAddWarehouseUserModal {
  trigger: JSX.Element;
}

export const AddWarehouseUserModal = ({ trigger }: IAddWarehouseUserModal) => {
  const [branchName, setBranchName] = useState('');
  const { restaurantId, warehouseId } = useParams<{ restaurantId: string; warehouseId: string }>();

  const { isLoading: isAssignWarehouseUserLoading, mutate: assignWarehouseUserApi } = useAssignWarehouseUserApi(
    restaurantId,
    warehouseId
  );

  const { isOpen: isOpenUsersModal, onOpen: onOpenUsersModal, onClose: onCloseUsersModal } = useDisclosure();
  const { isOpen: isOpenAlertModal, onOpen: onOpenAlertModal, onClose: onCloseAlertModal } = useDisclosure();
  const { data: warehouseData } = useGetWarehouseByIdApi(restaurantId, warehouseId);

  const {
    data: unassignedUsersListData,
    refetch: refetchUsers,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = useGetUnassignedUsersListApi(restaurantId, warehouseId);
  const { data: usersListData } = unassignedUsersListData ?? {};

  const defaultValues = {
    userId: '',
  };

  const { control, formState, handleSubmit, reset } = useForm<TInputs>({
    defaultValues,
  });

  const { errors } = formState;

  const onSubmit = (data: TInputs) => {
    // Check if basic user
    const userObj = usersListData.find((user: any) => user?.id?.toString() === data.userId);
    const isBasicUser = userObj?.roles?.[0]?.slug === 'restaurant_user';

    if (isBasicUser && !isOpenAlertModal) {
      setBranchName(userObj?.branch?.name);
      return onOpenAlertModal();
    }

    // Assign user to warehouse
    assignWarehouseUserApi(
      { userId: data?.userId },
      {
        onSuccess: () => {
          onCloseAlertModal();
          onCloseUsersModal();
        },
      }
    );
  };

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => {
          onOpenUsersModal();
          refetchUsers();
        },
      })}

      <Modal
        isOpen={isOpenUsersModal}
        onClose={onCloseUsersModal}
        size='xl'
        scrollBehavior='inside'
        onCloseComplete={() => reset(defaultValues)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='xs'>
              <Heading as='h2' size='lg'>
                Assign New User
              </Heading>
              <Text color='gray.500' fontWeight='normal' fontSize='md'>
                Please select a user from the list below to assign to the warehouse
              </Text>

              {errors?.userId && (
                <Text color='red.500' fontWeight='normal'>
                  {errors?.userId?.message}
                </Text>
              )}
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <form id='addWarehouseUserForm' onSubmit={handleSubmit(onSubmit)}>
              {(!!isLoadingUsers || !!isFetchingUsers) && (
                <VStack align='start' spacing='sm' w='full'>
                  <UserSkeleton />
                  <UserSkeleton />
                  <UserSkeleton />
                </VStack>
              )}

              {!usersListData?.length && !isLoadingUsers && !isFetchingUsers && (
                <Box color='gray.500'>There are no users available!</Box>
              )}

              {!!usersListData?.length && !isLoadingUsers && !isFetchingUsers && (
                <Controller
                  control={control}
                  rules={{ required: 'Please select one user.' }}
                  name='userId'
                  render={({ field: { onChange, onBlur, value } }) => (
                    <RadioGroup onChange={onChange} onBlur={onBlur} value={value} colorScheme='red'>
                      <VStack align='start' spacing='sm' w='full'>
                        {usersListData?.map((user: any) => (
                          <Radio key={user?.id} w='full' value={user?.id?.toString()} size='lg'>
                            <HStack align='center' spacing='md'>
                              <UserIcon />

                              <VStack align='start' flex={1} spacing='2xs'>
                                <Text fontWeight='bold' fontSize='md'>
                                  {user?.name}
                                </Text>
                                <Text fontSize='sm' color='gray.500'>
                                  {user?.roles?.[0]?.name}
                                </Text>
                              </VStack>
                            </HStack>
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                  )}
                />
              )}
            </form>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' variant='ghost' me='sm' onClick={onCloseUsersModal}>
              Cancel
            </Button>

            <Button
              colorScheme='red'
              variant='solid'
              disabled={isLoadingUsers || isFetchingUsers || !usersListData?.length}
              isLoading={isAssignWarehouseUserLoading}
              type='submit'
              form='addWarehouseUserForm'>
              Assign User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenAlertModal} onClose={onCloseAlertModal} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Warning</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text color='gray.800' fontWeight='500' fontSize='md'>
              Are you sure you want to re-assign the user from &quot;{branchName}&quot; to &quot;
              {warehouseData?.name?.en}
              &quot;?
            </Text>

            <Text mt='sm' color='gray.500'>
              User will not have access to his current branch anymore.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' variant='ghost' me='sm' onClick={onCloseAlertModal}>
              Cancel
            </Button>

            <Button
              colorScheme='red'
              variant='solid'
              type='submit'
              form='addWarehouseUserForm'
              isLoading={isAssignWarehouseUserLoading}>
              Apply Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
