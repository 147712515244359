import { Icon, IconProps } from '@chakra-ui/react';

export const UpArrowIcon = (props: IconProps) => {
  return (
    <Icon width='24px' height='24px' viewBox='0 0 24 24' fill='#fff' {...props}>
      <g clipPath='url(#clip0_4050_684)'>
        <path d='M9.5 16H15.5V10H19.5L12.5 3L5.5 10H9.5V16ZM5.5 18H19.5V20H5.5V18Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_4050_684'>
          <rect width='24' height='24' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </Icon>
  );
};
