import { Button } from '@elkaso-app/web-design';
import { ActionsModal } from './actions-modal';

export interface PendingRequestActionsCellProps {
  actions: any;
}
export const PendingRequestActionsCell = ({ actions }: PendingRequestActionsCellProps) => {
  return (
    <ActionsModal
      actions={actions}
      variant={'pending'}
      trigger={
        <Button color={'#160C45'} borderColor={'#160C45'} variant='outline'>
          Review
        </Button>
      }
    />
  );
};
