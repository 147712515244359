import { createSlice } from '@reduxjs/toolkit';

// Initial State
type SliceState = {
  environment?: string;
};

const initialState: SliceState = {
  environment: process.env.REACT_APP_ENV_VAR,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
});

export default configSlice.reducer;
